/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const UpdateUser = gql`
  mutation UpdateUser($input: UserEditInput!) {
    updateUser(input: $input) {
        id
        role
        username
        phoneNumber
        email
        firstName
        lastName
        assignedTenantRole
        assignedTenantId
        tenantRoles {
          tenant {
            id
            firstName
            lastName
            companyName
          }
          role
        }
    }
  }
`;
