import { getSelectedTenant } from "../service/store";

export function getList(resource, tenantId) {
  return {
    type: 'getList',
    resource: resource,
    payload: {
      pagination: { perPage: 0 },
      sort: { field: 'id', order: 'ASC' },
      filter: { tenantId: tenantId },
    }
  }
}

export function createElement(resource, data, tenantId) {
  const payload = data;
  payload.tenant = {
    id: tenantId
  }

  return {
    type: 'create',
    resource: resource,
    payload: { data: payload }
  }
}

export function deleteElement(resource, data) {
  return {
    type: 'delete',
    resource: resource,
    payload: data,
  }
}

export function updateElement(resource, data, tenantId) {
  const payload = data;
  payload.tenant = {
    id: tenantId
  }

  return {
    type: 'update',
    resource: resource,
    payload: { data: payload }
  }
}

export function getListCurrentTenant(resource) {
  const selectedTenant = getSelectedTenant();
  if(!selectedTenant) {
    throw new Error('Tenant Not Selected')
  }
  return getList(resource, selectedTenant.id)

}

/**
 * Use as follows: useQuery(createElementCurrentTenant('hairdresserSalon', {name: 'salone1'}));
 * @param resource
 * @param data
 */
export function createElementCurrentTenant(resource, data) {
  const selectedTenant = getSelectedTenant();
  if(!selectedTenant) {
    throw new Error('Tenant Not Selected')
  }
  return createElement(resource, data, selectedTenant.id)

}

export function deleteElementCurrentTenant(resource, data) {
  return deleteElement(resource, data)

}

export function updateElementCurrentTenant(resource, data) {
  const selectedTenant = getSelectedTenant();
  if(!selectedTenant) {
    throw new Error('Tenant Not Selected')
  }
  return updateElement(resource, data, selectedTenant.id)
}

export function getClientName(client) {
  let name = '';
  if(!client) {
    return ''
  }
  if (client.firstName) {
    name = (client.firstName || '') + ' '
  }
  if (client.lastName) {
    name += (client.lastName || '')
  }
  if (name.trim() !== '') {
    return name
  }
  return client.companyName || ''
}

export function getClientAddress(client) {
  let address = '';
  if (!client) {
    return ''
  }
  address = (client.address || '').trim()
  const city = (client.city || '').trim()
  if (address !== '' && city !== '') {
    address += ', '
  }
  address += city;
  return address;

}
