/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const CreateArticle = gql`
  mutation CreateArticle($input: ArticleCreateInput!) {
    createArticle(input: $input) {
      id
      barCode
      type
      group {
        id
        name
      }
      order
      pdv {
        id
        name
      }
      
    }
  }
`;
