import { client } from '../../graphql/client';
import { CreateAppointment } from "../../graphql/generated-queries/create-appointment-queries"
import { getSelectedTenant } from "../../service/store";

export async function createAppointment(input) {
  try {
    const tenant = getSelectedTenant();
    const tenantId = tenant ? tenant.id : null;
    if (tenantId) {
      input.data.tenant = {
        id: tenantId
      }
    }

    const { data } = await client.mutate({ mutation: CreateAppointment, variables: { input: {...input.data} } });
    return { data: {...data.createAppointment} };
  } catch (err) {
    console.error(err);
  }
}
