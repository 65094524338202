/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const SearchAppointments = gql`
  query SearchAppointments($order: [AppointmentSearchOrderInput!], $take: Int, $skip: Int, $search: AppointmentSearchInput) {
    searchAppointments(order: $order, take: $take, skip: $skip, search: $search) {
      items {
        id
        annotation
        start
        end
        sendReminder
        warnOfFreeAppointment
        repeatEndDate
        repeat
        status
        tenant {
          id
          companyName
        }
        client {
          id
          firstName
          lastName
          mobilePhone
        }
        employee {
          id
          firstName
          lastName
          phoneNumber
          hairdresserSalon {
            id
            name
          }
        }
        service {
          id
          name
          price
        }
      }
    }
  }
`;
