export enum AppointmentType {
  EXPECTED = 'Expected',
  EXECUTED = 'Executed',
  CANCELED = 'Canceled',
  NOT_ARRIVED = 'Not arrived',
}

export enum AppointmentTypeKeys {
  // EXPECTED = 'EXPECTED',
  EXECUTED = 'EXECUTED',
  CANCELED = 'CANCELED',
  NOT_ARRIVED = 'NOT_ARRIVED',
}
