/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const CreateClient = gql`
  mutation CreateClient($input: ClientCreateInput!) {
    createClient(input: $input) {
      id
      personType
      fiscalCode
      vatNumber
      firstName
      lastName
      companyName
      address
      zip
      city
      province
      state
      invoiceVia
      sdi
      pec
      lotteryId
      birthday
      note
      mobilePhone
      email
      discount
      dontSendNotifications
      dontSendBirthdayNotification
      privacyDate
      gdpr
      tenant {
          id
          companyName
      }
      createdAt
      updatedAt
    }
  }
`;
