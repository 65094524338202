import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  SelectInput,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  CheckboxGroupInput,
  FormDataConsumer
} from "react-admin";
import { useMediaQuery, Theme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { css } from "emotion";
import { useTranslate } from "ra-core";
import { useForm } from "react-final-form";
import { DEVICE_STATUS } from "../constants";
import SimpleList from "./components/SimpleList";
import {
  validateRTCode,
  validateSerialNumber,
  validateDeviceStatus,
  validatePrintFooter,
  validatePrintHeader, PRINT_HEADER_LINE_MAX_LENGTH
} from "../service/validator";
import {
  SaveCancelDeleteToolbar,
  SaveCancelToolbar
} from "./components/Toolbars";
import {ListActions} from "./components/ActionBar";
import {EmptyItemView} from "./components/EmptyItemView";
import {useEffectAsync} from "../use-effect-async";
import {getSelectedTenant} from "../service/store";
import {getTenant} from "../queries";
import {UserGlobalNotification} from "./components/UserGlobalNotification";

const mobileFormTitleStyle = css`
  position: fixed;
  background-color: white;
  color: black;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;
const mobileCloseStyle = css`
  position: absolute;
  right: 20px;
`;
const headerInputStyle = css`
  textarea {
    text-align: center;
  }
`;
const footerInputStyle = css`
  textarea {
    text-align: center;
  }
  margin-bottom: 100px !important;
`;

export const DevicesList = props => {
  window.hasSuperiusAdminChanges = false;

  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  if (isXSmall) {
    return (
      <>
        <UserGlobalNotification/>
        <List
          {...props}
          bulkActions={false}
          exporter={false}
          empty={
            <EmptyItemView
              {...props}
              title={translate("sps.labels.emptyDevices")}
            />
          }
        >
          <SimpleList
            linkType={"edit"}
            primaryText={record => record.friendlyName}
            secondaryText={record => record.rtCode}
            tertiaryText={record => record.status}
          />
        </List>
      </>
    );
  }

  return (
    <>
      <UserGlobalNotification/>
      <List
        {...props}
        bulkActionButtons={false}
        perPage={25}
        actions={
          localStorage.getItem("userrole") === "ADMIN" && (
            <ListActions {...props} />
          )
        }
        empty={
          <EmptyItemView
            {...props}
            title={translate("sps.labels.emptyDevices")}
          />
        }
      >
        <Datagrid rowClick="edit">
          <TextField source="rtCode" />
          <TextField source="serialNumber" />
          <TextField source="friendlyName" />
          <TextField source="status" />
          <TextField source="address" />
          <TextField source="city" />
          <EditButton basePath="/device" />
        </Datagrid>
      </List>
    </>
  );
};

const DeviceTitle = ({ record }) => {
  return <span>Device {record ? `"${record.friendlyName}"` : ""}</span>;
};
const DeviceMobileTitle = ({ record, title }) => {
  return (
    <div className={mobileFormTitleStyle}>
      {record && record.friendlyName ? record.friendlyName : title}
      <CloseIcon
        className={mobileCloseStyle}
        onClick={() => (window.location.href = "/device")}
      />
    </div>
  );
};

const splitStringByMaxLength = (original: string, maxLength: number) => {
  const result: string[] = [];
  if(!original) {
    return result
  }
  let tempString = original
  do {
    if(tempString.length < maxLength) {
      result.push(tempString)
      break
    }

    const stringParts = tempString.split(' ')
    let i = 0
    let firstPart = stringParts[i]
    for(i = 1; i < stringParts.length; i+=1) {
      if((firstPart + ' ' + stringParts[i]).length >= maxLength) {
        result.push(firstPart)
        firstPart = stringParts[i]
      } else {
        firstPart += (' ' + stringParts[i])
      }
    }
    result.push(firstPart)
    tempString = stringParts.splice(i).join(' ')
  } while(tempString.length >= maxLength)

  return result
}

const CustomDeviceHeader = ({data, tenantData, setTenantData}: any) => {
  const [deafultHeader, setDefaultHeader] = useState("");
  const translate = useTranslate();
  const form = useForm();
  const {formData, record} = data;
  useEffectAsync(async () => {
    const tenant = getSelectedTenant();
    try {
      const tenantData = await getTenant({...tenant});
      if (tenantData && tenantData.data) {
        const {
          reaCode,
          vatNumber,
          zip,
          city,
          province,
          firstName,
          lastName,
          companyName,
          address
        } = tenantData.data;
        setTenantData(tenantData.data);

        const headers: any = [];

        if (companyName) {
          const processedCompanyName = splitStringByMaxLength(companyName, PRINT_HEADER_LINE_MAX_LENGTH)
          headers.push(...processedCompanyName)
        }
        if (vatNumber) {
          headers.push('Partita IVA ' + vatNumber)
        }
        let name = ''
        if (firstName) {
          name = firstName + ' ';
        }
        if (lastName) {
          name += (lastName);
        }
        name = name.trim();
        if (name && name !== '') {
          headers.push(name)
        }
        if (address && address.trim() !== '') {
          headers.push(address)
        }
        let fullAddress = '';
        if (city) {
          fullAddress += (city + ' ')
        }
        if (zip) {
          fullAddress += (zip + ' ')
        }
        if (province) {
          fullAddress += (province + ' ')
        }
        fullAddress = fullAddress.trim();
        if (fullAddress && fullAddress.trim() !== '') {
          const processedAddress = splitStringByMaxLength(fullAddress, PRINT_HEADER_LINE_MAX_LENGTH)
          headers.push(...processedAddress)
        }
        if (reaCode) {
          headers.push(reaCode)
        }

        const defHeader = headers.join('\n')
        if (record.customHeader) {
          form.change("cheader", ["customHeader"]);
        } else if (!deafultHeader) {
          form.change("printHeader", defHeader);
        }
        setDefaultHeader(defHeader);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (
      formData.cheader &&
      !formData.cheader.length &&
      formData.printHeader !== deafultHeader
    ) {
      form.change("printHeader", deafultHeader);
    }
  });

  const findHelperText = (tenantData) => {
    if (formData.printHeader) {
      if(tenantData && tenantData.vatNumber) {
        const partivaLine = formData.printHeader
          .split("\n")
          .filter((val: string) => val.includes(tenantData.vatNumber))[0];
        if (!partivaLine) {
          return translate("sps.labels.partitaIvaRequired");
        } else {
          return "";
        }
      }
    } else {
      return translate("sps.labels.requiredField");
    }
  };
  const helperText = findHelperText(tenantData);

  return (
    <>
      <CheckboxGroupInput
        source="cheader"
        label=""
        choices={[
          {
            id: "customHeader",
            name: `${translate("sps.labels.useCustomHeader")}`
          }
        ]}
      />
      <TextInput
        className={headerInputStyle}
        multiline
        rows={10}
        disabled={
          !formData.cheader || (formData.cheader && !formData.cheader.length)
        }
        source="printHeader"
        label="sps.labels.header"
        inputProps={{
          wrap: "hard",
          // cols: 29,
          style: { width: "auto" }
        }}
        InputProps={{
          style: { justifyContent: "center" }
        }}
        variant={'outlined'}
        validate={validatePrintHeader}
        helperText={helperText}
        error={!!helperText}
      />
      <TextInput
        className={footerInputStyle}
        multiline
        rows={5}
        validate={validatePrintFooter}
        variant={'outlined'}
        source="printFooter"
        label="sps.labels.footer"
        inputProps={{
          wrap: "hard",
          // cols: 29,
          style: { width: "auto" }
        }}
        InputProps={{
          style: { justifyContent: "center" }
        }}
      />
    </>
  );
};

const validateDeviceForm = (values, translate, tenantData) => {
  let errors: any = {};
  if (values.printHeader) {
    if (tenantData && tenantData.vatNumber) {
      const partivaLine = values.printHeader
        .split("\n")
        .filter((val: string) => val.includes(tenantData.vatNumber))[0];
      if (!partivaLine) {
        errors.printHeader = [translate("sps.labels.partitaIvaRequired")];
      }
    }
  } else {
    errors.printHeader = [translate("sps.labels.requiredField")];
  }

  return errors
};

export const DevicesEdit = props => {
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("xs");
  });
  const [tenantData, setTenantData] = useState(null)
  const translate = useTranslate();

  window.hasSuperiusAdminChanges = true;
  return (
    <Edit
      title={
        isXSmall ? <DeviceMobileTitle {...props} /> : <DeviceTitle {...props} />
      }
      {...props}
      undoable={false}
    >
      <SimpleForm
        redirect="list"
        variant="outlined"
        toolbar={<SaveCancelToolbar/>}
        validate={values => validateDeviceForm(values, translate, tenantData)}
      >
        <TextInput disabled source="id" />
        <TextInput source="rtCode" disabled />
        <TextInput source="serialNumber" disabled />
        <TextInput source="friendlyName" autoFocus={true} />
        <SelectInput source="status" choices={DEVICE_STATUS} disabled />
        <FormDataConsumer>
          {formData => <CustomDeviceHeader data={formData} setTenantData={setTenantData} tenantData={tenantData}/>}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export const DeviceCreate = props => {
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("xs");
  });
  const [tenantData, setTenantData] = useState(null)
  const translate = useTranslate()

  window.hasSuperiusAdminChanges = true;
  return (
    <Create
      title={
        isXSmall ? (
          <DeviceMobileTitle title={"Create a Device"} record={null} />
        ) : (
          "Create a Device"
        )
      }
      {...props}
      undoable={false}
    >
      <SimpleForm
        redirect="list"
        variant="outlined"
        toolbar={<SaveCancelDeleteToolbar/>}
        validate={values => validateDeviceForm(values, translate, tenantData)}
      >
        <TextInput
          source="rtCode"
          autoFocus={true}
          validate={validateRTCode}
          format={v => (v ? v.trim() : v)}
        />
        <TextInput
          source="serialNumber"
          validate={validateSerialNumber}
          format={v => (v ? v.trim() : v)}
        />
        <TextInput source="friendlyName" />
        <SelectInput
          source="status"
          choices={DEVICE_STATUS}
          validate={validateDeviceStatus}
        />
        <FormDataConsumer>
          {formData => <CustomDeviceHeader data={formData} setTenantData={setTenantData} tenantData={tenantData}/>}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
