/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const Employee = gql`
  query Employee($id: EntityId!) {
    employee(id: $id) {
      id
      firstName
      lastName
      phoneNumber
      tenant {
        id
        vatNumber
        companyName
      }
      hairdresserSalon {
        id
        name
        order
      }
      hasAppointments
    }
  }
`;
