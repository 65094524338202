import { client } from '../../graphql/client';
import { SearchArticles, SearchArticlesList } from '../../graphql/generated-queries/articles-queries';
import {getSelectedTenant} from "../../service/store";

export async function getArticles(input, isList = false) {

  if (input.sort.field === 'group.name') {
    input.sort.field = 'group'
  }
  const variables = {
    ...input.filter,
    perPage: input.pagination.perPage,
    skip: input.pagination.page === 0 ? 0 : (input.pagination.page - 1) * input.pagination.perPage,
    order: [
      {[input.sort.field]: input.sort.order}
    ]
  }

  const tenant = getSelectedTenant();
  const tenantId = tenant ? tenant.tenantId : null;

  let groupId = null;
  if(input.filter && input.filter.group && input.filter.group.id) {
    groupId = input.filter.group.id
  }

  let type = null;
  if(input.filter && input.filter.type) {
    type = input.filter.type
  }

  let price:any = undefined
  let name:any = null
  if(input.filter && input.filter.name) {
    price = !isNaN(input.filter.name) ? parseFloat(input.filter.name) : parseFloat('')
    name = !isNaN(input.filter.name) ? '' : input.filter.name
  }

  try {
    const { data } = await client.query({ query: isList ? SearchArticlesList : SearchArticles, variables: {...variables, tenantId, groupId, price, name, type } } );
    return { data: data.searchArticles.items, total: data.searchArticles.total };
  } catch (err) {
    console.error(err);
  }
}
