//
import { client } from '../../graphql/client';
import { DeleteArticles } from '../../graphql/generated-queries/delete-articles-queries';

export async function removeArticle(input) {
  try {
    return await client.mutate({ mutation: DeleteArticles, variables: { ids: [input.id] } });
  } catch (err) {
    console.error(err);
  }
}
