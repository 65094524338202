import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  ReferenceInput,
  NumberField,
  NumberInput,
  FormDataConsumer,
  downloadCSV
} from "react-admin";
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { css, cx, injectGlobal } from "emotion";
import { getSelectedTenant } from "../service/store";
import { getTenantName } from "../service/tenant-service";
import {
  validateArticleName,
  validateArticlePrice,
  validateArticleGroup,
  validateArticleType,
  validateArticleVAT,
  validateDiscountAmount, validateDiscountPrice
} from "../service/validator";
import { ARTICLE_TYPES } from "../constants";
import {
  useMediaQuery,
  Theme,
  InputAdornment,
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SimpleList from "./components/SimpleList";
import { formatDecimals, parseDecimals } from "../service/formFormatter";
import Button from "@material-ui/core/Button";
import { getPdvs } from "../queries";
import {
  SaveCancelDeleteToolbar,
  SaveCancelToolbar,
  SaveDeleteToolbar,
  SaveToolbar
} from "./components/Toolbars";
import { useForm } from "react-final-form";
import { ListActions } from "./components/ActionBar";
import { useTranslate } from "ra-core";
import { useEffectAsync } from "../use-effect-async";
import {EmptyItemView} from "./components/EmptyItemView";
import {UserGlobalNotification} from "./components/UserGlobalNotification";

injectGlobal`
#group\\.id-helper-text span {
  display: none;
}

#discountPercentage-helper-text {
  margin-bottom: 15px;
}

#discountAmount-helper-text {
  margin-bottom: 15px;
}
`;
const smallLayoutStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const editContainerStyle = css`
  .simple-form {
    max-width: none !important;
    padding: 0px;
    margin: 0px;
  }
`;
const mobileFormTitleStyle = css`
  position: fixed;
  background-color: white;
  color: black;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;
const mobileCloseStyle = css`
  position: absolute;
  right: 20px;
`;
const formStyle = css`
  .simple-form {
    max-width: 720px !important;
  }
`;

makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "0px !important"
  }
}));

const forbiddenNames = [
  "totale",
  "sconto",
  "importo",
  "contante",
  "total",
  "discount",
  "amount",
  "cash"
];

export const ArticlesList = props => {
  window.hasSuperiusAdminChanges = false;
  let tenant = getSelectedTenant();
  if (!tenant || !tenant.id) {
    window.location.href = "/";
  }
  const translate = useTranslate();
  let tenantName = getTenantName(tenant);

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const articleExporter = articlesList => {
    const articlesForExport = articlesList.map(article => {
      const keys = Object.keys(article);
      const data = {};

      for(let i = 0; i < keys.length; i+=1) {
        const translation = translate('resources.article.fields.' + keys[i]);
        data[translation] = article[keys[i]]
      }
      data['IVA'] = article.pdv.name;
      data['Gruppo'] = article.group.name;
      return data
    });
    const csv = convertToCSV({
      data: articlesForExport,
      fields: ['Nome', 'Prezzo', 'Prezzo promo', 'Sconto', 'Tipo', 'IVA', 'Gruppo']
    });
    downloadCSV(csv, 'articoli'); // download as 'posts.csv` file
  };

  if (isXSmall) {
    return (
      <>
        <UserGlobalNotification/>
        <List
          {...props}
          perPage={10}
          exporter={false}
          sort={{field: "name", order: "ASC"}}
          filters={<DefaultArticleFilter/>}
          empty={
            <EmptyItemView
              {...props}
              title={translate("sps.labels.emptyArticles")}
            />
          }
        >
          <SimpleList
            linkType={"edit"}
            primaryText={record => record.name}
            secondaryText={record => record.group && record.group.name ? record.group.name : ''}
            tertiaryText={record => {
              let hasDiscount = record.actionPrice && record.actionPrice !== 0;
              if (hasDiscount) {
                return (<>
              <span style={{textDecoration: 'line-through', marginRight: '15px'}}>
              €{Number(record.price).toFixed(2)}</span>
                  €{Number(record.actionPrice).toFixed(2)}
                </>)
              }
              return (<> €{Number(record.price).toFixed(2)}</>)
            }}
          />
        </List>
      </>
    );
  }

  return (
    <>
      <UserGlobalNotification/>
      <List
        {...props}
        exporter={articleExporter}
        filters={<DefaultArticleFilter/>}
        sort={{field: "name", order: "ASC"}}
        perPage={25}
        bulkActionButtons={false}
        title={"Articoli: " + tenantName}
        actions={<ListActions {...props} />}
        empty={
          <EmptyItemView
            {...props}
            title={translate("sps.labels.emptyArticles")}
          />
        }
      >
        <Datagrid rowClick="edit">
          <TextField variant="outlined" source="name" label="sps.labels.name" />
          <NumberField
            source="actionPrice"
            variant="outlined"
            label="resources.article.fields.actionPrice"
            options={{ style: "currency", currency: "EUR" }}
          />
          <NumberField
            variant="outlined"
            source="price"
            options={{ style: "currency", currency: "EUR" }}
            label={"resources.article.fields.price"}
          />
          <TextField
            variant="outlined"
            source="group.name"
            label={"sps.labels.group"}
          />
          <TextField variant="outlined" source="type" label="sps.labels.type" />
          <EditButton basePath="/article" />
        </Datagrid>
      </List>
    </>
  );
};

const ArticleTitle = ({ record }) => {
  return <span>Articolo {record ? `"${record.name}"` : ""}</span>;
};

const MobileArticleTitle = ({ record, title }) => {
  return (
    <div className={mobileFormTitleStyle}>
      {record && record.name ? record.name : title}
      <CloseIcon
        className={mobileCloseStyle}
        onClick={() => (window.location.href = "/article")}
      />
    </div>
  );
};

const EditForm = ({ formData, ...rest }) => {
  const [isForbiddenName, setIsForbiddenName] = useState(false);
  const [isMultiuse, setIsMultiuse] = useState(false);
  const [pdvData, setPdvData] = useState(null);
  const form = useForm();
  const translate = useTranslate();
  if (formData.discountPercentage === undefined) {
    form.change("discountAmount", null);
    form.change("discountPercentage", null);
    form.change("actionPrice", null);
  }

  useEffectAsync(async () => {
    const { data } = await getPdvs();
    setPdvData(data);
  }, []);

  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("xs");
  });

  if (!pdvData) {
    return null;
  }

  const { price } = formData;
  return (
    <>
      <TextInput
        variant="outlined"
        source="name"
        label="resources.article.fields.name"
        validate={validateArticleName}
        format={v => (v ? v.trimStart() : v)}
        onChange={e => {
          const newName = e.currentTarget.value;
          if (forbiddenNames.includes(newName.toLowerCase())) {
            setIsForbiddenName(true);
            form.change("name", "");
          }
        }}
      />
      <div className={cx([smallLayoutStyle])}>
        <NumberInput
          style={{marginRight: 10}}
          variant="outlined"
          placeholder="0"
          source="price"
          validate={validateArticlePrice}
          label="resources.article.fields.price"
          onChange={() => {
            if (price) {
              form.change("discountAmount", null);
              form.change("discountPercentage", null);
              form.change("actionPrice", null);
            }
          }}
        />
        <NumberInput
          source="actionPrice"
          placeholder="0"
          variant="outlined"
          label="resources.article.fields.actionPrice"
          format={v => {
            return formatDecimals(v, 2);
          }}
          parse={v => {
            return parseDecimals(v, 2);
          }}
          onChange={e => {
            if (price) {
              form.change(
                "discountAmount",
                price - parseFloat(e.currentTarget.value)
              );
              form.change(
                "discountPercentage",
                ((price - parseFloat(e.currentTarget.value)) * 100) / price
              );
            }
          }}
        />
      </div>
      <div className={cx([smallLayoutStyle])}>
        <NumberInput
          source="discountPercentage"
          label={
            isXSmall
              ? "resources.article.fields.discountPercentageShort"
              : "resources.article.fields.discountPercentage"
          }
          placeholder="0,00"
          variant="outlined"
          initialValue="0,00"
          style={{ marginRight: 10 }}
          validate={validateDiscountAmount}
          format={v => {
            return formatDecimals(v, 2);
          }}
          parse={v => {
            return parseDecimals(v, 2);
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
          onChange={e => {
            if (price) {
              form.change(
                "discountAmount",
                (price *
                  parseFloat(
                    e.currentTarget.value.replace("%", "").trim([",", "."])
                  )) /
                  100
              );
              form.change(
                "actionPrice",
                price -
                  (parseFloat(
                    e.currentTarget.value.replace("%", "").trim([",", "."])
                  ) *
                    price) /
                    100
              );
            }
          }}
        />
        <NumberInput
          source="discountAmount"
          label={"resources.article.fields.discountAmount"}
          placeholder="0,00"
          variant="outlined"
          validate={validateDiscountPrice(price)}
          format={v => {
            return formatDecimals(v, 2);
          }}
          parse={v => {
            return parseDecimals(v, 2);
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>
          }}
          onChange={e => {
            if (price) {
              form.change(
                "discountPercentage",
                (parseFloat(e.currentTarget.value) * 100) / price
              );
              form.change(
                "actionPrice",
                price - parseFloat(e.currentTarget.value)
              );
            }
          }}
        />
      </div>
      <div className={cx([!isXSmall && smallLayoutStyle])}>
        <ReferenceInput
          style={{ marginRight: isXSmall ? 0 : 10, marginTop: -11 }}
          variant="outlined"
          label="Gruppo *"
          source="group.id"
          reference="group"
          link="edit"
          validate={validateArticleGroup}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => {
            const isMultiuse =
              formData.hasOwnProperty("type") && formData.type === "multiuso";
            setIsMultiuse(isMultiuse);
            if (isMultiuse) {
              //@ts-ignore
              const n2VAT = pdvData.find(val => val.name == "N2");
              //@ts-ignore
              form.change("pdv.id", n2VAT ? n2VAT.id : pdvData[0].id);
            }
            return (
              <SelectInput
                variant="outlined"
                source="type"
                choices={ARTICLE_TYPES}
                validate={validateArticleType}
                disabled={rest && rest.record && rest.record.id}
                label={"sps.labels.type"}
              />
            );
          }}
        </FormDataConsumer>
      </div>
      <div className={cx([!isXSmall && smallLayoutStyle])}>
        <ReferenceInput
          style={{ marginRight: isXSmall ? 0 : 10 }}
          disabled={isMultiuse}
          variant="outlined"
          label="IVA *"
          source="pdv.id"
          reference="pdv"
          link="edit"
          validate={validateArticleVAT}
        >
          <SelectInput optionText="internalName" />
        </ReferenceInput>
        <TextInput
          variant="outlined"
          source="barCode"
          label="resources.article.fields.barCode"
        />
      </div>
      <Dialog open={isForbiddenName} onClose={() => setIsForbiddenName(false)}>
        <DialogContent>
          <DialogContentText>
            {translate("sps.labels.articleForbiddenName")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsForbiddenName(false)}
            color="primary"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ArticlesEdit = props => {
  window.hasSuperiusAdminChanges = true;

  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("xs");
  });

  return (
    <div className={cx([isXSmall && editContainerStyle])}>
      <Edit
        className={formStyle}
        title={
          isXSmall ? (
            <MobileArticleTitle {...props} />
          ) : (
            <ArticleTitle {...props} />
          )
        }
        {...props}
        undoable={false}
      >
        <SimpleForm
          redirect="list"
          variant="outlined"
          toolbar={
            isXSmall ? (
              //@ts-ignore
              <SaveDeleteToolbar style={{ position: "relative" }} />
            ) : (
              <SaveCancelDeleteToolbar />
            )
          }
        >
          <FormDataConsumer>
            {formData => {
              return <EditForm {...formData} />;
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </div>
  );
};

export const ArticleCreate = props => {
  window.hasSuperiusAdminChanges = true;

  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("xs");
  });

  return (
    <div className={cx([isXSmall && editContainerStyle])}>
      <Create
        className={formStyle}
        title={
          isXSmall ? (
            <MobileArticleTitle title={"Aggiungi i articoli"} record={null} />
          ) : (
            "Aggiungi i articoli"
          )
        }
        {...props}
        undoable={false}
      >
        <SimpleForm
          redirect="list"
          variant="outlined"
          toolbar={
            isXSmall ? (
              //@ts-ignore
              <SaveToolbar style={{ position: "relative" }} />
            ) : (
              <SaveCancelToolbar />
            )
          }
        >
          <FormDataConsumer>
            {formData => {
              return <EditForm {...formData} />;
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </div>
  );
};

const DefaultArticleFilter = props => (
  <Filter
    className={css`
      padding-top: 10px !important;
    `}
    {...props}
    variant="outlined"
  >
    <TextInput
      label={"sps.labels.search"}
      source="name"
      alwaysOn
      variant="outlined"
    />
    <ReferenceInput
      variant="outlined"
      label="Gruppo"
      source="group.id"
      reference="group"
      perPage={100}
      alwaysOn
      emptyText={"sps.labels.all_groups"}
    >
      <SelectInput optionText="name" resettable />
    </ReferenceInput>
  </Filter>
);
