import React, { useState } from 'react';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Button,
  Select,
  MenuItem, FormControl, FormGroup, Checkbox, InputAdornment
} from '@material-ui/core';
import {Controller, useForm} from 'react-hook-form';
import {css} from 'emotion';
import {useMutation} from 'react-admin';
import {useTranslate} from 'ra-core';
import {controllaCF, controllaPIVA} from 'cfpiva';
import MuiPhoneNumber from 'material-ui-phone-number'

import {COUNTRIES_LIST} from "../../../constants";

import {createElementCurrentTenant} from '../../repository';
import {InvoiceType} from '../../enums/InvoiceType';
import {PersonType} from '../../enums/PersonType';
import {vatNumber as validateVatNumber, fiscalCode as validateFiscalCode} from "../../../service/validator";

const formField = css`
  width: 100%;
  margin-top: 12px !important;
`;
const errorText = css`
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  margin-top: 4px;
  color: red;
`;
const submitButton = css`
  width: 100%;
  background-color: white;
  padding-bottom: 16px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  margin-left: -16px;
  padding-top: 12px;
`;
const radioButtons = css`
  margin-top: 12px;
  margin-bottom: 12px;
`;
const subtitleText = css`
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: #212121;
  margin-top: 24px;
`;

const useClientCreator = (variables, onSuccess) => {
  return useMutation(createElementCurrentTenant('client', variables), {onSuccess});
}

export const CompanyForm = (props: any) => {
  const {handleClose} = props
  const [invoiceType, setInvoiceType] = useState(InvoiceType.SDI);
  const [dontSendNotifications, setDontSendNotifications] = useState(false);
  const [dontSendBirthdayNotification, setDontSendBirthdayNotification] = useState(false);
  const { register, watch, errors, setError, clearError, getValues, control, formState } = useForm();
  const translate = useTranslate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createClient, { loading: clientLoading, error: clientError, loaded }] = useClientCreator({
    firstName: watch("firstName"),
    lastName: watch("lastName"),
    mobilePhone: watch("mobilePhone"),
    email: watch('email'),
    birthday: watch('birthday'),
    note: watch("note"),
    companyName: watch("companyName"),
    fiscalCode: watch('fiscalCode'),
    vatNumber: watch("vatNumber"),
    address: watch('address'),
    zip: watch('postalCode'),
    city: watch('city'),
    province: watch('province'),
    state: watch("country"),
    dontSendNotifications: dontSendNotifications,
    dontSendBirthdayNotification: dontSendBirthdayNotification,
    invoiceVia: invoiceType,
    personType: PersonType.LEGAL,
  }, (data) => {
    if(handleClose) {
      handleClose(data.data)
    }else {
      window.location.reload()
    }
  });

  const handleInvoiceTypeChange = event => {
    setInvoiceType(event.target.value);
  };

  const onSubmit = (values) => {
    console.log(values)
    createClient();
  }

  const values = getValues()

  return (
    <form
      style={{ marginBottom: 130, maxWidth: 600 }}
      onSubmit={(event) => {
        let errors:string[] = []
        if (!values['companyName']) {
          setError('companyName', {required: translate('sps.labels.requiredField'), message: translate('sps.labels.requiredField')})
          errors.push('companyName')
        }
        if (!values['vatNumber']) {
          setError('vatNumber', {required: translate('sps.labels.requiredField'), message: translate('sps.labels.requiredField')})
          errors.push('vatNumber')
        } else {
          const err = validateVatNumber()(values['vatNumber'])
          if (err && err.message) {
            setError('vatNumber', {required: translate(err.message), message: translate(err.message)})
            errors.push('vatNumber')
          }
        }
        if(values['fiscalCode']) {
            const err = validateFiscalCode()(values['fiscalCode'])
            if (err && err.message) {
              setError('fiscalCode', {required: translate(err.message), message: translate(err.message)})
              errors.push('fiscalCode')
            }
        }
        event.preventDefault()
        if (!errors.length) {
          onSubmit(values)
          handleClose()
        }
      }}
      noValidate
    >
          <TextField
            name="companyName"
            label={translate('sps.labels.company_name')}
            placeholder={translate('sps.labels.company_name')}
            inputRef={register()}
            variant="outlined"
            className={formField}
          />
          {errors.companyName && <div className={errorText}>{translate('sps.labels.requiredField')}</div>}
          <TextField
            name="firstName"
            label={translate('sps.labels.first_name')}
            placeholder={translate('sps.labels.first_name')}
            inputRef={register({ required: true })}
            variant="outlined"
            className={formField}
          />
          {errors.firstName && <div className={errorText}>This field is required</div>}
          <TextField
            name="lastName"
            label={translate('sps.labels.last_name')}
            placeholder={translate('sps.labels.last_name')}
            inputRef={register({ required: true })}
            variant="outlined"
            className={formField}
          />
          {errors.lastName && <div className={errorText}>This field is required</div>}
          <Controller
            as={
              <MuiPhoneNumber
                defaultCountry={'it'}
                preferredCountries={'it'}
                disableAreaCodes={true}
                label={translate('ra.auth.phone_number')}
                variant={'outlined'}
                isValid={(value) => {
                  if(!formState.touched.mobilePhone) {
                    return true
                  }
                  if(!value || value.length < 7) {
                    setError("mobilePhone", {
                      minLength: translate('sps.validation.must_be_7_chars_long')
                    })
                    return false
                  }
                  if(errors.mobilePhone) {
                    clearError("mobilePhone")
                  }
                  return true
                }}
                style={{
                  marginTop: '8px',
                  width: '100%'
                }}
              />
            }
            control={control}
            // rules={{ required: true }}
            onChange={([selected]) => {
              return selected;
            }}
            name="mobilePhone"
          />
          <TextField
            name="email"
            label={translate('sps.labels.email')}
            placeholder={translate('sps.labels.email')}
            inputRef={register({
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address"
              }
            })}
            variant="outlined"
            type="email"
            className={formField}
          />
          {errors.email && <div className={errorText}>{(errors.email as any).message}</div>}
          <TextField
            name="fiscalCode"
            label={translate('sps.labels.fiscal_code')}
            placeholder={translate('sps.labels.fiscal_code')}
            inputRef={register({
              validate: value => {
                if (!value || value.trim() === '') {
                  return true
                }
                const cfValid = controllaCF(value || "");
                const vatValid = !isNaN(value) && (value && value.length === 11)
                return !!(!value || vatValid || cfValid);

              }
            })}
            variant="outlined"
            className={formField}
          />
          {errors.fiscalCode && <div className={errorText}>{translate((errors.fiscalCode as any).types.message)}</div>}
          <TextField
            name="vatNumber"
            label={translate('sps.labels.vat_number')}
            placeholder={translate('sps.labels.vat_number')}
            type="number"
            inputRef={register({
              validate: value => {
                if (!value || value.trim() === '') {
                  return true
                }
                return controllaPIVA(value)
              }
            })}
            variant="outlined"
            className={formField}
          />
          {errors.vatNumber && <div className={errorText}>{translate((errors.vatNumber as any).types.message)}</div>}
          <TextField
            name="address"
            label={translate('sps.labels.address')}
            placeholder={translate('sps.labels.address')}
            inputRef={register()}
            variant="outlined"
            className={formField}
          />
          <TextField
            name="postalCode"
            label={translate('sps.labels.postal_code')}
            placeholder={translate('sps.labels.postal_code')}
            inputRef={register({ minLength: 5, maxLength: 5 })}
            variant="outlined"
            className={formField}
          />
          {errors.postalCode && <div className={errorText}>Must contain 5 characters</div>}
          <TextField
            name="city"
            label={translate('sps.labels.city')}
            placeholder={translate('sps.labels.city')}
            inputRef={register()}
            variant="outlined"
            className={formField}
          />
          <TextField
            name="province"
            label={translate('sps.labels.province')}
            placeholder={translate('sps.labels.province')}
            inputRef={register()}
            variant="outlined"
            className={formField}
          />
          <Controller
            as={
              <Select
                name="country"
                variant={'outlined'}
                placeholder={translate('sps.labels.country')}
                className={formField}
                defaultValue={'Italy'}
              >
                {COUNTRIES_LIST.map(c => {
                  return (<MenuItem value={c.id}>{c.name}</MenuItem>)
                })}
              </Select>
            }
            control={control}
            rules={{ required: true }}
            onChange={([selected]) => {
              return selected.target.value;
            }}
            name="country"
            defaultValue={'Italy'}
          />
          {errors.country && <div className={errorText}>This field is required</div>}
          <TextField
            name="discount"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            label={translate('sps.labels.discount')}
            placeholder={translate('sps.labels.discount')}
            inputRef={register()}
            variant="outlined"
            className={formField}
            type={'number'}
          />
          <div className={subtitleText}>Invoice Via</div>
          <div className={radioButtons}>
            <RadioGroup value={invoiceType} onChange={handleInvoiceTypeChange} row>
              <FormControlLabel
                value={InvoiceType.SDI}
                control={<Radio color="primary" />}
                label="SDI"
              />
              <FormControlLabel
                value={InvoiceType.PEC}
                control={<Radio color="primary" />}
                label="PEC"
              />
            </RadioGroup>
          </div>
          <TextField
            name="insert"
            label={translate('sps.labels.insert')}
            placeholder={translate('sps.labels.insert')}
            inputRef={register()}
            variant="outlined"
            className={formField}
          />
          <TextField
            name="birthday"
            label={translate('sps.labels.birthday')}
            inputRef={register()}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            className={formField}
          />
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={dontSendNotifications} onChange={(e) => {
              setDontSendNotifications(e.target.checked)
            }} name="dontSendNotifications" />}
            label={translate("sps.labels.dontSendNotifications")}
          />
          {!dontSendNotifications && (<FormControlLabel
            control={<Checkbox checked={dontSendBirthdayNotification} onChange={(e) => {
              setDontSendBirthdayNotification(e.target.checked)
            }} name="dontSendBirthdayNotification" />}
            label={translate("sps.labels.dontSendBirthdayNotification")}
          />)}
        </FormGroup>
      </FormControl>
      <TextField
        multiline={true}
        rows={3}
        name="note"
        label={translate('sps.labels.note')}
        placeholder={translate('sps.labels.note')}
        inputRef={register()}
        variant="outlined"
        className={formField}
      />
      <div className={submitButton}>
        <Button type="submit" variant="contained" className={'save-bottom-fixed'}
                color="primary">{translate('sps.labels.save')}</Button>
      </div>
    </form>
  );
}
