import { client } from '../../graphql/client';
import {ForgotPassword} from "../../graphql/generated-queries/forgot-password-queries";

export async function forgotPassword(input) {
  try {

    const { data } = await client.mutate({ mutation: ForgotPassword, variables: { username: input.username } });
    return { data: {...data.forgotPassword} };
  } catch (err) {
    console.error(err);
  }
}
