/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const UpdateHairdresserSalon = gql`
  mutation UpdateHairdresserSalon($input: HairdresserSalonEditInput!) {
    updateHairdresserSalon(input: $input) {
      id
      name
      order
      address
      city
      postalCode
      province
      warnIfOverlapping
      remindOffer
      phoneNumber
      signOnMessage
      activateAppointmentReminder
      personalizedMessage
      birthdayMessages
      schedule
      tenant {
        id
        vatNumber
        companyName
      }
    }
  }
`;
