/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const UpdateClient = gql`
  mutation UpdateClient($input: ClientEditInput!) {
    updateClient(input: $input) {
      id
      personType
      fiscalCode
      vatNumber
      firstName
      lastName
      companyName
      address
      zip
      city
      province
      state
      invoiceVia
      sdi
      pec
      lotteryId
      birthday
      note
      mobilePhone
      email
      privacyDate
      discount
      dontSendNotifications
      dontSendBirthdayNotification
      tenant {
          id
          companyName
      }
      createdAt
      updatedAt
    }
  }
`;
