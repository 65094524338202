/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const SearchMessages = gql`
  query SearchMessages($order: [MessageSearchOrderInput!], $take: Int, $skip: Int, $search: MessageSearchInput) {
    searchMessages(order: $order, take: $take, skip: $skip, search: $search) {
      hasMore
      total
      items {
        id
        title
        scheduledDate
        message
        annotation
        sentDate
        adminOnlyVisible
        recipient
        sendInApp
        sendViaEMail
        sendViaSMS
      }
    }
  }
`;
