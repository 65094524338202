import { client as graphqlClient} from '../../graphql/client';
import { UpdateAppointment } from '../../graphql/generated-queries/update-appointment-queries';

export async function updateAppointment({ data }) {
  const {
    id,
    annotation,
    start,
    end,
    sendReminder,
    warnOfFreeAppointment,
    repeatEndDate,
    service,
    status,
    tenant,
    client,
    employee,
    repeat,
    changeRepeatAppointment,
  } = data;

  const tenantId = tenant ? tenant.id : null;
  const clientId = client ? client.id : null;
  const employeeId = employee ? employee.id : null;
  const serviceId = service ? service.id : null;

  const result = await graphqlClient.mutate({
    mutation: UpdateAppointment,
    variables: {input: {
        id,
        annotation,
        start,
        end,
        sendReminder,
        warnOfFreeAppointment,
        repeatEndDate,
        repeat,
        status,
        changeRepeatAppointment,
        service: {id: serviceId },
        tenant: {id: tenantId},
        client: {id: clientId},
        employee: {id: employeeId}
      }}
  });
  return { data: result.data.updateAppointment };
}
