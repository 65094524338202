// @ts-nocheck
import React, {useState} from 'react';
import { Field, Form } from 'react-final-form';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslate, useNotify, useSafeSetState } from 'ra-core';
import {forgotPassword, resetPassword} from "../../queries";
import {PASSWORD_MINIMUM_LENGTH, PASSWORD_VALIDATION_REGEX} from "../../service/validator";


const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
);

const Input = ({
                 meta: { touched, error }, // eslint-disable-line react/prop-types
                 input: inputProps, // eslint-disable-line react/prop-types
                 ...props
               }) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const FORM_STATE_USERNAME = 'username'
const FORM_STATE_RESET_PASSWORD = 'resetPassword'

const ForgotPasswordForm = ({ redirectTo }) => {
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles({});
  const [currentFormState, setCurrentFormState ] = useState(FORM_STATE_USERNAME)
  const validate = (values: FormData) => {
    const errors = { username: undefined };

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }

    if (currentFormState === FORM_STATE_RESET_PASSWORD) {
      if (!values.resetCode) {
        errors.resetCode = translate('ra.validation.required');
      }
      if (!values.password) {
        errors.password = translate('ra.validation.required');
      } else if (values.password.length < PASSWORD_MINIMUM_LENGTH) {
        errors.password = translate('ra.validation.minLength').replace('%{min}', PASSWORD_MINIMUM_LENGTH);
      } else if (!PASSWORD_VALIDATION_REGEX.test(values.password)) {
        errors.password = translate('Must be alphanumeric or one of following: . _ * ! &');
      }
    }
    return errors;
  };

  const submit = async (values) => {
    setLoading(true);

    if(currentFormState === FORM_STATE_USERNAME) {
      const res = await forgotPassword(values);
      setLoading(false);
      if (res.data && res.data.status === false) {
        notify('Unable to find phone number')
        return
      }
      return setCurrentFormState(FORM_STATE_RESET_PASSWORD)
    }
    if(currentFormState === FORM_STATE_RESET_PASSWORD) {
      const res = await resetPassword({
        username: values.username,
        resetCode: values.resetCode,
        password: values.password,
      })
      setLoading(false);
      if (res.data && res.data.status === false) {
        notify(res.data.message)
        return
      }
      window.location = '/'

    }

  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            {currentFormState === FORM_STATE_USERNAME && <div className={classes.input}>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </div>}
            {currentFormState === FORM_STATE_RESET_PASSWORD && <div className={classes.input}>
                <Field
                    autoFocus
                    id="resetCode"
                    name="resetCode"
                    component={Input}
                    label={translate('ra.auth.reset_code')}
                    disabled={loading}
                />
            </div>}
            {currentFormState === FORM_STATE_RESET_PASSWORD && <div className={classes.input}>
                <Field
                    id="password"
                    name="password"
                    type="password"
                    component={Input}
                    label={translate('ra.auth.password')}
                    disabled={loading}
                />
            </div>}
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              {currentFormState === FORM_STATE_USERNAME && translate('ra.auth.send_recovery')}
              {currentFormState === FORM_STATE_RESET_PASSWORD && translate('ra.auth.reset_password')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};


export default ForgotPasswordForm;
