import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-admin'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import Modal from '@material-ui/core/Modal';
import {css, cx, injectGlobal} from 'emotion'
import {useMediaQuery, Theme, MenuItem, Select, Button, Drawer, IconButton, Snackbar} from '@material-ui/core';
import {Fab} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TuneIcon from '@material-ui/icons/Tune';
import TodayIcon from '@material-ui/icons/Today';
import CloseIcon from '@material-ui/icons/Close';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { useTranslate } from 'ra-core'
import {it} from 'date-fns/locale'
import ReplayIcon from '@material-ui/icons/Replay';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './../styles/calendar.css';

import {getClientName, getListCurrentTenant} from "../repository";
import { AppointmentDetailsModal } from './AppointmentDetailsModal';
import {AppointmentForm} from "../pages/AppointmentForm";
import {CalendarDatePicker} from "./CalendarDatePicker";
import {CustomThreeDayView} from "./CustomThreeDayView";
import {
  addDays,
  differenceInCalendarDays,
  eachDayOfInterval,
  startOfWeek,
  subDays,
  format
} from "date-fns";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import {EventPopper} from "./EventPopper";
import {EmptyCalendarView} from "./EmptyCalendarView";
import {EmptyListView} from "./EmptyListView";
import * as style from './CalendarStyles';
import {repeatOptions, repeatOptionsSkip} from "../../constants";
import {getAppointments} from "../../queries";
import {useEffectAsync} from "../../use-effect-async";

injectGlobal`
  .rbc-time-header {
      position: sticky;
      top: 110px;
      background: white;
      z-index: 100;
      padding-top: 5px;
    }
  .rbc-event {
    position: relative !important;
    flex-flow: column !important;
  }
  .rbc-events-container {
    display: grid;
    grid-template-rows: repeat(1464, 1px);
    margin-right: 0px !important;
  }
  .rbc-time-gutter > .rbc-timeslot-group {
    border-bottom: 1px solid white;
  }
  .MuiDialogContentText-root {
    min-width: 0px !important;
  }
  .event-container-employee > .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`

const localizer = momentLocalizer(moment)

const formats = {
  timeGutterFormat: "HH:mm",
  dayFormat: "ddd DD.MM.",
}

export const MyCalendar = ({ emitDay, selectedEmployee, selectedHQ }) => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const urlParams = new URLSearchParams(window.location.search)
  const translate = useTranslate()
  const calendarViews = {
    day: {
      label: translate('sps.labels.day'),
      view: 'day',
      icon: <ViewDayIcon/>,
    },
    day3: {
      label: translate('sps.labels.day3'),
      view: 'month',
      icon: <ViewWeekIcon/>,
    },
    week: {
      label: translate('sps.labels.week'),
      view: 'week',
      icon: <ViewWeekIcon/>,
    },
  }


  const [employee, setEmployee] = selectedEmployee
  const [selectedHeadquarters, setSelectedHeadquarters] = selectedHQ
  const [day, setDay] = useState(new Date());
  // const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [createModalDefaultData, setCreateModalDefaultData] = useState({});
  const [showAllEmployees, setShowAllEmployees] = useState(false);
  // @ts-ignore
  const [currentlyOpen, setCurrentlyOpen] = useState(!window.localStorage.getItem('currently-open-appointment') ? null : getCurrentlyOpen(window.localStorage.getItem('currently-open-appointment')));
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(!!currentlyOpen);
  const [hasMultipleSalons, setHasMultipleSalons] = useState(false);
  const [currentCalendarView, setCurrentCalendarView] = useState(urlParams.has('view') ? urlParams.get('view') || 'week' : 'week');
  const [appointments, setAppointments] = useState([] as any);
  const [calendarText, setCalendarText] = useState('')
  const [hqEmployees, setHqEmployees] = useState<any>(null)
  const [salonsWithEmployees, setSalonsWithEmployees] = useState<any>(null)
  const [schedule, setSchedule] = useState<any>(  null)
  const [currentDisplayMode, setCurrentDisplayMode] = useState('calendar')
  const [reprogramData, setReprogramData] = useState<boolean | null>(null)
  const [reprogramDataWaitingList, setReprogramDataWaitingList] = useState<boolean | null>(null)
  const [isReminderOpen, setIsReminderOpen] = useState(false)
  const [refreshAppointments, setRefreshAppointments] = useState(false)

  const { data: appointmentsData, loading: appointmentsLoading, error: appointmentsError, loaded: appointmentsLoaded } = useQuery(getListCurrentTenant('appointment'));
  const { data: employeeData, loading: employeeLoading, error: employeeError, loaded: employeeLoaded } = useQuery(getListCurrentTenant('employee'));
  const { data: salonData, loading: salonLoading, error: salonError, loaded: salonLoaded } = useQuery(getListCurrentTenant('hairdresserSalon'));

  if(!urlParams.has('view') && currentDisplayMode !== 'calendar') {
    setCurrentCalendarView('week')
    setCurrentDisplayMode('calendar')
  }

  if(!urlParams.has('create') && isCreateModalOpen) {
    setIsCreateModalOpen(false)
  }


  function getCurrentlyOpen(currentlyOpen: string) {
    const data = JSON.parse(currentlyOpen)
    data.start = data.start ? new Date(data.start) : null
    data.end = data.end ? new Date(data.end) : null
    return data;
  }

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function openCreateModal() {
    window.history.pushState({}, '', window.location.pathname + '?create=true');
    setIsCreateModalOpen(true)
  }

  function closeCreateModal() {
    window.history.back()
    setIsCreateModalOpen(false)
  }

  useEffect(() => {
    if (employeeLoaded) {
      if(!employee) {
        setEmployee(employeeData[0]);
      }
      if(selectedHeadquarters && selectedHeadquarters.id) {
        if(selectedHeadquarters.employeeOrder) {
          const filteredEmployees = employeeData.filter(employee => JSON.parse(selectedHeadquarters.employeeOrder).includes(employee.id.toString()))
          const sortedEmployees = JSON.parse(selectedHeadquarters.employeeOrder).map(o => filteredEmployees.find(e => e.id==o)).filter(e => e!=undefined)
          setHqEmployees(sortedEmployees)
        } else {
          setHqEmployees(employeeData.filter(employee => employee.hairdresserSalon.id == selectedHeadquarters.id))
        }
      }
    }
    isSmall ? setCurrentCalendarView('day') : setCurrentCalendarView('week');
  }, [employeeLoaded, employeeData, isSmall, selectedHeadquarters]);

  useEffect(() => {
    if(!appointmentsLoaded) {
      return
    }
    let data = appointmentsData
      .filter(a => a.status !== 'CANCELED')
      .map(a => {
        const start = new Date(a.start);
        const end = new Date(a.end);
        const hasRepeat = a.repeat && a.repeat.toLowerCase() !== 'non si ripete'
        const repeatFrequency = hasRepeat ? repeatOptions[a.repeat] : 0;
        const title = a.client ? getClientName(a.client) : '';
        return { ...a, start, end, title, hasRepeat, repeatFrequency };
      });
    setAppointments(data)
  }, [appointmentsLoading, appointmentsData, appointmentsLoaded]);

  useEffectAsync(async () => {
    if(!appointmentsLoaded) {
      return
    }
    const appointmentsNewData = await getAppointments({
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: {
        perPage: 0,
        page: 0,
      }
    })
    if(!appointmentsNewData) {
      return;
    }
    console.log('Refreshing full data')
    let data = appointmentsNewData.data
      .filter(a => a.status !== 'CANCELED')
      .map(a => {
        const start = new Date(a.start);
        const end = new Date(a.end);
        const hasRepeat = a.repeat && a.repeat.toLowerCase() !== 'non si ripete'
        const repeatFrequency = hasRepeat ? repeatOptions[a.repeat] : 0;
        const title = a.client ? getClientName(a.client) : '';
        return { ...a, start, end, title, hasRepeat, repeatFrequency };
      });
    setAppointments(data)
  }, [refreshAppointments]);


  useEffect(() => {
    if (salonLoaded && !selectedHeadquarters && employeeData) {
      const salonsWithEmployeesId = new Set(employeeData.map(e => e.hairdresserSalon.id))
      const salonsWithEmployeesList = salonData.filter(salon => Array.from(salonsWithEmployeesId).includes(salon.id))
      setSalonsWithEmployees(salonsWithEmployeesList)
      setHasMultipleSalons(salonsWithEmployeesList.length > 1)
      setSelectedHeadquarters(salonsWithEmployeesList[0]);
    }
  }, [salonLoaded, salonData, selectedHQ, employeeData]);
  useEffect(() => {
    if(currentCalendarView.includes('week')) {
      const startDate = moment(day).subtract(day.getDay(), 'days')
      const endDate = moment(day).add(6-day.getDay(), 'days')
      const startText = startDate.date()>endDate.date() ? `${startDate.date()} ${startDate.format('MMMM').substring(0,3)} ` : startDate.date()

      setCalendarText(`${startText}-${endDate.date()} ${endDate.format('MMMM').substring(0,3)} ${day.getFullYear()}`)
    } else if(currentCalendarView=='day') {

      setCalendarText(`${day.getDate()} ${moment(day).format('MMMM').substring(0,3)} ${day.getFullYear()}`)
    } else if(currentCalendarView=='month') {
      const startDate = moment(day).subtract(0, 'days')
      const endDate = moment(day).add(2, 'days')
      const startText = startDate.date()>endDate.date() ? `${startDate.date()} ${startDate.format('MMMM').substring(0,3)} ` : startDate.date()

      setCalendarText(`${startText}-${endDate.date()} ${moment(day).format('MMMM').substring(0,3)} ${day.getFullYear()}`)
    }
  }, [day, currentCalendarView])
  useEffect(() => {
    const storageHeadquarters = localStorage.getItem('selectedHeadquarters')
    const storageEmployee = localStorage.getItem('selectedEmployee')
    const storageDay = null // localStorage.getItem('selectedDay') //TODO: check this if that would work
    const storageView = localStorage.getItem('selectedView')
    const storageMode = localStorage.getItem('selectedMode')
    let stoHeadquarters
    if (storageHeadquarters) {
      stoHeadquarters = JSON.parse(storageHeadquarters) as any
    } else {
      stoHeadquarters = selectedHeadquarters
    }
    if (selectedHeadquarters && employeeData) {
      if (selectedHeadquarters.id != stoHeadquarters.id && salonsWithEmployees && salonsWithEmployees.findIndex(s => s.id == stoHeadquarters.id) != -1) {
        setSelectedHeadquarters({
          ...salonsWithEmployees.find(s => s.id == stoHeadquarters.id),
          employeeOrder: salonsWithEmployees.findIndex(s => s.id == stoHeadquarters.id).employeeOrder
        })
        const employees = employeeData.filter(employee => employee.hairdresserSalon.id == stoHeadquarters.id)
        setHqEmployees(employees)
      }
      if (selectedHeadquarters && selectedHeadquarters.schedule) {
        const mySchedule = JSON.parse(selectedHeadquarters.schedule).schedule
        const realSchedule = mySchedule.map(value => {
          return {
            day: value.day,
            startTime: new Date(value.shifts[0].startTime),
            endTime: new Date(value.shifts[0].endTime),
            startTime2: value.shifts[1] ? new Date(value.shifts[1].startTime) : null,
            endTime2: value.shifts[1] ? new Date(value.shifts[1].endTime) : null,
            enabled: value.shifts[0].enabled,
          }
        });
        setSchedule(realSchedule)
        setTimeout(function () {
          const earliestStart = Object.values(realSchedule).reduce((acc:any, value:any) => {
            if(!acc || new Date(acc).getTime()>new Date(value.startTime).getTime()) {
              return new Date(value.startTime)
            }
            return acc
          }, undefined) as Date
          window.scrollTo(0,70 + new Date(earliestStart).getHours()*40)
          //horizontal scroll
          const todayDate = document.getElementById('today-date');
          const timeContainer = document.getElementsByClassName('rbc-time-content')
          if(todayDate && todayDate.getBoundingClientRect && timeContainer && timeContainer[0]) {
           const xOffset = todayDate.getBoundingClientRect().x
           timeContainer[0].scrollLeft = xOffset - 100;
          }
        }, 100)
      } else {
        setSchedule(null)
        window.scrollTo(0, 390)
      }
    }
    if(storageView) {
      setCurrentCalendarView(storageView)
    }
    if(hqEmployees) {
      if(!storageEmployee) {
        setEmployee(hqEmployees[0])
      } else if(storageEmployee=='none') {
        setShowAllEmployees(true)
        setEmployee('none')
        setCurrentCalendarView('day')
      } else if(isJson(storageEmployee) && hqEmployees.findIndex(h => h.id==(JSON.parse(storageEmployee) as any).id)!=-1) {
        // @ts-ignore
        setEmployee(JSON.parse(storageEmployee) as any)
      } else {
        setEmployee(hqEmployees[0])
      }
    }
    // if(storageDay && !!Date.parse(storageDay)) {
    //   setDay(new Date(storageDay))
    // }
    if(storageMode) {
      setCurrentDisplayMode(storageMode)
      if(storageMode=='list') {
        setCurrentCalendarView('work_week')
      }
    }

  }, [hqEmployees, employeeData, salonsWithEmployees])

  if(appointmentsLoading || appointmentsError || employeeLoading || employeeError || salonLoading || salonError) {
    return null
  }

  if(employeeData.length === 0 || salonData.length === 0) {
    return <EmptyCalendarView salonId={salonData[0] ? salonData[0].id: null}/>
  }


  const emitAndSetDay = (date) => {
    emitDay(date);
    setDay(date);
  }

  const formatTime = (time) => {
    return (time.toString().length === 1 ? `0${time}` : time)
  }

  const SelectCurrentDisplayMode = () => {
    return (
      <div className={cx([style.calendarPickerStyle, style.displayModeSelect])}>
        <Button
          className={style.modeContainer}
          variant={currentDisplayMode === 'calendar' ? 'contained' : 'text'}
          color={currentDisplayMode === 'calendar' ? 'primary' : 'default'}
          onClick = {() => {
            setCurrentDisplayMode('calendar')
            let currentView = localStorage.getItem('selectedView')
            if(!currentView) {
              currentView = 'week'
            }
            setCurrentCalendarView(currentView)
            localStorage.setItem('selectedMode', 'calendar')
            localStorage.setItem('selectedView', currentView)
            if (!employee) {
              setEmployee(hqEmployees[0])
            }
            if (window.location.search && window.location.search.includes('view=list')) {
              window.history.back()
            }
          }}
        >
          <TodayIcon />
        </Button>
        <Button
          className={style.modeContainer}
          variant={currentDisplayMode === 'list' ? 'contained' : 'text'}
          color={currentDisplayMode === 'list' ? 'primary' : 'default'}
          onClick = {() => {
            setReprogramData(null)
            setReprogramDataWaitingList(false)
            setCurrentlyOpen(null)
            setCurrentDisplayMode('list')
            setCurrentCalendarView('work_week')
            window.history.pushState({}, '', window.location.pathname + '?view=list');
          }}
        >
          <ListIcon />
        </Button>
      </div>
    )
  }

  const MyToolbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<null | HTMLButtonElement>(null)
    const [isOptionsDrawerOpen, setIsOptionsDrawerOpen] = useState(false)
    const isXSmall = useMediaQuery((theme: Theme) => {
      return theme.breakpoints.down('xs')
    });
    const translate = useTranslate()

    const goToBack = () => {
      const numOfDays = currentCalendarView.includes('week') ? 7 : 1
      const newDate = moment(day).subtract(numOfDays, 'days').toDate()
      emitAndSetDay(newDate)
      localStorage.setItem('selectedDay', (new Date(newDate)).toString())
    };

    const goToNext = () => {
      const numOfDays = currentCalendarView.includes('week') ? 7 : 1
      const newDate = moment(day).add(numOfDays, 'days').toDate()
      emitAndSetDay(newDate)
      localStorage.setItem('selectedDay', (new Date(newDate)).toString())
    };

    if(isXSmall) {
      return (
        <div className={style.toolbarWrapperSmall} id='toolbar-top-mobile'>
          <div className={style.mobileToolbarContainer}>
            <Button
              onClick={() => {
                const isCalendar = currentDisplayMode === 'calendar'
                setCurrentDisplayMode(isCalendar ? 'list' : "calendar")
                setCurrentCalendarView(isCalendar ? 'work_week' : 'week')
                setReprogramData(null)
                setReprogramDataWaitingList(false)
                setCurrentlyOpen(null)
                window.localStorage.removeItem('currently-open-appointment')
                if (isCalendar) {
                  window.history.pushState({}, '', window.location.pathname + '?view=list');
                } else if (window.location.search && window.location.search.includes('view=list')) {
                  window.history.back()
                }
                if (!employee) {
                  setEmployee(hqEmployees[0])
                }
              }}
              startIcon={currentDisplayMode === 'calendar' ? <ListIcon style={{ fontSize: 28 }} /> : <TodayIcon style={{ fontSize: 28 }}/>}
            />
            <div className={style.selectDateStyle} onClick={(e: any) => {
              setIsMenuOpen((!Boolean(isMenuOpen)) ? e.currentTarget : null)
            }}>
              {format(new Date(day), "eeee, dd MMMM yyyy", {locale: it})}
              <ExpandMoreIcon/>
            </div>
            <CalendarDatePicker currentCalendarView={currentCalendarView} day={day} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} setDate={setDay}/>
            <Button
              onClick={() => {
                setIsOptionsDrawerOpen(true)
              }}
              endIcon={<TuneIcon style={{ fontSize: 27 }}/>}
            />
            <Drawer
              anchor={"right"}
              open={isOptionsDrawerOpen}
              onClose={() => setIsOptionsDrawerOpen(false)}
              className={style.optionsDrawerStyle}
            >
              <div className={style.drawerContainerStyle}>
                <div className={style.closeIconContainer}><CloseIcon onClick={() => setIsOptionsDrawerOpen(false)}/></div>
                <div className={style.viewContainerStyle}>
                  <span className={style.drawerTitleTextStyle}>{translate('sps.labels.calendarView').toUpperCase()}</span>
                  <div className={style.viewOptionsContainerStyle}>
                    {Object.entries(calendarViews).map(([key, value]) => {
                      return (
                        <div
                          className={cx([style.viewOptionStyle, currentCalendarView==value.view && style.selectedViewStyle])}
                          onClick={() => {
                            setCurrentCalendarView(value.view)
                            localStorage.setItem('selectedView', value.view)
                            if(value.view !== 'day') {
                              setEmployee(hqEmployees[0])
                              setShowAllEmployees(false)
                            }
                          }}
                        >
                          {value.icon}
                          {value.label}
                        </div>
                      )
                    })
                    }
                  </div>
                </div>
                <div className={cx([style.viewContainerStyle, style.mobileSalonSelectStyle])}>
                  <span className={style.drawerTitleTextStyle}>{translate('sps.labels.salon').toUpperCase()}</span>
                  {(salonLoaded && salonsWithEmployees) &&
                  <Select
                      style={{marginRight: 20, borderBottom: 'none !important'}}
                      value={selectedHeadquarters ? selectedHeadquarters.id : ''}
                      defaultValue={selectedHeadquarters.id || salonsWithEmployees[0].id}
                      disabled={!hasMultipleSalons}
                      onChange={(event) => {
                        const e = salonsWithEmployees.find(e => e.id === event.target.value)
                        const employees = employeeData.filter(employee => employee.hairdresserSalon.id==e.id)
                        setSelectedHeadquarters(e)
                        setHqEmployees(employees)
                        setEmployee(employees[0])
                        localStorage.setItem('selectedHeadquarters', JSON.stringify(e))
                      }}
                      IconComponent={(props) => <ArrowForwardIosIcon fontSize='small' {...props}/>}
                  >
                    {salonsWithEmployees.map(salon => {
                      return <MenuItem key={salon.id} value={salon.id}>{salon.name}</MenuItem>
                    })}
                  </Select>
                  }
                </div>
                <div className={style.viewContainerStyle}>
                  <span className={style.drawerTitleTextStyle}>{translate('sps.labels.personal').toUpperCase()}</span>
                  <div className={style.personalSelectStyle}>
                    <div
                      className={cx([style.employeeContainerStyle, employee=='none' && style.selectedViewStyle])}
                      onClick={() => {
                        setShowAllEmployees(true)
                        setEmployee('none')
                        setCurrentCalendarView('day')
                        localStorage.setItem('selectedEmployee', 'none')
                      }}
                    >
                      <span className={cx([style.headerTextStyle, style.personalBadgeStyle])}>
                        <PeopleIcon/>
                      </span>
                      <span className={style.employeeNameStyle}>{translate('sps.labels.allPersonal')}</span>
                    </div>
                    {hqEmployees.map(myEmployee => {
                      let redValue, greenValue, blueValue
                      redValue = greenValue = blueValue = 0
                      for(let i=0; i<myEmployee.firstName.length; i++) {
                        redValue += Number(myEmployee.firstName.charCodeAt(i))
                      }
                      for(let i=0; i< (myEmployee.lastName || '').length; i++) {
                        blueValue += Number(myEmployee.lastName.charCodeAt(i))
                      }
                      greenValue = redValue+blueValue
                      const colorString = String(redValue.toString(16)+greenValue.toString(16)+blueValue.toString(16)+'D093C9').slice(0,6)
                      return (
                        <div
                          className={cx([style.employeeContainerStyle, (employee && employee.id && employee.id==myEmployee.id) && style.selectedViewStyle])}
                          onClick={() => {
                            setShowAllEmployees(false)
                            setEmployee(myEmployee)
                            localStorage.setItem('selectedEmployee', JSON.stringify(myEmployee))
                          }}
                        >
                          <span
                            className={
                              cx([
                                style.headerTextStyle,
                                style.personalBadgeStyle,
                                css `background-color: #${colorString};`
                              ])}
                            style={{color: 'white'}}
                          >
                            {myEmployee.firstName[0].toUpperCase()}
                          </span>
                          <span className={style.employeeNameStyle}>
                            {`${myEmployee.firstName} ${myEmployee.lastName || ''}`}
                          </span>
                        </div>)
                      }
                    )}
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
          {reprogramData &&
          <div
              className={cx([style.reprogramHeaderStyle])}
          >
            {translate('sps.labels.selectToReprogram')}
              <IconButton
                  className={style.closeReprogramStyle}
                  onClick={() => {
                    if (reprogramDataWaitingList) {
                      setCurrentlyOpen(null)
                      window.localStorage.removeItem('currently-open-appointment')
                      setCurrentDisplayMode('list')
                      setCurrentCalendarView('work_week')
                      setIsDetailsModalOpen(false)
                    } else {
                      setIsDetailsModalOpen(true)
                    }
                    setReprogramData(null)
                    setReprogramDataWaitingList(false)
                  }}
              >
                  <CloseIcon/>
              </IconButton>
          </div>}
        </div>
      )
    }

    return (
      <>
        <div className={cx([style.toolbarWrapper, reprogramData && style.mobileToolbarWrapper])} id='toolbar-top-web'>
          {(hasMultipleSalons && !reprogramData) && <div className={[style.center, style.hqName].join(' ')}>{selectedHeadquarters.name}</div>}
          {reprogramData &&
          <div
              className={cx([style.reprogramHeaderStyle])}
          >
            {translate('sps.labels.selectToReprogram')}
            <IconButton
              className={style.closeReprogramStyle}
              onClick={() => {
                if (reprogramDataWaitingList) {
                  setCurrentlyOpen(null)
                  window.localStorage.removeItem('currently-open-appointment')
                  setCurrentDisplayMode('list')
                  setCurrentCalendarView('work_week')
                  setIsDetailsModalOpen(false)
                } else {
                  setIsDetailsModalOpen(true)
                }
                setReprogramData(null)
                setReprogramDataWaitingList(false)
              }}
            >
              <CloseIcon/>
            </IconButton>
          </div>}
          <div className={style.toolbarContainer}>
            <div className={style.leftToolbarStyle}>
              {(salonLoaded && salonsWithEmployees) &&
                <Select
                    style={{marginRight: 20}}
                  value={selectedHeadquarters ? selectedHeadquarters.id : ''}
                  defaultValue={selectedHeadquarters.id || salonsWithEmployees[0].id}
                  variant="outlined"
                  disabled={!hasMultipleSalons}
                  onChange={(event) => {
                    const e = salonsWithEmployees.find(e => e.id === event.target.value)
                    const employees = employeeData.filter(employee => employee.hairdresserSalon.id==e.id)
                    setSelectedHeadquarters(e)
                    setHqEmployees(employees)
                    setEmployee(employees[0])
                    localStorage.setItem('selectedHeadquarters', JSON.stringify(e))
                  }}
                >
                  {salonsWithEmployees.map(salon => {
                    return <MenuItem key={salon.id} value={salon.id}>{salon.name}</MenuItem>
                  })}
                </Select>}
              {(employeeLoaded && hqEmployees) &&
              <Select
                  style={{marginRight: 20}}
                  value={employee!='none' ? employee.id : 'All'}
                  defaultValue={employee.id || hqEmployees[0].id}
                  variant="outlined"
                  disabled={!(hqEmployees.length>1)}
                  onChange={(event) => {
                    if(event.target.value=='All'){
                      setShowAllEmployees(true)
                      setEmployee('none')
                      setCurrentCalendarView('day')
                      localStorage.setItem('selectedEmployee', 'none')
                    } else {
                      setShowAllEmployees(false)
                      const e = hqEmployees.find(e => e.id === event.target.value)
                      setEmployee(e)
                      localStorage.setItem('selectedEmployee', JSON.stringify(e))
                    }
                  }}
              >
                <MenuItem key={'all'} value={'All'}>{translate('sps.labels.all')}</MenuItem>
                {hqEmployees.map(employee => {
                  return <MenuItem key={employee.id} value={employee.id}>{`${employee.firstName || ''} ${employee.lastName || ''}`}</MenuItem>
                })}
              </Select>}
              <div className={[style.center, style.arrowBox].join(' ')} onClick={goToBack}>&lt;</div>
              <div className={style.calendarPickerStyle}>
                <div className={style.selectTodayStyle}
                     onClick={()=> {
                       setDay(new Date())
                       localStorage.setItem('selectedDay', (new Date()).toString())
                     }}
                  >
                  {translate('sps.labels.today')}
                </div>
                <div className={style.selectDateStyle} onClick={(e: any) => {
                  setIsMenuOpen((!Boolean(isMenuOpen)) ? e.currentTarget : null)
                }}>
                  {calendarText}
                </div>
                <CalendarDatePicker currentCalendarView={currentCalendarView} day={day} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} setDate={setDay}/>
              </div>
              <div className={[style.center, style.arrowBox].join(' ')} onClick={goToNext}>&gt;</div>
              <SelectCurrentDisplayMode/>
            </div>
            <div className={style.leftToolbarStyle}>
              {currentDisplayMode=='calendar' &&
                <Select
                  style={{marginRight: 20}}
                  value={currentCalendarView ? currentCalendarView : 'day'}
                  defaultValue={'day'}
                  variant="outlined"
                  onChange={(event, value) => {
                    if (value) {
                      //@ts-ignore
                      setCurrentCalendarView(value.props.value)
                      //@ts-ignore
                      localStorage.setItem('selectedView', value.props.value.toString())
                      //@ts-ignore
                      if(employee=='none' && value.props.value!='day') {
                        setEmployee(hqEmployees[0])
                        localStorage.setItem('selectedEmployee', JSON.stringify(hqEmployees[0]))
                        setShowAllEmployees(false)
                      }
                    }
                  }}
                >
                  {Object.entries(calendarViews).map(([key, value]) => {
                    return <MenuItem key={key} value={value.view}>{value.label}</MenuItem>
                  })}
                </Select>}
              <Button
                startIcon={<AddIcon/>}
                variant='contained'
                color='primary'
                className={style.addButtonStyle}
                onClick={() => {
                  setCreateModalDefaultData({
                    day: new Date(),
                    employeeSelected: null
                  })
                  if(reprogramData) {
                    setCurrentlyOpen(
                      {...currentlyOpen,
                        start: new Date(new Date().toDateString()+' '+new Date(currentlyOpen.start).toTimeString()),
                        end: new Date(new Date().toDateString()+' '+new Date(currentlyOpen.end).toTimeString())
                      })
                  }
                  openCreateModal()
                  // setIsCreateModalOpen(true)
                }}
              >
                {translate('sps.labels.add')}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  const MyTimeSlotWrapper = (props) => {
    const { value } = props
    const weekStart = currentCalendarView=='week' ? startOfWeek(new Date(day)) : currentCalendarView=='month' ? subDays(new Date(day), 0) : new Date(day)

    if((weekStart.toDateString()==new Date(value).toDateString()) && new Date(value).getHours()==0 && new Date(value).getMinutes()==0) {
      const classList =  document.getElementsByClassName('rbc-time-gutter')
      if(classList[0] && classList[0].children && classList[0].id !='myList') {
        classList[0].innerHTML = ''
        classList[0].id='myList'
        for (let i = 0; i<24; i++) {
          const newRow = document.createElement('div')
          newRow.className='rbc-timeslot-group'
          newRow.id=i.toString()
          newRow.innerHTML = `<div style="height: 40px">${formatTime(i)}:00</div>`
          classList[0].appendChild(newRow)
        }
      }
    }

    let isDisabled = false

    const days = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday']
    if(schedule) {
      const today = currentCalendarView=='day' ? schedule.find(sc => sc.day.toLowerCase() == days[new Date(day).getDay()]) : schedule.find(sc => sc.day.toLowerCase() == days[new Date(value).getDay()])
      if(today && !today.enabled) {
        isDisabled = true
      } else {
        if(!today.endTime2 || !today.startTime2) {
          isDisabled = (Math.floor(new Date(value).getTime()/100000) < Math.floor(new Date(new Date(value).toDateString() + ' ' + new Date(today.startTime).toTimeString()).getTime()/100000)
            || Math.floor(new Date(value).getTime()/100000) >= Math.floor(new Date(new Date(value).toDateString() + ' ' + new Date(today.endTime).toTimeString()).getTime()/100000)
          )
        }else {
          isDisabled = (
            (Math.floor(new Date(value).getTime()/100000) < Math.floor(new Date(new Date(value).toDateString() + ' ' + new Date(today.startTime).toTimeString()).getTime()/100000)
            || Math.floor(new Date(value).getTime()/100000) >= Math.floor(new Date(new Date(value).toDateString() + ' ' + new Date(today.endTime).toTimeString()).getTime()/100000)
            ) &&
            ( (today.endTime2 !== null && Math.floor(new Date(value).getTime()/100000) >= Math.floor(new Date(new Date(value).toDateString() + ' ' + new Date(today.endTime2).toTimeString()).getTime()/100000))
            || (today.startTime2 !== null && Math.floor(new Date(value).getTime()/100000) < Math.floor(new Date(new Date(value).toDateString() + ' ' + new Date(today.startTime2).toTimeString()).getTime()/100000))
            )
          )
        }
      }
    }

    return (
      <div
        style={{height: 20}}
        className={cx([isDisabled && style.timeSlotDisabledStyle])}
      />
    )
  }

  const MyResourceHeader = (props) => {
    const { label, date } = props
    const translate = useTranslate()
    const days = {
      Mon: translate('sps.labels.monday'),
      Tue: translate('sps.labels.tuesday'),
      Wed: translate('sps.labels.wednesday'),
      Thu: translate('sps.labels.thursday'),
      Fri: translate('sps.labels.friday'),
      Sat: translate('sps.labels.saturday'),
      Sun: translate('sps.labels.sunday'),
    }

    return (
      <div className={cx([style.resourceHeaderStyle, differenceInCalendarDays(new Date(date),new Date())<0 && css`color: rgba(0,0,0,0.6);`])}>
        <span id={(new Date(date).getDate() == new Date().getDate()) ? 'today-date' : ''} className={cx([style.headerTextStyle,(new Date(date).getDate() == new Date().getDate()) && style.headerTodayStyle])}>{new Date(date).getDate()}</span>
        <span className={cx([(new Date(date).getDate() == new Date().getDate()) && style.dateHeaderTextStyle])}>{days[label.split(' ')[0]]}</span>
      </div>
    )
  }

  const EmployeeResourceHeader = (props) => {
    const { date } = props

    let redValue, greenValue, blueValue
    redValue = greenValue = blueValue = 0
    const diff = differenceInCalendarDays(new Date(date), new Date(day))
    for(let i=0; i<hqEmployees[diff].firstName.length; i++) {
      redValue += Number(hqEmployees[diff].firstName.charCodeAt(i))
    }
    for(let i=0; i< (hqEmployees[diff].lastName || '').length; i++) {
      blueValue += Number(hqEmployees[diff].lastName.charCodeAt(i))
    }
    greenValue = redValue+blueValue
    const colorString = String(redValue.toString(16)+greenValue.toString(16)+blueValue.toString(16)+'D093C9').slice(0,6)
    return (
      <div
        className={style.employeeResourceHeaderStyle}
      >
        <span
          className={
            cx([
              style.headerTextStyle,
              css `background-color: #${colorString};`
            ])}
          style={{color: 'white'}}
        >
          {hqEmployees[diff].firstName[0].toUpperCase()}
        </span>
        <span>
          {`${hqEmployees[diff].firstName} ${hqEmployees[diff].lastName || ''}`}
        </span>
      </div>
    )
  }

  const MyEventContainer = (props) => {
    const formatTime = (time: number) => {
      return time<10 ? '0'+time : time
    }
    const {id: eventId, client, employee, start, end, service} = props

    const [startTime,endTime] = [new Date(start), new Date(end)]
    const employeeString = `${employee.firstName || ''} ${employee.lastName || ''}`
    const timeString = `${formatTime(startTime.getHours())}:${formatTime(startTime.getMinutes())} - ${formatTime(endTime.getHours())}:${formatTime(endTime.getMinutes())}`
    const clientString = (client && client.id) ? `${getClientName(client)}` : ''
    const serviceString = (service && service.id) ? service.name : ''


    return (
      <div
        className={style.eventContainerStyle}
        onClick={(e) => {
          e.stopPropagation()
          setCurrentlyOpenEvent(eventId, null,true)
          setReprogramData(true)
          setReprogramDataWaitingList(true)
          setCurrentDisplayMode('calendar')
          setCurrentCalendarView('week')
        }}
      >
        <span className={style.eventContainerTextStyle}>{timeString}</span>
        {serviceString && <span className={cx([style.eventContainerTextStyle, css`font-weight: bold;`])}>{serviceString}</span>}
        {clientString && <span className={cx([style.eventContainerTextStyle, css`margin-bottom: 10px;`])}>{clientString}</span>}
        <span className={cx([style.eventContainerTextStyle, 'event-container-employee'])}>
          <PermIdentityIcon/>
          {employeeString}
        </span>
      </div>
    )
  }

  const MyEventWrapper = ({event, children}) => {
    const [menuOpen, setMenuOpen] = useState<any>(null)
    const { title, className } = children.props;
    const customClass = `${className} rbc-event--${event.id}`;
    const hourStart = moment(event.start).hour()*61 + moment(event.start).minutes();
    const hourStop = moment(event.end).hour()*61 + moment(event.end).minutes();
    const start = (hourStart/1464)*984
    const stop = (hourStop/1464)*984
    const colors = {
      borderColor: !style.STATUS_COLORS[event.status] ? style.STATUS_COLORS[0].colorLeft : style.STATUS_COLORS[event.status].colorLeft,
      color: !style.STATUS_COLORS[event.status] ? style.STATUS_COLORS[0].color : style.STATUS_COLORS[event.status].color,
    }
    const wrapperClass = css`
      grid-row: ${Math.floor(start)} / span ${Math.floor(stop - start)};
      background-color: ${colors.color} !important;
      border-color: ${colors.borderColor} !important;
      flex-direction: column;
      flex-flow: column;
      font-size: 12px !important;
    `

    return (
      <>
        <div
          title={title}
          className={cx([customClass, wrapperClass])}
          onClick={() => {
            setCurrentlyOpenEvent(event.id, event);
            setIsDetailsModalOpen(true)
          }}
          onMouseEnter={(e) => {
            setMenuOpen(e.currentTarget)
          }}
          onMouseLeave={() => {
            setMenuOpen(null)
          }}
        >
          <div style={{display: 'flex'}}>
            <div>
              {children.props.children}
            </div>
            <div style={{
              width: '15px',
              position: 'absolute',
              right: '1px',
              top: '0px',
              marginRight: 0,
              background: '#E9F4FC',
            }}>
              {event.repeat && event.repeat !== '' && event.repeat !== 'Non si ripete' ?
                (<ReplayIcon style={{
                  width: '0.8em',
                  height: '0.8em',
                  display: 'inline-block',
                  fontSize: '1.3rem',
                }}/>) : <></>
              }
            </div>
          </div>
          <div>
            <div className={style.eventContainerDetails}>
              {event.service && <span className={style.serviceDetailsStyle}>{event.service.name}</span>}
              {event.client && <span style={{marginBottom: 15}}>{getClientName(event.client)}</span>}
              <div className={style.wrapperPeopleStyle}>
                <PeopleIcon style={{width: 16, height: 16}}/>
                <span>{event.employee.firstName + ' ' + (event.employee.lastName || '')}</span>
              </div>
            </div>
          </div>
        </div>
        <EventPopper menuOpen={menuOpen} event={event} title={title}/>
      </>
    );
  }

  const CustomEmployeeView = (props: any) => {
    const {date} = props

    if(!hqEmployees) {
      return null
    }

    const range = date => {
      return eachDayOfInterval({start: date, end: addDays(date, employee=='none' ?  hqEmployees.length-1 : 0)})
    }


    return <TimeGrid {...props} range={range(date)} eventOffset={15} />
  }

  CustomEmployeeView.title = () => {
    return 'three day calendar'
  }

  CustomEmployeeView.navigate = (date, action) => {
    switch (action) {
      case "PREV":
        return subDays(date, 3)

      case 'NEXT':
        return addDays(date, 3)

      default:
        return date
    }
  }

  const CustomAppointmentListView = (props: any) => {
    const futureAppointments = appointmentsData.filter(a => {
      return(new Date(a.start).getTime() >= new Date().getTime() && a.warnOfFreeAppointment)
    }).sort((a,b) => new Date(a.start).getTime() > new Date(b.start).getTime())

    if(!futureAppointments.length) {
      return <EmptyListView/>
    }

    var currentDay = null

    return (
      <div className={cx([style.customAppointmentListContainer, isSmall && css`padding: 20px 10px;`])}>
        {futureAppointments.map(a => {
          //@ts-ignore
          if(currentDay === null || new Date(a.start).getDate() !== new Date(currentDay).getDate()) {
            currentDay = a.start
            return (
              <>
                <div className={style.dateStringStyle}>

                  {
                    //@ts-ignore
                    format(new Date(currentDay), "eeee, dd MMMM yyyy", {locale: it})
                  }
                </div>
                <MyEventContainer {...a}/>
              </>
            )
          }

          return <MyEventContainer {...a}/>
        })}
      </div>
    )
  }

  CustomAppointmentListView.title = () => {
    return 'custom appointment list view'
  }

  CustomAppointmentListView.navigate = (date, action) => {
    switch (action) {
      case "PREV":
        return subDays(date, 3)

      case 'NEXT':
        return addDays(date, 3)

      default:
        return date
    }
  }

  const components = {
    toolbar: MyToolbar,
    timeSlotWrapper: MyTimeSlotWrapper,
    eventWrapper: MyEventWrapper,
    week: {
      header: MyResourceHeader,
      event: () => null
    },
    month: {
      header: MyResourceHeader,
      event: () => null,
    },
    day: {
      header: EmployeeResourceHeader,
      event: () => null,
    },
  }

  const setCurrentlyOpenEvent = (id, event = null, isListReprogram = false) => {
    let foundAppointment = appointments.find(a => a.id === id)
    if(event) {
      foundAppointment = event
    }
    foundAppointment.warnOfFreeAppointment = isListReprogram ? false : foundAppointment.warnOfFreeAppointment
    setCurrentlyOpen(foundAppointment);
  }

  const getAppointmentsToShow = () => {

    // TODO: debugger
    let appointmentsList: any = []
    if (!showAllEmployees) {
      appointmentsList =  appointments.filter(a => a && a.employee.id === employee.id).map(a => {
        return {...a, resourceId: 1}
      });
    } else {
      appointmentsList =  appointments.filter(a => a && a.employee.hairdresserSalon.id === selectedHeadquarters.id).map(a => {
        return {...a, resourceId: 1}
      });
    }

    let fullData: any = [];

    for(let i = 0; i < appointmentsList.length; i+=1) {
      fullData.push(appointmentsList[i]);
      if (appointmentsList[i].hasRepeat && appointmentsList[i].repeatFrequency) {
        for (let j = 0; j < 52; j += 1) {
          const startDate = moment(appointmentsList[i].start).add(appointmentsList[i].repeatFrequency * (j + 1), 'days').toDate();
          if(schedule && schedule[startDate.getDay()] && !schedule[startDate.getDay()].enabled) {
            if(repeatOptionsSkip[appointmentsList[i].repeat] === 0) {
              continue;
            } //TODO: check if we need to move to another day
          }
          if(appointmentsList[i].repeatEndDate) {
            const finalRepeatDate = moment(appointmentsList[i].repeatEndDate).toDate()
            if(finalRepeatDate <= startDate) {
              break;
            }
          }
          fullData.push({
            ...appointmentsList[i],
            start: startDate,
            end: moment(appointmentsList[i].end).add(appointmentsList[i].repeatFrequency * (j + 1), 'days').toDate(),
            extraData: {
              start: moment(appointmentsList[i].start).add(appointmentsList[i].repeatFrequency * (j + 1), 'days').toDate(),
              end: moment(appointmentsList[i].end).add(appointmentsList[i].repeatFrequency * (j + 1), 'days').toDate(),
            }
          });
        }
      }
    }

    return fullData;
  }

  const getEmployeeAppointments = () => {
    if(employee == 'none') {
      return appointments
        .filter(a => new Date(a.start).toDateString() === day.toDateString())
        .map(a => {
          const employeeIndex = hqEmployees.findIndex(e => e.id == a.employee.id)
          return {...a, start: addDays(a.start, employeeIndex), end: addDays(a.end, employeeIndex), resourceId: 1}
        })
    }
    return appointments
      .filter(a => a && new Date(a.start).toDateString()==day.toDateString())
      .map(a => {
        return {...a, resourceId:1}
      })
  }

  return (
    <>
      <div>
        {employee &&
          //@ts-ignore
        <Calendar view={currentCalendarView || 'week'}
          className={style.calendarStyle}
          selectable // Allows adding of event directly in calendar
          showMultiDayTimes
          localizer={localizer}
          date={day}
          resources={[
            { resourceId: 1, resourceTitle: 'Board room' },
          ]}
          resourceIdAccessor="resourceId"
          onNavigate={() => {
          }}
          events={(currentCalendarView === 'day' && showAllEmployees) ? getEmployeeAppointments() : getAppointmentsToShow()}
          views={{
            week: true,
            day: CustomEmployeeView,
            month: CustomThreeDayView,
            work_week: CustomAppointmentListView
          }}
          eventPropGetter={(event: any) => {
            if (!style.STATUS_COLORS[event.status]) {
              return {}
            }
            return {
              style: {
                borderLeftColor: style.STATUS_COLORS[event.status].colorLeft,
                borderColor: style.STATUS_COLORS[event.status].color
              }
            }
          }}
          components={components}
          formats={formats}
          onSelectEvent={(event) => {
            setCurrentlyOpenEvent(event.id, event);
            setIsDetailsModalOpen(true)
          }}
          onSelectSlot={(slotInfo) => {
            if(currentCalendarView=='week' || currentCalendarView=='month') {
              setCreateModalDefaultData({
                day: slotInfo.start,
                startSelected: slotInfo.start,
                endSelected: slotInfo.end,
                employeeSelected: employee
              })
              if(reprogramData) {
                setCurrentlyOpen(
                  {...currentlyOpen,
                    start: slotInfo.start,
                    end: slotInfo.end,
                    employee: employee,
                  })
              }
            } else {
              setCreateModalDefaultData({
                day: day,
                startSelected: new Date(day.toDateString()+' '+new Date(slotInfo.start).toTimeString()),
                endSelected: new Date(day.toDateString()+' '+new Date(slotInfo.end).toTimeString()),
                employeeSelected: hqEmployees[differenceInCalendarDays(new Date(slotInfo.end), day)],
              })
              if(reprogramData) {
                setCurrentlyOpen(
                  {...currentlyOpen,
                    start: new Date(day.toDateString()+' '+new Date(slotInfo.start).toTimeString()),
                    end: new Date(day.toDateString()+' '+new Date(slotInfo.end).toTimeString()),
                    employee: hqEmployees[differenceInCalendarDays(new Date(slotInfo.end), day)],
                  })
              }
            }
            openCreateModal()
          }}
        />}
        <Modal className={style.modalContainer} open={isDetailsModalOpen} onClose={() => setIsDetailsModalOpen(false)}>
            <AppointmentDetailsModal
              handleClose={(action, appointment) => {
                setIsDetailsModalOpen(false);
                let data = []
                if (action === 'delete') {
                  data = appointments.filter(e => e.id !== appointment.id)
                }
                if (action === 'update') {
                  if(appointment.status=="EXECUTED" && selectedHeadquarters.hasOwnProperty('remindOffer') && selectedHeadquarters.remindOffer) {
                    setIsReminderOpen(true)
                  }
                  let index = appointments.findIndex(e => e.id === appointment.id)
                  const currentAppointments = appointments
                  currentAppointments[index] = {
                    ...appointment,
                    start: new Date(appointment.start),
                    end: new Date(appointment.end),
                    title: !appointment.client ? '' : getClientName(appointment.client),
                  }
                  if(appointment.status=='CANCELED') {
                    delete currentAppointments[index]
                  }
                  data = [...currentAppointments] as any
                }
                if(action != 'close') {
                  setAppointments(data)
                  setCurrentlyOpen(undefined)
                  window.localStorage.removeItem('currently-open-appointment')
                }
                setRefreshAppointments(!refreshAppointments)
              }}
              appointment={currentlyOpen}
              otherAppointments={appointments}
              setReprogramData={setReprogramData}
              salonData={selectedHeadquarters}
            />
        </Modal>
        <Modal className={style.modalContainer} open={isCreateModalOpen} onClose={() => closeCreateModal()}>
          <div className={style.fullscreenModal}>
              <AppointmentForm
                initialValues={{...createModalDefaultData}}
                handleClose={(data) => {
                  if (!data) {
                    closeCreateModal()
                    return
                  }
                  const appointmentsList = [...appointments] as any
                  appointmentsList.push({
                    ...data.data,
                    start: new Date(data.data.start),
                    end: new Date(data.data.end),
                    title: !data.data.client ? '' : getClientName(data.data.client),
                  })
                  setAppointments(appointmentsList)
                  closeCreateModal()
                }}
                appointment={currentlyOpen}
                otherAppointments={appointments}
                initialEmployee={employee === "none" ? employeeData[0] : employee}
                isReprogram={!!reprogramData}
                setReprogramData={setReprogramData}
                salonData={selectedHeadquarters}
              />
          </div>
        </Modal>
      </div>
      {(isSmall && !isCreateModalOpen && !isDetailsModalOpen && !reprogramData) &&
        <Fab color="primary" className={style.fab} onClick={() => {
          setCreateModalDefaultData({
            day: new Date(),
            employeeSelected: null
          })
          if(reprogramData) {
            setCurrentlyOpen(
              {...currentlyOpen,
                start: new Date(new Date().toDateString()+' '+new Date(currentlyOpen.start).toTimeString()),
                end: new Date(new Date().toDateString()+' '+new Date(currentlyOpen.end).toTimeString())
              })
          }
          openCreateModal()
        }}>
          <AddIcon/>
        </Fab>}
      <Snackbar
        anchorOrigin={{horizontal: 'center', vertical: isSmall ? 'top' : 'bottom'}}
        autoHideDuration={5000}
        message={translate('sps.labels.remindToOffer')}
        open={isReminderOpen}
        className={css`.MuiSnackbarContent-root{flex-wrap: unset !important;} .MuiPaper-root { font-size: 12px; .MuiSnackbarContent-action {padding: 0px !important;}}`}
        action={
          <Button
            color="inherit"
            onClick={() => {
              setIsReminderOpen(false)
            }}
          >
            <CloseIcon/>
          </Button>
        }
        onClose={() => {
          setIsReminderOpen(false)
        }}
      />
    </>
  );
}
