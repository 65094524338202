import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices
} from "react-admin";
import { USER_ROLES, ARTICLE_TYPES } from "../constants";
import { controllaCF, controllaPIVA } from "cfpiva";
import IBAN from 'iban';

const exactLength = (
  length,
  message = "sps.validation.exact_length"
) => value =>
  !value || value.length === length
    ? undefined
    : {
        message: message,
        args: { length }
      };

export const PASSWORD_MINIMUM_LENGTH = 4;
export const PASSWORD_VALIDATION_REGEX = /^[a-z0-9._*!&]+$/i;
export const PRINT_HEADER_LINE_MAX_LENGTH = 29;

export const validateRequired = [required()];
export const validateNonRequiredPassword = [
  minLength(PASSWORD_MINIMUM_LENGTH),
  regex(
    PASSWORD_VALIDATION_REGEX,
    "Must be alphanumeric or one of following: . _ * ! &"
  )
];
export const validatePassword = [required(), ...validateNonRequiredPassword];
export const validateRequiredEmail = [required(), email()];
export const validateEmail = [email()];
export const validatePin = [
  exactLength(4, "sps.labels.pinLength"),
  regex(/^[1-9][0-9]*$/, "Must be number")
];
export const validateRole = [required(), choices(USER_ROLES.map(r => r.id))];
export const validateAteco = [
  required(),
  maxLength(6),
  regex(/^[1-9][0-9]*$/, "Must be number")
];
export const validateClientPersonType = [
  required(),
  choices(["PRIVATE", "LEGAL"])
];
export const validateTenantStatus = [
  required(),
  choices(['ACTIVE', 'WARNED', 'SUSPENDED', 'DEACTIVATED'])
];

export const fiscalCode = (
  message = "sps.validation.invalid_fiscal_code"
) => value => {
  // valid for testing: GBNFLP24C01E284W
  const cfValid = controllaCF(value || "");
  const vatValid = !isNaN(value) && (value && value.length === 11)
  return !value || vatValid || cfValid
    ? undefined
    : {
        message: message
      };
};
export const vatNumber = (message = "sps.validation.invalid_vat_number") => value => {
  // valid for testing: 02188520544
  const pIvaValid = controllaPIVA(value || "");
  return !value || pIvaValid
    ? undefined
    : {
        message: message
      };
};
export const validateFiscalCode = [fiscalCode()];
export const validateVatNumber = [
  required(),
  number(""),
  exactLength(11, "Must be 11 characters long"),
  vatNumber()
];
export const validateNumericBirthday = [
  regex(/^[0-3][0-9]\/[0-1][0-9]/i, "Must be in dd/mm format")
];
export const validateZip = [
  exactLength(5, "Must be 5 characters long"),
  regex(/^[0-9]+$/, "Must be only numbers")
];
export const validateProvince = [exactLength(2, "Must be 2 characters long")];
export const validateSdi = [
  minLength(6, "Must be minimum 6 characters long"),
  maxLength(7, "Must be maximum 7 characters long")
];
export const validateLotteryId = [
  minLength(8, "sps.validation.must_be_8_chars_long"),
  maxLength(16, "sps.validation.must_be_16_chars_long")
];
export const validateTenantVatNumber = [required(), ...validateVatNumber];
export const validateTenantCompanyName = [required(), minLength(2, "sps.labels.requiredField"),];
export const validateState = [required()];
export const validateTenantRole = [required()];

const validPhone = (message = "sps.validation.must_be_7_chars_long") => value => {
  if(!value || value === '+' || (value[0] === '+' && (value.length === 2 || value.length === 3))) {
    return undefined
  }
  if(value.length > 6) {
    return undefined
  }
  return {
    message: message
  }
};

const validRequiredPhone = (message = "sps.validation.must_be_7_chars_long") => value => {
  if(!value) {
    return {
      message: message
    }
  }
  if(value.length > 6) {
    return undefined
  }
  return {
    message: message
  }
};

const validDiscount = (message = "Il valore non puo' essere 0") => value => {
  if(value === 0 || value === '0') {
    return {
      message: message
    }
  }
  return undefined
};

const validIBAN = (message = "IBAN deve essere valido") => value => {
  if(value === 0 || value === '0') {
    return {
      message: message
    }
  }
  if(!IBAN.isValid(value)) {
    return {
      message: message
    }
  }
  return undefined
};

const validPrintHeader = (message = "Il massimo limite e' di: 10 linee e 29 caratteri") => value => {
  if (!value || value.trim() === '') {
    return undefined;
  }
  const allLines = value.split('\n')
  if (allLines.length > 10) {
    return {
      message: message
    }
  }
  for (let i = 0; i < allLines.length; i += 1) {
    if (allLines[i].length > PRINT_HEADER_LINE_MAX_LENGTH) {
      return {
        message: "Il massimo limite e' di: 10 linee e 29 caratteri"
      }
    }
  }
  return undefined
};

const validPrintFooter = (message = "Il massimo limite e' di: 4 linee e 29 caratteri") => value => {
  if (!value || value.trim() === '') {
    return undefined;
  }
  const allLines = value.split('\n')
  if (allLines.length > 5) {
    return {
      message: message
    }
  }
  for (let i = 0; i < allLines.length; i += 1) {
    if (allLines[i].length > PRINT_HEADER_LINE_MAX_LENGTH) {
      return {
        message: "Il massimo limite e' di: 4 linee e 29 caratteri"
      }
    }
  }
  return undefined
};

export const validatePhoneNumber = [
  validPhone()
];

export const validatePrintHeader = [
  validPrintHeader()
];
export const validatePrintFooter = [
  validPrintFooter()
];

export const validateIBAN = [
  validIBAN()
];
export const validateRequiredPhoneNumber = [
  validRequiredPhone()
];
export const validateUsername = [
  required(),
  minLength(2),
  regex(/^[a-z0-9]+$/i, "Must be alphanumeric")
];
export const validateBIC = [
  required(),
  minLength(2),
  maxLength(20),
];

export const validateBankName = [
  required(),
  minLength(1),
  maxLength(100),
];

export const validateReaCode = [
  minLength(1),
];
export const validateGroupName = [required(), minLength(2), maxLength(40)];
export const validateFirstName = [required(), minLength(2)];
export const validateLastName = [required(), minLength(2)];
export const validateRTCode = [required(), minLength(2)];
export const validateSerialNumber = [required(), minLength(2)];
export const validateDeviceStatus = [required()];
export const validateArticleName = [required(), minLength(1), maxLength(50)];
export const validateArticlePrice = [
  required(),
  minValue(0),
  maxValue(999999.99)
];
export const validateArticleGroup = [required()];
export const validateArticleVAT = [required()];
export const validateOfferValidDays = [minValue(1), maxValue(1000)];
export const validateArticleType = [
  required(),
  choices(ARTICLE_TYPES.map(r => r.id))
];
export const validateDiscountAmount = [
  validDiscount(),
  minValue(0, "Il valore non e' valido"),
  maxValue(100),
  regex(/^[0-9]+$/i, "Must be numeric")
];

export const validateDiscountPrice = (price) => [
  validDiscount(),
  minValue(0, "Il valore non e' valido"),
  maxValue(price || 0),
  regex(/^[0-9]+$/i, "Must be numeric")
];
export const validateClientDiscount = [
  minValue(0),
  maxValue(100),
  regex(/^[0-9]+$/i, "Must be numeric")
];

export const validateTenantId = [
  required(),
  minLength(5),
];
export const validateVentilazioneIva = [required()];
export const validatePDVInternalName = [
  required(),
  minLength(2),
  maxLength(50)
];
export const validatePDVShortName = [required(), minLength(1), maxLength(2)];
export const validatePDVName = [required(), minLength(2)];
export const validatePDVPercentage = [required(), minValue(0), maxValue(100)];
