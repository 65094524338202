import { client } from './graphql/client';
import { EmailLogin } from './graphql/generated-queries/email-login-queries';
import {removeSelectedTenant, setSelectedTenant} from "./service/store";
import {storeUserTokenData, clearUserTokenData} from "./auth";

interface ICredentials {
    username: string;
    password: string;
}


export default {
    // called when the user attempts to log in
    login: async (credentials: ICredentials) => {
        const { username, password } = credentials;
        try {
            const { data } = await client.mutate({ mutation: EmailLogin , variables: { username, password } });
            const { emailLogin: { token } } = data;
            const decodedToken = storeUserTokenData(token)

            if(decodedToken && decodedToken.user && decodedToken.user.tenant) {
                setSelectedTenant(decodedToken.user.tenant)
                if(!decodedToken.user.tenant.iban && decodedToken.user.tenant.id) {
                    window.location.href = '/tenant/' + decodedToken.user.tenant.id
                } else {
                    window.location.href = '/article'
                }
            }
        } catch(err) {
            throw new Error(err.graphQLErrors?.[0]?.message || err)
        }
        return Promise.resolve({ redirectTo: '/' });
    },
    // called when the user clicks on the logout button
    logout: () => {
        clearUserTokenData();
        removeSelectedTenant();
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            clearUserTokenData();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('username') && localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        const role = localStorage.getItem('userrole');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    getTenantRole: () => {
        const role = localStorage.getItem('tenantRole');
        return role ? Promise.resolve(role) : Promise.reject();
    },
};
