/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const PDVS = gql`
  query PDVS {
    pDVS {
      id
      internalName
      name
      shortName
      percentage
    }
  }
`;
