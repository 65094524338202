import React from 'react';
import {MenuItemLink, UserMenu} from 'react-admin';
import CustomMenu from './components/Menu';
import Notification from './components/Notification';
import AppBar from './components/AppBar'
import Layout from './components/Layout'

import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';

const CustomUserMenu = props => {
  const firstLetter = (localStorage.getItem('username') || 'A')[0].toUpperCase();
  const isDevice = !!window.Android
  if(isDevice) {
    return (<Avatar style={{backgroundColor: 'rgba(255,255,255, 0.2)', margin: '12px'}}>{firstLetter}</Avatar>)
  }
  return (
    <UserMenu {...props} icon={((<Avatar style={{backgroundColor: 'rgba(255,255,255, 0.2)'}}>{firstLetter}</Avatar>))}>
    <MenuItemLink
      to="/my-profile"
      primaryText="My profile"
      leftIcon={<PersonIcon/>}
    />
    </UserMenu>)
};

const CustomAppBar = props => <AppBar {...props} userMenu={<CustomUserMenu />} />;

export const CustomLayout = props => <Layout style={{background: '#FAFAFA'}} {...props} appBar={CustomAppBar}
                                             menu={CustomMenu} notification={Notification}/>
