import React, {useState} from 'react'
import {css, cx} from "emotion";
import {useTranslate} from 'ra-core'
import {Grow, Paper, Popper, Theme, useMediaQuery} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help';

const containerStyle = css`
  display: flex;
  flex-direction: column;
  padding: 70px;
`
const toUseCalendarStyle = css`
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 30px;
`
const mobileTextStyle = css`
  flex-direction: column;
  justify-content: center;
  text-align: center;
`
const paperStyle = css`
  display: flex;
  max-width: 350px;
  padding: 10px;
  font-size: 14px;
  color: white !important;
  background-color: rgba(0,0,0,0.3) !important;
`

export const EmptyListView = () => {
  const translate = useTranslate()
  const [isHelpOpen, setIsHelpOpen] = useState<any>(null)
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('xs')
  });

  return  (
    <div className={containerStyle}>
      <span className={cx([toUseCalendarStyle, isXSmall && mobileTextStyle])}>{translate('sps.labels.youHaveNoWaiting')}</span>
      <span
        className={cx([toUseCalendarStyle, css`color: black !important;`, isXSmall && mobileTextStyle])}
        onClick={(e) => setIsHelpOpen(isHelpOpen ? null : e.currentTarget)}
        onMouseEnter={(e) => setIsHelpOpen(e.currentTarget)}
        onMouseLeave={(e) => setIsHelpOpen(null)}
      >
        {translate('sps.labels.howToAddList')}
        <HelpIcon/>
      </span>
      <Popper
        transition
        disablePortal
        role={undefined}
        anchorEl={isHelpOpen}
        open={Boolean(isHelpOpen)}
        placement={'bottom-start'}
        className={css`z-index: 1000;`}
      >
        {({TransitionProps, placement}) => (
          <Grow {...TransitionProps}>
            <Paper className={paperStyle}>
              <span>{translate('sps.labels.toAddAppointment')}</span>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
