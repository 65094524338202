import { client } from '../../graphql/client';
import { UpdateMessage } from '../../graphql/generated-queries/update-message-queries';

export async function updateMessage({ data }) {
  const {
    id,
    title,
    message,
    annotation,
    sentDate,
    adminOnlyVisible,
    recipient,
    sendInApp,
    scheduledDate,
    sendViaEMail,
    sendViaSMS,
  } = data;

  const result = await client.mutate({
    mutation: UpdateMessage,
    variables: {
      input: {
        id,
        title,
        message,
        scheduledDate,
        annotation,
        sentDate,
        adminOnlyVisible,
        recipient,
        sendInApp,
        sendViaEMail,
        sendViaSMS,
      }
    }
  });
  return { data: result.data.updateMessage };
}
