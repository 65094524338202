/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const UpdateAppointment = gql`
  mutation UpdateAppointment($input: AppointmentEditInput!) {
    updateAppointment(input: $input) {
      id
      annotation
      start
      end
      sendReminder
      warnOfFreeAppointment
      repeatEndDate
      changeRepeatAppointment
      repeat
      status
      tenant {
        id
        companyName
      }
      client {
        id
        firstName
        lastName
      }
      employee {
        id
        firstName
        lastName
        phoneNumber
        hairdresserSalon {
          id
          name
        }
      }
      service {
        id
        name
      }
    }
  }
`;
