/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const UpdateArticle = gql`
  mutation UpdateArticle($input: ArticleEditInput!) {
    updateArticle(input: $input) {
      id
      barCode
      type
      group {
        id
        name
      }
      order
      pdv {
        id
        name
      }
    }
  }
`;
