import { client } from "../../graphql/client";
import { CreateUser } from "../../graphql/generated-queries/create-user-queries";
import { getSelectedTenant } from "../../service/store";

export async function createUser(input) {
  const tenant = getSelectedTenant();
  const tenantId = tenant ? tenant.id : null;
  if (tenantId) {
    input.data.assignedTenantId = String(tenantId);
    input.data.role = "USER";
  }

  try {
    const {
      data: { sms, ...inputData }
    } = input;

    if (sms) {
      for (const notificationType of ["sendSMSPassword"]) {
        inputData[`${notificationType}`] = sms.includes(notificationType);
      }
    }

    const { data } = await client.mutate({
      mutation: CreateUser,
      variables: { input: { ...inputData } }
    });
    return { data: { ...data.createUser } };
  } catch (err) {
    console.error(JSON.stringify(err));
    throw new Error(err.graphQLErrors?.[0]?.message || err);
  }
}
