import React from 'react'
import {Dialog, DialogTitle, Button, makeStyles} from '@material-ui/core'
import {useEffectAsync} from "../../use-effect-async";
import {css} from "emotion";
import { useTranslate } from 'ra-core'
import {MessageIconDisplay} from "./MessageIconDisplay";

const containerStyle = css`
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-width: 400px;
`
const titleStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
`
const titleTextStyle = css`
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,1);
  font-size: 24px;
  font-weight: bold;
  padding: 0px !important;
`
const messageDetailStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
`
const messageDetailTextStyle = css`
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.6);
  font-size: 14px;
`
const messageStyle = css`
  margin-bottom: 15px;
  white-space: pre-wrap;
`
const buttonStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
`

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '0px !important',
  }
}))

interface IMessageDialogProps {
  isOpen: boolean
  setIsOpen(state): any
  messageId: string
  message: any
}

export const MessageDetailDialog = (props: IMessageDialogProps) => {
  const { isOpen, setIsOpen, message } = props
  const classes = useStyles()
  const translate = useTranslate()

  useEffectAsync(() => {
    //remove comment when messages exist, change Message to message
    //setMessage(await getMessage({messageId: messageId})
  }, [])

  return (
    <>
      <Dialog
        className={classes.modal}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className={containerStyle}>
          <div className={titleStyle}>
            <DialogTitle className={titleTextStyle}>{message.title}</DialogTitle>
            <MessageIconDisplay message={message}/>
          </div>
          <div className={messageDetailStyle}>
            <span className={messageDetailTextStyle}>To {message.recepient}</span>
            <span className={messageDetailTextStyle}>{new Date(message.scheduledDate ? message.scheduledDate : message.sentDate).toLocaleString()}</span>
          </div>
          <div className={messageStyle}>
            {message.message}
          </div>
          <div className={buttonStyle}>
            <Button
              onClick={() => setIsOpen(false)}
            >
              {translate('sps.labels.close')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}
