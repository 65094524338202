import React, {useEffect, useState} from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  SelectInput,
  AutocompleteInput,
  TextInput,
  Edit,
  RadioButtonGroupInput,
  FormDataConsumer,
  showNotification,
} from "react-admin";
import {
  validateTenantCompanyName,
  validateTenantId,
  validateTenantVatNumber,
  validateVentilazioneIva,
  validateState,
  validateTenantStatus,
  validateReaCode,
  validateFiscalCode,
  validateZip,
  validateIBAN,
  validateBIC,
  validateBankName,
} from "../service/validator";

import {
  COUNTRIES_LIST,
  TENANT_STATUS,
  PROVINCE_ITALY_LIST,
  ATECO_CODES
} from "../constants";
import {EditOnlyToolbar, SaveToolbar} from "./components/Toolbars";
import {Theme, useMediaQuery, IconButton, TextField as MuiTextField, Tab, Tabs, Box, Card} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import {css, cx} from "emotion";
import {useTranslate} from "ra-core";
import {useForm} from "react-final-form";
import {AppSettingsInput} from "./components/AppSettingsInput";
import authProvider from "../authProvider";
import {useDispatch} from 'react-redux';
import {toggleSidebar, useRefresh} from 'ra-core';
import {useEffectAsync} from "../use-effect-async";
import {getSelectedTenant} from "../service/store";
import {getTenant} from "../queries";
import {TenantOnboarding} from "./components/EmptyItemView";

const mobileFormTitleStyle = css`
  position: fixed;
  background-color: white;
  color: black;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;
const mobileCloseStyle = css`
  position: absolute;
  right: 20px;
`;
const clearButtonStyle = css`
  position: absolute !important;
  right: 30px;
  padding: 18px !important;
  z-index: 100000;
  .MuiIconButton-label {
    background-color: white;
  }
`;

const boxStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px; 
  text-align: left;
`

const overrideForm = css`
    max-width: 360px;
    padding: 20px 100px;
    background: white;
    border-radius: 0px; 
    border: 0px !important;
    box-shadow: none !important;
`
const bankHeadlineText = css`
    color: rgba(0,0,0,0.87);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 24px;
`
const bankHeadlineSubText = css`
  color: rgba(0,0,0,0.6);
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
`

const bankOtherInfoHead = css`
  color: #702588;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.29px;
  line-height: 20px;
  text-decoration: underline;
`

const bankOtherInfoSubtitle = css`
  color: rgba(0,0,0,0.6);
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 20px;
`

export const TenantsList = props => {
  window.hasSuperiusAdminChanges = false;
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="tenantId" label="Tenant ID" />
        <TextField source="companyName" />
        <TextField source="vatNumber" />
        <TextField source="status" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="fiscalCode" />
        <TextField source="city" />
        <TextField source="address" />
        <TextField source="province" />
        <TextField source="ateco" />
        <TextField source="hasHairAndBeauty" />
        <TextField source="state" />
      </Datagrid>
    </List>
  );
};

const MobileFormTitle = ({ title, record }) => {
  const dispatch = useDispatch();
  const refresh = useRefresh();

  return (
    <div className={mobileFormTitleStyle}>
      {record && record.companyName ? record.companyName : title}
      <CloseIcon
        className={mobileCloseStyle}
        onClick={() => {
          dispatch(toggleSidebar())
          refresh()
        }}
      />
    </div>
  );
};

const TenantTitle = ({ record }) => {
  return <span>Tenant {record ? `"${record.companyName}"` : ""}</span>;
};

const TenantCreateForm = props => {
  const [atecoError, setAtecoError] = useState<string>("");
  const translate = useTranslate();
  const form = useForm();
  const [permission, setPermission] = useState('USER');

  useEffect(() => {
    (async () => {
      const permissions = await authProvider.getPermissions()
      setPermission(permissions)
    })()
  })

  const {isCreate, formData: {formData: {ateco: formAteco, isVentilazioneIVA}}} = props

  if(isVentilazioneIVA===undefined) {
    form.change('isVentilazioneIVA', false)
  }

  if (atecoError === "blurred") {
    form.change("ateco", "");
    setAtecoError(translate("sps.labels.codeDoesntExist"));
  } else if (atecoError === "includes") {
    setAtecoError(" ");
  }

  if(formAteco==='' && atecoError!== translate("sps.labels.requiredField")) {
    setAtecoError(translate("sps.labels.requiredField"))
  }

  return (
    <>
      <TextInput variant="outlined" validate={validateTenantId} source="tenantId" disabled={!isCreate} {...props} />
      <TextInput
        source="vatNumber"
        variant="outlined"
        validate={validateTenantVatNumber}
        disabled={!isCreate}
        {...props}
        label={"sps.labels.vat_number"}
      />
      <RadioButtonGroupInput
        source="isVentilazioneIVA"
        choices={[
          { id: true, name: translate("sps.labels.yes").toUpperCase() },
          { id: false, name: "NO" }
        ]}
        disabled={permission === 'USER'}
        optionValue={"id"}
        label={"sps.labels.is_ventilazione_iva"}
        validate={validateVentilazioneIva}
        {...props}
      />
      <RadioButtonGroupInput
        source="hasHairAndBeauty"
        choices={[
          {id: true, name: "ON"},
          {id: false, name: "OFF"}
        ]}
        disabled={permission === 'USER'}
        optionValue={"id"}
        defaultValue={false}
        label="sps.labels.hair_and_beauty"
        {...props}
      />
      <AppSettingsInput
        source="appSettings"
        subValue={'cardReader'}
        label={translate('sps.labels.cardReader')}
        disabled={permission === 'USER'}
      />
      <AppSettingsInput
        source="appSettings"
        subValue={'internetPackage'}
        label={translate('sps.labels.internetPackage')}
        disabled={permission === 'USER'}
        style={{marginTop: '23px'}}
      />
      <SelectInput
        variant="outlined"
        source="status"
        label={"sps.labels.status"}
        choices={TENANT_STATUS}
        validate={validateTenantStatus}
        defaultValue={TENANT_STATUS[0].id}
        disabled={permission === 'USER'}
        {...props}
      />
      <TextInput
        source="companyName"
        label={"sps.labels.company_name"}
        validate={validateTenantCompanyName}
        format={v => (v ? v.trimStart() : v)}
        {...props}
      />
      <TextInput
        source="firstName"
        label={"sps.labels.first_name"}
        format={v => (v ? v.trimStart() : v)}
        {...props}
      />
      <TextInput
        source="lastName"
        label={"sps.labels.last_name"}
        format={v => (v ? v.trimStart() : v)}
        {...props}
      />
      <TextInput
        source="fiscalCode"
        label={"sps.labels.fiscal_code"}
        validate={validateFiscalCode}
        {...props}
      />
      <div
        className={css`
          display: flex;
          position: relative !important;
        `}
        style={{ position: "relative" }}
      >
        <AutocompleteInput
          options={{
            InputProps: {
              onChange: e => {
                const val = e.currentTarget.value as string;
                // form.change('ateco', val)
                if (val.length >= 6) {
                  setAtecoError(
                    !ATECO_CODES.includes(val)
                      ? translate("sps.labels.codeDoesntExist")
                      : " "
                  );
                } else {
                  if (atecoError !== " ") {
                    setAtecoError(" ");
                  }
                }
              },
              onBlur: e => {
                const val = e.currentTarget.value as string;
                if (ATECO_CODES.includes(val)) {
                  form.change("ateco", val);
                  setAtecoError("includes");
                } else {
                  setAtecoError("blurred");
                }
              },
              error: atecoError !== " " && atecoError !== "",
              helperText:
                atecoError === "" && !form.getFieldState('ateco')
                  ? translate("sps.labels.requiredField")
                  : atecoError,
              type: "number",
              id: "atecoInputField"
            }
          }}
          shouldRenderSuggestions={val => {
            return val ? val.trim().length >= 1 : false;
          }}
          source="ateco"
          label="Ateco"
          choices={ATECO_CODES.map(val => ({ id: val, name: val }))}
          {...props}
        />
        <IconButton
          className={clearButtonStyle}
          onClick={() => {
            form.change("ateco", "");
            const atecoInput = document.getElementById("ateco");
            if(atecoInput){
              //@ts-ignore
              atecoInput.value = "";
            }
          }}
          disabled={!form.getFieldState("ateco")}
        >
          <ClearIcon color="disabled" fontSize="small" />
        </IconButton>
      </div>
      <TextInput source="address" {...props} label={"sps.labels.address"} />
      <TextInput source="city" {...props} label={"sps.labels.city"} />
      <TextInput
        source="zip"
        validate={validateZip}
        {...props}
        label={"sps.labels.postal_code"}
      />
      <SelectInput
        source="province"
        label="sps.labels.province"
        choices={PROVINCE_ITALY_LIST}
        {...props}
      />
      <SelectInput
        source="state"
        label="sps.labels.country"
        validate={validateState}
        choices={COUNTRIES_LIST}
        defaultValue={"Italy"}
        {...props}
      />
      <TextInput source="reaCode" validate={validateReaCode} {...props} />

      {permission === 'ADMIN' && (<FormDataConsumer>
        {({formData}) => {
          const hasCardReader = (formData.appSettings || {}).cardReader;
          if (!hasCardReader) {
            form.change("secretSharedKey", null);
            form.change("currency", null);
            return false
          }
          return (
            <>
              <MuiTextField
                size={"medium"}
                onChange={event => {
                  const val = event.currentTarget.value || ''
                  form.change("secretSharedKey", val);
                }}
                variant="outlined"
                value={formData.secretSharedKey || ''}
                label={translate("sps.labels.secretSharedKey")}
              />
              <SelectInput
                source="currency"
                variant="outlined"
                label={"sps.labels.currency"}
                choices={[
                  { id: 'EUR', name: 'EUR' },
                ]}
                defaultValue={'EUR'}
              />
            </>
          );
        }}
      </FormDataConsumer>)
      }
    </>
  );
};

const TenantBankingForm = props => {

  return (
    <>
      <h3 className={bankHeadlineText}>Aggiungi il tuo conto corrente</h3>
      <p className={bankHeadlineSubText}>Per pagare i canoni dovrai indicare i codici IBAN e BIC del tuo conto corrente. Puoi trovare queste informazioni sull' estrato conto o contattando direttamente la tua banca.</p>
      <TextInput
        source="bic"
        validate={validateBIC}
        disabled={!props.isCreate}
        {...props}
        label={"sps.labels.bic"}
      />
      <TextInput
        source="iban"
        disabled={!props.isCreate}
        validate={validateIBAN}
        {...props}
        label={"sps.labels.iban"}
      />
      <TextInput
        source="bankAccountName"
        validate={validateBankName}
        disabled={!props.isCreate}
        {...props}
        label={"sps.labels.bankAccountName"}
      />
      <p className={bankOtherInfoHead}>Ulteriori informazioni</p>
      <p className={bankOtherInfoSubtitle}>Fornendo i tuoi dati bancari creerai un mandato di pagamento che autorizzerà Marketino ad inviare ad ogni scadenza istruzioni alla tua banca per addebitare il tuo conto. Hai diritto ad un rimborso dalla tua banca secondo i termini e condizioni con essa concordati.</p>
    </>
  );
}

const validateForm = (values, translate) => {
  const requiredFields = ["companyName", "ateco", "state"];
  const errors: any = requiredFields.reduce((acc: any, value: any) => {
    if (!values[value]) {
      acc[value] = [translate("sps.labels.requiredField")];
    }

    return acc;
  }, {});

  return errors;
};

export const TenantEditForm = props => {

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const translate = useTranslate();
  const dispatch = useDispatch();

  return (
    <Edit
      title={
        isXSmall ? (
          <MobileFormTitle {...props} title={null} />
        ) : (
          <TenantTitle {...props} />
        )
      }
      {...props}
      undoable={false}
    >
      <SimpleForm
        validate={values => validateForm(values, translate)}
        redirect={() => {
          dispatch(toggleSidebar())
          props.setNextScreen && props.setNextScreen()
        }}
        toolbar={isXSmall ? <SaveToolbar /> : <EditOnlyToolbar />}
        variant="outlined"
        className={overrideForm}
      >
        <input type="text" autoFocus={false} style={{display: 'none'}}/>
        <FormDataConsumer>
          {formData => {
            return(<TenantCreateForm {...props} formData={formData} isCreate={false} variant="outlined"/>)
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}

export const TenantEditBanking = props => {

  const [permission, setPermission] = useState('USER');

  useEffect(() => {
    (async () => {
      const permissions = await authProvider.getPermissions()
      setPermission(permissions)
    })()
  })

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const translate = useTranslate();
  const dispatch = useDispatch();

  return (
    <Edit
      title={''}
      {...props}
      undoable={false}
    >
      <SimpleForm
        validate={values => validateForm(values, translate)}
        redirect={() => {
          dispatch(toggleSidebar())
        }}
        toolbar={isXSmall ? <SaveToolbar /> : <EditOnlyToolbar />}
        variant="outlined"
        className={overrideForm}
      >
        <input type="text" autoFocus={false} style={{display: 'none'}}/>
        <FormDataConsumer>
          {formData => {
            return(<TenantBankingForm {...props} formData={formData} isCreate={!formData.record.iban || permission === 'ADMIN'} variant="outlined"/>)
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}

export const TenantEdit = props => {

  const [isOnboarded, setIsOnboarded] = useState(null)
  const [currentTab, setCurrentTab] = useState(0)
  const [shownMessage, setShownMessage] = useState(false)
  const dispatch = useDispatch()

  useEffectAsync(async () => {
    const tenant = getSelectedTenant();
    const tenantData = await getTenant({...tenant});
    const hasOnboarded = tenantData?.data?.iban !== null
    // @ts-ignore
    setIsOnboarded(hasOnboarded);
    if(hasOnboarded) {
      setCurrentTab(1)
    }
  }, []);

  const setNextScreen = () => {
    setCurrentTab(currentTab + 1)
  }

  if(isOnboarded === null) {
    return <h4>Loading</h4>
  }
  if(!isOnboarded && !shownMessage) {
      dispatch(showNotification('sps.labels.activation_required', 'info', {
        messageArgs: {},
        undoable: false,
      }));
      setShownMessage(true)
  }

  const displayTabs = currentTab !== 0;

  return (
    <div className={'list-page'} style={{maxWidth: '800px'}}>
      <Card>
        <Box className={boxStyle} textAlign={ currentTab === 0 ?  "center" : "left" } m={0}>
          {displayTabs && <Tabs
            value={currentTab}
            onChange={(event, value) => setCurrentTab(value)}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="off"
            variant="scrollable"
          >
            <Tab value={1} label={'Dettaglio'}/>
            <Tab value={2} label={'Pagamenti'}/>
          </Tabs> }
          <>
            <div key={0} role="tabpanel" hidden={currentTab !== 0}>
              <TenantOnboarding {...props} setNextScreen={setNextScreen}/>
            </div>
            <div key={1} role="tabpanel" hidden={currentTab !== 1}>
              <TenantEditForm {...props} setNextScreen={setNextScreen}/>
            </div>
            <div key={2} role="tabpanel" hidden={currentTab !== 2}>
              <TenantEditBanking {...props} setNextScreen={setNextScreen}/>
            </div>
          </>
        </Box>
      </Card>
    </div>
  );
};

export const TenantCreate = props => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const translate = useTranslate()

  return (
    <Create
      title={
        isXSmall ? (
          <MobileFormTitle record={null} title={"Create a tenant"} />
        ) : (
          "Create a tenant"
        )
      }
      {...props}
    >
      <SimpleForm
        validate={values => validateForm(values, translate)}
        redirect="edit"
        toolbar={isXSmall ? <SaveToolbar /> : <EditOnlyToolbar />}
        variant="outlined"
      >
        <FormDataConsumer>
            {formData => {
              return(<TenantCreateForm {...props} formData={formData} isCreate={true}/>)
            }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
