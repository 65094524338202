import {Tenants} from "../../graphql/generated-queries/tenants-queries";
import {client} from "../../graphql/client";

export async function getAllTenants() {
  try {
    const { data } = await client.query({ query: Tenants })
    return (data || {}).tenants
  } catch(err) {
    console.log(err)
  }
}