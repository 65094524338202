import React from 'react';
import Button from '@material-ui/core/Button';
import { useSetLocale } from 'react-admin';
import {setSelectedLanguage} from "../../service/store";



const LocaleSwitcher = () => {
  const setLocale = useSetLocale();

  function setLanguageGlobal(lang) {
    setLocale(lang)
    setSelectedLanguage(lang)
  }

  return (
    <div>
      <Button onClick={() => setLanguageGlobal('en')}>EN</Button>
      <Button onClick={() => setLanguageGlobal('it')}>IT</Button>
    </div>
  );
};

export default LocaleSwitcher;
