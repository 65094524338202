import React from "react";
import {
  IconButton,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery
} from "@material-ui/core";
import { Calendar, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  isSameDay,
  format,
  addDays,
  subDays
} from "date-fns";
import { css } from "emotion";
import { Formik } from "formik";
import { useTranslate } from "ra-core";
import clsx from "clsx";
import {i18nDateProvider} from "../../i18n-provider";
const popperContainerStyle = css`
  //padding: 0px 15px;
`;
const calendarContainerStyle = css`
  display: flex;
  flex-direction: row;
`;
const calendarOutputContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const calendarStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 10px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayWrapper: {
      position: "relative"
    },
    day: {
      width: 36,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: "0 2px",
      padding: "2px 2px",
      color: "inherit"
    },
    customDayHighlight: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "2px",
      right: "2px",
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: "50%"
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled
    },
    highlightNonCurrentMonthDay: {
      color: "#676767"
    },
    highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: "50%"
    },
    firstHighlight: {
      extend: "highlight",
      // borderTopLeftRadius: "50%",
      // borderBottomLeftRadius: "50%",
      borderRadius: "50%"
    },
    endHighlight: {
      extend: "highlight",
      // borderTopRightRadius: "50%",
      // borderBottomRightRadius: "50%",
      borderRadius: "50%"
    }
  })
);

const WeekWrapper = props => {
  const {
    date,
    selectedDate,
    dayInCurrentMonth,
    classes,
    currentCalendarView
  } = props;
  const weekStart = currentCalendarView.includes("week")
    ? startOfWeek(selectedDate)
    : currentCalendarView === "month"
    ? subDays(selectedDate, 0)
    : selectedDate;
  const weekEnd = currentCalendarView.includes("week")
    ? endOfWeek(selectedDate)
    : currentCalendarView === "month"
    ? addDays(selectedDate, 2)
    : selectedDate;

  const dayIsBetween = isWithinInterval(date, {
    start: weekStart,
    end: weekEnd
  });
  const isFirstDay = isSameDay(date, weekStart);
  const isLastDay = isSameDay(date, weekEnd);

  const wrapperClassName = clsx({
    [classes.highlight]: dayIsBetween,
    [classes.firstHighlight]: isFirstDay,
    [classes.endHighlight]: isLastDay
  });

  const dayClassName = clsx(classes.day, {
    [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
    [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
  });

  return (
    <div className={wrapperClassName}>
      <IconButton className={dayClassName}>
        <span> {format(date, "d")} </span>
      </IconButton>
    </div>
  );
};

interface IMessageScheduledPopper {
  isMenuOpen: HTMLButtonElement | null;
  currentCalendarView: string;
  setIsMenuOpen(state): any;
  setDate(value): any;
  day: Date;
}

export const CalendarDatePicker = (props: IMessageScheduledPopper) => {
  const {
    isMenuOpen,
    setIsMenuOpen,
    setDate,
    day,
    currentCalendarView
  } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const weeks = [1, 2, 3, 4, 5, 6];
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("xs");
  });

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      isMenuOpen &&
      event.target &&
      //@ts-ignore
      event.target.className &&
      //@ts-ignore
      typeof event.target.className != "object" &&
      //@ts-ignore
      event.target.className.includes("MuiPickers")
    ) {
      return;
    }

    setIsMenuOpen(null);
  };

  return (
    <>
      <Formik
        initialValues={{
          date: new Date(day)
        }}
        onSubmit={value => {
          const output = new Date(value.date);
          localStorage.setItem("selectedDay", output.toString());
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Popper
            transition
            disablePortal
            role={undefined}
            anchorEl={isMenuOpen}
            open={Boolean(isMenuOpen)}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                // style={{transformOrigin: 'bottom'}}
              >
                <ClickAwayListener
                  onClickAway={(event: React.MouseEvent<EventTarget>) => {
                    handleSubmit();
                    handleClose(event);
                  }}
                >
                  <Paper className={popperContainerStyle}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18nDateProvider()}>
                      <div className={calendarOutputContainer}>
                        <div className={calendarContainerStyle}>
                          <div
                            style={{
                              borderRight: isXSmall ? "none" : "1px solid #CCC"
                            }}
                            className={calendarStyle}
                          >
                            <Calendar
                              date={values.date}
                              onChange={val => {
                                setFieldValue("date", val);
                                setDate(val);
                                if (val) {
                                  localStorage.setItem(
                                    "selectedDay",
                                    val.toString()
                                  );
                                }
                              }}
                              renderDay={(
                                date,
                                selectedDate,
                                dayInCurrentMonth
                              ) => (
                                <WeekWrapper
                                  currentCalendarView={currentCalendarView}
                                  date={date}
                                  selectedDate={selectedDate}
                                  dayInCurrentMonth={dayInCurrentMonth}
                                  classes={classes}
                                />
                              )}
                            />
                          </div>
                          {!isXSmall && (
                            <div
                              className={calendarOutputContainer}
                              style={{ padding: 15 }}
                            >
                              {weeks.map(week => (
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    setDate(addDays(day, week * 7))
                                  }
                                  style={{ marginBottom: 10 }}
                                >
                                  {translate("sps.labels.inWeekNumber").replace(
                                    "NUM",
                                    week.toString()
                                  )}
                                </Button>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>
                  </Paper>
                </ClickAwayListener>
              </Grow>
            )}
          </Popper>
        )}
      </Formik>
    </>
  );
};
