//
import { client } from '../../graphql/client';
import { User } from '../../graphql/generated-queries/user-queries';

export async function getUser(input) {
  try {
    const { data } = await client.query({ query: User, variables: { id: input.id } });
    return { data: data.user };
  } catch (err) {
    console.error(err);
  }
}
