import React from 'react';
import { List, Datagrid, TextField, NumberInput,NumberField, EditButton, Edit, SimpleForm, TextInput, Create } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import {EditOnlyToolbar, SaveCancelDeleteToolbar} from './components/Toolbars';
import {
  validatePDVInternalName,
  validatePDVName,
  validatePDVPercentage,
  validatePDVShortName
} from "../service/validator";
import SimpleList from "./components/SimpleList";

export const PDVSList = (props) => {
  window.hasSuperiusAdminChanges = false

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  if(isXSmall) {
    return (
      <List {...props} perPage={25} bulkActions={false} exporter={false}>
        <SimpleList
          linkType={'edit'}
          primaryText={record => record.name}
          secondaryText={record => record.internalName}
          tertiaryText={record => record.percentage}
        />
      </List>
    )
  }

  return (
    <List {...props} sort={{ field: 'name', order: 'ASC' }}  bulkActionButtons={false} title="VAT" perPage={25}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="internalName" />
        <TextField source="shortName" />
        <NumberField source="percentage" label="Percentage (%)"/>
        <EditButton basePath="/pdv"/>
      </Datagrid>
    </List>
  );
};

const PDVTitle = ({ record }) => {
  return <span>VAT: {record ? `"${record.id}"` : ''}</span>;
};

export const PDVEdit = (props) => {
  window.hasSuperiusAdminChanges = true
  return (
    <Edit title={<PDVTitle {...props}  />} {...props}  undoable={false}>
      <SimpleForm redirect="list" toolbar={<EditOnlyToolbar />} variant="outlined">
        <TextInput source="name" validate={validatePDVName} autoFocus={true} format={v => v ? v.trimStart() : v} />
        <TextInput source="internalName" validate={validatePDVInternalName} format={v => v ? v.trimStart() : v} />
        <TextInput source="shortName" validate={validatePDVShortName} format={v => v ? v.trimStart() : v}/>
        <NumberInput source="percentage" label="Percentage (%)" validate={validatePDVPercentage}
                     format={v => {
                       if(!v) {
                         return v
                       }
                       return parseFloat(Number(v).toFixed(2));

                     }}
                     parse={v => {
                       if(!v) {
                         return v
                       }
                       return parseFloat(Number(v).toFixed(2));
                     }}
        />
      </SimpleForm>
    </Edit>
  );
}

export const PDVCreate = (props) => {
  window.hasSuperiusAdminChanges = true
  return (
    <Create title="Create a VAT" {...props} undoable={false}>
      <SimpleForm redirect="list" variant="outlined" toolbar={<SaveCancelDeleteToolbar />}>
        <TextInput source="name" validate={validatePDVName} autoFocus={true} format={v => v ? v.trimStart() : v} />
        <TextInput source="internalName" validate={validatePDVInternalName} format={v => v ? v.trimStart() : v} />
        <TextInput source="shortName" validate={validatePDVShortName} format={v => v ? v.trimStart() : v} />
        <NumberInput source="percentage" label="Percentage (%)" validate={validatePDVPercentage}
                     format={v => {
                       if(!v) {
                         return v
                       }
                       return parseFloat(Number(v).toFixed(2));

                     }}
                     parse={v => {
                       if(!v) {
                         return v
                       }
                       return parseFloat(Number(v).toFixed(2));
                     }}
        />
      </SimpleForm>
    </Create>
  );
}
