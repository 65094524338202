import React, {useState} from 'react'
import {useEffectAsync} from "../../use-effect-async";
import { Formik } from 'formik'
import {css, cx} from "emotion";
import {Button, FormControlLabel, Switch, Theme, useMediaQuery} from "@material-ui/core";
import { useTranslate } from 'ra-core'
import {getHairdresserSalon, updateHairdresserSalon} from "../../queries";
import { Link } from 'react-router-dom';

const containerStyle = css`
  display: flex;
  flex-direction: column;
`
const buttonContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  margin-left: auto;
  padding: 10px;
  padding-top: 20px;
`
const buttonStyle = css`
  width: 49%;
  padding: 5px !important;
`
const radioButtonStyles = css`
    display: flex;
    text-align: right;
`
const mobileRadioButtonStyle = css`
  flex-direction: column;
`
const mobileButtonContainerStyle = css`
  width: 100%;
  margin-left: unset;
`

export const HSWayOfWorkingForm = () => {
  const [data, setData] = useState<any | undefined>(undefined)
  const translate = useTranslate()
  const salonId = window.location.pathname.split('/')[2]
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('xs')
  });

  useEffectAsync(async ()=> {
    const result = await getHairdresserSalon({id: salonId})
    if(result && result.data) {
      const {remindOffer, warnIfOverlapping} = result.data
      setData({remindOffer: remindOffer, warnIfOverlapping: warnIfOverlapping})
    }
  }, [])

  return (
    <div className={containerStyle}>
      {data &&
        <Formik
          initialValues={{...data}}
          onSubmit={async (value) => {
            await updateHairdresserSalon({data:{...value, id: salonId}})
            window.location.href = '/hairdresserSalon'
          }}
        >
          {({values, errors, handleChange, handleSubmit, handleBlur, setFieldValue}) =>
            <>
              <div className={cx([radioButtonStyles, isXSmall && mobileRadioButtonStyle])}>
                <FormControlLabel
                  control={
                    <Switch
                      name="overlapWarn"
                      checked={values.warnIfOverlapping}
                      onChange={(e, val) => setFieldValue('warnIfOverlapping', val)}
                      onBlur={handleBlur}
                      color='primary'/>}
                  label={translate('sps.labels.overlapWarn')}
                  labelPlacement='start'
                />
                <FormControlLabel
                  control={
                    <Switch
                      name="remindOffer"
                      checked={values.remindOffer}
                      onChange={(e, val) => setFieldValue('remindOffer', val)}
                      onBlur={handleBlur}
                      color='primary'
                    />}
                  label={translate('sps.labels.reminderForNewAppointment')}
                  labelPlacement='start'
                />
              </div>
              <div className={cx([buttonContainerStyle, isXSmall && mobileButtonContainerStyle])}>
                {!isXSmall &&
                <Button
                  className={buttonStyle}
                  variant="outlined"
                  component={Link}
                  to={{pathname: "/hairdresserSalon"}}
                >
                  {translate('sps.labels.cancel')}
                </Button>}
                <Button
                  className={cx([buttonStyle, isXSmall && css`width: 100%;padding: 0px !important;`])}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit as any}
                >
                  {translate('sps.labels.save')}
                </Button>
              </div>
            </>
          }
        </Formik>}
    </div>
  )
}
