/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const Clients = gql`
  query Clients {
    clients {
      id
      personType
      fiscalCode
      vatNumber
      firstName
      lastName
      companyName
      address
      zip
      city
      province
      state
      invoiceVia
      sdi
      pec
      lotteryId
      birthday
      note
      discount
      dontSendNotifications
      dontSendBirthdayNotification
      mobilePhone
      email
      privacyDate
      gdpr
      tenant {
          id
          companyName
      }
      createdAt
      updatedAt
    }
  }
`;

export const SearchClients = gql`
  query SearchClients($expression: String, $companyName: String, $tenantId: String, $perPage: Int, $skip: Int, $order: [ClientSearchOrderInput!]) {
    searchClients(search: {companyName: $companyName, tenantId: $tenantId, expression: $expression}, order:$order,
      skip: $skip,
      take: $perPage
    ) {
      total
      items {
        id
        personType
        fiscalCode
        vatNumber
        firstName
        lastName
        companyName
        address
        zip
        city
        province
        state
        invoiceVia
        sdi
        pec
        lotteryId
        birthday
        note
        mobilePhone
        email
        discount
        dontSendNotifications
        dontSendBirthdayNotification
        privacyDate
        tenant {
          id
          companyName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchDetailedClients = gql`
  query SearchClients($expression: String, $companyName: String, $tenantId: String, $perPage: Int, $skip: Int, $order: [ClientSearchOrderInput!]) {
    searchClients(search: {companyName: $companyName, tenantId: $tenantId, expression: $expression}, order:$order,
      skip: $skip,
      take: $perPage
    ) {
      total
      items {
        id
        personType
        fiscalCode
        vatNumber
        firstName
        lastName
        companyName
        address
        zip
        city
        province
        state
        invoiceVia
        sdi
        pec
        lotteryId
        birthday
        note
        mobilePhone
        email
        discount
        dontSendNotifications
        dontSendBirthdayNotification
        privacyDate
        tenant {
          id
          companyName
        }
        createdAt
        updatedAt
        appointments {
          id
          annotation
          start
          end
          sendReminder
          warnOfFreeAppointment
          repeatEndDate
          repeat
          status
          tenant {
            id
            companyName
          }
          client {
            id
            firstName
            lastName
            mobilePhone
          }
          employee {
            id
            firstName
            lastName
            phoneNumber
            hairdresserSalon {
              id
              name
            }
          }
          service {
            id
            name
            price
          }
        }
      }
    }
  }
`;

