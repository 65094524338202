import React, {useState} from 'react'
import { Button, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import {css} from "emotion";
import { MessageCreateDialog } from "./components/MessageCreateDialog";
import {MessageDetailDialog} from "./components/MessageDetailDialog";
import {MessageIconDisplay} from "./components/MessageIconDisplay";
import {useEffectAsync} from "../use-effect-async";
import {getMessages} from "../queries";

const containerStyle = css`
  display: flex;
  flex-direction: column;
`
const headerContainerStyle = css`
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
  border-bottom: 1px solid #cdcdcd;
`
const titleContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`
const titleStyle = css`
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.6);
  font-size: 24px;
  font-weight: bold;
`
const gridStyle = css`
  padding: 15px;
`
const gridItemStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #cdcdcd;
  cursor: pointer;
`
const messageContentStyle = css`
  display: flex;
  flex-direction: column;
  padding: 15px;
  max-width: 800px;
`
const messageTitleStyle = css`
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.6);
  font-size: 24px;
`
const messageStyle = css`
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.6);
  font-size: 14px;
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
`
const timeContainerStyle = css`
  display: flex;
  flex-direction: column;
`
const timeStyle = css`
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.6);
  font-size: 20px;
`
const iconContainerStyle = css`
  display: flex;
  flex-direction: row;
  
`

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const isToday = (date: Date) => {
  const today = new Date()
  return date && date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
};

let dummyData = [] as Message[];

interface Message {
  id: string;
  title: string;
  message: string;
  sentDate: Date;
  isToday: boolean;
  recepient: string;
  sendInApp?: boolean;
  sendViaEMail?: boolean;
  sendViaSMS?: boolean;
  scheduledDate?: Date;
}

export const MessagesList = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState('')
  const [messages, setMessages] = useState(dummyData)
  const classes = useStyles()

  useEffectAsync(async () => {
    const data = await getMessages({
      pagination: {perPage: 10000}
    });
    if (data && data.data) {
      const messages = (data.data || []).map((e) => {
        return {...e, sentDate: new Date(e.sentDate)}
      })
      setMessages(messages || []);
    } else {
      setMessages([])
    }
  }, [isDialogOpen])

  //replace dummyData with real data
  const data = (messages || [])
    .sort(((a, b) => b.sentDate.getTime() - a.sentDate.getTime()))
    .map((message) => {
      return {...message, isToday: message.scheduledDate ? isToday(new Date(message.scheduledDate)) : isToday(message.sentDate)}
    })

  const showMessageDetails = (messageId) => {
    setSelectedMessage(messageId)
    setIsDetailDialogOpen(true)
  }

  const timeString = (message: Message) => {
    const time = message.scheduledDate ? new Date(message.scheduledDate) : new Date(message.sentDate)

    return message.isToday ?
      `${time.getHours()}:${time.getMinutes()}`
      : `${time.toLocaleString('default', {month: 'short'})} ${time.getDate()}`

  }

  return (
    <div className={containerStyle}>
      <div className={headerContainerStyle}>
        <div className={titleContainerStyle}>
          <span className={titleStyle}>
            Messages
          </span>
        </div>
        <Button onClick={() => setIsDialogOpen(true)} className={classes.button} startIcon={<AddIcon/>} color="primary" variant="contained">COMPOSE</Button>
      </div>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={gridStyle}
      >
        {data.map((message) =>
          <div key={message.id} onClick={() => showMessageDetails(message.id)} className={gridItemStyle}>
            <div className={messageContentStyle}>
              <span className={messageTitleStyle}>{message.title}</span>
              <span className={messageStyle}>{message.message}</span>
            </div>
            <div className={timeContainerStyle}>
              <span className={timeStyle}>
                {timeString(message)}
              </span>
              <div className={iconContainerStyle}>
              <MessageIconDisplay message={message}/>
              </div>
            </div>
          </div>
        )}
      </Grid>
      <MessageCreateDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen}/>
      {messages && selectedMessage !== '' &&
      (<MessageDetailDialog
        isOpen={isDetailDialogOpen}
        setIsOpen={setIsDetailDialogOpen}
        messageId={selectedMessage}
        message={messages.find(e => e.id === selectedMessage)}
      />)
      }
    </div>
  )
}
