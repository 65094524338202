import React, {useEffect, useState} from "react";
import {css, cx} from "emotion";
import {TextField, InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslate} from "ra-core";
import {getArticles} from "../../queries";

const containerStyle = css`
  width: 100%;
  position: relative;
  z-index: 2000000;
`;
const searchContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 15px;
`;
const closeIconStyle = css`
  cursor: pointer;
`;
const clientContainer = css`
  display: flex;
  justify-content: flex-start;
  padding: 15px;
  padding-left: 0px;
  width: 100%;
  cursor: pointer;
  border-bottom: solid 1px lightgray;
`;
const chosenClientStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  width: 100%;
  margin-bottom: 10px;
`;

const removeClientStyle = css`
  position: absolute !important;
  right: 10px !important;
  width: 20px !important;
  cursor: pointer;
`;

interface IClientSearchProps {
  setValue: any;
  service: any;
  afterSelect?: any;
  data: any
  allServices?: any
  index?: any
  errorsMap?: any
  setErrorsMap?: any
}

const PAGE_SIZE = 8;

export const ServiceSearch = ({
                                setValue,
                                service: selectedService,
                                data,
                                afterSelect,
                                allServices,
                                index,
                                errorsMap,
                                setErrorsMap,
                              }: IClientSearchProps) => {
  const translate = useTranslate();
  const [search, setSearch] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);


  useEffect(() => {
    (
      async () => {
        const results = (await getArticles({
          filter: {
            name: search,
            type: 'servizio'
          },
          pagination: {
            page: 1,
            perPage: PAGE_SIZE,
          },
          sort: {
            field: 'name',
            order: 'ASC',
          }
        }, true))

        if (!search) {
          setFilteredServices((results?.data || []).filter(e => e.type === 'servizio').slice(0, PAGE_SIZE));
        } else {
          setFilteredServices((results?.data || []).filter(e => e.type === 'servizio').slice(0, PAGE_SIZE));
        }
      }
    )()
  }, [data, search]);

  return (
    <div className={containerStyle}>
      <div className={searchContainer}>
        <TextField
          value={search}
          placeholder={translate("sps.labels.services")}
          style={{flex: 1, marginBottom: 15}}
          onChange={e => {
            return setSearch((e.currentTarget.value || ""))
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon/>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {search && (
                  <CloseIcon
                    className={closeIconStyle}
                    onClick={() => setSearch("")}
                  />
                )}
              </InputAdornment>
            )
          }}
          // error={!selectedService}
          // helperText={
          //   !selectedService
          //     ? translate("sps.labels.requiredField")
          //     : ""
          // }
        />
        {selectedService && (
          <div className={chosenClientStyle}>
            <span

            >{selectedService.name}</span>
            <CloseIcon
              className={removeClientStyle}
              onClick={() => {
                const currentService = allServices.service
                currentService[index] = null
                setValue({
                  ...allServices,
                  service: currentService,
                })
              }}
            />
          </div>
        )}
        <>
          {filteredServices.map((ser: any) => (
            <div
              className={clientContainer}
              onClick={() => {
                const currentService = allServices.service
                currentService[index] = ser
                setValue({
                  ...allServices,
                  service: currentService,
                })

                setSearch("");
                if (errorsMap) {
                  errorsMap.service = false
                  setErrorsMap({...errorsMap})
                }
                afterSelect && afterSelect(ser)
              }}
            >
                <span
                  className={cx([
                    ser &&
                    selectedService &&
                    selectedService.id === ser.id &&
                    css`
                        background-color: #ccc;
                      `
                  ])}
                >{ser.name}</span>
              <span style={{marginLeft: 'auto'}}>{parseFloat(ser.price).toFixed(2)}</span>
            </div>
          ))}
        </>

      </div>
    </div>
  );
};
