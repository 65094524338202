import React, { useState } from "react";
import { useEffectAsync } from "../../use-effect-async";
import { Formik } from "formik";
import { css, cx } from "emotion";
import {
  Button,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Switch,
  useMediaQuery
} from "@material-ui/core";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { useTranslate } from "ra-core";
import { getHairdresserSalon, updateHairdresserSalon } from "../../queries";
import { Link } from "react-router-dom";
import { CustomTimeInput } from "./CustomTimeInput";

const containerStyle = css`
  display: flex;
  flex-direction: column;
`;
const buttonContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  padding: 10px;
  padding-top: 20px;
  margin-left: auto;
`;
const buttonStyle = css`
  width: 49%;
  padding: 5px 0px !important;
`;

const addShiftStyle = css`
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  cursor: pointer;
`;

const mobileAddStyle = css`
  margin-bottom: 10px;
  margin-top: 15px;
`;
const disabledButtonStyle = css`
  color: rgba(0, 0, 0, 0.3);
  cursor: crosshair;
`;
const mobileButtonContainerStyle = css`
  width: 100%;
  margin-left: unset;
`;
const timeDisplayedStyle = css`
  color: rgba(0, 0, 0, 0.3);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 20px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    item: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  })
);

export const HSSchedule = () => {
  const [data, setData] = useState<Array<any> | undefined>(undefined);
  const translate = useTranslate();
  const classes = useStyles();
  const salonId = window.location.pathname.split("/")[2];
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("xs");
  });

  useEffectAsync(async () => {
    const result = await getHairdresserSalon({ id: salonId });
    if (result && result.data && result.data.schedule) {
      const { schedule: scheduleData } = result.data;
      setData(JSON.parse(scheduleData).schedule);
    } else {
      const defaultData = [
        {
          day: "Sunday",
          shifts: [
            {
              startTime: "2000-01-01T07:00:00.000Z",
              endTime: "2000-01-01T15:00:00.000Z",
              enabled: false
            }
          ]
        },
        {
          day: "Monday",
          shifts: [
            {
              startTime: "2000-01-01T07:00:00.000Z",
              endTime: "2000-01-01T15:00:00.000Z",
              enabled: true
            }
          ]
        },
        {
          day: "Tuesday",
          shifts: [
            {
              startTime: "2000-01-01T07:00:00.000Z",
              endTime: "2000-01-01T15:00:00.000Z",
              enabled: true
            }
          ]
        },
        {
          day: "Wednesday",
          shifts: [
            {
              startTime: "2000-01-01T07:00:00.000Z",
              endTime: "2000-01-01T15:00:00.000Z",
              enabled: true
            }
          ]
        },
        {
          day: "Thursday",
          shifts: [
            {
              startTime: "2000-01-01T07:00:00.000Z",
              endTime: "2000-01-01T15:00:00.000Z",
              enabled: true
            }
          ]
        },
        {
          day: "Friday",
          shifts: [
            {
              startTime: "2000-01-01T07:00:00.000Z",
              endTime: "2000-01-01T15:00:00.000Z",
              enabled: true
            }
          ]
        },
        {
          day: "Saturday",
          shifts: [
            {
              startTime: "2000-01-01T07:00:00.000Z",
              endTime: "2000-01-01T15:00:00.000Z",
              enabled: true
            }
          ]
        }
      ];
      setData(defaultData);
    }
  }, []);

  return (
    <div className={containerStyle}>
      <Grid container spacing={2}>
        <Grid
          xs={isXSmall ? 12 : 8}
          className={cx([timeDisplayedStyle, classes.item])}
        >
          {translate("sps.labels.timeDisplayedInCalendar")}
        </Grid>
        {!isXSmall ? null : <Grid xs={4}></Grid>}
      </Grid>
      {data && (
        <Formik
          initialValues={{ schedule: data }}
          onSubmit={async value => {
            await updateHairdresserSalon({
              data: { schedule: JSON.stringify(value), id: salonId }
            });
            window.location.href = "/hairdresserSalon";
          }}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            setFieldValue
          }) => (
            <>
              <div className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item className={classes.item} xs={isXSmall ? 2 : 3}>
                    {translate("sps.labels.day")}
                  </Grid>
                  <Grid item className={classes.item} xs={3}>
                    {translate("sps.labels.startWork")}
                  </Grid>
                  <Grid item className={classes.item} xs={isXSmall ? 4 : 3}>
                    {translate("sps.labels.endWork")}
                  </Grid>
                  <Grid item className={classes.item} xs={3} />
                  {values.schedule.map((value: any, index: number) => (
                    <>
                      <Grid item className={classes.item} xs={isXSmall ? 2 : 3}>
                        {translate(`sps.labels.${value.day.toLowerCase()}`)}
                      </Grid>
                      {(value.shifts || []).map((shift: any, shiftIndex: number) => {
                        return (
                          <>
                            {shiftIndex != 0 && (
                              <Grid item xs={isXSmall ? 2 : 3} />
                            )}
                            <Grid
                              item
                              className={classes.item}
                              xs={isXSmall ? 4 : 3}
                            >
                              <CustomTimeInput
                                hasZeros={true}
                                label={undefined}
                                setTime={time =>
                                  setFieldValue(
                                    `schedule[${index}].shifts[${shiftIndex}].startTime`,
                                    time
                                  )
                                }
                                initialTime={shift.startTime}
                                disabled={!value.shifts[0].enabled}
                                style={{}}
                              />
                            </Grid>
                            <Grid
                              item
                              className={classes.item}
                              xs={isXSmall ? 4 : 3}
                            >
                              <CustomTimeInput
                                label={undefined}
                                setTime={time =>
                                  setFieldValue(
                                    `schedule[${index}].shifts[${shiftIndex}].endTime`,
                                    time
                                  )
                                }
                                initialTime={shift.endTime}
                                disabled={!value.shifts[0].enabled}
                                style={{}}
                              />
                            </Grid>
                            {shiftIndex == 0 && (
                              <>
                                {!isXSmall && (
                                  <Grid item className={classes.item} xs={2}>
                                  <span
                                    onClick={() => {
                                      if (value.shifts[0].enabled) {
                                        const newStartTime = value.shifts[0].startTime;
                                        const newEndTime = value.shifts[0].endTime;
                                        newStartTime.setHours(1 + newEndTime.getHours());
                                        newEndTime.setHours(2 + newEndTime.getHours());
                                        setFieldValue(
                                          `schedule[${index}].shifts[${values.schedule[index].shifts.length}]`,
                                          {
                                            startTime: new Date(newStartTime),
                                            endTime: new Date(newEndTime)
                                          }
                                        );
                                      }
                                    }}
                                    className={cx([
                                      addShiftStyle,
                                      !value.shifts[0].enabled &&
                                      disabledButtonStyle
                                    ])}
                                  >
                                    {translate("sps.labels.addShift")}
                                  </span>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  className={classes.item}
                                  xs={isXSmall ? 2 : 1}
                                >
                                  <Switch
                                    key={`schedule[${index}].shifts[${shiftIndex}].enabled`}
                                    name={`schedule[${index}].shifts[${shiftIndex}].enabled`}
                                    checked={shift.enabled}
                                    onChange={(event, value) =>
                                      setFieldValue(
                                        `schedule[${index}].shifts[${shiftIndex}].enabled`,
                                        value
                                      )
                                    }
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                              </>
                            )}
                            {shiftIndex != 0 && (
                              <>
                                {!isXSmall && (
                                  <>
                                    <Grid item className={classes.item} xs={2}>
                                    <span
                                      onClick={() => {
                                        const newValue =
                                          values.schedule[index].shifts;
                                        newValue.splice(shiftIndex, 1);
                                        setFieldValue(
                                          `schedule[${index}].shifts`,
                                          newValue
                                        );
                                      }}
                                      className={addShiftStyle}
                                    >
                                      {translate("sps.labels.remove")}
                                    </span>
                                    </Grid>
                                    <Grid item xs={1} />
                                  </>
                                )}
                                {isXSmall && (
                                  <Grid item className={classes.item} xs={1}>
                                    <Button
                                      startIcon={<RemoveCircleOutlineIcon />}
                                      onClick={() => {
                                        const newValue =
                                          values.schedule[index].shifts;
                                        newValue.splice(shiftIndex, 1);
                                        setFieldValue(
                                          `schedule[${index}].shifts`,
                                          newValue
                                        );
                                      }}
                                    />
                                  </Grid>
                                )}
                              </>
                            )}
                          </>
                        )
                      })}
                      {isXSmall && (
                        <>
                          <Grid item className={classes.item} xs={3} />
                          <Grid item className={classes.item} xs={4}>
                            <span
                              onClick={() => {
                                if (value.shifts[0].enabled) {
                                  const newStartTime = value.shifts[0].startTime;
                                  const newEndTime = value.shifts[0].endTime;
                                  newStartTime.setHours(1 + newEndTime.getHours());
                                  newEndTime.setHours(2 + newEndTime.getHours());
                                  setFieldValue(
                                    `schedule[${index}].shifts[${values.schedule[index].shifts.length}]`,
                                    {
                                      startTime: new Date(newStartTime),
                                      endTime: new Date(newEndTime)
                                    }
                                  );
                                }
                              }}
                              className={cx([
                                addShiftStyle,
                                mobileAddStyle,
                                !value.shifts[0].enabled && disabledButtonStyle
                              ])}
                            >
                              {translate("sps.labels.addShift")}
                            </span>
                          </Grid>
                          <Grid item className={classes.item} xs={5} />
                        </>
                      )}
                    </>
                  ))}
                </Grid>
              </div>
              <div
                className={cx([
                  buttonContainerStyle,
                  isXSmall && mobileButtonContainerStyle
                ])}
              >
                {!isXSmall && (
                  <Button
                    className={buttonStyle}
                    variant="outlined"
                    component={Link}
                    to={{ pathname: "/hairdresserSalon" }}
                  >
                    {translate("sps.labels.cancel")}
                  </Button>
                )}
                <Button
                  className={cx([
                    buttonStyle,
                    isXSmall &&
                      css`
                        width: 100%;
                        margin-right: 20px !important;
                        padding: 0px !important;
                      `
                  ])}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit as any}
                >
                  {translate("sps.labels.save")}
                </Button>
              </div>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};
