import { client } from "../../graphql/client";
import { UpdateDevice } from "../../graphql/generated-queries/update-device-queries";

export async function updateDevice({ data }) {
  const {
    tenant,
    cheader,
    __typename,
    createdAt,
    updatedAt,
    ...inputData
  } = data;

  const tenantId = tenant ? tenant.id : null;

  if (cheader) {
    for (const notificationType of ["customHeader"]) {
      inputData[`${notificationType}`] = cheader.includes(notificationType);
    }
  }

  const result = await client.mutate({
    mutation: UpdateDevice,
    variables: {
      input: {
        ...inputData,
        tenant: {
          id: tenantId
        }
      }
    }
  });
  return { data: result.data.updateDevice };
}
