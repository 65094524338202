import React, {useEffect, useState} from 'react'
import {Button} from "@material-ui/core";
import {css} from "emotion";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ScheduleIcon from '@material-ui/icons/Schedule';

const fieldStyle = css`
  min-width: 40% !important;
`
const inputStyle = css`
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px !important;
  }
`
const buttonStyle = css`
  min-width: 0px !important;
  margin-right: 10px !important;
`

interface ICustomTimeInput {
  label: string | undefined
  initialTime?: Date
  style?:  React.CSSProperties
  setTime(date: Date): any
  disabled?: boolean
  hasZeros?: boolean
}

export const CustomTimeInput = ({setTime, initialTime, label, style, disabled, hasZeros}: ICustomTimeInput) => {
  const [hour, setHour] = useState(initialTime ? new Date(initialTime).getHours() : 8)
  const [minute, setMinute] = useState(initialTime ? new Date(initialTime).getMinutes() : 0)
  const [selected, setSelected] = useState(0)

  useEffect(() => {
    const time = new Date(`${new Date().toDateString()} ${formatTime(hour.toString())}:${formatTime(minute.toString())}`)
    setTime(time)
  }, [])

  const formatTime = (time: string) => {
    return time.length === 1 ? `0${time}` : time
  }

  function leftPadZero(num) {
    return ('0' + num).slice(-2)
  }

  function generateSelect(hasZeros = true) {
    let data = [] as any;
    for (let i = 0; i < 24; i += 1) {
      for (let j = 0; j < 60; j += 5) {
        if(!hasZeros && i === 0 && j === 0) {
          continue;
        }
        data.push((<option key={leftPadZero(i) + leftPadZero(j)} value={leftPadZero(i) + ':' + leftPadZero(j)}>{leftPadZero(i)}:{leftPadZero(j)} </option>))
      }
    }
    return data
  }

  return (
    <>
      <FormControl variant="outlined" className={fieldStyle} style={style}>
        <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
        <Select
          className={inputStyle}
          native
          disabled={disabled}
          value={formatTime(hour.toString()) + ':' + formatTime(minute.toString())}
          onChange={(event) => {
            if (selected !== 1) {
              setSelected(1)
            }
            const newTime = event.target.value as any
            const parts = newTime.split(':');
            setHour(parseInt(parts[0]))
            setMinute(parseInt(parts[1]))
            const time = new Date(`${new Date().toDateString()} ${formatTime(parts[0])}:${formatTime(parts[1])}`)
            setTime(time)
          }}
          label={label}
          inputProps={{
            endAdornment: (
              <Button className={buttonStyle} disabled={disabled}>
                <ScheduleIcon width={12} height={12}/>
              </Button>
            )
          }}
        >
          {generateSelect(hasZeros)}
        </Select>
      </FormControl>

    </>
  )
}
