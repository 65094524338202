/* tslint:disable:max-line-length */
import gql from "graphql-tag";

export const Device = gql`
  query Device($id: EntityId!) {
    device(id: $id) {
      id
      rtCode
      serialNumber
      friendlyName
      status
      address
      zip
      city
      province
      customHeader
      printHeader
      printFooter
      tenant {
        id
        companyName
      }
    }
  }
`;
