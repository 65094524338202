import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { HideOnScroll } from 'react-admin';
import classNames from 'classnames';
import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { toggleSidebar, useTranslate } from 'ra-core';

const useStyles = makeStyles(
  theme => ({
    toolbar: {
      paddingRight: 4,
      minHeight: 56,
    },
    menuButton: {
      marginLeft: '4px',
      marginRight: '4px',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
  { name: 'RaAppBar' }
);

/**
 * The AppBar component renders a custom MuiAppBar.
 *
 * @param {ReactNode} children React node/s to be render as children of the AppBar
 * @param {Object} classes CSS class names
 * @param {string} className CSS class applied to the MuiAppBar component
 * @param {string} color The color of the AppBar
 * @param {Component} logout The logout button component that will be pass to the UserMenu component
 * @param {boolean} open State of the <Admin/> Sidebar
 * @param {Element} userMenu A custom user menu component for the AppBar. <UserMenu/> component by default
 *
 * @example
 *
 * const MyAppBar = props => {
 *   const classes = useStyles();
 *   return (
 *       <AppBar {...props}>
 *           <Typography
 *               variant="h6"
 *               color="inherit"
 *               className={classes.title}
 *               id="react-admin-title"
 *           />
 *        </AppBar>
 *    );
 *};
 */
const AppBar = props => {
  const {
    children,
    classes: classesOverride,
    className,
    color = 'secondary',
    logo,
    logout,
    open,
    title,
    userMenu,
    ...rest
  } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  // @ts-ignore
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const translate = useTranslate();

  return (
    <HideOnScroll>
      <MuiAppBar className={className} color={color} {...rest}>
        <Toolbar
          disableGutters
          variant={'regular'}
          className={classes.toolbar}
        >
          {(isXSmall) &&
            <Tooltip
                title={translate(
                    open
                        ? 'ra.action.close_menu'
                        : 'ra.action.open_menu',
                    {
                      _: 'Open/Close menu',
                    }
                )}
                enterDelay={500}
            >
              <IconButton
                  color="inherit"
                  onClick={() => {
                    if (isXSmall) {
                      dispatch(toggleSidebar())
                    } else {
                      console.log('Opening android menu') //TODO: handle opening of android app if available
                    }
                  }}
                  className={classNames(classes.menuButton)}
              >
                {!isXSmall && <MenuIcon
                    classes={{
                      root: open
                          ? classes.menuButtonIconOpen
                          : classes.menuButtonIconClosed,
                    }}
                />}
                {isXSmall && <ArrowBackIosIcon
                    classes={{
                      root: open
                          ? classes.menuButtonIconOpen
                          : classes.menuButtonIconClosed,
                    }}
                />}
              </IconButton>
            </Tooltip>}
          {Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
          ) : (
            children
          )}
          {cloneElement(userMenu, { logout })}
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  );
};

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
    'transparent',
  ]),
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.element,
};

export default AppBar;
