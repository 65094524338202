import React from 'react'
import {css} from "emotion";
import {useTranslate} from 'ra-core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';

const containerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px
`
const calendarIconStyle = css`
  width: 350px !important;
  height: 350px !important;
  margin-bottom: 20px;
  color: rgba(0,0,0,0.3)
`
const toUseCalendarStyle = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 40px;
`

interface IEmptyCalendarView {
  salonId: string
}

export const EmptyCalendarView = ({salonId}: IEmptyCalendarView) => {
  const translate = useTranslate()

  return  (
    <div className={containerStyle}>
      <CalendarTodayIcon className={calendarIconStyle}/>
      <span className={toUseCalendarStyle}>{translate('sps.labels.toUseCalendar')}</span>
      <Button
        variant={'contained'}
        startIcon={<AddIcon/>}
        color={'primary'}
        onClick={() => window.location.href= salonId ? `employee/create/${salonId}` : 'hairdresserSalon/create'}
      >
        {translate('sps.labels.create')}
      </Button>
    </div>
  )
}