import React from 'react';
import { Edit, TextInput, SimpleForm, ListButton, SaveButton, Toolbar } from 'react-admin';
import PhoneNumberField from './components/PhoneNumberField';
import { toolbarStyles} from "./components/Toolbars";
import { withStyles } from '@material-ui/core';

export const ProfileToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props} className={'form-toolbar'}>
    <div id='other-buttons'>
      <ListButton variant="outlined" basePath={'/'} label={'sps.labels.cancel'}>Cancella</ListButton>
    </div>
    <SaveButton />
  </Toolbar>
))

export const ProfileEdit = ({ ...props }) => {
  const userId = localStorage.getItem('userId');

  return (
      <Edit
        id={userId}
        resource="user"
        basePath="/my-profile"
        redirect={false}
        title="My profile"
        undoable={false}
        {...props}
      >
        <SimpleForm toolbar={<ProfileToolbar />} variant="outlined">
          <TextInput source="username" disabled />
          <PhoneNumberField source="phoneNumber" label={'Phone Number'} autoFocus={true} />
        </SimpleForm>
      </Edit>
  );
};

