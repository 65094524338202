import { client } from '../../graphql/client';
import { SearchUsers } from '../../graphql/generated-queries/users-queries';
import { getSelectedTenant } from "../../service/store";

export async function getUsers(input) {

  const variables = {
    ...input.filter,
    perPage: input.pagination.perPage,
    skip: (input.pagination.page - 1) * input.pagination.perPage,
    order: [
      {[input.sort.field]: input.sort.order}
      ]
   }

  const tenant = getSelectedTenant();
  const tenantId = tenant ? tenant.tenantId : null;

  try {
    const { data } = await client.query({ query: SearchUsers, variables: {...variables, tenantId } } );
    return { data: data.searchUsers.items, total: data.searchUsers.total };
  } catch (err) {
    console.error(err);
  }
}
