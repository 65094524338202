import React from 'react';
import {useQuery, useTranslate} from 'react-admin';
import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab'
import '../styles/time-picker.css';
import { getListCurrentTenant } from "../repository";

interface IEmployeePickerProps {
  setService(value): any;

  employee: any;
  index: any
  allServices: any
  errorsMap: any

  setErrorsMap(value): any
}

export const EmployeePicker = ({setService, employee, index, allServices, errorsMap, setErrorsMap}: IEmployeePickerProps) => {

  const {data: employeeData, loading: employeeLoading, error: employeeError} = useQuery(getListCurrentTenant('employee'));
  const translate = useTranslate();

  if (employeeLoading) {
    return (
      <div>
        <Autocomplete
          loading={true}
          renderInput={(params) =>
            <TextField
              {...params}
              variant='outlined'
              label={translate('sps.labels.selectEmployee') + ' *'}
            />
          }
          options={[]}
        />
      </div>
    )
  }

  if (employeeError) {
    throw new Error('Error getting the data');
  }

  return (
      <Autocomplete
        defaultValue={employee}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant='outlined'
              label={translate('sps.labels.selectEmployee') + ' *'}
              error={errorsMap.employee}
              helperText={errorsMap.employee ? translate('sps.labels.requiredField') : ''}
            />
          )
        }}
        options={employeeData}
        getOptionLabel={(option:any) => option.firstName+' '+option.lastName}
        onChange={(event, value) => {
          const currentEmployee = allServices.employee
          currentEmployee[index] = value
          setService({
            ...allServices,
            employee: currentEmployee,
          })
          if (errorsMap) {
            errorsMap.employee = !value
            setErrorsMap({...errorsMap})
          }
        }}
      />
  );
}
