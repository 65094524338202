//
import { client } from '../../graphql/client';
import { Device } from '../../graphql/generated-queries/device-queries';

export async function getDevice(input) {
  try {
    const { data } = await client.query({ query: Device, variables: { id: input.id } });
    return { data: data.device };
  } catch (err) {
    console.error(err);
  }
}
