import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';

// @ts-ignore
export default createMuiTheme({
    typography: {
        fontFamily: [
            '"Roboto", sans-serif',
        ].join(',')
    },
    palette: {
        primary: {
            light: "#C55AC0",
            main: "#702588",
            dark: "#610061",
            contrastText: "#fff",
        },
        secondary: {
            light: "#C55AC0",
            main: "#702588",
            dark: "#610061",
            contrastText: "#fff",
        },
        text: indigo,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    overrides: {
        MuiButton: {
            root: {
                height: '36px'
            },
            textPrimary: {
                color: '#000000',
                backgroundColor: 'transparent'
            }
        }
    },

});
