//
import { client } from '../../graphql/client';
import { Client, DetailedClient } from '../../graphql/generated-queries/client-queries';

export async function getClient(input, isDetailed = false) {
  try {
    const { data: { client: userData } } = await client.query({ query: isDetailed ? DetailedClient : Client, variables: { id: input.id } });
    userData['category'] = []
    for (const notificationType of ["dontSendNotifications", "dontSendBirthdayNotification"]){
      if(userData[`${notificationType}`]) {
        userData['category'].push(notificationType)
      }
    }

    return { data: userData };
  } catch (err) {
    console.error(err);
  }
}
