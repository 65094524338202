/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const Appointment = gql`
  query Appointment($id: EntityId!) {
    appointment(id: $id) {
      id
      annotation
      start
      end
      sendReminder
      warnOfFreeAppointment
      repeatEndDate
      repeat
      status
      tenant {
        id
        companyName
      }
      client {
        id
        firstName
        lastName
        mobilePhone
      }
      employee {
        id
        firstName
        lastName
        phoneNumber
        hairdresserSalon {
          id
          name
        }
      }
      service {
        id
        name
        price
      }
    }
  }
  
`;
