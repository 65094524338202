/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const Tenants = gql`
  query Tenants {
    tenants {
      id
      tenantId
      vatNumber
      isVentilazioneIVA
      status
      companyName
      fiscalCode
      vatId
      firstName
      ateco
      hasHairAndBeauty
      lastName
      address
      zip
      city
      province
      state
      reaCode
      offerValidity
      successiveReceipt
      useQuotes
      sendAutomaticMonthly
      secretSharedKey
      offerValidity
      createdAt
      updatedAt
      currency
      bic
      iban
      bankAccountName
    }
  }
`;


export const SearchTenants = gql`
  query SearchTenants($expression: String, $perPage: Int, $skip: Int, $order: [TenantSearchOrderInput!]) {
    searchTenants(search: {expression: $expression }, order:$order, skip: $skip, take: $perPage ) {
      total
      items {
        id
        tenantId
        vatNumber
        isVentilazioneIVA
        status
        companyName
        fiscalCode
        vatId
        firstName
        ateco
        lastName
        hasHairAndBeauty
        address
        zip
        city
        province
        state
        reaCode
        successiveReceipt
        useQuotes
        sendAutomaticMonthly
        secretSharedKey
        offerValidity
        currency
        createdAt
        updatedAt
        bic
        iban
        bankAccountName
      }
    }
  }
`;
