//
import { client } from '../../graphql/client';
import { DeleteDevices } from '../../graphql/generated-queries/delete-devices-queries';

export async function removeDevice(input) {
  try {
    return await client.mutate({ mutation: DeleteDevices, variables: { ids: [input.id] } });
  } catch (err) {
    console.error(err);
  }
}
