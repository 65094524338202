/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const ResetPassword = gql`
  mutation ResetPassword($username: String!, $resetCode: String!, $password: String!) {
    resetPassword(username: $username, resetCode: $resetCode, password: $password) {
      status
      message
    }
  }
`;
