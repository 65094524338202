/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const ForgotPassword = gql`
  mutation ForgotPassword($username: String!) {
    forgotPassword(username: $username) {
      status
    }
  }
`;
