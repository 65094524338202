//
import { client } from '../../graphql/client';
import { Message } from '../../graphql/generated-queries/message-queries';

export async function getMessage(input) {
  try {
    const { data } = await client.query({ query: Message, variables: { id: input.id } });
    return { data: data.message };
  } catch (err) {
    console.error(err);
  }
}
