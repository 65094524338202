import React from 'react'
import {css} from "emotion";
import TextsmsIcon from '@material-ui/icons/Textsms';
import EmailIcon from '@material-ui/icons/Email';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';

const containerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  color: rgba(0,0,0,0.6);
`
const iconStyle = css`
  margin-right: 7px;
  height: 20px !important;
  width: 20px !important;
`

interface IMessageIconDisplayProps {
  message: any
}

export const MessageIconDisplay = (props: IMessageIconDisplayProps) => {
  const { message: { sendInApp, sendViaEMail, sendViaSMS } } = props

  return (
    <>
      <div className={containerStyle}>
        {sendInApp &&
          <MobileScreenShareIcon className={iconStyle}/>
        }
        {sendViaEMail &&
        <EmailIcon className={iconStyle}/>
        }
        {sendViaSMS &&
        <TextsmsIcon className={iconStyle}/>
        }
      </div>
    </>
  )
}