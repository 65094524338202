// @ts-nocheck
import React, {useState} from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
} from 'react-admin';
import { useTranslate } from 'ra-core'
import {useMediaQuery, Theme, Tabs, Tab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  validateFirstName,
} from '../service/validator';
import SimpleList from "./components/SimpleList";
import {SaveCancelDeleteToolbar, SaveCancelToolbar, SaveDeleteToolbar, SaveToolbar} from "./components/Toolbars";
import {HSWayOfWorkingForm} from "./components/HSWayOfWorkingForm";
import {HSMessages} from "./components/HSMessages";
import {HSEmployees} from "./components/HSEmployees";
import {css, cx, injectGlobal} from "emotion";
import {HSSchedule} from "./components/HSSchedule";
import {ListActions} from "./components/ActionBar";
import {EmptyItemView} from "./components/EmptyItemView";
import {UserGlobalNotification} from "./components/UserGlobalNotification";

const listItemStyle = css`
  padding: 20px !important; 
  .MuiButtonBase-root { 
    border-bottom: 1px solid rgba(0,0,0,0.3); 
    padding-left: 0px;
    }
`
const listContainerStyle = css`
  .list-page{
    box-shadow: none !important;
   }
`
const containerStyle = css`
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-around !important;
  padding: 40px 150px !important;
  background-color: #FAFAFA !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  background-color: #FAFAFA !important;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
`
const formContainerStyle = css`
  display: flex !important;
  flex-direction: column !important;
  width: 90%;
  justify-content: space-around !important;
  background-color: white !important;
  [class*="RaEdit-main"] {
    margin-top: 0px;
  }
  .simple-form {
    max-width: none !important;
    border-radius: 0px;
    border: none !important;
    border-top: 1px solid rgba(0,0,0,0.12) !important;
  }
`
const mobileFormContainerStyle = css`
  .simple-form {
    padding: 5px !important;
    max-width: 720px !important;
  }
`
const titleStyle = css`
  display: flex;
  padding: 20px 40px;
  color: rgba(0,0,0,0.87);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 24px;
  border-bottom: 1px solid #E0E0E0;
  margin-bottom: 24px;
`
const tabsStyle = css`
  .edit-page {
    div {
      margin-top: 0px !important;
    }
  }
`
const formStyle = css`
  max-width: none !important;
`
const inputRowStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const mobileInputRowStyle = css`
  flex-direction: column;
`
const mobileAppTitleStyle = css`
  position: fixed;
  background-color: white;
  color: black;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #CCC;
`
const mobileCloseStyle = css`
  position: absolute;
  right: 20px;
`
injectGlobal`
  body {
    max-width: 100%;
    overflow-x: hidden;
  }
  .MuiToolbar-root {
    position: relative !important;
  }
  .MuiTab-root {
    padding: 6px 5px !important;
    font-size: 1rem !important;
  }
`

export const HairdresserSalonList = (props) => {
  window.hasSuperiusAdminChanges = false
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const translate = useTranslate()

  if (isXSmall) {
    return (
      <div className={listContainerStyle}>
        <UserGlobalNotification />
        <List
          {...props}
          bulkActions={false}
          exporter={false}
          sort={{ field: 'name', order: 'ASC' }}
          pagination={null}
          empty={<EmptyItemView {...props} title={translate('sps.labels.emptySalon')}/>}
        >
          <SimpleList
            linkType={'edit'}
            primaryText={record => record.name}
            secondaryText={record => ((record.address || '') + (record.city ? ', ' + (record.city || '') : ''))}
            className={listItemStyle}
          />
        </List>
      </div>
    )
  }

  return (
    <>
      <UserGlobalNotification/>
      <List
        {...props}
        sort={{field: 'name', order: 'ASC'}}
        bulkActionButtons={false}
        perPage={25}
        actions={<ListActions {...props} />}
        empty={<EmptyItemView {...props} title={translate('sps.labels.emptySalon')}/>}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" label={'sps.labels.name'}/>
          <TextField source="address" label={'sps.labels.address'}/>
          <TextField source="city" label={'sps.labels.city'}/>
          <EditButton basePath="/hairdresserSalon"/>
        </Datagrid>
      </List>
    </>
  );
};

const HairdresserSalonTitle = ({record, setSalonTitle}) => {
  setSalonTitle(record.name)
  if (!record || !record.tenant) {
    return <span>Create a Salon </span>
  }
  return <span>Salone di {record ? `"${record.tenant.companyName}"` : ''}</span>;
};
const MobileHairdresserSalonTitle = ({record, title}) => {

  return (
    <div className={mobileAppTitleStyle}>
      {(record && record.name) ? record.name : title}
      <CloseIcon
        className={mobileCloseStyle}
        onClick={() => window.location.href='/hairdresserSalon'}
      />
    </div>
  )
}

const HairdresserSalonDetailsForm = () => {
  const translate = useTranslate()

  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('xs')
  });

  return (
    <>
      <TextInput variant="outlined" source="name" label={translate('sps.labels.salonName')} validate={validateFirstName} format={v => v ? v.trimStart() : v}/>
      <div className={cx([inputRowStyle, isXSmall && mobileInputRowStyle])}>
        <TextInput style={{marginRight: isXSmall ? 0 : 15}} variant="outlined" source="address" label={translate('sps.labels.address')} format={v => v ? v.trimStart() : v}/>
        <TextInput variant="outlined" source="city" label={translate('sps.labels.city')} format={v => v ? v.trimStart() : v}/>
      </div>
      <div className={cx([inputRowStyle, isXSmall && mobileInputRowStyle])}>
        <TextInput style={{marginRight: isXSmall ? 0 : 15}} variant="outlined" source="postalCode" label={translate('sps.labels.postal_code')} format={v => v ? v.trimStart() : v}/>
        <TextInput variant="outlined" source="province" label={translate('sps.labels.province')} format={v => v ? v.trimStart() : v}/>
      </div>
    </>
  )
}


const HairdresserSalonPage = (props) => {
  const urlParams = new URLSearchParams(window.location.search)
  const [currentTab, setCurrentTab] = useState(urlParams.has('window') ? Number(urlParams.get('window')) : 0)
  const [salonTitle, setSalonTitle] = useState('')
  const translate = useTranslate();

  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('xs')
  });

  let isCreate = props.isCreate || false
  const tabs = [HSWayOfWorkingForm, HSMessages, HSEmployees, HSSchedule]

  const newElement= document.getElementsByClassName('jss4')
  if(newElement.length) {
    newElement[0].style.padding = 0
  }

  const myRedirect = (basePath, salonId) => {
    return `${basePath}/${salonId}?window=3` // redirect to employees
  }

  return (
    <div className={cx([!isXSmall && containerStyle])}>
      <div className={cx([formContainerStyle, isXSmall && mobileFormContainerStyle])}>
        {(!isXSmall && !isCreate) && <div className={titleStyle}>{salonTitle}</div>}
        <Tabs
          value={currentTab}
          onChange={(event, value) => setCurrentTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="off"
          variant="scrollable"
          className={cx([tabsStyle, isXSmall && css`padding-top: 20px;`])}
        >
          <Tab value={0} label={translate('sps.labels.address')}></Tab>
          {!isCreate && ( <Tab value={1} label={translate('sps.labels.wayOfWorking')}></Tab>)}
          {!isCreate && ( <Tab value={2} label={translate('sps.labels.messages')}></Tab>)}
          {!isCreate && ( <Tab value={3} label={translate('sps.labels.employees')}></Tab>)}
          {!isCreate && ( <Tab value={4} label={translate('sps.labels.schedule')}></Tab>)}
        </Tabs>
        <div role="tabpanel" hidden={currentTab !== 0}>
          {isCreate && (
            <Create title={isXSmall ? <MobileHairdresserSalonTitle {...props} title={"Create a salon"}/> : <HairdresserSalonTitle setSalonTitle={setSalonTitle} {...props} />} {...props} undoable={false}>
              <SimpleForm className={formStyle} redirect={myRedirect} variant="outlined" toolbar={isXSmall ? <SaveToolbar/> : <SaveCancelToolbar style={{width: '50%', marginLeft: 'auto'}} />}>
                <HairdresserSalonDetailsForm/>
              </SimpleForm>
            </Create>)
          }
          {!isCreate && (
            <Edit title={isXSmall ? <MobileHairdresserSalonTitle {...props}/> :  <HairdresserSalonTitle setSalonTitle={setSalonTitle} {...props} />} {...props} undoable={false}>
              <SimpleForm className={formStyle} redirect="list" variant="outlined" toolbar={isXSmall ? <SaveDeleteToolbar style={{display: 'flex'}}/> : <SaveCancelDeleteToolbar/>}>
                <HairdresserSalonDetailsForm/>
              </SimpleForm>
            </Edit>)
          }
        </div>
        {!isCreate && (
          <>
            {tabs.map((Tab, index) => {
              return (
                <div role="tabpanel" hidden={currentTab !== index+1}>
                  <Edit title={<div/>} {...props} undoable={false}>
                    <SimpleForm className={formStyle} redirect="list" variant="outlined" toolbar={<div/>}>
                      <Tab/>
                    </SimpleForm>
                  </Edit>
                </div>
              )
            })}
          </>
        )
        }
      </div>
    </div>
  )
}

export const HairdresserSalonEdit = (props) => {
  window.hasSuperiusAdminChanges = true
  return <HairdresserSalonPage isCreate={false} {...props} />
}

export const HairdresserSalonCreate = (props) => {
  window.hasSuperiusAdminChanges = true
  return <HairdresserSalonPage isCreate={true} {...props} />
}
