export function getSelectedTenant() {
  const data = localStorage.getItem("selectedTenantData");
  if (!data) {
    return null;
  }

  return JSON.parse(data);
}

export function setSelectedTenant(resource) {
  if (!resource) {
    return removeSelectedTenant();
  }
  localStorage.setItem("selectedTenantId", resource.id);
  localStorage.setItem("selectedTenantData", JSON.stringify(resource));
  console.log(resource);

  return resource;
}

export function removeSelectedTenant() {
  localStorage.removeItem("selectedTenantId");
  localStorage.removeItem("selectedTenantData");
}

export function getSelectedLanguage() {
  const lang = localStorage.getItem("preferred-language");
  if (!lang) {
    return null;
  }

  return lang;
}

export function setSelectedLanguage(lang) {
  if (!lang) {
    return removeSelectedTenant();
  }
  localStorage.setItem("preferred-language", lang);

  return lang;
}

export function removeSelectedLanguage() {
  localStorage.removeItem("preferred-language");
}
