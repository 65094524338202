import React from 'react';
import { useInput } from 'ra-core';
import MuiPhoneNumber from 'material-ui-phone-number'
import { useTranslate } from 'ra-core';

export const PhoneNumberField = ({label, format, helperText, onBlur, onFocus, onChange, options, parse, resource, source, validate, ...rest }: any) => {

  const { input, meta: { error, touched }, } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  });

  const translate = useTranslate();

  return (
    <>
        <MuiPhoneNumber
        defaultCountry={'it'}
        preferredCountries={'it'}
        onChange={input.onChange}
        disableAreaCodes={true}
        value={rest.record ? rest.record.phoneNumber || rest.record.mobilePhone || '' : '' }
        isValid={() => !(touched && error)}
        label={translate('ra.auth.phone_number')}
        variant="outlined"
        style={{
          width: '256px',
          marginTop: '8px',
          marginBottom: '4px',
        }}
      />
        {(touched && error) &&
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
             id="phone-number-helper-text">{error.message ? translate(error.message) : translate(error)}</p>
        }
    </>
  )
};

export default PhoneNumberField;
