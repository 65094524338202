import { client } from '../../graphql/client';
import { UpdateArticle } from '../../graphql/generated-queries/update-article-queries';

export async function updateArticle({ data }) {
  const {
    id,
    name,
    price,
    barCode,
    type,
    order,
    group,
    pdv,
  } = data;

  const result = await client.mutate({
    mutation: UpdateArticle,
    variables: {
      input: {
        id,
        name,
        price,
        barCode,
        actionPrice: data.actionPrice === '' ? null : data.actionPrice,
        discountPercentage: data.discountPercentage === '' ? null : data.discountPercentage,
        discountAmount: data.discountAmount === '' ? null : data.discountAmount,
        type,
        order,
        group: {
          id: group.id
        },
        pdv: {
          id: pdv.id
        },
      }
    }
  });
  return { data: result.data.updateArticle };
}
