/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const CreateHairdresserSalon = gql`
  mutation CreateHairdresserSalon($input: HairdresserSalonCreateInput!) {
    createHairdresserSalon(input: $input) {
      id
      name
      address
      city
      postalCode
      province
      order
      warnIfOverlapping
      remindOffer
      phoneNumber
      signOnMessage
      activateAppointmentReminder
      personalizedMessage
      birthdayMessages
      schedule
      employeeOrder
      tenant {
        id
        companyName
        vatNumber
        
      }
    }
  }
`;
