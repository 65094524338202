//
import { client } from '../../graphql/client';
import { DeleteEmployees } from '../../graphql/generated-queries/delete-employees-queries';

export async function removeEmployee(input) {
  try {
    return await client.mutate({ mutation: DeleteEmployees, variables: { ids: [input.id] } });
  } catch (err) {
    console.error(err);
  }
}
