/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const CreateAppointment = gql`
  mutation CreateAppointment($input: AppointmentCreateInput!) {
    createAppointment(input: $input) {
      annotation
      start
      end
      sendReminder
      warnOfFreeAppointment
      repeatEndDate
      repeat
      status
      tenant {
        id
        companyName
      }
      client {
        id
        firstName
        lastName
      }
      employee {
        id
        firstName
        lastName
        phoneNumber
        hairdresserSalon {
          id
          name
        }
      }
      service {
        id
        name
      }
    }
  }
`;
