import { client } from "../../graphql/client";
import { CreateDevice } from "../../graphql/generated-queries/create-device-queries";
import { getSelectedTenant } from "../../service/store";

export async function createDevice(input) {
  const tenant = getSelectedTenant();
  const tenantId = tenant ? tenant.id : null;
  if (tenantId) {
    input.data.tenant = {
      id: tenantId
    };
  }

  const {
    data: { cheader, ...inputData }
  } = input;

  if (cheader) {
    for (const notificationType of ["customHeader"]) {
      inputData[`${notificationType}`] = cheader.includes(notificationType);
    }
  }

  try {
    const { data } = await client.mutate({
      mutation: CreateDevice,
      variables: { input: { ...inputData } }
    });
    return { data: { ...data.createDevice } };
  } catch (err) {
    console.error(err);
  }
}
