import { client } from '../../graphql/client';
import { CreatePDV } from '../../graphql/generated-queries/create-pdv-queries';

export async function createPdv(input) {
  try {
    const { data: { percentage, internalName = undefined, name = undefined, shortName = undefined } } = input;
    const { data } = await client.mutate({
      mutation: CreatePDV,
      variables: { input: { percentage: parseFloat(percentage), internalName, name, shortName } }
    });
    return  { data: {...data.createPDV} };
  } catch (err) {
    console.error(JSON.stringify(err));
    throw new Error(err.graphQLErrors?.[0]?.message || err)
  }
}
