// @ts-nocheck
import React, { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import inflection from "inflection";
import { makeStyles, useMediaQuery, Theme } from "@material-ui/core";
import DefaultIcon from "@material-ui/icons/ViewList";
import classnames from "classnames";
import {
  getResources,
  useTranslate,
  Translate,
  ReduxState,
  toggleSidebar
} from "ra-core";
import { MenuItemLink, DashboardMenuItem } from "react-admin";
import { getSelectedTenant } from "../../service/store";
import authProvider from "../../authProvider";
import { useEffectAsync } from "../../use-effect-async";
import { Divider } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import QuickEntryIcon from "@material-ui/icons/PlaylistAdd";
import TuneIcon from "@material-ui/icons/Tune";
import DevicesIcon from "@material-ui/icons/Devices";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import TocIcon from "@material-ui/icons/Toc";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from '@material-ui/icons/Receipt';

const useStyles = makeStyles(
  {
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "100vh",
      backgroundColor: "white"
    },
    button: {
      marginBottom: 24
    }
  },
  { name: "RaMenu" }
);

const translatedResourceName = (resource: any, translate: Translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label
          })
        : inflection.humanize(inflection.pluralize(resource.name))
  });

const Menu = ({
  classes: classesOverride,
  className,
  dense,
  hasDashboard,
  onMenuClick,
  logout,
  ...rest
}) => {
  const [permissions, setPermissions] = useState("USER");

  useEffectAsync(async () => {
    const perm = await authProvider.getPermissions();
    setPermissions(perm);
  }, []);

  const translate = useTranslate();
  const classes = useStyles({ classes: classesOverride });
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual);
  const selectedTenant = getSelectedTenant();

  let filteredResources = resources.filter(r => r.hasList);

  if (selectedTenant && selectedTenant.id) {
    let userIndex = filteredResources.findIndex(e => e.name === "user");
    if (userIndex >= 0) {
      filteredResources.splice(userIndex + 1, 0, "divider");
    }
    let articleIndex = filteredResources.findIndex(e => e.name === "article");
    if (articleIndex >= 0) {
      if (!isXSmall) {
        filteredResources.splice(articleIndex + 1, 0, {
          name: "article-quick",
          primaryText: translate("sps.labels.article_quick_entry"),
          fullIcon: <QuickEntryIcon />
        });
      }
      filteredResources.splice(articleIndex + 2, 0, "divider");
    }
    let employeeIndex = filteredResources.findIndex(e => e.name === "employee");
    if (employeeIndex >= 0) {
      filteredResources.splice(employeeIndex + 1, 0, "divider");
    }
  }

  const itemIcons = {
    device: DevicesIcon,
    user: PeopleOutlineIcon,
    article: TocIcon,
    "article-quick": PlaylistAddIcon,
    client: PersonIcon
  };

  // Used to force redraw on navigation
  useSelector((state: ReduxState) => state.router.location.pathname);

  return (
    <div className={classnames(classes.main, className)} {...rest}>
      {/*{isXSmall && window && window.Android && window.Android.openApp && (*/}
      {/*  <>*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      color="primary"*/}
      {/*      size="small"*/}
      {/*      startIcon={<ArrowBackIosIcon />}*/}
      {/*      className={classes.button}*/}
      {/*      onClick={() => {*/}
      {/*        console.log("calling back button", window.Android);*/}
      {/*        window.Android.openApp();*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Back*/}
      {/*    </Button>*/}
      {/*  </>*/}
      {/*)} TODO: uncomment this*/}
      {!isXSmall && (
        <>
          <MenuItemLink
            key={"#"}
            to={`#`}
            onClick={() => dispatch(toggleSidebar())}
            dense={dense}
            primaryText={""}
            leftIcon={open ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
            sidebarIsOpen={open}
          />
          <Divider />
        </>
      )}
      {hasDashboard && permissions !== "USER" && (
        <DashboardMenuItem
          onClick={onMenuClick}
          dense={dense}
          sidebarIsOpen={open}
          primaryText={translate(
            selectedTenant ? "sps.labels.allTenants" : "sps.labels.tenantList"
          )}
          classes={{
            active: isXSmall ? null : "menu-item-active"
          }}
        />
      )}
      {selectedTenant && selectedTenant.id && (
        <MenuItemLink
          key={"tenant"}
          to={`/tenant/${selectedTenant.id}`}
          classes={{
            active: isXSmall ? null : "menu-item-active"
          }}
          onClick={onMenuClick}
          dense={dense}
          primaryText={translate("sps.labels.details")}
          leftIcon={<DefaultIcon />}
          sidebarIsOpen={open}
        />
      )}
      <Divider />
      {filteredResources.map((resource, index) => {
        const CustomIcon = itemIcons[resource.name];
        if(resource.name === "hairdresserSalon" && selectedTenant && selectedTenant.id && !selectedTenant.hasHairAndBeauty) {
          return null
        }
        if (resource === "divider") {
          return <Divider key={"divider" + index} />;
        }
        return (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              resource.primaryText ||
              translatedResourceName(resource, translate)
            }
            leftIcon={
              CustomIcon ? (
                <CustomIcon />
              ) : resource.fullIcon ? (
                resource.fullIcon
              ) : resource.icon ? (
                <resource.icon />
              ) : (
                <DefaultIcon />
              )
            }
            classes={{
              active: isXSmall ? null : "menu-item-active"
            }}
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
          />
        );
      })}
      {selectedTenant &&
        selectedTenant.id &&
        selectedTenant.hasHairAndBeauty &&
        !(window.Android && window.Android.openApp) && (
          <MenuItemLink
            key={"hair-and-beauty"}
            to={`/hair-and-beauty`}
            onClick={onMenuClick}
            dense={dense}
            primaryText={translate("sps.labels.hair_and_beauty")}
            leftIcon={<DefaultIcon />}
            sidebarIsOpen={open}
          />
        )}
      <Divider />
      {selectedTenant && selectedTenant.id && (
        <MenuItemLink
          key={"advanced-settings"}
          to={`/advanced-settings`}
          onClick={onMenuClick}
          dense={dense}
          primaryText={translate("sps.labels.advanced_settings")}
          leftIcon={<TuneIcon />}
          sidebarIsOpen={open}
        />
      )}
      {isXSmall && !window.Android && logout}
      {isXSmall && window.Android && window.Android.openApp && (
        <MenuItemLink
          key={"app-back"}
          to={`/app-back`}
          onClick={() => {window.Android.openApp()}}
          dense={dense}
          primaryText={translate("sps.labels.app_back")}
          leftIcon={<ReceiptIcon />}
          sidebarIsOpen={open}
        />
      )}
    </div>
  );
};

export default Menu;
