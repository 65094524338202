/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const Employees = gql`
  query SearchEmployees($tenantId: String, $perPage: Int, $skip: Int, $order: [EmployeeSearchOrderInput!]) {
    searchEmployees(search: {tenantId: $tenantId}, order:$order,
      skip: $skip,
      take: $perPage
    ) {
      total 
      items {
        id
        firstName
        lastName
        phoneNumber
        tenant {
          id
          vatNumber
          companyName
        }
        hairdresserSalon {
          id
          name
          order
        }
      }
    }
  }
`;



export const SearchEmployees = gql`
  query SearchEmployees($perPage: Int, $skip: Int, $order: [EmployeeSearchOrderInput!], $search: EmployeeSearchInput) {
    searchEmployees(search: $search, order:$order,
      skip: $skip,
      take: $perPage
    ) {
      total
      items {
        id
        firstName
        lastName
        phoneNumber
        tenant {
          id
          vatNumber
          companyName
        }
        hairdresserSalon {
          id
          name
          order
        }
      }
    }
  }
`;

