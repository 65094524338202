import React, { useState } from 'react';

import { MyCalendar } from '../components/MyCalendar';

export const Schedule = ({selectedEmployee, selectedHQ}) => {
  const [, setCurrentDay] = useState(new Date())

  return (
    <div>
      <MyCalendar emitDay={setCurrentDay} selectedEmployee={selectedEmployee} selectedHQ={selectedHQ}/>
    </div>
  )
}
