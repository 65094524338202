import React, { useState } from 'react';
import { css } from 'emotion';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { useTranslate } from 'ra-core';

import { Header } from '../../components/Header';
import { PersonType } from '../../enums/PersonType';
import { PhysicalPersonForm } from './PhysicalPersonForm';
import { CompanyForm } from './CompanyForm';

import '../../styles/client-create.css';

const containerStyle = css`
  height: 100%;
  max-width: 600px;
`
const contentContainer = css`
  margin-left: 16px;
  margin-right: 16px;
  overflow: scroll;
  height: 100%;
`;
const radioButtons = css`
  margin-top: 24px;
  margin-bottom: 12px;
`;

interface IClientCreateProps {
  handleClose(e?: any): void;
}

export const ClientCreate = ({ handleClose }: IClientCreateProps) => {
  const [personType, setPersonType] = useState(PersonType.PRIVATE);
  const translate = useTranslate();

  const handlePersonTypeChange = event => {
    setPersonType(event.target.value);
  };

  return (
    <div className={containerStyle}>
      <Header title={translate('sps.labels.add_new_client')} handleClose={handleClose} />
      <div className={contentContainer}>
        <div className={radioButtons}>
          <RadioGroup value={personType} onChange={handlePersonTypeChange} row>
            <FormControlLabel
              value={PersonType.PRIVATE}
              control={<Radio color="primary" />}
              label={translate('sps.labels.physical_person')}
            />
            <FormControlLabel
              value={PersonType.LEGAL}
              control={<Radio color="primary" />}
              label={translate('sps.labels.company')}
            />
          </RadioGroup>
        </div>

        {personType === PersonType.PRIVATE && <PhysicalPersonForm handleClose={(e) => handleClose(e)} />}
        {personType === PersonType.LEGAL && <CompanyForm handleClose={(e) => handleClose(e)}/>}
      </div>
    </div>
  );
}
