//
import { client } from '../../graphql/client';
import { Employee } from '../../graphql/generated-queries/employee-queries';

export async function getEmployee(input) {
  try {
    const { data } = await client.query({ query: Employee, variables: { id: input.id } });
    return { data: data.employee };
  } catch (err) {
    console.error(err);
  }
}
