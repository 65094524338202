import { client } from '../../graphql/client';
import { Appointment } from '../../graphql/generated-queries/appointment-queries';

export async function getAppointment(input) {
  try {
    const { data } = await client.query({ query: Appointment, variables: { id: input.id } });
    return { data: data.appointment };
  } catch (err) {
    console.error(err);
  }
}
