/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const Articles = gql`
  query Articles {
    articles {
      id
      name
      price
      actionPrice
      discountPercentage
      discountAmount
      barCode
      type
      order
      pdv {
        id
        name
        internalName
        percentage
      }
      group {
        tenant {
          id
        }
        id
        name
      }
    }
  }
`;


export const SearchArticles = gql`
  query SearchArticles($name: String, $tenantId: String, $price: Float, $groupId: EntityId, $type: ArticleType, $perPage: Int, $skip: Int, $order: [ArticleSearchOrderInput!]) {
    searchArticles(search: {name: $name, tenantId: $tenantId, price: $price group: {id: $groupId}, type: $type}, order:$order,
      skip: $skip,
      take: $perPage
    ) {
      total
      items {
        id
        name
        price
        actionPrice
        discountPercentage
        discountAmount
        barCode
        type
        order
        pdv {
          id
          name
          internalName
          percentage
        }
        group {
          tenant {
            id
          }
          id
          name
        }
      }
    }
  }
`;

export const SearchArticlesList = gql`
  query SearchArticles($name: String, $tenantId: String, $price: Float, $groupId: EntityId, $type: ArticleType, $perPage: Int, $skip: Int, $order: [ArticleSearchOrderInput!]) {
    searchArticles(search: {name: $name, tenantId: $tenantId, price: $price group: {id: $groupId}, type: $type}, order:$order,
      skip: $skip,
      take: $perPage
    ) {
      total
      items {
        id
        name
        price
        type
        order
      }
    }
  }
`;
