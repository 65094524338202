/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const Client = gql`
  query Client($id: EntityId!) {
    client(id: $id) {
      id
      personType
      fiscalCode
      vatNumber
      firstName
      lastName
      companyName
      address
      zip
      city
      province
      state
      invoiceVia
      sdi
      pec
      lotteryId
      birthday
      note
      discount
      dontSendNotifications
      dontSendBirthdayNotification
      mobilePhone
      email
      privacyDate
      gdpr
      tenant {
          id
          companyName
      }
      createdAt
      updatedAt
    }
  }
`;

export const DetailedClient = gql`
  query Client($id: EntityId!) {
    client(id: $id) {
      id
      personType
      fiscalCode
      vatNumber
      firstName
      lastName
      companyName
      address
      zip
      city
      province
      state
      invoiceVia
      sdi
      pec
      lotteryId
      birthday
      note
      discount
      dontSendNotifications
      dontSendBirthdayNotification
      mobilePhone
      email
      privacyDate
      gdpr
      tenant {
          id
          companyName
      }
      createdAt
      updatedAt
      appointments {
        id
        annotation
        start
        end
        sendReminder
        warnOfFreeAppointment
        repeatEndDate
        repeat
        status
        tenant {
          id
          companyName
        }
        client {
          id
          firstName
          lastName
          mobilePhone
        }
        employee {
          id
          firstName
          lastName
          phoneNumber
          hairdresserSalon {
            id
            name
          }
        }
        service {
          id
          name
          price
        }
      }
    }
  }
`;
