import { client } from '../../graphql/client';
import { SearchHairdresserSalons } from '../../graphql/generated-queries/hairdresser-salons-queries';
import {getSelectedTenant} from "../../service/store";

export async function getHairdresserSalons(input) {

  const variables = {
    ...input.filter,
    perPage: input.pagination.perPage,
    skip: (input.pagination.page - 1) * input.pagination.perPage,
    order: [
      {[input.sort.field]: input.sort.order}
    ]
  }

  const tenant = getSelectedTenant();
  const tenantId = tenant ? tenant.id : null;

  try {
    const { data } = await client.query({ query: SearchHairdresserSalons, variables: {...variables, tenantId } });
    return { data: data.searchHairdresserSalons.items, total: data.searchHairdresserSalons.total };
  } catch (err) {
    console.error(err);
  }
}
