export function getTenantName(tenant) {

  let tenantName = tenant ? tenant.id : '';
  if(tenant && tenant.tenantId) {
    tenantName = tenant.tenantId
  }
  if(tenant && (tenant.firstName ||tenant.lastName)) {
    tenantName = (tenant.firstName || '')  + ' ' +  (tenant.lastName || '')
  }
  if(tenant && tenant.companyName) {
    tenantName = tenant.companyName;
  }

  return tenantName

}
