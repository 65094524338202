import { client } from '../../graphql/client';
import { UpdateGroup } from '../../graphql/generated-queries/update-group-queries';

export async function updateGroup({ data }) {
  const { id, name, order } = data;
  const result = await client.mutate({
    mutation: UpdateGroup,
    variables: {input: {id, name, order }}
  });
  return { data: result.data.updateGroup };
}
