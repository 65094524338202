/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const Users = gql`
  query Users {
    users {
      id
      role
      username
      firstName
      lastName
      phoneNumber
      email
    }
  }
`;

export const SearchUsers = gql`
  query SearchUsers($username: String, $tenantId: String, $role: UserRole, $perPage: Int, $skip: Int, $order: [UserSearchOrderInput!]) {
    searchUsers(search: {username: $username, role: $role, tenantId: $tenantId}, order:$order,
      skip: $skip,
      take: $perPage
    ) {
      total
      items {
        id
        role
        username
        firstName
        lastName
        phoneNumber
        email
        tenantRoles {
          tenant {
              id
              firstName
              lastName
              companyName
          }
          role
        }
      }
    }
  }
`;

export const SearchManagers = gql`
  query SearchManagers($username: String, $tenantId: String, $role: UserRole, $perPage: Int, $skip: Int, $order: [UserSearchOrderInput!]) {
  searchManagers(search: {username: $username, role: $role, tenantId: $tenantId}, order:$order,
      skip: $skip,
      take: $perPage
    ) {
      total
      items {
        id
        role
        username
        firstName
        lastName
        phoneNumber
        email
        tenantRoles {
          tenant {
              id
              firstName
              lastName
              companyName
          }
          role
        }
      }
    }
  }
`;
