import React, {useState} from 'react'
import {TextField} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useTranslate } from 'ra-core'
import {getArticles} from "../../queries";
import {useEffectAsync} from "../../use-effect-async";
import {css} from 'emotion'

const serviceOptionsStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

interface IServicePickerProps {
  service: any
  allServices: any
  index: any
  errorsMap: any
  setService(value): any
  setErrorsMap(value): any
}

export const ServicePicker = (props: IServicePickerProps) => {
  const [articles, setArticles] = useState<Array<any>| null>(null)
  const translate = useTranslate()
  const {service, setService, allServices, index, errorsMap, setErrorsMap} = props

  useEffectAsync(async () => {
    const result = await getArticles({
      pagination: {
        perPage: 10000,
        skip: 0,
        type: 'servizio',
        order: [{
          name: "ASC"
        }]
      },
      sort: {
        field: '',
      },
    }, true)
    if(result) {
      const { data } = result
      // if(!service) {
      //   const currentService = allServices.service
      //   currentService[index] = data[0]
      //   setService({
      //     ...allServices,
      //     service: currentService,
      //   })
      // }
      setArticles(data.filter(article => article.type==="servizio"))
    }
  }, [])

  if(!articles && !service) {
    return (
      <div>
        <Autocomplete
          style={{marginBottom: 15}}
          loading={true}
          renderInput={(props) => {
            return (
              <TextField {...props} variant='outlined'
                         label={translate('sps.labels.service') + ' *'}
                         error={errorsMap.service}
                         helperText={errorsMap.service ? translate('sps.labels.requiredField') : ''}
              />)
          }}
          options={[]}
        />
      </div>
    )
  }

  const bottomMargin = errorsMap.service ? 0 : 15;

  return (
    <Autocomplete
      defaultValue={service}
      style={{marginBottom: bottomMargin}}
      renderInput={(props) => {

        return (<TextField {...props} variant='outlined'
                           label={translate('sps.labels.service') + ' *'}
                           error={errorsMap.service}
                           helperText={errorsMap.service ? translate('sps.labels.requiredField') : ''}
        />)
      }}
      //@ts-ignore
      options={articles}
      getOptionLabel={(option: any) => option.name}
      renderOption={(option, state) => {
        return (
          <div className={serviceOptionsStyle}>
            <span>{option.name}</span>
            <span>{parseFloat(option.price).toFixed(2)}</span>
          </div>)
      }}
      onChange={(event, value) => {
        if (errorsMap) {
          errorsMap.service = !value
          setErrorsMap({...errorsMap})
        }
        //@ts-ignore
        if (!value || !articles.includes(value)) {
          return
        }
        const currentService = allServices.service
        currentService[index] = value
        setService({
          ...allServices,
          service: currentService,
        })

      }}
    />
  )
}
