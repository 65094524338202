import { client } from "../../graphql/client";
import { UpdateUser } from "../../graphql/generated-queries/update-user-queries";

export async function updateUser({ data }) {
  const {
    id,
    password,
    username,
    role,
    phoneNumber,
    email,
    assignedTenantId,
    assignedTenantRole,
    firstName,
    lastName
  } = data;
  const result = await client.mutate({
    mutation: UpdateUser,
    variables: {
      input: {
        id,
        password,
        username,
        role,
        email,
        phoneNumber,
        assignedTenantId,
        assignedTenantRole,
        firstName,
        lastName
      }
    }
  });
  return { data: result.data.updateUser };
}
