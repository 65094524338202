import { client } from '../../graphql/client';
import { UpdateEmployee } from '../../graphql/generated-queries/update-employee-queries';

export async function updateEmployee({ data }) {
  const {
    id,
    firstName,
    lastName,
    phoneNumber,
    tenant,
    hairdresserSalon
  } = data;

  const tenantId = tenant ? tenant.id : null;

  const result = await client.mutate({
    mutation: UpdateEmployee,
    variables: {input: {
        id,
        firstName,
        lastName,
        phoneNumber,
        hairdresserSalon: {id: hairdresserSalon.id},
        tenant: {id: tenantId}

      }}
  });
  return { data: result.data.updateEmployee };
}
