import React from 'react'

import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import { addDays, eachDayOfInterval, subDays } from 'date-fns'

export class CustomThreeDayView extends React.Component<any>{

  public range = date => {
    return eachDayOfInterval({start: subDays(date, 0), end: addDays(date, 2)})
  }

  public static title = () => {
    return 'three day calendar'
  }

  public static navigate = (date, action) => {
    switch (action) {
      case "PREV":
        return subDays(date, 3)

      case 'NEXT':
        return addDays(date, 3)

      default:
        return date
    }
  }

  render() {
    let { date } = this.props
    let range = this.range(date)

    return <TimeGrid {...this.props} range={range} eventOffset={15} />
  }
}
