import { css } from "emotion";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

export const deleteIconStyle = css`
  .MuiSvgIcon-root {
    display: block !important;
  }
`
export const dialogContainer = css`
  display: flex;
  flex-direction: column;
  padding: 20px;
`
export const buttonContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
`
export const buttonStyle = css`
  border-radius: 12px !important;
  height: 20px !important;
  background-color: #9A9A9A !important;
  color: white !important;
  box-shadow: none !important;
  text-transform: lowercase !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`

export const buttonStyleExpanded = css`
  border-radius: 12px !important;
  height: 20px !important;
  background-color: #702588 !important;
  color: white !important;
  box-shadow: none !important;
  text-transform: lowercase !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`
export const articleContainerStyle = css`
  // display: flex;
  // justify-content: space-between;
  padding:  12px 0px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
`
export const articleStyle = css`
  padding-left: 10px;
`
export const groupTextStyle = css`
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
`
export const blankDivStyle = css`
  width: 100%;
  height: 60px;
`
export const expansionPanelSummaryStyle = css`
  display: flex;
  min-height: 56px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #F7F7F8;
  border: 1px solid rgba(0, 0, 0, .125);
`
export const dragHandleStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const groupDetailContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`
export const articleListContainerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 12px 0px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`

export const mobileFormTitleStyle = css`
  position: fixed;
  background-color: white;
  color: black;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #CCC;
`
export const mobileCloseStyle = css`
  position: absolute;
  right: 20px;
`
export const saveCancelButtonsStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);


export const ExpansionPanelSummary = withStyles({
  root: {
    cursor: 'default !important',
    backgroundColor: 'white',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    display: 'flex',
    justifyContent: 'space-evenly',
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
  },
}))(MuiExpansionPanelDetails);
