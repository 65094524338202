//
import { client } from '../../graphql/client';
import { DeleteMessages } from '../../graphql/generated-queries/delete-messages-queries';

export async function removeMessage(input) {
  try {
    return await client.mutate({ mutation: DeleteMessages, variables: { ids: [input.id] } });
  } catch (err) {
    console.error(err);
  }
}
