import { client } from "../../graphql/client";
import { Tenant } from "../../graphql/generated-queries/tenant-queries";
import { getTenantName } from "../../service/tenant-service";
import {getSelectedTenant, setSelectedTenant} from "../../service/store";

export async function getTenant(input) {
  try {
    const { data } = await client.query({
      query: Tenant,
      variables: { id: input.id }
    });
    const tenant = data.tenant || {};
    tenant.additionalOptions = [];
    tenant.monthlyData = [];
    tenant.friendlyName = getTenantName(tenant);

    const resultData = tenant;
    const selectedTenant = getSelectedTenant();
    if(selectedTenant && resultData && parseInt(selectedTenant.id) === parseInt(resultData.id) &&  parseInt(resultData.id) !== 0) {
      setSelectedTenant(resultData)
    }
    return { data: { ...tenant } };
  } catch (err) {
    console.error(err);
  }
}
