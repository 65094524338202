export const formatDecimals = (v, len) => {
  if(!v) {
    return v
  }
  return parseFloat(Number(v).toFixed(len));
}

export const parseDecimals = (v, len) => {
  if(!v) {
    return v
  }
  return parseFloat(Number(v).toFixed(len));
}

