import { client } from '../../graphql/client';
import { CreateGroup } from '../../graphql/generated-queries/create-group-queries';
import { getSelectedTenant } from '../../service/store';
import { SearchGroups } from "../../graphql/generated-queries/groups-queries";

export async function createGroup(input) {

  const tenant = getSelectedTenant();
  const tenantId = tenant ? tenant.id : null;
  if (tenantId) {
    input.data.tenant = {
      id: tenantId
    }
  }

  const {data: {searchGroups}} = await client.query({query: SearchGroups, variables: {tenantId: tenantId}})

  try {
    const { data } = await client.mutate({ mutation: CreateGroup, variables: { input: { ...input.data, order: searchGroups.total + 1 } } });
    return { data: { ...data.createGroup } };
  } catch (err) {
    console.error(err);
    throw new Error(err.graphQLErrors?.[0]?.message || err)
  }
}
