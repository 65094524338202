import {client} from '../../graphql/client';
import {ResetPassword} from "../../graphql/generated-queries/reset-password-queries";

export async function resetPassword(input) {
  try {

    const {data} = await client.mutate({
      mutation: ResetPassword,
      variables: {username: input.username, resetCode: input.resetCode, password: input.password}
    });
    return {data: {...data.resetPassword}};
  } catch (err) {
    console.error(err);
  }
}
