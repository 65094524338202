/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const UpdateGroup = gql`
  mutation UpdateGroup($input: GroupEditInput!) {
    updateGroup(input: $input) {
      id
      name
      order
      tenant {
          id
          companyName
      }
    }
  }
`;
