/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const Groups = gql`
  query Groups {
    groups {
      id
      name
      order
      tenant {
          id
          companyName
      }
    }
  }
`;


export const SearchGroups = gql`
    query SearchGroups($name: String, $tenantId: EntityId, $perPage: Int, $skip: Int, $order: [GroupSearchOrderInput!]) {
        searchGroups(search: {name: $name, tenant: {id: $tenantId}}, order:$order,
            skip: $skip,
            take: $perPage
        ) {
            total
            items {
                id
                name
                order
                tenant {
                    id
                    companyName
                }
            }
        }
    }
`;
