import React from "react";
import { Filter, TextInput } from 'react-admin';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';


export const getGroupFilter = (props) => {
  const {setSearch} = props
  return (
      <Filter {...props} variant="standard">
        <TextInput
          variant="standard"
          onChange={(value) => setSearch(value.currentTarget.value)}
          label="sps.labels.search_entity.groups"
          source="name"
          alwaysOn
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Filter>

  )
}


