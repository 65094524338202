import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';

require('./polyfill.js')

declare global {
  interface Window { hasSuperiusAdminChanges: any; Android: any; }
}

// window.Android = {
//   openApp: () => {
//     console.log('Entering back to app')
//   }
// }
window.hasSuperiusAdminChanges = window.hasSuperiusAdminChanges || {};

ReactDOM.render(<App />, document.getElementById('root'));
