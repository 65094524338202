import React from "react";
import { css, cx } from "emotion";
import { Grow, Paper, Popper } from "@material-ui/core";
import { useTranslate } from "ra-core";
import { differenceInHours, differenceInMinutes, format } from "date-fns";

const eventContainerDetails = css`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
const papperStyle = css`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 24px;
  width: 350px;
`;
const employeeDetailsStyle = css`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
  padding-bottom: 5px;
`;
const nameBadgeStyle = css`
  background-color: rgba(0, 0, 0, 0.03);
  font-size: 20px;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
`;
const contactInfoStyle = css`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;
const lighterStyle = css`
  font-weight: normal;
  color: rgba(0, 0, 0, 0.4);
`;
const appointmentDetailsStyle = css`
  display: flex;
  justify-content: space-between;
  max-width: 340px;
  align-items: center;
`;
const detailsSegmentStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 90%;
  margin-right: 10px;
`;

const overflowStyle = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

interface IEventPopper {
  menuOpen: any;
  event: any;
  title: string;
}

function getClientName(client) {
  let name = '';
  if(client.firstName) {
    name = (client.firstName || '') + ' '
  }
  if(client.lastName) {
    name += (client.lastName || '')
  }
  if(name.trim() !== '') {
    return name
  }
  return client.companyName || ''
}

export const EventPopper = ({
  menuOpen,
  event: { client, employee, service, start, end, annotation },
  title
}: IEventPopper) => {
  const translate = useTranslate();
  const hours = differenceInHours(end, start);
  const minutes =
    differenceInMinutes(end, start) - differenceInHours(end, start) * 60;
  const durationString = `${hours != 0 ? hours + "h" : ""} ${
    minutes != 0 ? minutes + "m" : ""
  }`;
  const timeString =
    format(new Date(start), "hh:mm a") +
    " - " +
    format(new Date(end), "HH:mm a");

  return (
    <Popper
      transition
      disablePortal
      role={undefined}
      anchorEl={menuOpen}
      open={Boolean(menuOpen)}
      placement={"right"}
      className={css`
        z-index: 1000;
      `}
    >
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps}>
          <Paper className={papperStyle}>
            <div className={eventContainerDetails}>
              <div className={employeeDetailsStyle}>
                <span className={nameBadgeStyle}>{employee.firstName[0]}</span>
                <div className={contactInfoStyle}>
                  <span>{(employee.firstName || '') + " " + (employee.lastName || '')}</span>
                  <span className={lighterStyle}>{employee.phoneNumber}</span>
                </div>
              </div>
              <div className={appointmentDetailsStyle}>
                <div className={cx([detailsSegmentStyle, overflowStyle])}>
                  <span className={lighterStyle}>{timeString}</span>
                  {service && <span>{service.name}</span>}
                  <span className={lighterStyle}>
                    {[
                      durationString,
                      client
                        ? `${translate("sps.labels.with")} ${getClientName(client)}`
                        : ""
                    ].join(" ")}
                  </span>
                  {annotation && (
                    <span className={lighterStyle}>{annotation}</span>
                  )}
                </div>
              </div>
            </div>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
