import React from 'react';
import { css } from 'emotion';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useHistory } from "react-router-dom";

const header = css`
  display: flex;
  flex-direction: row;
  height: 56px;
  align-items: center;
  border-bottom-color: rgba(0,0,0,0.12);
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;
const headerAction = css`
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`;
const headerTitle = css`
  flex: 1;
  text-align: center;
`;
const text = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0,0,0,0.87);
`;

interface IHeaderProps {
  title: string;
  handleClose?(): void;
  hasExit?: boolean
}

export const Header = ({title, handleClose, hasExit=true}: IHeaderProps) => {
  const history = useHistory();

  const closeAction = () => {
    handleClose ? handleClose() : history.goBack();
  }

  return (
    <div className={header}>
      <div className={headerAction} />
      <div className={[headerTitle, text].join(' ')}>{title}</div>
      {hasExit &&
        <div className={headerAction} onClick={closeAction}><CloseRoundedIcon htmlColor="rgba(0,0,0,0.54)"/></div>}
    </div>
  );
}
