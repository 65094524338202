//
import { client } from '../../graphql/client';
import { HairdresserSalon } from '../../graphql/generated-queries/hairdresser-salon-queries';

export async function getHairdresserSalon(input) {
  try {
    const { data } = await client.query({ query: HairdresserSalon, variables: { id: input.id } });
    return { data: data.hairdresserSalon };
  } catch (err) {
    console.error(err);
  }
}
