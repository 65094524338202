import { client } from '../../graphql/client';
import { SearchGroups } from '../../graphql/generated-queries/groups-queries';
import {getSelectedTenant} from "../../service/store";

export async function getGroups(input) {

  const variables = {
    ...input.filter,
  }

  if(input.sort) {
    variables.order = [{[input.sort.field]: input.sort.order}]
  }

  if(input.pagination) {
    variables.perPage = input.pagination.perPage;
    variables.skip = (input.pagination.page - 1) * input.pagination.perPage;
  }

  const tenant = getSelectedTenant();
  const tenantId = tenant ? tenant.id : null;

  try {
    const { data } = await client.query({ query: SearchGroups, variables: {...variables, tenantId } } );
    return {data: data.searchGroups.items.sort((a, b) => a.order - b.order), total: data.searchGroups.total};
  } catch (err) {
    console.error(err);
  }
}

