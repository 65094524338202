// @ts-nocheck
import React from "react";
import {Toolbar, SaveButton, DeleteButton, ListButton} from "react-admin";
import {useTranslate} from 'ra-core'
import {withStyles, useMediaQuery, Theme} from '@material-ui/core';
import {css, cx} from 'emotion'
import DeleteIcon from '@material-ui/icons/DeleteOutline';

const deleteButtonStyle = css`
  position: relative !important;
  .MuiIconButton-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
  }
`
const mobileButtonStyle = css`
  .MuiIconButton-label {
    width: initial;
  }
  .MuiIconButton-root {
    color: rgba(0,0,0,0.8);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
  }
`
const saveCancelButtonsStyle = css`
  display: flex;
  width: 50%;
  justify-content: flex-end;
`
const toolbarContainerStyle = css`
  .MuiToolbar-root{
    position: relative !important;
    display: flex;
    flex-display: row !important;
  }
`

export const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export const EditOnlyToolbar = withStyles(toolbarStyles)(props => {
  const translate = useTranslate()
  let basePath = props.basePath
  if (props.basePath === '/tenant' && props.record && props.record.id) {
    basePath = '/'
  }

  return (<Toolbar {...props} className={'form-toolbar'}>
    <div id='other-buttons'>
      <ListButton variant="outlined" basePath={basePath} label={'sps.labels.cancel'}>{translate('sps.labels.cancel')}</ListButton>
    </div>
    <SaveButton/>
  </Toolbar>)
})

export const FullToolbarCheckDeleteSelf = withStyles(toolbarStyles)(props => {
  const translate = useTranslate()
  let isSelf = false;
  if(props.record && props.record.id && localStorage.getItem('userId')) {
    isSelf = props.record.id === localStorage.getItem('userId')
  }
  let basePath = props.basePath
  if (props.basePath === '/tenant' && props.record && props.record.id) {
    basePath = '/'
  }

  return (
    <Toolbar {...props} className={'form-toolbar'}>
      {props.record.id && !isSelf &&
      <DeleteButton
        icon={<DeleteIcon />}
        undoable={false}
        style={{marginRight: '50%', paddingTop: 10, width: '100%', color: 'black'}}
        record={props.record}
        className={'delete-button'}
        label={"sps.labels.delete"} />
      }
      <div id='other-buttons'>
        <ListButton variant="outlined" basePath={basePath} label={'sps.labels.cancel'}>{translate('sps.labels.cancel')}</ListButton>
      </div>
      <SaveButton record={props.record}/>
    </Toolbar>
  )
});

export const SaveCancelDeleteToolbar = withStyles(toolbarStyles)(props => {
  const translate = useTranslate()

  let basePath = props.basePath
  let isDeleteVisible = props.isDeleteVisible || true
  let isDeleteDisabled = false
  if(props.record && props.record['__typename'] === 'Employee') {
    isDeleteDisabled = !!props.record.hasAppointments
  }
  if (props.basePath === '/tenant' && props.record && props.record.id) {
    basePath = '/'
  }
  let cancelOnClick = null
  if (props.basePath === '/employee' && props.record && props.record.id) {
    cancelOnClick = () => {
      window.history.back()
    }
  }

  const deleteButtonStyle = {marginRight: '50%', paddingTop: 10}
  if (props.permission === 'USER' && props.tenantRole === 'EMPLOYEE') {
    deleteButtonStyle.visibility = 'hidden'
  }

  return (
    <Toolbar {...props} className={cx([toolbarContainerStyle])}>
      {(props.record.id && isDeleteVisible) &&
      (props.redirect ?
      <DeleteButton
        undoable={false}
        style={deleteButtonStyle}
        record={props.record}
        redirect={props.redirect}
        className={'delete-button'}
        label={"sps.labels.delete"}
        disabled={isDeleteDisabled}
        icon={<DeleteIcon />}
      /> :
      <DeleteButton
        undoable={false}
        style={deleteButtonStyle}
        record={props.record}
        className={'delete-button'}
        label={"sps.labels.delete"}
        disabled={isDeleteDisabled}
        icon={<DeleteIcon />}
      />
      )
      }
      <div className={saveCancelButtonsStyle}>
        <div style={{width: '100%'}} id='other-buttons'>
          {
            cancelOnClick ?
              (<ListButton variant="outlined" onClick={cancelOnClick} basePath={'#'} label={'sps.labels.cancel'}>{translate('sps.labels.cancel')}</ListButton>)
              : (<ListButton variant="outlined" basePath={basePath} label={'sps.labels.cancel'}>{translate('sps.labels.cancel')}</ListButton>)
          }
        </div>
        <SaveButton {...props} style={{width: '100%'}} record={props.record} className={'save-bottom-fixed'}/>
      </div>
    </Toolbar>
  )
});

export const SaveDeleteToolbar = withStyles(toolbarStyles)(props => {
  const translate = useTranslate()
  let isDeleteVisible = props.isDeleteVisible || true
  let isDeleteDisabled = false
  if(props.record && props.record['__typename'] === 'Employee') {
    isDeleteDisabled = !!props.record.hasAppointments
  }
  if (props.basePath === '/tenant' && props.record && props.record.id) {
    basePath = '/'
  }
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const deleteButton = document.getElementsByClassName('delete-button')
  if(isXSmall && deleteButton && deleteButton[0] && deleteButton[0].children.length<3 && !isDeleteDisabled) {
    const deleteText = document.createElement("span")
    deleteText.innerText = `${translate('sps.labels.delete')}`
    deleteText.className = 'form-delete-text-style'
    deleteButton[0].appendChild(deleteText)
  }

  return (
    <Toolbar {...props} className={cx(['form-toolbar',deleteButtonStyle, isXSmall && mobileButtonStyle])}>
      {(props.record.id && isDeleteVisible) &&
        <DeleteButton
          undoable={false}
          style={{width: isXSmall ? 'none' : '100%', marginRight: isXSmall ? '100%' : 0, paddingTop: 10}}
          record={props.record}
          className={'delete-button'}
          label="sps.labels.delete"
          aria-label="sps.labels.delete"
          disabled={isDeleteDisabled}
          icon={<DeleteIcon />}
        />
      }
      <SaveButton record={props.record} className={'save-bottom-fixed'}/>
    </Toolbar>
  )
});


export const SaveCancelToolbar = withStyles(toolbarStyles)(props => {
  const translate = useTranslate()
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  let basePath = props.basePath
  if (props.basePath === '/tenant' && props.record && props.record.id) {
    basePath = '/'
  }
  if(props.newPath) {
    basePath = props.newPath
  }
  return (
    <Toolbar {...props} className={'form-toolbar'}>
      {!isXSmall && (<div id='other-buttons'>
        <ListButton variant="outlined" basePath={basePath} label={'sps.labels.cancel'}>{translate('sps.labels.cancel')}</ListButton>
      </div>)}
      <SaveButton record={props.record} className={'save-bottom-fixed'}/>
    </Toolbar>
  )
});

export const SaveToolbar = withStyles(toolbarStyles)(props => {
  return (
    <Toolbar {...props} className={'form-toolbar'}>
      <SaveButton record={props.record} className={'save-bottom-fixed'}/>
    </Toolbar>
  )
});
