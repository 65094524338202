import React, { useState } from 'react';
import { css, cx } from 'emotion';
import moment from 'moment';
import { useMutation } from 'react-admin';
import Modal from '@material-ui/core/Modal';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslate } from 'ra-core';
import {Button, Theme, useMediaQuery} from '@material-ui/core'
import {differenceInHours, differenceInMinutes} from 'date-fns'

import { AppointmentTypeKeys } from '../enums/AppointmentType';
import {deleteElementCurrentTenant, getClientName, updateElementCurrentTenant} from '../repository';
import { AppointmentForm } from '../pages/AppointmentForm';

const modal = css`
  width: 40%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
  padding: 24px;
`;
const mobileModal = css`
  width: 70%;
  padding: 12px;
`
const header = css`
  justify-content: center;
  padding-left: 11px;
  padding-right: 11px;
  display: flex;
  flex-direction: column;
`;
const timeText = css`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;
const serviceText = css`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;
const detailsText = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0,0,0,0.6);
`;
const nameText = css`
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`;
const content = css`
  padding-left: 11px;
  padding-right: 11px;
`;
const editText = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0,0,0,0.87);
  width: 100%;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;
  cursor: pointer;
`;

const statusText = css`
  color: rgba(0,0,0,0.87);
  width: 100%;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0,0,0,0.20);
`;

const statusItemText = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0,0,0,0.87);
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: -11px;
  margin-right: -11px;
  padding-left: 11px;
  padding-right: 11px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: rgba(207,150,207, 0.3);
  }
`;
const fullscreenModal = css`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: scroll;
`;
const deleteModal = css`
  width: 230px;
  min-height: 130px;
  background-color: white;
`;
const modalContainer = css`
  align-items: center;
  justify-content: center;
  display: flex;
`;
const deleteModalText = css`
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.6);
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 12px;
  font-size: 16px;
`;
const deleteModalButtonsContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const cancelButton = css`
  width: 70px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.6);
  text-transform: uppercase;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
  color: black;
`;
const okButton = css`
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
  color: #702588;
`;
const actionsContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const saveCancelStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
const statusContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0px 15px 0px;
`
const checkedStyle = css`
  background-color: rgba(207,150,207, 0.3);
  color: #56156F;
`
const confirmedStyle = css`
  margin-left: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`
const reprogrammStyle = css`
  color: rgba(0,0,0,0.6);
  margin-left: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`
const closeMobileStyle = css`
  position: relative;
  right: -20px;
`
const replyAllStyle = css`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`

interface IAppointmentDetailsModalProps {
  appointment: any;
  otherAppointments?: any[];
  handleClose(action: string, appointment: any): void;
  setReprogramData(value: any): void
  salonData: any
}

export const AppointmentDetailsModal = ({ appointment, handleClose, otherAppointments, setReprogramData, salonData }: IAppointmentDetailsModalProps) => {
  const [isAppointmentUpdateModalOpen, setIsAppointmentUpdateModalOpen] = useState(false);
  const [isAppointmentDeleteModalOpen, setIsAppointmentDeleteModalOpen] = useState(false);
  const [appointmentStatus, setAppointmentStatus] = useState(appointment.status)
  const [isUpdateAppointmentInProgress, setIsUpdateAppointmentInProgress] = useState(false)
  const translate = useTranslate();
  const durationHours = differenceInHours(new Date(appointment.end), new Date(appointment.start))
  const durationMinutes = differenceInMinutes(new Date(appointment.end), new Date(appointment.start))-durationHours * 60
  const durationString = `${durationHours}h `+(durationMinutes ? durationMinutes+'m' : '')

  const [deleteAppointment] = useMutation();
  const [updateAppointment] = useMutation();

  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const onDeleteAppointment = () => {
    deleteAppointment(deleteElementCurrentTenant('appointment', {id: appointment.id}), {
      onSuccess: (data) => {
        handleClose('delete', data.data);
      }
    });
  }

  const onUpdateAppointment = (status) => {
    setIsUpdateAppointmentInProgress(true)
    updateAppointment(updateElementCurrentTenant('appointment', { id: appointment.id, status, changeRepeatAppointment: (appointment.repeat !== null && appointment.repeat !== 'Non si ripete') ?  'single' : null, start: appointment.start, end: appointment.end }), {
      onSuccess: (data) => {
        handleClose('update', data.data);
        setIsUpdateAppointmentInProgress(false)
      },
      onFailure: (err) => {
        console.error(err)
        setIsUpdateAppointmentInProgress(false)
      }
    })
  }

  return (
    <div className={cx([modal, isSmall && mobileModal])}>
      <div className={header}>
        <div className={nameText}>
          {(appointment.client && appointment.client.id) && getClientName(appointment.client)
          }
          {(!(appointment.client && appointment.client.id)) &&
            ((appointment.employee.firstName || '') + ' ' + (appointment.employee.lastName || ''))
          }
          {!isSmall &&
            <Button
              className={reprogrammStyle}
              startIcon={<ReplyAllIcon className={replyAllStyle}/>}
              onClick={() => {
                setReprogramData(true)
                handleClose('close', {id: null})
              }}
            >
              {translate('sps.labels.reprogram')}
            </Button>}
          {isSmall &&
          <Button
              className={cx([reprogrammStyle, isSmall && closeMobileStyle])}
              onClick={() => handleClose('delete', {id: null})}
          >
              <CloseIcon/>
          </Button>
          }
        </div>
        <div className={timeText} id={'appointment-time-text'}>
          {moment(appointment.start).format('HH:mm') + ' - ' + moment(appointment.end).format('HH:mm')}
          <div
            className={confirmedStyle}
            style={{color: appointment.status === AppointmentTypeKeys.CANCELED ? 'red' :
            appointment.status===AppointmentTypeKeys.EXECUTED ? '#0AB153' :
            appointment.status===AppointmentTypeKeys.NOT_ARRIVED ? '#D84108' : '#0000ff'}}
          >
            {appointment.status===AppointmentTypeKeys.CANCELED ? translate('sps.labels.canceled') :
              appointment.status===AppointmentTypeKeys.EXECUTED ? translate('sps.labels.executed') :
                appointment.status===AppointmentTypeKeys.NOT_ARRIVED ? translate('sps.labels.not_arrived') : translate('sps.labels.confirmed')}
          </div>
        </div>
        {appointment.service &&
          <div className={serviceText}>{appointment.service.name}</div>}
        <div className={detailsText}>
          {durationString+` ${translate('sps.labels.with')} ${appointment.employee.firstName || ''} ${appointment.employee.lastName || ''}`}
          {isSmall &&
            <Button
              className={cx([isSmall && closeMobileStyle])}
              startIcon={<ReplyAllIcon className={replyAllStyle}/>}
              onClick={() => {
                setReprogramData(true)
                handleClose('close', {id: null})
              }}
            />}
        </div>
        {appointment.annotation && appointment.annotation !== '' &&
        <div className={serviceText}>{appointment.annotation}</div>}
      </div>
      <div className={content}>
        <div className={editText} onClick={() => {setIsAppointmentUpdateModalOpen(true)}}>{translate('sps.labels.edit')}</div>
        <Modal open={isAppointmentUpdateModalOpen}>
          <div className={fullscreenModal}>
            <AppointmentForm appointment={appointment} handleClose={(data) => {
              setIsAppointmentUpdateModalOpen(false)
              if(data && data.data) {
                handleClose('update', data.data)
              }
            }}
              otherAppointments={otherAppointments} salonData={salonData}
            />
          </div>
        </Modal>
        <div className={statusText}/>
        <div className={statusContainer}>
          {Object.values(AppointmentTypeKeys).map(statusType =>
            <div key={statusType} className={cx([statusItemText,appointmentStatus==statusType && checkedStyle ])} onClick={() => {setAppointmentStatus(appointmentStatus==statusType ? 'EXPECTED' : statusType)}}>
              {translate(`sps.labels.${statusType.toLowerCase()}`)}
              {statusType==appointmentStatus &&
                <CheckIcon/>}
            </div>
          )}
        </div>
      </div>
      <div className={actionsContainer}>
        <Button
          startIcon={<DeleteOutlineIcon/>}
          onClick={() => setIsAppointmentDeleteModalOpen(true)}
        >
          {translate('sps.labels.delete')}
        </Button>
        <div className={saveCancelStyle}>
          {!isSmall &&
          <Button
            onClick={() => handleClose('delete', {id: null})}
          >
            {translate('sps.labels.cancel')}
          </Button>}
          <Button
            color='primary'
            variant='contained'
            onClick={() => {onUpdateAppointment(appointmentStatus)}}
            disabled={isUpdateAppointmentInProgress}
          >
            {translate('sps.labels.save')}
          </Button>
        </div>
      </div>
      <Modal className={modalContainer} open={isAppointmentDeleteModalOpen}>
          <div className={deleteModal}>
            <div className={deleteModalText}>{translate('sps.labels.delete_appointment_message')}</div>
            {appointment.repeat && appointment.repeat !== 'Non si ripete' && (<div className={deleteModalText}>{translate('sps.labels.delete_appointment_message_2')}</div>)}
            <div className={deleteModalButtonsContainer}>
              <div
                className={cancelButton}
                onClick={() => setIsAppointmentDeleteModalOpen(false)}
              >
                Annulla
              </div>
              <div className={okButton} onClick={onDeleteAppointment}>Conferma</div>
            </div>
          </div>
        </Modal>
    </div>
  );
}
