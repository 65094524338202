import { client } from '../../graphql/client';
import { UpdateHairdresserSalon } from '../../graphql/generated-queries/update-hairdresser-salon-queries';

export async function updateHairdresserSalon({ data }) {
  const {
    id,
    name,
    order,
    address,
    province,
    postalCode,
    city,
    tenant,
    warnIfOverlapping,
    remindOffer,
    phoneNumber,
    signOnMessage,
    activateAppointmentReminder,
    personalizedMessage,
    birthdayMessages,
    schedule,
    employeeOrder,
  } = data;

  const tenantId = tenant ? tenant.id : null;

  const result = await client.mutate({
    mutation: UpdateHairdresserSalon,
    variables: {input: {
        id,
        name,
        address,
        province,
        postalCode,
        city,
        order,
        warnIfOverlapping,
        remindOffer,
        phoneNumber,
        signOnMessage,
        activateAppointmentReminder,
        personalizedMessage,
        birthdayMessages,
        schedule,
        employeeOrder,
        tenant: {id: tenantId}
      }}
  });
  return { data: result.data.updateHairdresserSalon };
}
