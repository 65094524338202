import React from 'react'
import {Button, ClickAwayListener, Grow, Paper, Popper, TextField} from "@material-ui/core";
import {Calendar, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {css} from "emotion";
import {Formik} from "formik";

const popperContainerStyle = css`
  padding: 15px;
`
const calendarContainerStyle = css`
  display: flex;
  flex-direction: row;
`
const calendarOutputContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const buttonContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const buttonStyle = css`
  margin-bottom: 20px !important;
`

interface IMessageScheduledPopper {
  isMenuOpen: HTMLButtonElement | null
  setIsMenuOpen(state): any
  translate(value): any
  setFieldValue(name, value): any
}

export const MessageScheduledPopper = (props: IMessageScheduledPopper) => {
  const { isMenuOpen, setIsMenuOpen, translate, setFieldValue } = props

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    //@ts-ignore
    if (isMenuOpen && event.target.className.includes('MuiPickers')) {
      return;
    }

    setIsMenuOpen(null);
  };

  return (
    <>
      <Formik
        initialValues={{
          date: new Date(),
          time: new Date()
        }}
        onSubmit={(value) => {
          setFieldValue('scheduledDate',new Date(new Date(value.date).toDateString()+' '+new Date(value.time).toLocaleTimeString()))
          setIsMenuOpen(null)
        }}
      >
        {({values, handleSubmit, setFieldValue,}) =>
          <Popper
            transition
            disablePortal
            role={undefined}
            anchorEl={isMenuOpen}
            open={Boolean(isMenuOpen)}
          >
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                // style={{transformOrigin: 'top'}}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper className={popperContainerStyle}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className={calendarOutputContainer}>
                        <div className={calendarContainerStyle}>
                          <div className={calendarOutputContainer}>
                            <Calendar
                              disablePast
                              date={values.date}
                              onChange={(val) => setFieldValue('date', val)}
                            />
                          </div>
                          <div className={calendarOutputContainer} style={{paddingTop: 5}}>
                            <TextField
                              className={buttonStyle}
                              value={new Date(values.date).toDateString()}
                              disabled
                            />
                            <TextField
                              name={'time'}
                              type={"time"}
                              value={new Date(values.time).toTimeString().substring(0,5)}
                              onChange={event => {
                                setFieldValue('time', new Date(new Date().toDateString()+' '+event.currentTarget.value))}
                              }
                            />
                          </div>
                        </div>
                        <div className={buttonContainerStyle}>
                          <Button
                            color={"primary"}
                            onClick={() => setIsMenuOpen(null)}
                          >
                            {translate('sps.labels.cancel')}
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit as any}
                          >
                            {translate('sps.labels.scheduledSend')}
                          </Button>
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>
                  </Paper>
                </ClickAwayListener>
              </Grow>
            )}
          </Popper>}
      </Formik>
    </>
  )
}
