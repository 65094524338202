import * as React from 'react';
import { cloneElement } from 'react';
import {
  TopToolbar, CreateButton, ExportButton, sanitizeListRestProps,
} from 'react-admin';

export const ListActions = ({
                              currentSort,
                              className,
                              resource,
                              filters,
                              displayedFilters,
                              exporter, // you can hide ExportButton if exporter = (null || false)
                              filterValues,
                              permanentFilter,
                              hasCreate, // you can hide CreateButton if hasCreate = false
                              basePath,
                              selectedIds,
                              onUnselectItems,
                              showFilter,
                              maxResults,
                              total,
                              ...rest
                            }) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters && cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
    <CreateButton variant="contained" color="primary" basePath={basePath} label={'sps.labels.add'}/>
    <ExportButton
      disabled={total === 0}
      resource={resource}
      icon={null}
      sort={currentSort}
      filter={{...filterValues, ...permanentFilter}}
      exporter={exporter}
      maxResults={maxResults}
      style={{marginRight: '28px'}}
    />
  </TopToolbar>
);

