
import { client } from '../../graphql/client';
import { DeletePDVS } from '../../graphql/generated-queries/delete-pdvs-queries';

export async function removePdv(input) {
  try {

    await client.mutate({
      mutation: DeletePDVS,
      variables: {ids: [input.id] }
    });

    return null;
  } catch (err) {
    console.error(err);
  }
}
