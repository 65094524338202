import { client } from '../../graphql/client';
import { SearchEmployees } from '../../graphql/generated-queries/employees-queries';
import {getSelectedTenant} from "../../service/store";

export async function getEmployees(input) {

  if(input.sort && input.sort.field === 'hairdresserSalon.name') {
    input.sort.field = 'hairdresserSalon'
  }

  const variables = {
    ...input.filter,
    perPage: input.pagination.perPage,
    skip: (input.pagination.page - 1) * input.pagination.perPage,
    order: [
      {[input.sort.field]: input.sort.order}
    ]
  }

  const tenant = getSelectedTenant();
  const tenantId = tenant ? tenant.id : null;
  variables['search'] = {...variables['search'], tenant: { id: tenantId } }

  try {
    const { data } = await client.query({ query: SearchEmployees, variables: {...variables } });
    return { data: data.searchEmployees.items, total: data.searchEmployees.total };
  } catch (err) {
    console.error(err);
  }
}
