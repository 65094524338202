/* tslint:disable:max-line-length */
import gql from "graphql-tag";

export const SearchDevices = gql`
  query SearchDevices(
    $order: [DeviceSearchOrderInput!]
    $take: Int
    $skip: Int
    $tenantId: String
  ) {
    searchDevices(
      order: $order
      take: $take
      skip: $skip
      search: { tenantId: $tenantId }
    ) {
      total
      items {
        id
        rtCode
        serialNumber
        friendlyName
        status
        address
        zip
        city
        province
        customHeader
        printHeader
        printFooter
        tenant {
          id
          companyName
        }
      }
    }
  }
`;
