/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const CreateEmployee = gql`
  mutation CreateEmployee($input: EmployeeCreateInput!) {
    createEmployee(input: $input) {
      id
      firstName
      lastName
      phoneNumber
      hairdresserSalon {
        id
        name
        order
      }
    }
  }
`;
