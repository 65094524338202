/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const UpdatePDV = gql`
  mutation UpdatePDV($input: PDVEditInput!) {
    updatePDV(input: $input) {
      id
      internalName
      name
      shortName
      percentage
    }
  }
`;
