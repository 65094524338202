
import { client } from '../../graphql/client';
import { DeleteGroups } from '../../graphql/generated-queries/delete-groups-queries';

export async function removeGroup(input) {
  try {

    await client.mutate({
      mutation: DeleteGroups,
      variables: {ids: [input.id] }
    });

    return { data: {id: null} };
  } catch (err) {
    console.error(err);
  }
}
