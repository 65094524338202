// @ts-nocheck
import React, {useEffect, useState} from 'react'
import { Datagrid, TextField, List, Resource, Filter, TextInput } from 'react-admin'
import { useDispatch} from 'react-redux';
import { toggleSidebar } from 'ra-core';
import { getSelectedTenant, removeSelectedTenant, setSelectedTenant } from "../service/store";
import authProvider from '../authProvider';
import { useMediaQuery, Theme } from '@material-ui/core';

import SimpleList from '../views/components/SimpleList';

const Dashboard = () => {

  window.hasSuperiusAdminChanges = false
  const chooseTenant = (tenantId, basePath, resource) => {
    setSelectedTenant(resource)
    // @ts-ignore
    window.location.href = '/article'
  };

  const [isUser, setIsUser] = useState(true);
  const dispatch = useDispatch();

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  useEffect(() => {
    (async() => {
      const permissions = await authProvider.getPermissions()
      if(!permissions || permissions === 'USER') {
        return;
      }
      if(permissions === 'ADMIN' || permissions === 'SUPPORT' ) {
        setIsUser(false);
      }

      const tenant = getSelectedTenant();
      removeSelectedTenant()
      if(tenant) {
        window.location.reload();
      }

    }) ()

  }, [])


  if(isUser) {
    dispatch(toggleSidebar())
    return <div/>
  }

  const TenantList = (props) => (
    <List {...props}
          title="Select tenant"
          exporter={false}
          bulkActionButtons={false}
          filters={<TenantFilter />}
          perPage={25}
    >
      <Datagrid rowClick={chooseTenant}>
        <TextField source="tenantId"  label={"Tenant ID"}/>
        <TextField source="companyName" label={"sps.labels.company_name"}/>
        <TextField source="vatNumber" label={"sps.labels.vat_number"}/>
        <TextField source="firstName" label={"sps.labels.first_name"}/>
        <TextField source="lastName" label={"sps.labels.last_name"}/>
        <TextField source="status" />
      </Datagrid>
    </List>
  );

  const MobileTenantList = (props) => (
    <List {...props} perPage={10} bulkActions={false} exporter={false} title="Select tenant" filters={<TenantFilter />}>
      <SimpleList
        linkType={null}
        onRowClick={(id, data) => {
          chooseTenant(id, 'tenant', data)
        }}
        primaryText={record => record.companyName}
        secondaryText={record => record.vatNumber}
        tertiaryText={record => record.tenantId}
      />
    </List>
  );

  return (
    <>
      <h4>Select tenant to log in as one</h4>
      <Resource name="tenant" list={isXSmall ? MobileTenantList : TenantList} />
    </>
  )

}


const TenantFilter = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput label="Search tenant" source="expression" alwaysOn variant="outlined" autoFocus/>
  </Filter>
);

export default Dashboard;
