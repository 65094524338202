import React, {useState} from 'react'
import {
  Dialog,
  makeStyles
} from '@material-ui/core'
import { useTranslate } from 'ra-core';
import { Formik } from 'formik'
import * as Yup from 'yup';
import {getAllTenants} from "../../queries/tenant/get-all-tenants";
import {useEffectAsync} from "../../use-effect-async";
import {MessageCreateForm} from "./MessageCreateForm";
import {createMessage} from "../../queries";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '0px !important',
  }
}))

const validationSchema = Yup.object().shape({
  title: Yup.string().required("You need to write a message title!"),
  message: Yup.string().required("You need to write a message!"),
})

interface INewMessageDialogProps {
  isDialogOpen: boolean
  setIsDialogOpen(state): any
}


export const MessageCreateDialog = (props: INewMessageDialogProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<null | HTMLButtonElement>(null)
  const [tenants, setTenants] = useState([])
  const { isDialogOpen, setIsDialogOpen } = props

  useEffectAsync(async () => {
    setTenants(await getAllTenants())
  }, [])

  const classes = useStyles()
  const translate = useTranslate()
  const initialData = {
    title: '',
    message: '',
    sentDate: new Date(),
    recipient: null,
    specificTenants: false,
    adminOnlyVisible: false,
    sendInApp: true,
    sendViaEMail: false,
    sendViaSMS: false,
    scheduledDate: null,
    tenantIds: [],
  }

  return (
    <>
        <Dialog className={classes.modal} open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              console.log(values)
              // @ts-ignore
              const createMessageParams = {
                title: values.title,
                message: values.message,
                sentDate: values.sentDate,
                recipient: values.adminOnlyVisible ? '' : JSON.stringify(values.tenantIds),
                adminOnlyVisible: values.adminOnlyVisible,
                sendInApp: values.sendInApp,
                sendViaEMail: values.sendViaEMail,
                sendViaSMS: values.sendViaSMS,
                scheduledDate: values.scheduledDate || null,
              };

              await createMessage({data: createMessageParams})
              setIsDialogOpen(false)
            }}
          >
            {(
            {
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              errors,
              setFieldValue,
            }
          ) =>
            <MessageCreateForm
              values={values}
              handleChange={handleChange as any}
              handleBlur={handleBlur as any}
              handleSubmit={handleSubmit}
              translate={translate}
              tenants={tenants}
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              setIsDialogOpen={setIsDialogOpen}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          }
          </Formik>
        </Dialog>
    </>
  )
}
