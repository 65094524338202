import { client } from '../../graphql/client';
import { UpdateClient } from '../../graphql/generated-queries/update-client-queries';

export async function updateClient({ data }) {
  const {
    tenant,
    category,
    createdAt,
    updatedAt,
    __typename,
    ...updateData
  } = data;

  const tenantId = tenant ? tenant.id : null;

  if(category) {
    for (const notificationType of ["dontSendNotifications", "dontSendBirthdayNotification"]){
      updateData[`${notificationType}`] = category.includes(notificationType)
    }
  }

  const result = await client.mutate({
    mutation: UpdateClient,
    variables: {input: {
        tenant: {id: tenantId},
        ...updateData
      }}
  });
  return { data: result.data.updateClient };
}
