import React, {useEffect, useState} from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  EditButton,
  PasswordInput,
  SelectInput,
  Filter,
  SelectField,
  FormDataConsumer,
  CheckboxGroupInput,
  required
} from "react-admin";
import {useMediaQuery, Theme} from "@material-ui/core";
import {
  validateNonRequiredPassword,
  validatePassword,
  validateEmail,
  validatePhoneNumber, validateRequiredPhoneNumber,
  validateTenantRole,
  validateUsername
} from "../service/validator";
import CloseIcon from "@material-ui/icons/Close";
import {USER_TENANT_ROLES, USER_TENANT_ROLES_MAP} from "../constants";
import PhoneNumberField from "./components/PhoneNumberField";
import SimpleList from "./components/SimpleList";
import {
  SaveCancelDeleteToolbar,
  SaveDeleteToolbar
} from "./components/Toolbars";
import { css } from "emotion";
import { useTranslate } from "ra-core";
import { ListActions } from "./components/ActionBar";
import {EmptyItemView} from "./components/EmptyItemView";
import {UserGlobalNotification} from "./components/UserGlobalNotification";
import authProvider from "../authProvider";

const mobileFormTitleStyle = css`
  position: fixed;
  background-color: white;
  color: black;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;
const mobileCloseStyle = css`
  position: absolute;
  right: 20px;
`;

export const UsersList = props => {
  window.hasSuperiusAdminChanges = false;
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const translate = useTranslate();

  if (isXSmall) {
    return (
      <>
        <UserGlobalNotification/>
        <List
          {...props}
          perPage={10}
          bulkActions={false}
          exporter={false}
          filters={<UserFilter/>}
          empty={
            <EmptyItemView
              {...props}
              title={translate("sps.labels.emptyUsers")}
            />
          }
        >
          <SimpleList
            linkType={"edit"}
            primaryText={record => record.username}
            secondaryText={record => record.phoneNumber}
            tertiaryText={record => {
              return USER_TENANT_ROLES_MAP[record.tenantRoles[0].role];
            }}
          />
        </List>
      </>
    );
  }

  return (
    <>
      <UserGlobalNotification/>
      <List
        {...props}
        sort={{field: "username", order: "ASC"}}
        actions={<ListActions {...props} />}
        perPage={25}
        bulkActionButtons={false}
        filters={<UserFilter/>}
        undoable={false}
        empty={
          <EmptyItemView {...props} title={translate("sps.labels.emptyUsers")}/>
        }
      >
        <Datagrid rowClick="edit">
          <TextField source="username" label={'sps.labels.username'}/>
          <TextField source="phoneNumber" label={'ra.auth.phone_number'}/>
          <TextField source="email" label={'sps.labels.email'}/>
          <SelectField source="tenantRoles[0].role" choices={USER_TENANT_ROLES} label="sps.labels.tenantRole"
                       sortable={false}/>
          <EditButton basePath="/user"/>
        </Datagrid>
      </List>
    </>
  );
};

const MobileFormTitle = ({ title, record }) => {
  return (
    <div className={mobileFormTitleStyle}>
      {record && record.username ? record.username : title}
      <CloseIcon
        className={mobileCloseStyle}
        onClick={() => (window.location.href = "/user")}
      />
    </div>
  );
};

export const UserCreate = props => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const translate = useTranslate();

  window.hasSuperiusAdminChanges = true;
  return (
    <Create
      title={
        isXSmall ? (
          <MobileFormTitle title={"Aggiungi un utente"} record={null} />
        ) : (
          "Aggiungi un utente"
        )
      }
      {...props}
    >
      <SimpleForm
        autocomplete="chrome-off"
        redirect="list"
        variant="outlined"
        toolbar={isXSmall ? <SaveDeleteToolbar/> : <SaveCancelDeleteToolbar/>}
      >
        <TextInput
          source="username"
          label={'sps.labels.username'}
          validate={validateUsername}
          autoFocus={true}
          format={v => (v ? v.trimStart() : v)}
        />
        <PasswordInput source="password" validate={validatePassword} autocomplete={'new-password'} />
        <TextInput source={"firstName"} isRequired={false} label={'sps.labels.first_name'}/>
        <TextInput source={"lastName"} isRequired={false} label={'sps.labels.last_name'}/>
        <TextInput source={"email"} isRequired={false} validate={validateEmail} label={'sps.labels.email'}/>
        <FormDataConsumer>
          {({formData}) => {
            console.log(formData)
            const isRequired = formData && formData.sms && formData.sms.length > 0
            return (
              <PhoneNumberField source="phoneNumber" validate={isRequired ? validateRequiredPhoneNumber : validatePhoneNumber} label={'sps.labels.phone_number'}/>)
          }}
        </FormDataConsumer>
        <SelectInput source="assignedTenantRole" validate={validateTenantRole} choices={USER_TENANT_ROLES}
                     label={'sps.labels.tenantRole'}/>
        <CheckboxGroupInput
          source="sms"
          label=""
          choices={[
            {
              id: "sendSMSPassword",
              name: `${translate("sps.labels.sendPasswordViaSMS")}`
            }
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.username}"` : ""}</span>;
};

export const UsersEdit = props => {
  const [permission, setPermission] = useState('USER');
  const [tenantRole, setTenantRole] = useState('');
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  useEffect(() => {
    (async () => {
      const permissions = await authProvider.getPermissions()
      const tenantRole = await authProvider.getTenantRole()
      setPermission(permissions)
      setTenantRole(tenantRole)
    })()
  })

  window.hasSuperiusAdminChanges = true;

  return (
    <Edit
      title={
        isXSmall ? <MobileFormTitle {...props} /> : <UserTitle {...props} />
      }
      {...props}
      undoable={false}
    >
      <SimpleForm
        autocomplete="chrome-off"
        redirect="list"
        variant="outlined"
      // @ts-ignore
        toolbar={isXSmall ? <SaveDeleteToolbar permission={permission} tenantRole={tenantRole}/> :
        // @ts-ignore
          <SaveCancelDeleteToolbar permission={permission} tenantRole={tenantRole}/>}
      >
        <TextInput
          source="username"
          label={'sps.labels.username'}
          validate={validateUsername}
          format={v => (v ? v.trimStart() : v)}
        />
        <PasswordInput
          source="password"
          type="password"
          validate={validateNonRequiredPassword}
          autoFocus={true}
        />
        <TextInput source={"firstName"} isRequired={false}  label={'sps.labels.first_name'} />
        <TextInput source={"lastName"} isRequired={false} label={'sps.labels.last_name'} />
        <TextInput source={"email"} isRequired={false} label={'sps.labels.email'}  validate={validateEmail} />
        <PhoneNumberField source="phoneNumber" validate={validatePhoneNumber}  />
        <SelectInput
          source="assignedTenantRole"
          choices={USER_TENANT_ROLES}
          defaultValue={USER_TENANT_ROLES[0].id}
          label={'sps.labels.tenantRole'}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

const UserFilter = props => (
  <Filter {...props} variant="outlined">
    <TextInput label="sps.labels.search_by_username" source="username" alwaysOn />
  </Filter>
);
