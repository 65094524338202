import { client } from '../../graphql/client';
import { CreateHairdresserSalon } from "../../graphql/generated-queries/create-hairdresser-salon-queries";
import { getSelectedTenant } from '../../service/store';

export async function createHairdresserSalon(input) {
  try {
    const tenant = getSelectedTenant();
    const tenantId = tenant ? tenant.id : null;
    if (tenantId) {
      input.data.tenant = {
        id: tenantId
      }
    }

    const { data } = await client.mutate({ mutation: CreateHairdresserSalon, variables: { input: {...input.data} } });
    return { data: {...data.createHairdresserSalon} };
  } catch (err) {
    console.error(err);
  }
}
