import * as queries from './queries/index';

export const capitalize = (s, toLowerCase = false) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + ( toLowerCase ? s.slice(1).toLowerCase() : s.slice(1) );
}

export const graphqlDataProvider =  {
  getList: async (resource, params) => {
    const cb =  queries['get'  + capitalize(resource) + 's'];
    if(cb) {
      return cb(params)
    }
    return Promise.reject('Get List for resource: ' + resource + ' not implemented');
  },
  getOne: (resource, params) => {

    const cb =  queries['get'  + capitalize(resource)];
    if(cb) {
      return cb(params)
    }
    return Promise.reject('Get One for resource: ' + resource + ' not implemented');
  },
  getMany: (resource, params) => {

    const cb =  queries['get'  + capitalize(resource) + 's'];
    if(cb) {
      return cb(params)
    }
    return Promise.reject('Get Many for resource: ' + resource + ' not implemented');
  },
  getManyReference: (resource, params) => {

    const cb =  queries['get'  + capitalize(resource) + 's'];
    if(cb) {
      return cb(params)
    }
    return Promise.reject('Get Many Reference for resource: ' + resource + ' not implemented');
  },
  create: (resource, params) => {

    const cb =  queries['create'  + capitalize(resource)];
    if(cb) {
      return cb(params)
    }
    return Promise.reject('Create for resource: ' + resource + ' not implemented');
  },
  update: (resource, params) => {

    const cb =  queries['update'  + capitalize(resource)];
    if(cb) {
      return cb(params)
    }
    return Promise.reject('Update for resource: ' + resource + ' not implemented');
  },
  updateMany: (resource, params) => {

    const cb =  queries['update'  + capitalize(resource) + 's'];
    if(cb) {
      return cb(params)
    }
    return Promise.reject('Update Many for resource: ' + resource + ' not implemented');
  },
  delete: (resource, params) => {

    const cb =  queries['remove'  + capitalize(resource)];
    if(cb) {
      return cb(params)
    }
    return Promise.reject('Delete for resource: ' + resource + ' not implemented');
  },
  deleteMany: (resource, params) => {

    const cb =  queries['remove' + capitalize(resource) + 's'];
    if(cb) {
      return cb(params)
    }
    return Promise.reject('Delete Many for resource: ' + resource + ' not implemented');
  }
};

