/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const CreateGroup = gql`
  mutation CreateGroup($input: GroupCreateInput!) {
    createGroup(input: $input) {
      id
      name
      tenant {
          id
          companyName
      }
    }
  }
`;
