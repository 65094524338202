import { client } from "../../graphql/client";
import { UpdateTenant } from "../../graphql/generated-queries/update-tenant-queries";
import {getSelectedTenant, setSelectedTenant} from "../../service/store";

export async function updateTenant({ data }) {
  const {
    id,
    vatNumber,
    isVentilazioneIVA,
    status,
    companyName,
    fiscalCode,
    vatId,
    ateco,
    firstName,
    lastName,
    address,
    zip,
    city,
    province,
    state,
    hasHairAndBeauty,
    reaCode,
    appSettings,
    monthlyData,
    offerValidity,
    currency,
    secretSharedKey,
    iban,
    bic,
    bankAccountName,
  } = data;

  let successiveReceipt = null;
  let useQuotes = null;
  let sendAutomaticMonthly = null;
  const myAppSettings = {};
  if (appSettings) {
    useQuotes = appSettings['useQuotes'];
    myAppSettings["successiveReceipt"] = !!appSettings['successiveReceipt'];
    myAppSettings["cardReader"] = !!appSettings['cardReader']
    myAppSettings["internetPackage"] = !!appSettings['internetPackage']
  }
  if (monthlyData) {
    sendAutomaticMonthly = monthlyData.includes("sendAutomaticMonthly");
  }
  let offerValidityInt = null;
  if (offerValidity) {
    // @ts-ignore
    offerValidityInt = parseInt(offerValidity);
  }

  let result;
  try {
    result = await client.mutate({
      mutation: UpdateTenant,
      variables: {
        input: {
          id,
          vatNumber,
          isVentilazioneIVA: isVentilazioneIVA,
          status,
          companyName,
          fiscalCode,
          vatId,
          firstName,
          lastName,
          ateco,
          address,
          zip,
          city,
          hasHairAndBeauty,
          province,
          state,
          reaCode,
          successiveReceipt,
          useQuotes,
          sendAutomaticMonthly,
          secretSharedKey,
          currency,
          offerValidity: offerValidityInt,
          appSettings: myAppSettings,
          iban,
          bic,
          bankAccountName,
        }
      }
    });
  } catch (e) {
    console.error(e);
    throw new Error(e.graphQLErrors?.[0]?.message || e);
  }

  const resultData = result.data.updateTenant;
  const selectedTenant = getSelectedTenant();
  if(selectedTenant && resultData && parseInt(selectedTenant.id) === parseInt(resultData.id) &&  parseInt(resultData.id) !== 0) {
    setSelectedTenant(resultData)
  }

  return { data: resultData };
}
