import { client } from '../../graphql/client';
import { UpdatePDV } from '../../graphql/generated-queries/update-pdv-queries';

export async function updatePdv({ data }) {
  try {
    const { internalName = undefined, name = undefined, shortName = undefined, percentage, id } = data;
    const { data: success } = await client.mutate({
      mutation: UpdatePDV ,
      variables: { input: { percentage: parseFloat(percentage), internalName, name, shortName, id } } });
    if (success) {
      return { data: { id, internalName, name, shortName, percentage } };
    }
  } catch (err) {
    console.error(JSON.stringify(err));
    throw new Error(err.graphQLErrors?.[0]?.message || err)
  }
}
