
import { client } from '../../graphql/client';
import { DeleteUsers } from '../../graphql/generated-queries/delete-users-queries';

export async function removeUser(input) {
  try {

    await client.mutate({
      mutation: DeleteUsers,
      variables: {ids: [input.id] }
    });

    return { data: {id: null} };
    // return null;
  } catch (err) {
    console.error(err);
  }
}
