import React, { useState} from 'react'
import {useEffectAsync} from "../../use-effect-async";
import { Formik } from 'formik'
import {css, cx} from "emotion";
import {Button, FormControlLabel, Switch, TextField, Theme, useMediaQuery} from "@material-ui/core";
import { useTranslate } from 'ra-core'
import MuiPhoneInput from 'material-ui-phone-number'
import {getHairdresserSalon, updateHairdresserSalon} from "../../queries";
import { Link } from 'react-router-dom';
import moment from 'moment';

const containerStyle = css`
  display: flex;
  flex-direction: column;
`
const buttonContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  padding-top: 20px;
  width: 50%;
  margin-left: auto;
`
const cancelModalText = css`
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.6);
  font-size: 16px;
`;
const dividerContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  margin-bottom: 20px;
`
const sideDivider = css`
  height: 50%;
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
`
const buttonStyle = css`
  width: 49%;
  padding: 5px !important;
`
const inputStyle = css`
  margin-bottom: 15px !important;
`
const switchInputStyle = css`
  justify-content: flex-end;
`
const inputRowStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`
const mobileInputRowStyle = css`
  flex-direction: column;
`
const mobileButtonContainerStyle = css`
  width: 100%;
  margin-left: unset;
`

export const HSMessages = () => {
  const translate = useTranslate()
  const [data, setData] = useState<any | undefined>(undefined)
  const salonId = window.location.pathname.split('/')[2]
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('xs')
  });

  useEffectAsync(async ()=> {
    const result = await getHairdresserSalon({id: salonId})
    if(result && result.data) {
      const {phoneNumber, birthdayMessages, signOnMessage, activateAppointmentReminder, personalizedMessage} = result.data
      setData({
        phoneNumber: phoneNumber,
        signOnMessage: signOnMessage,
        activateAppointmentReminder: activateAppointmentReminder,
        birthdayMessages: birthdayMessages,
        personalizedMessage: personalizedMessage,
        reminderDisplay: `${translate('sps.labels.weRemindYou')} ${moment(new Date()).format('DD/MM/YYYY')} alle 12:00. ${translate('sps.labels.ifYouDontCome')}#phoneNumber#. #signOnMessage# (via Marketino)`,
        personalizedDisplay: `${translate('sps.labels.happyBirthday')}#personalizedMessage#`,
      })

      // console.log('data', result.data)
    }
  }, [])

  return (
    <div className={containerStyle}>
      {data &&
        <Formik
          initialValues={{...data}}
          onSubmit={async (value) => {
            await updateHairdresserSalon({data:{...value, id: salonId}})
            window.location.href = '/hairdresserSalon'
          }}
        >
          {({values, errors, handleChange, handleSubmit, handleBlur, setFieldValue}) =>
            <>
              <span
                className={cancelModalText}
                style={{marginBottom: 20, color: 'rgba(0,0,0,0.3)'}}
              >
                {translate('sps.labels.settingsRelatedTo')}
              </span>
              <div className={cx([inputRowStyle, isXSmall && mobileInputRowStyle])}>
                <MuiPhoneInput
                  className={inputStyle}
                  style={{marginRight: isXSmall ? 0 : 15}}
                  name="phoneNumber"
                  label={translate('sps.labels.phoneContact')}
                  value={values.phoneNumber}
                  variant="outlined"
                  onChange={(val) => setFieldValue('phoneNumber', val)}
                  defaultCountry={'it'}
                />
                <TextField
                  className={inputStyle}
                  name="signOnMessage"
                  label={translate('sps.labels.signOnMessage')}
                  value={values.signOnMessage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={
                    {maxLength: 50}
                  }
                  variant="outlined"
                />
              </div>
              <div className={dividerContainerStyle}>
                <span className={cancelModalText} style={{marginRight: 2}}>{translate('sps.labels.reminder')}</span>
                <div style={{width: '100%'}}>
                  <div className={sideDivider}/>
                  <div style={{height: '50%'}}/>
                </div>
              </div>
              <TextField
                className={inputStyle}
                name="signOnMessage"
                label={translate('sps.labels.display')}
                value={values.reminderDisplay.replace('#phoneNumber#', `${values.phoneNumber || ''}`).replace('#signOnMessage#', `${values.signOnMessage || ''}`)}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                multiline rows={5}
                disabled
              />
              <FormControlLabel
                className={cx([inputStyle,!isXSmall && switchInputStyle])}
                control={
                  <Switch
                    name="activateAppointmentReminder"
                    checked={values.activateAppointmentReminder}
                    onChange={(e, val) => setFieldValue('activateAppointmentReminder', val)}
                    onBlur={handleBlur}
                    color='primary'
                  />}
                label={translate('sps.labels.createAppointmentReminder')}
                labelPlacement='start'
              />
              <div className={dividerContainerStyle}>
                <span className={cancelModalText} style={{marginRight: 2}}>{translate('sps.labels.birthday')}</span>
                <div style={{width: '100%'}}>
                  <div className={sideDivider}/>
                  <div style={{height: '50%'}}/>
                </div>
              </div>
              <TextField
                className={inputStyle}
                name="personalizedMessage"
                label={translate('sps.labels.personalizedMessage')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.personalizedMessage}
                multiline rows={2}
                inputProps={
                  {maxLength: 120}
                }
                variant="outlined"
              />
              <TextField
                className={inputStyle}
                name="signOnMessage"
                label={translate('sps.labels.display')}
                value={values.personalizedDisplay.replace('#personalizedMessage#', `${values.personalizedMessage || ''}`)}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                multiline rows={5}
                disabled
              />
              <FormControlLabel
                className={cx([inputStyle,!isXSmall && switchInputStyle])}
                control={
                  <Switch
                    name="birthdayMessages"
                    checked={values.birthdayMessages}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    color='primary'
                  />}
                label={translate('sps.labels.birthdayMessages')}
                labelPlacement='start'
              />
              <div className={cx([buttonContainerStyle, isXSmall && mobileButtonContainerStyle])}>
                {!isXSmall &&
                <Button
                  className={buttonStyle}
                  variant="outlined"
                  component={Link}
                  to={{pathname: "/hairdresserSalon"}}
                >
                  {translate('sps.labels.cancel')}
                </Button>}
                <Button
                  className={cx([buttonStyle, isXSmall && css`width: 100%;padding: 0px !important;`])}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit as any}
                >
                  {translate('sps.labels.save')}
                </Button>
              </div>
            </>
          }
        </Formik>}
    </div>
  )
}
