/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const CreateMessage = gql`
  mutation CreateMessage($input: MessageCreateInput!) {
    createMessage(input: $input) {
      id
      title
      scheduledDate
      message
      annotation
      sentDate
      adminOnlyVisible
      recipient
      sendInApp
      sendViaEMail
      sendViaSMS
    }
  }
`;
