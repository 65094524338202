import { client } from '../../graphql/client';
import { PDV } from '../../graphql/generated-queries/pdv-queries';

export async function getPdv(input) {
  try {
    const { data } = await client.query({ query: PDV, variables: { id: input.id } });
    return { data: data.pDV };
  } catch (err) {
    console.error(err);
  }
}
