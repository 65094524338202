//@ts-nocheck
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';

import authProvider from './authProvider';
import { i18nProvider } from './i18n-provider';
import { graphqlDataProvider } from './build-query';
import { ArticlesList, ArticlesEdit, ArticleCreate } from './views/ArticlesList';
import { GroupsList, GroupsEdit, GroupCreate } from './views/GroupsList';
import { ProfileEdit } from './views/ProfileEdit';
import { UsersList, UserCreate, UsersEdit } from './views/UsersList';
import { ManagersList, ManagerCreate, ManagerEdit } from './views/ManagersList';
import { CustomLayout } from './views/CustomLayout';
import { TenantEdit, TenantCreate } from './views/TenantsList';
import { ClientsEdit, ClientsList, ClientsCreate } from './views/ClientsList';
import { EmployeeEdit, EmployeeCreate } from './views/EmployeeList';
import { DeviceCreate, DevicesEdit, DevicesList } from './views/DevicesList';
import { PDVSList, PDVCreate, PDVEdit } from './views/PDVSList';
import CustomLoginPage from './pages/CustomLoginPage';
import Theme from './theme';
import Dashboard from './views/Dashboard';
import { getSelectedTenant } from './service/store';
import UserIcon from '@material-ui/icons/PeopleOutlineOutlined';
import SalonIcon from '@material-ui/icons/EditLocationOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DevicesIcon from '@material-ui/icons/Devices';
import GroupIcon from '@material-ui/icons/DeviceHub';
import EuroIcon from '@material-ui/icons/Euro';
import ArticleIcon from '@material-ui/icons/Toc';
import ContactMailIcon from '@material-ui/icons/ContactMail'
import { createBrowserHistory as createHistory } from 'history';

import './admin.css';
import { AppointmentAppRoot } from './appointment-app/AppointmentAppRoot';
import {
  HairdresserSalonCreate,
  HairdresserSalonList,
  HairdresserSalonEdit
} from "./views/HairdresserSalonList";
import {ArticleQuickList} from "./views/ArticleQuickList";
import {AdvancedTenantSettings} from "./views/AdvancedTenantSettings";
import {MessagesList} from "./views/Messages";
import {css, cx} from "emotion";
import {useTheme} from "@material-ui/core";

const smallContainerStyle = css`
  .RaLayout-content-4 {
    padding: 0px !important;
  }
`

export const App = observer(() => {
  window.localStorage.setItem('document.referrer', window.location.pathname)
  const selectedTenant = getSelectedTenant();
  const history = createHistory();
  const theme = useTheme()
  const isXSmall = theme.breakpoints.down('xs')
  const viewport = document.getElementsByName('viewport')
  if(viewport && viewport[0]) {
    viewport[0].content = "width=device-width, minimum-scale=1"
  }

  return (
    <div className={cx([isXSmall && smallContainerStyle])}>
      <Admin
        dataProvider={graphqlDataProvider}
        authProvider={authProvider}
        theme={Theme}
        history={history}
        i18nProvider={i18nProvider}
        title={'Superius Administrator Dashboard'}
        dashboard={Dashboard}
        loginPage={CustomLoginPage}
        customRoutes={[
          <Route
            key="my-profile"
            path="/my-profile"
            component={ProfileEdit}
          />,
          <Route
            key="article-quick"
            path="/article-quick"
            component={ArticleQuickList}
          />,
          <Route
            key="hair-and-beauty"
            path="/hair-and-beauty"
            noLayout
            component={AppointmentAppRoot}
          />,
          <Route
            key="advanced-settings"
            path="/advanced-settings"
            component={AdvancedTenantSettings}
          />
        ]}
        layout={CustomLayout}
      >
        {permissions => [
          selectedTenant ? <Resource name="device" undoable={false} list={DevicesList} edit={DevicesEdit} create={['ADMIN', 'SUPPORT'].includes(permissions) ? DeviceCreate : null} icon={DevicesIcon} /> : null,
          selectedTenant &&
          <Resource name="user" undoable={false} list={UsersList} edit={UsersEdit}
                    create={UserCreate} icon={UserIcon}/>,
          selectedTenant &&
          <Resource name="group" undoable={false} list={GroupsList} edit={GroupsEdit}
                    create={GroupCreate} icon={GroupIcon}/>,
          selectedTenant && <Resource name="article" undoable={false} options={{ label: 'sps.labels.articles' }} list={ArticlesList} edit={ArticlesEdit} create={ArticleCreate} icon={ArticleIcon} />,

          selectedTenant && <Resource name="hairdresserSalon" undoable={false} options={{ label: 'sps.labels.salon' }} list={HairdresserSalonList} edit={HairdresserSalonEdit} create={HairdresserSalonCreate} icon={SalonIcon} />,
          selectedTenant && <Resource name="employee" undoable={false} options={{ label: 'sps.labels.employees' }} edit={EmployeeEdit} create={EmployeeCreate} icon={SupervisorAccountIcon} />,

          selectedTenant && <Resource name="client" undoable={false} options={{ label: 'sps.labels.client' }} list={ClientsList} edit={ClientsEdit} create={ClientsCreate} icon={UserIcon} />,
          !selectedTenant && <Resource name="user" edit={UsersEdit} undoable={false} />,
          <Resource name="tenant" edit={TenantEdit} icon={UserIcon} undoable={false} create={TenantCreate} />,
          !selectedTenant && permissions === 'ADMIN' ? <Resource name="pdv" options={{ label: 'sps.labels.vat' }} undoable={false} list={PDVSList} create={PDVCreate} edit={PDVEdit} icon={EuroIcon} /> : null,
          (selectedTenant || (!selectedTenant && permissions === 'SUPPORT')) && <Resource name="pdv" options={{ label: 'sps.labels.vat' }} icon={EuroIcon} undoable={false} />,
          !selectedTenant && permissions === 'ADMIN' && <Resource name="manager" options={{ label: ('sps.labels.allUsers') }} undoable={false} list={ManagersList} edit={ManagerEdit} create={ManagerCreate} icon={SupervisorAccountIcon} />,
          !selectedTenant && permissions === 'ADMIN' && <Resource name="message" options={{ label: ('sps.labels.messages') }} undoable={false} list={MessagesList} edit={ManagerEdit} create={ManagerCreate} icon={ContactMailIcon} />,
        ]}
      </Admin>
    </div>
  );
});




