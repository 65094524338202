/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const CreateTenant = gql`
  mutation CreateTenant($input: TenantCreateInput!) {
    createTenant(input: $input) {
      id
      tenantId
      vatNumber
      isVentilazioneIVA
      status
      companyName
      fiscalCode
      vatId
      firstName
      ateco
      lastName
      address
      zip
      city
      hasHairAndBeauty
      province
      state
      reaCode
      successiveReceipt
      useQuotes
      sendAutomaticMonthly
      secretSharedKey
      offerValidity
      createdAt
      updatedAt
      currency
      bic
      iban
      bankAccountName
    }
  }
`;
