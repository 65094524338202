import polyglotI18nProvider from "ra-i18n-polyglot";
import english from "ra-language-english";
import italian from "ra-language-italian";
import { merge } from "lodash";
import { getSelectedLanguage } from "./service/store";
import itLocale from "date-fns/locale/it";
import enLocale from "date-fns/locale/en-GB";

const preferredLanguage = getSelectedLanguage();

const DEFAULT_LANGUAGE = preferredLanguage || "it";

const englishMessages = merge(english, {
  ra: {
    auth: {
      send_recovery: "Send recovery code",
      phone_number: "Phone Number",
      reset_code: "Reset Code",
      reset_password: "Reset password"
    },
    message: {
      delete_title: "Are you sure?",
      delete_content:
        "Are you sure you want to proceed? Upon deleting, it won't be displayed on the list."
    }
  },
  sps: {
    auth: {
      forgot_password: "Forgot password",
      sign_in_as_user: "Sign in as user?"
    },
    validation: {
      must_be_16_chars_long: "Must be 16 characters long",
      must_be_7_chars_long: "Must be 7 characters long",
      must_be_8_chars_long: "Must be 8 characters long",
      vat_must_be_numeric: "VAT must be an numeric value",
      exact_length: "Must be %{length} characters",
      vat_exists: "There exists VAT with all or some of those parameters",
      vat_number: "Wrong VAT number",
      invalid_fiscal_code: "Invalid Fiscal Code",
      invalid_vat_number: "Invalid VAT Number"
    },
    labels: {
      details: "Options",
      activation_required: "Attenzione! Per procedere con l'utilizzo devi completare l'attivazione.",
      add: "Add",
      save: "Save",
      edit: "Edit",
      search: "Search",
      status: "Status",
      cancel: "Cancel",
      calendar: "Hair & Beauty",
      annotation: "Note",
      employees: "Employees",
      start: "Start",
      duration: "Duration",
      enter_the_beginning: "Start time",
      enter_the_duration: "Duration",
      send_a_reminder: "Send a reminder",
      select_employee: "Select Employee",
      search_by_name: "Search by name",
      search_by_price: "Search by price",
      search_by_username: "Search by username",
      start_typing: "Start typing...",
      add_an_appointment: "Create an appointment",
      edit_appointment: "Edit appointment",
      remove_appointment: "Remove appointment",
      remove_from_ppointment: "Remove from appointment",
      article_quick_entry: "Quick Entry",
      hair_and_beauty: "Hair & Beauty",
      date_of_appointment: "Date of the appointment",
      add_customer: "Add customer",
      client_not_selected: "Customer not selected",
      employee_not_selected: "Employee not selected",
      add_employee: "Add employee",
      add_new_client: "Add new client",
      physical_person: "Private",
      operational_headquarters: "Operational headquarters",
      delete_appointment_message:
        "Are you sure that you want to delete the appointment?",
      delete_appointment_message_2:
        "Eliminando questo appuntamento si eliminano anche gli appuntamenti collegati che si ripetono.",
      ignore_appointment_overlap_message:
        "There is an overlap with the selected time, do you want to continue and add the appointment?",
      company: "Company",
      first_name: "First name",
      last_name: "Last name",
      mobile_phone: "Mobile phone",
      email: "E-mail",
      lottery_code: "Lottery code",
      birthday: "Birthday",
      note: "Note",
      person_type: "Person type",
      company_name: "Company name",
      fiscal_code: "Fiscal code",
      vat_number: "VAT number",
      address: "Address",
      postal_code: "Postal code",
      city: "City",
      province: "Province",
      country: "Country",
      type: "Type",
      contact: "Contact",
      insert: "Insert",
      username: "Username",
      username_taken: "Username already taken",
      all_groups: "All Groups",
      is_ventilazione_iva: "Is Ventilazione IVA?",
      advanced_settings: "Advanced",
      app_back: "Back to Shop",
      line: "Line",
      successiveReceipt:
        "After creating the receipt, start creating the next receipt",
      useQuotes: "Use offers",
      validationEstimate: "Number of days",
      sendAutomaticMonthly: "Automatically send monthly data",
      choose: "CHOOSE",
      accountingDate: "Accounting date",
      selectMonth: "Select month",
      selectEmployee: "Select employee",
      selectService: "Select service",
      insertEmail: "Enter the email",
      reportPeriods:
        "Select the period and the email on which you want to send the reports",
      sales: "Sales",
      printOnEnd: "Print this text at the end of each receipt",
      offerValidity: "Validity of the offer",
      accounting: "Accounting",
      sendDataManually: "Send the data manually",
      data: "Data",
      selectTheData: "Here you can select the data to send via email",
      title: "Subject",
      writeAMessage: "Write a message",
      onlyToSomeTenants: "Send only to some tenants",
      visibleToAdmins: "Visible for admins only",
      scheduledSend: "Scheduled send",
      sendInApp: "Send inApp message",
      sendViaEMail: "Send via e-mail",
      sendViaSMS: "Send via SMS",
      close: "close",
      wayOfWorking: "Way of working",
      messages: "Messages",
      schedule: "Schedule",
      salonName: "Salon name",
      overlapWarn: "Notify me if appointments are overlapping",
      reminderForNewAppointment:
        "Remind me to offer new appointments after provided service",
      signOnMessage: "Signture on the message",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      weRemindYou: "We want to remind you of the appointment scheduled on ",
      ifYouDontCome: "If you are not able to come, please let us know on ",
      yourHairSalon: "Your hair salon (via Marketino)",
      display: "Display",
      createAppointmentReminder:
        "Enable reminder notifications when I create an appointment",
      phoneContact: "Phone contact",
      personalizedMessage: "Personalised message",
      birthdayMessages: "Turn on birthday messages",
      dontSendNotifications: "Dont send notifications",
      dontSendBirthdayNotification: "Dont send birthday notifications",
      happyBirthday: "We wish you a happy birthday. ",
      reminder: "Reminder",
      settingsRelatedTo:
        "Here you can set everything related to sending reminders and birthday greetings to your clients.",
      sendReminderTooltip:
        'You can turn notifications on or off for all appointments in the Salon - Messages - Set a reminder when I create an appointment section',
      numberOfResults: "Number of results: ",
      day: "Day",
      startWork: "Start work",
      endWork: "End work",
      addShift: "Add shift",
      remove: "Remove",
      create: "Create",
      name: "Name",
      enterEdit: "Do you want to enter edit mode?",
      exitEdit: "Do you want to exit edit mode without saving?",
      groupsExistAttention:
        "The group can't be deleted because there are items in it. Please remove all the items and then try again.",
      attention: "Attention",
      delete: "Delete",
      secretSharedKey: "Secret Shared Key",
      oldDate:
        "You have selected a date that has already passed, do you want to proceed with the appointment?",
      yes: "yes",
      week: "Week",
      month: "Month",
      day3: "3 days",
      today: "Today",
      all: "All",
      inWeekNumber: "IN NUM WEEKS",
      repeat: "Repeat",
      currency: "Currency",
      service: "Service",
      services: "Services",
      addService: "Add service",
      warnOfFreeAppointments: "Notify me about free appointments",
      no_employees:
        "In order to start using the calendar to book appointments, we advise you to add at least one employee to your salon.",
      frequency: "Frequency",
      dontRepeat: 'Doesn"t repeat',
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
      confirmed: "Confirmed",
      with: "with",
      reprogram: "Reschedule",
      executed: "Processed",
      canceled: "Canceled",
      not_arrived: "Not arrived",
      calendarView: "Calendar view",
      salon: "Salon",
      personal: "Personal",
      allPersonal: "All personal",
      requiredField: "Required field",
      selectToReprogram: "Select a time to book",
      selectGroup: "Select group",
      startTyping: "Start typing",
      selectVAT: "Select VAT",
      cardReader: "Card Reader",
      internetPackage: "Internet Package",
      selectArticleType: "Select an article type",
      startingFrom: "Starting from",
      bic: "BIC (SWIFT code)",
      iban: "IBAN",
      bankAccountName: "Bank account name",
      articleForbiddenName:
        "The name of the article must not contain words such as: Total, Discount, Amount, Cash",
      toUseCalendar:
        "To use the calendar to book appointments, you should add the details of your salon and employee",
      youHaveNoWaiting: "You have no appointments on the waiting list",
      howToAddList: "How to add an appointment on the list",
      toAddAppointment:
        'To add an appointment you must select the "Notify me about free appointments" option in the appointment details',
      client: "Client",
      timeDisplayedInCalendar:
        "The time is displayed in the appointment calendar",
      remindToOffer: "Don't forget to schedule the next appointment",
      lengthShorthenThan: "Length must be shorter than",
      codeDoesntExist: "This code does not exist",
      emptyGroups: "Add groups and start working",
      emptyArticles: "Add articles and start working",
      emptyUsers: "Add users and start working",
      emptyDevices: "Add devices and start working",
      emptySalon: "Add salon and start working",
      emptyClient: "Add clients and start working",
      setPin: "Set pin for drawer opening",
      private: "Private",
      legal: "Legal",
      companyName: "Company name",
      pinLength: "PIN must be 4 numeric digits",
      sendPasswordViaSMS: "Send the password via sms",
      header: "Header",
      footer: "Footer",
      useCustomHeader: "Use custom header",
      clientData: "Client data",
      appointments: "Appointments",
      partitaIvaRequired: "VAT number is a required",
      allUsers: "All Users",
      vat: "VAT",
      articles: "Articles",
      allTenants: "All Tenants",
      tenantList: "Tenant List",
      tenantRole: "Tenant Role",
      onboarding_header: "Welcome to Marketino!",
      onboarding_subtitle: "Attiva il tuo account compilando un paio di informazioni",
      discount: "Discount %",
      price: "Price",
      group: "Group",
      EXECUTED: 'Executed',
      CANCELED: 'Canceled',
      CONFIRMED: 'Confirmed',
      NOT_ARRIVED: 'Not arrived',
      EXPECTED: 'Expected',
      search_entity: {
        groups: "Search groups...",
        clients: "Search clients..."
      }
    }
  },
  resources: {
    hairdresserSalon: {
      empty: "In this section you can set up everything related to your salon.",
      invite:
        "To organise your calendar, we advise you to enter the information regarding your salon."
    },
    device: {
      empty: "Add your device and start working!",
      invite: ""
    },
    user: {
      empty: "Here you can add your users!",
      invite: ""
    },
    group: {
      name: "Group",
      empty: "In this section you can create groups for your articles.",
      invite: ""
    },
    article: {
      name: "Article |||| Articles",
      empty: "Add articles and organise them better in groups.",
      invite: "",
      fields: {
        name: "Name",
        type: "Type",
        price: "Price",
        actionPrice: "Action Price",
        discountPercentage: "Discount Percentage",
        discountPercentageShort: "Discount %",
        discountAmount: "Discount Amount",
        barCode: "Bar Code",
        group: "Group"
      }
    },
    client: {
      empty: "Here you can add your clients!",
      invite: ""
    }
  }
});

const italianMessages = merge(italian, {
  ra: {
    auth: {
      send_recovery: "Invia il codice di recupero",
      phone_number: "Numero di telefono",
      reset_code: "Reimposta codice",
      reset_password: "Reimposta la password"
    },
    message: {
      delete_title: "Sei sicuro di voler procedere?",
      delete_content: "Appena eliminato non sara' visibile più nella lista."
    },
    validation: {
      email: 'Contabilità email non e" valido'
    },
    action: {
      create: "Aggiungi"
    }
  },
  sps: {
    auth: {
      forgot_password: "Hai dimenticato la password",
      sign_in_as_user: "Accedi come utente?"
    },
    validation: {
      must_be_16_chars_long: "Deve contenere 16 caratteri",
      must_be_7_chars_long: "Deve contenere 7 caratteri",
      must_be_8_chars_long: "Deve contenere 8 caratteri",
      vat_must_be_numeric: "La partita IVA deve essere un valore numerico",
      exact_length: "Deve contenere %{length} caratteri",
      invalid_vat_number: "Partita IVA non e' valida",
      invalid_fiscal_code: "Codice fiscale non e' valido"
    },
    labels: {
      activation_required: "Attenzione! Per procedere con l'utilizzo devi completare l'attivazione.",
      details: "Opzioni",
      add: "Aggiungi",
      save: "Salva",
      edit: "Modifica",
      search: "Cerca",
      status: "Stato",
      cancel: "Cancella",
      calendar: "Hair & Beauty",
      annotation: "Nota",
      employees: "Operatori",
      start: "Inizio",
      duration: "Durata",
      enter_the_beginning: "Orario di inizio",
      enter_the_duration: "Durata",
      send_a_reminder: "Inviare un promemoria",
      select_employee: "Scegli l'impiegato",
      search_by_name: "Ricerca per nome",
      search_by_price: "Ricerca per prezzo",
      search_by_username: "Ricerca per nome utente",
      start_typing: "Inizia a digitare...",
      add_an_appointment: "Aggiungi un appuntamento",
      edit_appointment: "Modifica l'appuntamento",
      hair_and_beauty: "Hair & Beauty",
      remove_appointment: "Rimuovi appuntamento",
      remove_from_appointment: "Rimuovi dall' appuntamento",
      article_quick_entry: "Inserimento veloce",
      date_of_appointment: "Data dell' appuntamento",
      add_customer: "Aggiungi cliente",
      client_not_selected: "Cliente non selezionato",
      employee_not_selected: "Operatore non selezionato",
      add_employee: "Aggiungi l'operatore",
      add_new_client: "Aggiungi nuovo cliente",
      physical_person: "Persona fisica",
      operational_headquarters: "Sede operativa",
      delete_appointment_message:
        "Sei sicuro di voler rimuovere l'appuntamento?",
      delete_appointment_message_2:
        "Eliminando questo appuntamento si eliminano anche gli appuntamenti collegati che si ripetono.",
      company: "Azienda",
      first_name: "Nome",
      last_name: "Cognome",
      mobile_phone: "Numero di cellulare",
      email: "Email",
      lottery_code: "Codice lotteria",
      birthday: "Compleanno",
      note: "Nota",
      person_type: "Tipo",
      company_name: "Ragione sociale",
      fiscal_code: "Codice fiscale",
      vat_number: "Partita IVA",
      address: "Indirizzo",
      postal_code: "CAP",
      city: "Città",
      province: "Provincia",
      country: "Stato",
      type: "Tipo",
      contact: "Contatto",
      insert: "Inserisci",
      username: "Nome utente",
      username_taken: "Nome utente gia' in uso",
      all_groups: "Tutti i Gruppi",
      is_ventilazione_iva: "Ventilazione IVA?",
      advanced_settings: "Avanzato",
      app_back: "Ritorna allo Shop",
      line: "Linea",
      successiveReceipt:
        "Dopo aver creato lo scontrino, inizia subito a creare uno scontrino nuovo",
      useQuotes: "Usa i preventivi",
      validationEstimate: "N. di giorni",
      ignore_appointment_overlap_message:
        "C'è una sovrapposizione con l'orario selezionato, vuoi continuare e aggiungere l'appuntamento?",
      sendAutomaticMonthly: "Invia in automatico i dati mensili via email",
      choose: "SCEGLI",
      accountingDate: "Dati contabilità",
      selectMonth: "Seleziona il mese",
      selectEmployee: "Seleziona personale",
      selectService: "Seleziona servizio",
      insertEmail: "Indirizzo email",
      reportPeriods:
        'Seleziona il periodo e l"email su quale inviare i dati contabilità',
      sales: "Vendite",
      printOnEnd: "Stampa questo testo alla fine di ogni scontrino",
      offerValidity: "Validità dei preventivi",
      accounting: "Contabilità",
      sendDataManually: "Invia i dati manualmente",
      data: "Dati",
      selectTheData: "Qui poi selezionare i dati per inviarli tramite email",
      title: "Soggetto",
      writeAMessage: "Scrivi il tuo messaggio",
      onlyToSomeTenants: "Invia solo ad alcuni account",
      visibleToAdmins: "Visibile solo per i admin",
      scheduledSend: "Invio programmato",
      sendInApp: "Invia messaggio tramite app",
      sendViaEMail: "Invia via email",
      sendViaSMS: "Invia via SMS",
      close: "chiudi",
      wayOfWorking: "Opzioni salone",
      messages: "SMS",
      schedule: "Orario",
      salonName: "Nome del salone",
      overlapWarn: "Avvisami se gli appuntamenti si sovrappongono",
      reminderForNewAppointment:
        "Ricordami di prenotare nuovi appuntamenti dopo il servizio fornito",
      signOnMessage: "Firma sul messaggio",
      monday: "Lunedì",
      tuesday: "Martedì",
      wednesday: "Mercoledì",
      thursday: "Giovedì",
      friday: "Venerdì",
      saturday: "Sabato",
      sunday: "Domenica",
      weRemindYou: "Il tuo appuntamento e' previsto per il ",
      ifYouDontCome: "Nel caso non ce la facessi, avvisaci al ",
      yourHairSalon: "Il tuo salone Capelli (via Marketino)",
      display: "Visualizzazione",
      createAppointmentReminder:
        "Imposta un promemoria quando creo un appuntamento",
      phoneContact: "Numero di contatto",
      personalizedMessage: "Messaggio personalizzato",
      birthdayMessages: "Attiva i messaggi per il compleanno",
      happyBirthday: "Ti auguriamo un buon compleanno. ",
      dontSendNotifications: "Non inviare notifiche",
      dontSendBirthdayNotification: "Non inviare notifiche di compleanno",
      reminder: "Promemoria",
      settingsRelatedTo:
        'Qui puoi settare le impostazioni relative all"invio di messaggi di promemoria e auguri di compleanno ai tuoi clienti.',
      sendReminderTooltip:
        'Puoi attivare o disattivare le notifiche per tutti i appuntamenti nella sezione Salone - Messaggi - Imposta un promemoria quando creo un appuntamento',
      numberOfResults: "Numero di risultati: ",
      day: "Giorno",
      startWork: "Inizio lavoro",
      endWork: "Fine lavoro",
      addShift: "Aggiungi turno",
      remove: "Elimina",
      create: "Aggiungi",
      name: "Nome",
      enterEdit: "Vuoi entrare in modalità modifica?",
      exitEdit: "Vuoi uscire dalla modalità modifica senza salvare?",
      groupsExistAttention:
        "Il gruppo non può essere eliminato perché contiene elementi. Rimuovi tutti gli elementi e riprova.",
      attention: "Attenzione",
      delete: "Elimina",
      secretSharedKey: "Secret Shared Key",
      oldDate:
        "Hai selezionato una data gia’ passata, vuoi procedere con l’appuntamento?",
      yes: "si",
      week: "Settimana",
      month: "Mese",
      day3: "3 giorni",
      today: "Oggi",
      all: "tutti",
      inWeekNumber: "TRA NUM SETTIMANA",
      repeat: "Ripeti",
      currency: "Currency",
      service: "Servizio",
      services: "Servizi",
      addService: "Aggiungi servizio",
      warnOfFreeAppointments: "Avvertimi su appuntamenti liberi",
      no_employees:
        "In modo da poter usare il calendario per prenotare i appuntamenti deve essere aggiunto almeno un impiegato al tuo salone.",
      frequency: "Frequenza",
      dontRepeat: "Non si ripete",
      daily: "Quotidiano",
      weekly: "Settimanalmente",
      monthly: "Mensile",
      confirmed: "Confermato",
      with: "con",
      reprogram: "Riprogramma",
      executed: "Eseguito",
      canceled: "Annullato",
      not_arrived: "Non arrivato",
      EXECUTED: 'Eseguito',
      CANCELED: 'Annullato',
      CONFIRMED: 'Confermato',
      NOT_ARRIVED: 'Non arrivato',
      EXPECTED: 'Previsto',
      calendarView: "Visualizzazione calendario",
      salon: "Salone",
      personal: "Personale",
      allPersonal: "Tutto il personale",
      requiredField: "Campo obbligatorio",
      selectToReprogram: "Seleziona un orario per prenotare",
      selectGroup: "Scegli il gruppo",
      startTyping: "Inizia a digitare",
      selectVAT: 'Scegli l"IVA',
      cardReader: 'Lettore Carta',
      internetPackage: 'Opzione Internet',
      selectArticleType: "Scegli il tipo di articoli",
      startingFrom: "a partite da",
      bic: "BIC (Codice swift)",
      iban: "IBAN",
      bankAccountName: "Nome associato al conto",
      articleForbiddenName:
        "Il nome del articolo non deve contenere parole come: Totale, Sconto, Importo, Contante",
      toUseCalendar:
        "Per usare il calendario per prenotare i appuntamenti, dovresti aggiungere i dati del tuo salone e l'operatore",
      youHaveNoWaiting: 'Non hai appuntamenti sulla lista d\'attesa',
      howToAddList: "Come aggiungere un appuntamento sulla lista",
      toAddAppointment:
        'Per aggiungere un appuntamento devi selezionare l"opzione "Avvertimi su appuntamenti liberi" nei dettagli del appuntamento',
      client: "Cliente",
      timeDisplayedInCalendar:
        "L'orario viene visualizzato nel calendario degli appuntamenti",
      remindToOffer: "Non dimeticare di prenotare l'appuntamento successivo",
      lengthShorthenThan: "La lunghezza deve essere inferiore a",
      codeDoesntExist: "Questo codice non esiste",
      emptyGroups: "Aggiungi i gruppi e comincia a lavorare",
      emptyArticles: "Aggiungi gli articoli e comincia a lavorare",
      emptyUsers: "Aggiungi utenti e inizia a lavorare",
      emptyDevices: "Aggiungi dispositivi e inizia a lavorare",
      emptySalon: "In questa sezione puoi settare tutto relativo al tuo salone",
      emptyClient: "Aggiungi clienti e inizia a lavorare",
      setPin: "Imposta pin per l'apertura del cassetto",
      private: "Persona fisica",
      legal: "Azienda",
      companyName: "Ragione Sociale",
      pinLength: "PIN deve essere di 4 cifre numeriche",
      sendPasswordViaSMS: "Invia la password tramite sms",
      header: "Intestazione",
      footer: "Footer",
      useCustomHeader: "Usa il print header personalizzato",
      clientData: "Dati del cliente",
      appointments: "Appuntamenti",
      partitaIvaRequired: "Partita IVA e' obbligatoria",
      allUsers: "Tutti gli Utenti",
      vat: "IVA",
      articles: "Articoli",
      allTenants: "Tutti i tenant",
      tenantList: "Lista dei tenant",
      tenantRole: "Tenant ruolo",
      onboarding_header: "Benvenuto a Marketino!",
      onboarding_subtitle: "Attiva il tuo account compilando un paio di informazioni",
      discount: "Sconto",
      price: "Prezzo",
      group: "Gruppo",
      search_entity: {
        groups: "Cerca gruppo...",
        clients: "Cerca cliente..."
      }
    }
  },
  resources: {
    group: {
      name: "Gruppo |||| Gruppi",
      fields: {
        name: "Nome"
      },
      empty: "In questa sezione puoi creare gruppi per i tuoi articoli.",
      invite: ""
    },
    article: {
      name: "Articolo |||| Articoli",
      fields: {
        id: "id",
        name: "Nome",
        type: "Tipo",
        price: "Prezzo",
        actionPrice: "Prezzo promo",
        discountPercentage: "Sconto",
        discountPercentageShort: "Sconto",
        discountAmount: "Sconto",
        barCode: "Codice a barre",
        group: "Gruppo"
      },
      empty:
        "Aggiungi i articoli e organizzali meglio associandoli al proprio gruppo.",
      invite: ""
    },
    hairdresserSalon: {
      empty: "In questa sezione puoi settare tutto relativo al tuo salone.",
      invite:
        "In modo da poter usare il calendario per prenotare i appuntamenti deve essere aggiunto almeno un impiegato al tuo salone."
    },
    device: {
      name: "Dispositivo |||| Dispositivi",
      empty: "Aggiungi i dispositivi e comincia a lavorare!",
      invite: "",
      fields: {
        rtCode: "RT Codice",
        serialNumber: "Numero di serie",
        friendlyName: "Descrizione",
        status: "Stato",
        address: "Indirizzo",
        city: "Città"
      }
    },
    user: {
      empty: "Aggiungi i tuoi utenti!",
      invite: ""
    },
    client: {
      name: "Cliente |||| Clienti",
      empty: "Aggiungi i tuoi clienti!",
      invite: ""
    }
  }
});
export const i18nProvider = polyglotI18nProvider(
  locale => (locale === "it" ? italianMessages : englishMessages),
  DEFAULT_LANGUAGE
);

export const i18nDateProvider = () => {
  return i18nProvider.getLocale() === "it" ? itLocale : enLocale;
};
