// @ts-nocheck
import React, {
  useState
} from 'react';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
  createStyles,
} from '@material-ui/core/styles';
import {Notification} from 'react-admin'
import ForgotPasswordForm from './form/ForgotPasswordForm'
import {storeUserTokenData} from '../auth'
import { withTranslate } from 'react-admin';
import DefaultLoginForm from './form/CustomLoginForm'
import LocaleSwitcher from "../views/components/LocaleSwitcher";
import {setSelectedTenant} from "../service/store";

const styles = (theme) =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
    link: {
      marginTop: '20px',
    }
  });

const Login = (props) => {

  const [currentForm, setCurrentForm] = useState('login')
  const theme = createMuiTheme(props.theme);
  const urlParams = new URLSearchParams(window.location.search)

  if(urlParams.has('session_token')) {
    const sessionToken = urlParams.get('session_token');
    const decodedToken = storeUserTokenData(sessionToken)
    const domain = process.env.REACT_APP_COOKIE_DOMAINS ? (';domain=' + process.env.REACT_APP_COOKIE_DOMAINS) : ''
    document.cookie = 'token=' + urlParams.get('session_token') + domain
    let showOnboarding = false;
    if (decodedToken && decodedToken.user && decodedToken.user.tenant) {
      setSelectedTenant(decodedToken.user.tenant)
      if(!decodedToken.user.tenant.iban) {
        showOnboarding = true
      }
    }
    if (urlParams.has('redirect_to')) {
      window.location.href = '/' + urlParams.get('redirect_to')
    } else if (showOnboarding) {
      window.location.href = '/tenant/' + decodedToken.user.tenant.id
    } else {
      window.location.href = '/article'
    }

    return
  }


  const showForgotPasswordForm = (e) => {
    e.preventDefault()
    setCurrentForm('forgot-password')
  }

  const showLoginForm = (e) => {
    e.preventDefault()
    setCurrentForm('login')
  }

  const { translate } = props;
  const { classes, className, ...rest } = props;

  let showForm = <DefaultLoginForm/>;

  if (currentForm === 'forgot-password') {
    showForm = <ForgotPasswordForm/>
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div
        className={classnames(classes.main, className)}
        {...rest}
      >
        <img src="/marketino_txt_logo.jpg" alt="marketino logo" style={{maxWidth: '80%'}}/>
        <Card className={classes.card}>
          {showForm}
        </Card>

        {currentForm === 'login' &&
        <Link className={classes.link} href="#" onClick={(e) => showForgotPasswordForm(e)}>
          {translate('sps.auth.forgot_password')}
        </Link>}

        {currentForm === 'forgot-password' &&
        <Link className={classes.link} href="#" onClick={(e) => showLoginForm(e)}>
          {translate('sps.auth.sign_in_as_user')}
        </Link>}

        <Notification/>
        <LocaleSwitcher/>
      </div>
    </MuiThemeProvider>
  );


}

const LoginPage = withStyles(styles)(withTranslate(Login));

export default LoginPage;
