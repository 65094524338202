/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const HairdresserSalons = gql`
  query HairdresserSalons {
    hairdresserSalons {
      id
      name
      address
      city
      postalCode
      province
      order
      warnIfOverlapping
      remindOffer
      phoneNumber
      signOnMessage
      activateAppointmentReminder
      personalizedMessage
      birthdayMessages
      schedule
      employeeOrder
      tenant {
        id
        vatNumber
        companyName
      }
      createdAt
      updatedAt
    }
  }
`;


export const SearchHairdresserSalons = gql`
  query SearchHairdresserSalons($tenantId: EntityId, $perPage: Int, $skip: Int, $order: [HairdresserSalonSearchOrderInput!]) {
    searchHairdresserSalons(search: {tenant: {id: $tenantId}}, order:$order,
      skip: $skip,
      take: $perPage
    ) {
      total
      items {
        id
        name
        address
        city
        postalCode
        province
        order
        warnIfOverlapping
        remindOffer
        phoneNumber
        signOnMessage
        activateAppointmentReminder
        personalizedMessage
        birthdayMessages
        schedule
        employeeOrder
        tenant {
          id
          vatNumber
          companyName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

