// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  useQuery,
  Loading,
  useMutation
} from "react-admin";
// Be sure to include styles at some point, probably during your bootstrapping
import Datasheet from "react-datasheet/lib/DataSheet";
import Select from "react-select";
import Button from "@material-ui/core/Button";

import "react-datasheet/lib/react-datasheet.css";
import { css, injectGlobal, keyframes, cx } from "emotion";
import { updateElementCurrentTenant } from "../appointment-app/repository";
import EditIcon from "@material-ui/icons/CreateOutlined";
import ReadModeIcon from "@material-ui/icons/VisibilityOutlined";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Modal from "@material-ui/core/Modal";
import { useTranslate } from "ra-core";

const ignoreOverlapModal = css`
  width: 230px;
  height: 130px;
  background-color: white;
  &:focus {
    outline: none !important;
  }
`;
const placeholderStyle = css`
  color: rgba(0, 0, 0, 0.3);
  text-align: start !important;
  margin-bottom: 15px;
  font-size: 14px !important;
`;
const deleteTextStyle = css`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
`;
const modalContainer = css`
  align-items: center;
  justify-content: center;
  display: flex;
`;
const ignoreOverlapModalText = css`
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 12px;
  font-size: 16px;
  height: 60%;
`;
const ignoreOverlapModalButtonsContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px;
`;
const ignoreOverlapModalButton = css`
  width: 70px;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
`;
const pulseKeyframes = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;
const pulseButtonStyle = css`
  animation: ${pulseKeyframes} 2s infinite;
`;

injectGlobal`
.cell {
 span {
  text-align: left;
  padding: 5px;
 }
}

tr:first-child td.cell:first-child, tr:first-child td.cell:nth-child(2) {
  width: 25%;
}

.data-grid-container .data-grid .cell {
  background: white !important;
}

.data-grid-container .data-grid .cell.error {
  border: 2px solid red !important;
}

td {
  max-height: 35px !important;
  .MuiButton-text {
    padding: 0px !important;
  }
  .MuiButton-root {
    padding: 0px !important;
    font-size: unset !important;
    line-height: unset !important;
  }
}

.data-grid-container .data-grid tr:first-child .cell {
  background: white !important;
  padding: 20px 16px !important;
}

.data-grid-container .data-grid .cell.selected {
  box-shadow: none !important;
  border-color: #702588 !important;
}

.data-grid-container .data-grid .cell.read-only {
  background: #FAFAFA !important;
}

.data-grid-container .data-grid tr:first-child .cell.read-only {
  background: #F4F4F4 !important;
  position: sticky;
  top: 0px;
}

.data-grid-container .data-grid tr .cell:nth-child(3) span {
  text-align: right !important;
}

.rad {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  display: inline-block;
}
.rad > input { /* HIDE ORG RADIO & CHECKBOX */
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.rad > .icon { 
  display: inline-block;
  vertical-align: middle;
  width:  50px;
  height: 36px;
  color: rgba(0,0,0,0.38);
  transition: 0.2s;
  border: 1px solid rgba(0,0,0,0.12);
  background: white;
  text-align: center;
}

.rad:first-child > .icon { 
  border-radius: 4px 0 0 4px;
}

.rad:last-child > .icon { 
  border-radius: 0 4px 4px 0;
}

.icon > svg { 
  width: 70%;
  height: 70%;
  top: 15%;
  position: relative;
}

.rad:hover > .icon{ 
  background: white;
}

.rad > input:checked + .icon{ 
  background-color: #702588;
  color: white;
  border-color: #702588;
}

.data-grid {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  width: 100%;
}

.data-grid tr .value-viewer {
  color: rgba(0,0,0,0.87);
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-grid tr:first-child .value-viewer {
  color: rgba(0,0,0,0.6);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
}
.cell > input {
  border: none !important;
}
.data-editor {
  font-size: 20px;
  padding: 8px 0px 8px 8px;
  text-align: start !important;
}
`;

function getResource(resource) {
  return {
    type: "getList",
    resource: resource,
    payload: {
      pagination: { page: 0, perPage: 10000 },
      sort: { field: "id", order: "ASC" },
      filter: {}
    }
  };
}

export const ArticleQuickList = () => {
  const { data: articleData, loading: loadingArticleData } = useQuery(
    getResource("article")
  );
  const { data: taxData, loading: loadingTaxData } = useQuery(
    getResource("pdv")
  );
  const { data: groupData, loading: loadingGroupData } = useQuery(
    getResource("group")
  );

  const [createArticle, { loading: createLoading }] = useMutation();
  const [removeArticle, { loading: removeLoading }] = useMutation();
  const [updateArticle, { loading: updateLoading }] = useMutation();
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [listEnd, setListEnd] = useState(undefined);
  const translate = useTranslate();

  useEffect(() => {
    if (listEnd) {
      listEnd.scrollIntoView({ behavior: "smooth" });
    }
  }, [listEnd]);

  if (
    loadingArticleData ||
    loadingTaxData ||
    loadingGroupData ||
    createLoading ||
    updateLoading ||
    loadingProcess ||
    removeLoading
  ) {
    if (
      !loadingArticleData &&
      !loadingTaxData &&
      !loadingGroupData &&
      !createLoading &&
      !updateLoading &&
      !removeLoading
    ) {
      setLoadingProcess(false);
    }
    return <Loading />;
  }

  const onSave = (articles, removedArticles) => {
    setLoadingProcess(true);
    const toCreate = [];
    const toUpdate = [];
    const toRemove = [];

    for (let i = 0; i < articles.length; i+=1) {
      const article = articles[i];
      if (!article.name || article.name.trim() === "") {
        continue;
      }
      if (!article.id) {
        // if(!article.group) {
        //   errors[i + 1 + "-" + 0] = true;
        //   hasErrors = true;
        // }
        // if(!article.pdv) {
        //   errors[i + 1 + "-" + 3] = true;
        //   hasErrors = true;
        // }
        // if(!article.type) {
        //   errors[i + 1 + "-" + 4] = true;
        //   hasErrors = true;
        // }
        // if(hasErrors) {
        //   this.setState({ errors });
        //   return;
        // }
        toCreate.push({
          name: article.name,
          price: parseFloat(
            (article.price || "0").toString().replace(",", ".")
          ),
          type: article.type,
          group: {
            id: article.group.id
          },
          pdv: {
            id: article.pdv.id
          }
        });
      } else if(article.touched) {
        toUpdate.push({
          ...article,
          price: parseFloat((article.price || "0").toString().replace(",", "."))
        });
      }
    }
    for (let article of removedArticles) {
      if (article.id) {
        toRemove.push({
          id: article.id
        });
      }
    }

    let counter = toCreate.length + toUpdate.length + toRemove.length;
    if(counter === 0) {
      window.location.reload();
    }
    const onSuccess = () => {
      counter -= 1;
      if (counter <= 0) {
        window.location.reload();
      }
    };

    for (let article of toUpdate) {
      updateArticle(updateElementCurrentTenant("article", { ...article }), {
        onSuccess: onSuccess
      });
    }

    for (let article of toCreate) {
      createArticle(
        {
          type: "create",
          resource: "article",
          payload: { data: article }
        },
        {
          onSuccess: onSuccess
        }
      );
    }
    for (let article of toRemove) {
      removeArticle(
        {
          type: "delete",
          resource: "article",
          payload: { id: article.id }
        },
        {
          onSuccess: onSuccess
        }
      );
    }
  };

  return (
    <div>
      <ComponentSheet
        translate={translate}
        articles={articleData}
        tax={taxData}
        groups={groupData}
        onSave={onSave}
      />
      <div ref={el => setListEnd(el)} />
    </div>
  );
};

class ComponentSheet extends React.Component {

  constructor(props) {
    super(props);
    this.groups = props.groups.map(g => {
      return {
        label: g.name,
        value: g
      };
    });
    this.vats = props.tax.map(g => {
      return {
        label: g.name + " (" + g.percentage + ")",
        value: g
      };
    });
    this.types = [
      {
        label: "Servizio",
        value: "servizio"
      },
      {
        label: "Prodotto",
        value: "prodotto"
      },
      {
        label: "Buono Multiuso",
        value: "multiuso"
      }
    ];
    this.state = {
      articles: props.articles.sort((a, b) => a.group.id - b.group.id),
      removedArticles: [],
      selected: {
        start: {
          i: 1,
          j: 0
        },
        end: {
          i: 1,
          j: 0
        }
      },
      errors: {},
      inEditMode: false,
      showEditModal: false,
      showEditModeExitModal: false,
      exitedEditMode: false,
      href: ""
    };
  }

  groupValue = id => {
    if (this.state.articles[id]) {
      const { group } = this.state.articles[id];
      return group && group.name ? group.name : "";
    } else {
      return "";
    }
  };
  vatValue = id => {
    if (this.state.articles[id]) {
      const { pdv } = this.state.articles[id];
      return pdv && pdv.name ? pdv.name : "";
    } else {
      return "";
    }
  };
  typeValue = id => {
    if (this.state.articles[id]) {
      const { type } = this.state.articles[id];
      return type;
    } else {
      return "";
    }
  };
  articleName = id => {
    if (this.state.articles[id]) {
      const { name } = this.state.articles[id];
      return name;
    } else {
      return "";
    }
  };
  articlePrice = id => {
    if (this.state.articles[id]) {
      const { price } = this.state.articles[id];
      if (price == null && !this.state.errors[id + 1 + "-" + 2]) {
        let errors = this.state.errors; //TODO: copy this one
        errors[id + 1 + "-" + 2] = true;
        this.setState({ errors });
      }
      return price != null ? Number(price).toFixed(2) : null;
    } else {
      return "";
    }
  };
  setArticleName = (id, name) => {
    const articles = this.state.articles;
    if (!articles[id]) {
      let group = this.groups[0] || {};
      if (articles[id - 1]) {
        group = articles[id - 1].group;
      }
      let pdv = this.vats[0] || {};
      if (articles[id - 1]) {
        pdv = articles[id - 1].pdv;
      }
      let type = this.types[0] || {};
      if (articles[id - 1]) {
        type = articles[id - 1].type;
      }
      articles[id] = {
        // add default group and pdv
        group,
        pdv,
        type,
        price: null
      };
    }
    articles[id].name = name;
    articles[id].touched = true;
    this.setState({ articles });
  };

  setArticlePrice = (id, price) => {
    const articles = this.state.articles;
    if (!articles[id]) {
      let group = this.groups[0] || {};
      if (articles[id - 1]) {
        group = articles[id - 1].group;
      }
      let pdv = this.vats[0] || {};
      if (articles[id - 1]) {
        pdv = articles[id - 1].pdv;
      }
      let type = this.types[0] || {};
      if (articles[id - 1]) {
        type = articles[id - 1].type;
      }
      articles[id] = {
        // add default group and pdv
        group,
        pdv,
        type
      };
    }
    articles[id].price = price;
    articles[id].touched = true;
    this.setState({ articles });
  };

  setArticleVAT = (id, vatName) => {
    const articles = this.state.articles;
    if (articles[id] && this.vats.length > 0) {
      const pdvSet = this.vats.find(e => e.value && e.value.name === vatName);
      if(pdvSet && pdvSet.value) {
        articles[id].pdv = pdvSet.value;
        articles[id].touched = true;
        this.setState({ articles });
      }
    }
  };

  groupComponent = id => {
    const val =
      this.state &&
      this.state.articles[id] &&
      this.state.articles[id].group &&
      this.state.articles[id].group.name;
    return (
      <Select
        autoFocus
        defaultMenuIsOpen
        value={[{ value: this.state && this.state.articles[id], label: val }]}
        onChange={(a, b) => {
          const articles = this.state.articles;
          if (!articles[id]) {
            articles[id] = {};
          }
          articles[id].touched = true;
          articles[id].group = a.value;
          if(!articles[id].pdv) {
            let pdv = this.vats[0] || {};
            if (articles[id - 1]) {
              pdv = articles[id - 1].pdv;
            }
            articles[id].pdv = pdv
          }
          if(!articles[id].type) {
            let type = this.types[0] || {};
            if (articles[id - 1]) {
              type = articles[id - 1].type;
            }
            articles[id].type = type
          }

          this.setState({ articles });
        }}
        options={this.groups}
      />
    );
  };

  vatComponentDisabled = id => {
    const type =
      this.state &&
      this.state.articles[id] &&
      this.state.articles[id].type
    return type === 'multiuso'
  }

  vatComponent = id => {
    const val =
      this.state &&
      this.state.articles[id] &&
      this.state.articles[id].pdv &&
      this.state.articles[id].pdv.name;

    return (
      <Select
        autoFocus
        defaultMenuIsOpen
        value={[{ value: this.state && this.state.articles[id], label: val }]}
        onChange={(a, b) => {
          const articles = this.state.articles;
          if (!articles[id]) {
            articles[id] = {};
          }

          articles[id].pdv = a.value;
          articles[id].touched = true;
          this.setState({ articles });
        }}
        options={this.vats}
      />
    );
  };

  typeComponent = id => {
    const val =
      this.state && this.state.articles[id] && this.state.articles[id].type;
    return (
      <Select
        autoFocus
        defaultMenuIsOpen
        value={[{ value: this.state && this.state.articles[id], label: val }]}
        onChange={a => {
          const articles = this.state.articles;
          if (!articles[id]) {
            articles[id] = {};
          }

          articles[id].type = a.value;
          articles[id].touched = true;
          this.setState({ articles });
        }}
        options={this.types}
      />
    );
  };

  onEnterEditMode = () => {
    this.setState({ showEditModal: true });
  };

  onExitEditMode = () => {
    this.setState({ inEditMode: false });
    window.onbeforeunload = null;
  };

  generateGrid = () => {
    let rows = [
      [
        {
          value: "Group",
          readOnly: true,
          component: <span className="value-viewer">Gruppo</span>,
          forceComponent: true,
          onDoubleClick: this.onDoubleClick
        },
        {
          readOnly: true,
          value: "Nome del articolo",
          onDoubleClick: this.onDoubleClick
        },
        { readOnly: true, value: "Articolo Prezzo" },
        {
          value: "Tax",
          readOnly: true,
          component: <span className="value-viewer">IVA</span>,
          forceComponent: true
        },
        {
          value: "Type",
          readOnly: true,
          component: <span className="value-viewer">Tipo</span>,
          forceComponent: true
        },
        {
          value: "",
          readOnly: true,
          component: <span className="value-viewer"></span>,
          forceComponent: true
        }
      ]
    ];
    rows = rows.concat(
      _.range(0, this.state.articles.length + 1).map(id => [
        {
          value: this.groupValue(id),
          component: this.groupComponent(id),
          readOnly: !this.state.inEditMode
        },
        { value: this.articleName(id), readOnly: !this.state.inEditMode },
        { value: this.articlePrice(id), readOnly: !this.state.inEditMode },
        {
          value: this.vatValue(id),
          component: this.vatComponent(id),
          readOnly: !this.state.inEditMode || this.vatComponentDisabled(id)
        },
        {
          value: this.typeValue(id),
          component: this.typeComponent(id),
          readOnly: !this.state.inEditMode
        },
        { value: "", component: <></>, readOnly: false }
      ])
    );
    return rows;
  };

  CellRenderer = props => {
    const translate = useTranslate();
    const {
      as: Tag,
      cell,
      row,
      col,
      columns,
      attributesRenderer,
      selected,
      editing,
      updated,
      style,
      ...rest
    } = props;

    const attributes = cell.attributes || {};
    // attributes.style = { width: columns[col].width }
    if (col === 0) {
      attributes.title = cell.label;
    }
    let isErrorable = true;
    const currentEditingCell = document.getElementsByClassName("editing");
    if (currentEditingCell.length) {
      isErrorable =
        // eslint-disable-next-line eqeqeq
        currentEditingCell[0].id.split(" ")[1] == row ? false : true;
      // console.log(currentEditingCell[0].id.split(" ")[1], row, isErrorable);
    }
    if (isErrorable && this.state.errors[row + "-" + col]) {
      // console.log(this.state.errors);
      rest.className += " error ";
    }

    if (
      col === 5 &&
      row > 0 &&
      row < this.state.articles.length + 1 &&
      this.state.inEditMode
    ) {
      return (
        <td className="cell">
          <span
            onClick={() => {
              const articles = this.state.articles;
              const removedArticles = this.state.removedArticles;
              removedArticles.push(articles[row - 1]);
              articles.splice(row - 1, 1);
              this.setState({
                articles,
                removedArticles
              });
            }}
            className={deleteTextStyle}
          >
            <DeleteOutlineIcon />
            {translate("sps.labels.remove").toUpperCase()}
          </span>
        </td>
      );
    }
    let placeholder = null;
    const editingCell = document.getElementsByClassName("data-editor");
    if (col === 0 && row === this.state.articles.length + 1) {
      placeholder = this.props.translate("sps.labels.selectGroup");
    }
    if (!editingCell.length || (editingCell[0] && !editingCell[0].value)) {
      if (col === 1 && row === this.state.articles.length + 1) {
        placeholder = this.props.translate("sps.labels.startTyping");
      }
      if (col === 2 && row === this.state.articles.length + 1) {
        placeholder = "0.00";
      }
    }
    if (col === 3 && row === this.state.articles.length + 1) {
      placeholder = this.props.translate("sps.labels.selectVAT");
    }
    if (col === 4 && row === this.state.articles.length + 1) {
      placeholder = this.props.translate("sps.labels.selectArticleType");
    }

    return (
      <td
        id={`${col} ${row}`}
        style={{
          padding: row === this.state.articles.length + 1 ? "20px 5px" : 0
        }}
        {...rest}
        {...attributes}
        onClick={a => {
          if (!this.state.inEditMode) {
            const inEdit = this.onEnterEditMode();
            if (!inEdit) {
              return;
            }
          }
          return props.onDoubleClick();
        }}
      >
        {props.children}
        {placeholder && <div className={placeholderStyle}>{placeholder}</div>}
      </td>
    );
  };

  render() {
    return (
      <>
        <div
          style={{
            marginTop: "16px",
            marginBottom: "16px",
            marginLeft: "50px",
            marginRight: "16px",
            display: "flex",
            alignItems: "center"
          }}
        >
          <div>
            <h4>{this.props.translate("sps.labels.article_quick_entry")}</h4>
          </div>
          <div style={{ marginLeft: "60px" }}>
            <label className="rad">
              <input
                type="radio"
                name="rad1"
                value="readOnly"
                checked={!this.state.inEditMode}
                onChange={() => {
                  this.setState({ showEditModeExitModal: true });
                }}
              />
              <div className="icon">
                <ReadModeIcon />
              </div>
            </label>
            <label className="rad">
              <input
                type="radio"
                name="rad1"
                value="editMode"
                checked={this.state.inEditMode}
                onChange={() => {
                  this.onEnterEditMode();
                }}
              />
              <div className="icon">
                <EditIcon />
              </div>
            </label>
          </div>
          {this.state.inEditMode && (
            <>
              <Button
                id={"quicksave-button-internal"}
                onClick={() => {
                  window.onbeforeunload = null;
                  this.props.onSave(
                    this.state.articles,
                    this.state.removedArticles
                  );
                }}
                style={{ marginLeft: "auto", color: "#702588" }}
                className={cx([this.state.exitedEditMode && pulseButtonStyle])}
              >
                <SaveIcon />
                <span style={{ marginLeft: "10px" }}>SAVE</span>
              </Button>
              <Button
                onClick={() => this.setState({ showEditModeExitModal: true })}
              >
                <RemoveIcon />
                <span style={{ marginLeft: "10px" }}>
                  {this.props.translate("sps.labels.cancel")}
                </span>
              </Button>
            </>
          )}
        </div>
        <Datasheet
          data={this.generateGrid()}
          valueRenderer={cell => cell.value}
          onCellsChanged={changes => {
            console.log(changes)
            for (let change of changes) {
              const errors = this.state.errors;
              delete errors[change.row + "-" + change.col];
              const id = change.row - 1;
              const index = change.col;
              let value = change.value;
              if (index === 1) {
                this.setArticleName(id, value);
              }
              if (index === 2) {
                if (value) {
                  value = value.replace(",", ".");
                }
                if (
                  isNaN(Number(value)) &&
                  change.row < this.state.articles.length
                ) {
                  errors[change.row + "-" + change.col] = true;
                  value = 0.0;
                  this.setArticlePrice(id, Math.abs(Number(value)).toFixed(2));
                } else if (Number(value) < 0 || Number(value) > 999999.99) {
                  errors[change.row + "-" + change.col] = true;
                } else {
                  this.setArticlePrice(id, Math.abs(Number(value)).toFixed(2));
                }
              }
              if (index === 4) {
                console.log('val', value)
                console.log('change', change)
                if(change.cell && change.cell.value === 'multiuso') {
                  this.setArticleVAT(id, 'N2')
                }
              }
              this.setState({ errors });
            }
          }}
          cellRenderer={this.CellRenderer}
          onSelect={selected => {
            if (!this.state.articles[selected.start.i - 1]) {
              selected.start.j = 1;
              selected.end.j = 1;
            }
            if (selected.start.j >= 5) {
              selected.start.j = 4;
            }
            if (selected.end.j >= 5) {
              selected.end.j = 4;
            }
            this.setState({ selected: selected });
          }}
          selected={this.state.selected}
        />

        {this.state.showEditModal && (
          <Modal className={modalContainer} open={this.state.showEditModal}>
            <div className={ignoreOverlapModal}>
              <div className={ignoreOverlapModalText}>
                {this.props.translate("sps.labels.enterEdit")}
              </div>
              <div className={ignoreOverlapModalButtonsContainer}>
                <Button
                  className={ignoreOverlapModalButton}
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    this.setState({ showEditModal: false });
                    window.onbeforeunload = null;
                  }}
                >
                  {this.props.translate("sps.labels.cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={ignoreOverlapModalButton}
                  onClick={() => {
                      this.setState({ showEditModal: false, inEditMode: true });
                      window.onbeforeunload = function() {
                        return "Your work will be lost.";
                      }; // makes chrome ask to refresh
                      document
                        .querySelectorAll('a[role="menuitem"')
                        .forEach(e => {
                          e.addEventListener("click", event => {
                            const href = e.href ? e.href : "";
                            event.stopPropagation();
                            event.preventDefault();
                            this.setState({
                              showEditModeExitModal: true,
                              href
                            });
                          });
                        });
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {this.state.showEditModeExitModal && (
          <Modal
            className={modalContainer}
            open={this.state.showEditModeExitModal}
          >
            <div className={ignoreOverlapModal}>
              <div className={ignoreOverlapModalText}>
                {this.props.translate("sps.labels.exitEdit")}
              </div>
              <div className={ignoreOverlapModalButtonsContainer}>
                <Button
                  className={ignoreOverlapModalButton}
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    this.setState({
                      showEditModeExitModal: false,
                      exitedEditMode: true
                    });
                    const a = document.getElementById(
                      "quicksave-button-internal"
                    );
                    if (a) {
                      a.focus();
                    }
                  }}
                >
                  {this.props.translate("sps.labels.cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={ignoreOverlapModalButton}
                  onClick={() => {
                      this.setState({
                        showEditModeExitModal: false,
                        inEditMode: false
                      });
                      window.onbeforeunload = null;
                      window.location.href = this.state.href;
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}
