/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const CreatePDV = gql`
  mutation CreatePDV($input: PDVCreateInput!) {
    createPDV(input: $input) {
      id
      internalName
      name
      shortName
      percentage
    }
  }
`;
