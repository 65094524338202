/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const UpdateTenant = gql`
  mutation UpdateTenant($input: TenantEditInput!) {
    updateTenant(input: $input) {
      id
      tenantId
      vatNumber
      isVentilazioneIVA
      status
      companyName
      fiscalCode
      ateco
      vatId
      hasHairAndBeauty
      firstName
      lastName
      address
      zip
      appSettings
      city
      province
      state
      reaCode
      offerValidity
      successiveReceipt
      useQuotes
      sendAutomaticMonthly
      secretSharedKey
      offerValidity
      currency
      createdAt
      updatedAt
      bic
      iban
      bankAccountName
    }
  }
`;


