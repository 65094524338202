import React, {useState} from "react";
import {Calendar, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import {useTranslate} from 'ra-core'
import {css} from "emotion";
import {i18nDateProvider} from "../../i18n-provider";
import {Header} from "../components/Header";

interface IDataPickerModal {
  selectedDate: any
  repeatOption: string | null
  repeatOptions: any
  setShowCalendarPickerModal(value): any
  setSelectedDate(value): any
  setRepeatOption(value): any
}

const saveButtonStyle = css`
  padding: 15px;
`

export const DatePickerModal = (props: IDataPickerModal) => {
  const {selectedDate, repeatOption, repeatOptions, setSelectedDate, setShowCalendarPickerModal, setRepeatOption} = props
  const translate = useTranslate()
  const [date, setDate]= useState(selectedDate)
  const [repeat, setRepeat] = useState(repeatOption)

  return (
    <>
      <Header
        title={translate('sps.labels.date_of_appointment')}
        handleClose={() => setShowCalendarPickerModal(false)}
        hasExit
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18nDateProvider()}>
        <Calendar
          date={date}
          onChange={((date) => setDate(date))}
        />
      </MuiPickersUtilsProvider>
      <Autocomplete
        defaultValue={repeat}
        disableClearable
        style={{margin: 15}}
        renderInput={(props) => <TextField {...props} variant='outlined'
                                           label={translate('sps.labels.frequency')}/>}
        options={Object.keys(repeatOptions)}
        onChange={(event, value) => {
          if (Object.keys(repeatOptions).includes(value)) {
            setRepeat(value)
          }
        }}
      />
      <div className={saveButtonStyle}>
        <Button
          color={"primary"}
          variant={"contained"}
          style={{width: '100%'}}
          onClick={() => {
            setSelectedDate(date)
            setRepeatOption(repeat)
            setShowCalendarPickerModal(false)
          }}
        >
          {translate('sps.labels.save')}
        </Button>
      </div>
    </>
  )
}
