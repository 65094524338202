import React, {useEffect, useState} from 'react';
import {useTranslate} from 'ra-core';
import { css, cx } from 'emotion';
import DateFnsUtils from '@date-io/date-fns';
import { repeatOptions } from '../../constants';

import {format, addHours, addMinutes, differenceInCalendarDays, differenceInHours, differenceInMinutes} from 'date-fns'
import TextField from '@material-ui/core/TextField';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {
  useMediaQuery,
  Theme,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  Switch,
  Popper,
  Grow,
  ClickAwayListener,
  Paper, Radio, RadioGroup,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RepeatIcon from '@material-ui/icons/Repeat';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import ArticleIcon from '@material-ui/icons/KeyboardArrowRight';
import PersonAddIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Modal from '@material-ui/core/Modal';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import {useMutation} from 'react-admin';
import { it } from 'date-fns/locale'
import Tooltip from '@material-ui/core/Tooltip';

import {Header} from '../components/Header';
import {EmployeePicker} from './EmployeePicker';
import {deleteElementCurrentTenant, getClientName, updateElementCurrentTenant} from '../repository';
import {Appointment} from '../interfaces/Appointment';
import {i18nDateProvider} from "../../i18n-provider";
import {ServicePicker} from "../components/ServicePicker";
import {ClientSearch} from "./ClientSearch";
import {Autocomplete} from "@material-ui/lab";
import {createAppointment} from "../../queries";
import {DatePickerModal} from "./DatePickerModal";
import {CustomTimeInput} from "../../views/components/CustomTimeInput";
import {ServiceSearch} from "./ServiceSearch";

const dateInput = css`
  //width: 30%;
  min-width: 250px !important;
  .MuiInput-underline:before {
    border: none !important;
  }
  .MuiInput-underline:after {
    border: none !important;
  }
`;
const timeAndDuration = css`
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  width: 30%;
`;
const mobileTimeAndDuration = css`
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-right: 0px;
`
const sendReminderStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const mobileSendReminderStyle = css`
  padding: 15px;
  flex-direction: column;
  margin-bottom: 15px;
  .MuiFormControlLabel-root {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-right: 15px;
  }
`
const marginBottom50 = css`
  margin-bottom: 50px;
`
const submitButton = css`
  position: fixed;
  width: inherit;
  background-color: white;
  padding-bottom: 16px;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const pageContent = css`
  padding: 15px;
  width: 70%;
  height: 100%;
  border-right: 1px solid rgba(0,0,0,0.12);
`
const mobilePageContent = css`
  border: none;
  width: 100%;
  padding: 0px;
`
const ignoreOverlapModal = css`
  outline: none;
  width: 360px;
  background-color: white;
  border-radius: 4px;
  padding: 15px;
`;
const modalContainer = css`
  align-items: center;
  justify-content: center;
  display: flex;
`;
const clientModalContainer = css`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
  flex-direction: column;
`
const ignoreOverlapModalText = css`
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.6);
  font-size: 16px;
`;
const ignoreOverlapModalButtonsContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
`;
const attentionTextStyle = css`
  color: rgba(0,0,0,0.87);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 24px;
  margin-bottom: 20px;
`
const ignoreOverlapModalButton = css`
  width: 70px;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.6);
  text-transform: uppercase;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
`;
const iconButtonStyle = css`
  position: relative;
  z-index: 10000;
`
const buttonContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
`
const newAppointmentContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow: scroll;
`
const searchClientContainerStyle = css`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 15px;
`
const calendarInputContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const serviceContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  margin-bottom: 25px;
`
const servicesTitleStyle = css`
  padding: 15px;
  olor: rgba(0,0,0,0.87);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 24px;
`
const mobileAnnotationStyle = css` 
  padding: 0px 15px;
  margin-bottom: 20px;
`
const mobileServiceContainerStyle = css`
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0px;
`
const serviceAndPersonaleStyle = css`
  display: flex;
  flex-direction: column;
  width: 70%;
`
const mobileServiceAndPersonaleStyle = css`
  width: 90%;
  margin-bottom: 15px;
`
const buttonStyle = css`
  width: 40%;
`
const popperContainerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  min-width: 350px;
  background-color: white;
`
const popperSaveCancelStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const mobileOverlayButtonStyle = css`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid rgba(0,0,0,0.3);
  align-items: center;
`
const selectServiceButton = css`
  margin-bottom: 20px;
  padding-right: 0;
  padding-left: 0;
`

const mobileCalendarPickerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const mobilePickerRepeatTextStyle = css`
  color: rgba(0,0,0,0.87);
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
`
const mobileButtonStyle = css`
  padding: 0px 15px;
`
const mobileAppointmentContainerStyle = css`
  flex-direction: column; 
  overflow: scroll;
`
const mobileClientContainer = css`
  display: flex;
  padding: 15px;
`
const mobileSaveButtonContainer = css`
  padding: 0px 15px 15px 15px;
`
const mobileCalendarStyle = css`
  .MuiPickersCalendarHeader-dayLabel {
    margin: 0px 10px !important;
  }
  .MuiTypography-caption {
    font-size: 16px !important;
  }
  .MuiTypography-body2 {
    font-size: 16px !important;
  }
  .MuiPickersDay-day {
    margin: 0px 10px !important;
  }
`

interface IAppointmentUpdateProps {
  appointment?: Appointment;
  initialValues?: any
  otherAppointments?: Appointment[]
  initialEmployee?: any
  isReprogram?: boolean
  setReprogramData?(value: any): void
  handleClose(data: any): void;
  salonData: any
}

export const AppointmentForm = ({appointment, handleClose, initialValues, otherAppointments, initialEmployee, isReprogram = false, setReprogramData, salonData}: IAppointmentUpdateProps) => {
  const [updateAppointment] = useMutation();
  const [deleteAppointment] = useMutation()
  const {
    state:
      {
        day,
        startSelected,
        endSelected,
        employeeSelected,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        clientSelected,
      } = {
        day: appointment ? appointment.start : new Date(),
        startSelected: null,
        endSelected: null,
        employeeSelected: appointment ? appointment.employee : initialEmployee ? initialEmployee : null,
        clientSelected: appointment ? appointment.client : null
      }
  } = {state: initialValues};
  const translate = useTranslate();

  const [client, setClient] = useState(appointment ? appointment.client : null);
  const [selectedDate, setSelectedDate] = useState(day);
  const [annotation, setAnnotation] = useState(appointment ? appointment.annotation : '');
  const [sendReminder, setSendReminder] = useState(appointment ? appointment.sendReminder : salonData ? salonData.activateAppointmentReminder : false);
  const [warnOfFreeAppointment, setWarnOfFreeAppointment] = useState(appointment ? appointment.warnOfFreeAppointment : false)
  const [showIgnoreOverlapModal, setShowIgnoreOverlapModal] = useState(false);
  const [showClientAddModel, setShowClientAddModal] = useState(false)
  const [showServiceAddModal, setShowServiceAddModal] = useState(false)
  const [selectedServiceAddModal, setSelectedServiceAddModal] = useState(-1)
  const [showCalendarPickerModal, setShowCalendarPickerModal] = useState(false)
  const [calendarInputOpen, setCalendarInputOpen] = useState(false)
  const [oldDaySaveDialog, setOldDateSaveDialog] = useState(false)
  const [changeRepeatSaveDialog, setChangeRepeatSaveDialog] = useState(false)
  const [changeRepeatAppointment, setChangeRepeatAppointment] = useState('single')

  const [isRepeatMenuOpen, setIsRepeatMenuOpen] = useState<HTMLButtonElement | null>(null)
  const [repeatOption, setRepeatOption] = useState(appointment ? appointment.repeat : translate('sps.labels.dontRepeat'))
  const [service, setService] = useState({
    start: [appointment ? appointment.start :
      startSelected ? startSelected :
      new Date(new Date().toDateString() + ' ' + Number(new Date().getHours()).toString().padStart(2,'0') + ':00')],
    duration: [appointment ? new Date(new Date(appointment.start).toDateString() + ` ${differenceInHours(appointment.end, appointment.start)}:${differenceInMinutes(appointment.end, appointment.start)-differenceInHours(appointment.end, appointment.start)*60}`) :
      (endSelected) ? new Date(new Date(endSelected).toDateString() + ` ${differenceInHours(endSelected, startSelected)}:${differenceInMinutes(endSelected, startSelected)-differenceInHours(endSelected, startSelected)*60}`) :
      new Date(new Date().toDateString() + ' 01:00')],
    service: [appointment ? appointment.service : null],
    employee: [appointment ? appointment.employee : employeeSelected],
  })
  const [appointments, setAppointments] = useState<any>(null)
  const [pendingAppointments, setPendingAppointments] = useState<any>(null)
  const [errorsMap, setErrorsMap] = useState<any>({})
  const isEditForm = !!appointment && !isReprogram
  const {warnIfOverlapping} = salonData

  const [, setIsSavingInProgress] = useState(true)

  const addDelay = (n) => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, n);
    });
  }

  const handleChangeRepeatAppointment = (e) => {
    setChangeRepeatAppointment(e.target.value)
  }

  const goBackToDetails = async (data) => {
    await addDelay(450)
    console.log('Finished saving: ', data)
    return window.location.reload()
  }

  useEffect(() => {
    (async () => {

      if (!appointments) {
        return
      }
      setIsSavingInProgress(true)
      if (isReprogram) {
        //@ts-ignore
        setReprogramData(null)
        deleteAppointment(deleteElementCurrentTenant('appointment', {...appointment}))
      }
      if (!isEditForm) {
        const cr = await Promise.all(appointments.map(async appointmentData => {
          return await createAppointment({data: {...appointmentData}})
        }))
        await goBackToDetails(cr)
      } else {
        //@ts-ignore
        const upa = await updateAppointment(updateElementCurrentTenant('appointment', {
        // @ts-ignore
          id: appointment.id,
          ...appointments[0],
        }))
        if (appointments.length > 1) {
          const newAppointments = appointments.slice(1, appointments.length)
          let cr = await Promise.all(newAppointments.map(async (appointmentData, index) => {
            const {id, ...rest} = appointmentData
            return await createAppointment({data: {...rest}})
          }))
          await goBackToDetails(cr)
        }
        await goBackToDetails(upa)
      }

      setIsSavingInProgress(false)
    })()
  }, [appointments, errorsMap])


  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const handleDateChange = date => {
    setSelectedDate(date);
  };
  const handleDateClose = () => {
    setCalendarInputOpen(false)
  }

  const getNewStartTime = () => {
    const lastStart = new Date(service.start[service.start.length-1])
    const lastDuration = new Date(service.duration[service.start.length-1])
    return addHours(addMinutes(lastStart, lastDuration.getMinutes()),lastDuration.getHours()).toTimeString()
  }

  const addService = () => {
    let lastEmployee = employeeSelected
    if(!lastEmployee && service.employee.length > 0) {
      lastEmployee = service.employee[service.employee.length - 1]
    }
    setService({
      start: [...service.start, new Date(new Date(day).toDateString()+' '+getNewStartTime())],
      duration: [...service.duration, new Date(new Date(day).toDateString() + ' 01:00')],
      service: [...service.service, null],
      employee: [...service.employee, lastEmployee],
    })

    if(isSmall) {
      setShowServiceAddModal(true)
    }
    setSelectedServiceAddModal(service.start.length)
  }


  const removeService = (index) => {
    const startCopy = service.start
    const durationCopy = service.duration
    const serviceCopy = service.service
    const employeeCopy = service.employee
    startCopy.splice(index, 1)
    durationCopy.splice(index, 1)
    serviceCopy.splice(index, 1)
    employeeCopy.splice(index, 1)
    setService({
      start: startCopy,
      duration: durationCopy,
      service: serviceCopy,
      employee: employeeCopy,
    })
  }

  const isAppointmentOverlapping = (first, second) => {
    const [startFirst, startSecond] = [new Date(first.start).getTime(), new Date(second.start).getTime()]
    const [endFirst, endSecond] = [new Date(first.end).getTime(), new Date(second.end).getTime()]
    return (
      (startFirst>startSecond && startFirst<endSecond) ||
      (endFirst>startSecond && endFirst<endSecond) ||
      (startSecond>startFirst && startSecond<endFirst) ||
      (endSecond>startFirst && endSecond<endFirst) ||
      (startFirst==startSecond && endFirst==endSecond)
    )
  }

  const isOverlapping = (earliestAppointment, currentAppointments) => {
    if(!otherAppointments) {
      return false
    }
    const possibleOverlap = otherAppointments.filter(app => {
      return (
        differenceInCalendarDays(new Date(app.start), new Date(earliestAppointment)) >= 0
        && app.id!=(appointment ? appointment.id : null)
      )
    })

    return currentAppointments.reduce((acc, currApp) => {
      const overlapWithOthers = possibleOverlap.filter(po => po.employee.id==currApp.employee.id).reduce((otherAcc, otherApp) => {
          otherAcc = otherAcc || isAppointmentOverlapping(otherApp, currApp)
          return otherAcc
        }, false)
      const overlapWithSelf = currentAppointments.filter(po => po.employee.id==currApp.employee.id).reduce((selfAcc, selfApp) => {
          if(currApp.id != selfApp.id) {
            selfAcc = selfAcc || isAppointmentOverlapping(selfApp, currApp)
          }
          return selfAcc
        }, false)
      acc = acc || overlapWithOthers || overlapWithSelf

      return acc
    }, false)
  }

  const submitForm = (changeRepeatAppointment: string|null = null) => {

    // debugger
    const appointmentsList = [] as any
    let earliestAppointment = null
    setSelectedServiceAddModal(0); // to trigger an error if first submit
    let currentErrorsMap = errorsMap
    for(let i =0; i < service.start.length;i++) {
      if(!service.employee || !service.employee[i]) {
        currentErrorsMap.employee = true
      }
      if(!service.service || !service.service[i]) {
        currentErrorsMap.service = true
      }
      const startTime = new Date(new Date(selectedDate).toDateString()+' '+new Date(service.start[i]).toTimeString())
      appointmentsList.push({
        id: i,
        start: startTime,
        end: addHours(addMinutes(new Date(startTime), new Date(service.duration[i]).getMinutes()), new Date(service.duration[i]).getHours()),
        service: {id: service.service[i] ? service.service[i].id : null},
        employee: {id: service.employee[i] ? service.employee[i].id : null},
        client: {id: client ? client.id : null},
        sendReminder: sendReminder,
        warnOfFreeAppointment: warnOfFreeAppointment,
        annotation: annotation,
        repeat: repeatOption,
        status: (appointment && !isReprogram) ? appointment.status : 'EXPECTED',
        changeRepeatAppointment: changeRepeatAppointment
      })
      if(!earliestAppointment ||
        new Date(earliestAppointment).getTime()>new Date(appointmentsList[i].start).getTime()) {
        earliestAppointment = appointmentsList[i].start
      }
    }
    const hasError = Object.values(currentErrorsMap).reduce((acc, el) => {
      acc = acc || el

      return acc
    }, false) || (warnOfFreeAppointment && !client)
    setErrorsMap({...currentErrorsMap})

    if(hasError) {
      scrollToError('client-add-modal-client')
    }

    if(!hasError) {
      const appointmentsOverlapping = warnIfOverlapping ? isOverlapping(earliestAppointment, appointmentsList) : false
      if(appointmentsOverlapping &&
        (
          (!isEditForm ||
            (appointment && (
              differenceInMinutes(new Date(appointment.start), new Date(appointmentsList[0].start)) != 0 ||
              differenceInMinutes(new Date(appointment.end), new Date(appointmentsList[0].end)) != 0 ||
              appointmentsList.length>1
              )
            )
          )
        )
      ){
        setPendingAppointments(appointmentsList)
        setShowIgnoreOverlapModal(true)
      } else {
        setAppointments(appointmentsList.map(a => {
          const {id, ...rest} = a

          return rest
        }))
      }
    }

    return true
  }

  const scrollToError = (elementId) =>  {
   const el =  document.getElementById(elementId)
    if(el) {
     el.scrollIntoView({behavior: "smooth", block: "end"})
    }
  }

  return (
    <div className={cx([css`height: 100%;`, isSmall && css`height: auto;min-height: 100%;`])}>
      <Header
        hasExit={isSmall}
        handleClose={() => handleClose(null)}
        title={isEditForm ? translate('sps.labels.edit_appointment') : translate('sps.labels.add_an_appointment')}
      />
      <div
        className={cx([newAppointmentContainerStyle, isSmall && mobileAppointmentContainerStyle])}
      >
        {isSmall &&
          <>
            <div
                className={cx([mobileOverlayButtonStyle, (warnOfFreeAppointment && !client) && css`color: red;`])}
                onClick={() => setShowClientAddModal(true)}
                id={'client-add-modal-client'}
            >
              <span>{client ? `${getClientName(client)}` : translate('sps.labels.add_customer')}</span>
              <PersonAddIcon/>
            </div>
            <div
              className={mobileOverlayButtonStyle}
              style={{marginBottom: 15}}
              onClick={() => setShowCalendarPickerModal(true)}
            >
              <div className={mobileCalendarPickerStyle}>
                <span>{format(new Date(selectedDate), "eeee, dd MMMM yyyy", {locale: it})}</span>
                <span className={mobilePickerRepeatTextStyle}>{repeatOption}</span>
              </div>
              <KeyboardArrowRightIcon/>
            </div>
          </>
        }
        <div className={cx([pageContent, isSmall && mobilePageContent])}>
          {!isSmall &&
            <div className={calendarInputContainer}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18nDateProvider()}>
                <KeyboardDatePicker
                  className={dateInput}
                  variant='inline'
                  id="date-picker-dialog"
                  format="eeee, dd MMM yyyy"
                  open={calendarInputOpen}
                  onClose={handleDateClose}
                  onClick={() => setCalendarInputOpen(true)}
                  InputAdornmentProps={{
                    component: () =>
                      <IconButton className={iconButtonStyle}>
                        <ExpandMoreIcon/>
                      </IconButton>
                  }
                  }
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <Button
                size="small"
                startIcon={<RepeatIcon style={{color: 'green'}}/>}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setIsRepeatMenuOpen((!Boolean(isRepeatMenuOpen)) ? e.currentTarget : null)}
              >
                <span style={{color: 'green'}}>{translate('sps.labels.repeat')}</span>
              </Button>
              <Popper
                transition
                disablePortal
                role={undefined}
                anchorEl={isRepeatMenuOpen}
                open={Boolean(isRepeatMenuOpen)}
                className={css`z-index: 100;`}
              >
                {({TransitionProps, placement}) => (
                  <Grow
                    {...TransitionProps}
                    style={{transformOrigin: 'bottom'}}
                  >
                    <ClickAwayListener onClickAway={() => setIsRepeatMenuOpen(null)}>
                      <Paper className={popperContainerStyle}>
                        <Autocomplete
                          defaultValue={repeatOption}
                          disableClearable
                          style={{marginBottom: 15, width: '100%'}}
                          renderInput={(props) => <TextField {...props} variant='outlined'
                                                             label={translate('sps.labels.frequency')}/>}
                          options={Object.keys(repeatOptions)}
                          onChange={(event, value) => {
                            if (Object.keys(repeatOptions).includes(value)) {
                              setRepeatOption(value)
                            }
                          }}
                        />
                        <div className={popperSaveCancelStyle}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setRepeatOption(Object.keys(repeatOptions)[0])
                              setIsRepeatMenuOpen(null)
                            }}
                            className={buttonStyle}
                          >
                            {translate('sps.labels.cancel')}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setIsRepeatMenuOpen(null)}
                            className={buttonStyle}
                            style={{marginRight: 15}}
                          >
                            {translate('sps.labels.save')}
                          </Button>
                        </div>
                      </Paper>
                    </ClickAwayListener>
                  </Grow>
                )}
              </Popper>
            </div>}
          {isSmall &&
            <div className={servicesTitleStyle}>{translate('sps.labels.services')}</div>
          }
          {service && service.service.map(((value, index) =>
            <>
              <div className={cx([serviceContainerStyle, isSmall && mobileServiceContainerStyle])}>
                <div className={cx([timeAndDuration, isSmall && mobileTimeAndDuration])} style={{marginBottom: Object.values(errorsMap).includes(true) ? 22: 0}}>
                  <CustomTimeInput
                    style={{marginBottom: 15, marginRight: isSmall ? 10 : 0, width: isSmall ? '100%' : 'unset'}}
                    label={translate('sps.labels.start')}
                    initialTime={service.start[index]}
                    hasZeros={true}
                    setTime={(time) => {
                      const currentTime = service.start
                      currentTime[index] = time
                      setService({
                        ...service,
                        start: currentTime
                      })
                    }}
                  />
                  <CustomTimeInput
                    style={{width: isSmall ? '100%' : 'unset'}}
                    label={translate('sps.labels.duration')}
                    initialTime={service.duration[index]}
                    hasZeros={false}
                    setTime={(time) => {
                      const currentTime = service.duration
                      currentTime[index] = time
                      setService({
                        ...service,
                        duration: currentTime
                      })
                    }}
                  />
                </div>
                <div className={cx([serviceAndPersonaleStyle, isSmall && mobileServiceAndPersonaleStyle])}>
                  {!isSmall && <ServicePicker
                    allServices={service}
                    service={service.service[index]}
                    index={index}
                    errorsMap={errorsMap}
                    setErrorsMap={setErrorsMap}
                    setService={setService}
                  />}
                  {isSmall && (
                    <div
                      className={cx([mobileOverlayButtonStyle, selectServiceButton, (!(service.service && service.service[index]) && selectedServiceAddModal !== -1) && css`color: red;`])}
                      onClick={() => {setShowServiceAddModal(true); setSelectedServiceAddModal(index)}}
                    >
                      <span>{service && service.service && service.service[index] ? `${service.service[index].name}` : (translate('sps.labels.selectService') + ' *')}</span>
                      <ArticleIcon/>
                    </div>
                  )}
                  <EmployeePicker
                    allServices={service}
                    employee={service.employee[index] === 'none' ? initialEmployee : service.employee[index]}
                    index={index}
                    errorsMap={errorsMap}
                    setErrorsMap={setErrorsMap}
                    setService={setService}
                  />
                </div>
              </div>
              {index !== (service.service.length-1) &&
                <div className={cx([isSmall && mobileButtonStyle])}>
                  <Button
                    style={{marginBottom: 15}}
                    startIcon={<DeleteOutlineIcon/>}
                    onClick={() => removeService(index+1)}
                  >
                    {translate('sps.labels.delete')}
                  </Button>
                </div>}
            </>
          ))}
          <div className={cx([isSmall && mobileButtonStyle])}>
            <Button
              style={{marginBottom: 15}}
              color='secondary'
              startIcon={<AddIcon/>}
              onClick={() => addService()}
            >
              {translate('sps.labels.addService')}
            </Button>
          </div>
          <div className={cx([isSmall && mobileAnnotationStyle])}>
            <TextField
              value={annotation}
              style={{width: '100%'}}
              label={translate('sps.labels.annotation')}
              variant='outlined'
              multiline
              rows={isSmall ? 1 : 3}
              onChange={(e) => setAnnotation(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="start"><TextsmsOutlinedIcon
                  htmlColor="rgba(0,0,0,0.54)"/></InputAdornment>,
              }}
            />
          </div>
          <div className={cx([sendReminderStyles, isSmall && mobileSendReminderStyle])}>
            <Tooltip title={translate('sps.labels.sendReminderTooltip')} id="sendReminderTooltip">
              <FormControlLabel
                aria-label="sendReminderTooltip"
                value="top"
                control={<Switch
                  checked={sendReminder}
                  value={sendReminder}
                  onClick={() => setSendReminder(!sendReminder)}
                />}
                label={translate('sps.labels.send_a_reminder')}
                labelPlacement="start"
              />
            </Tooltip>
            <FormControlLabel
              value="top"
              className={cx([isSmall && marginBottom50])}
              control={<Switch
                checked={warnOfFreeAppointment}
                value={warnOfFreeAppointment}
                onClick={() => setWarnOfFreeAppointment(!warnOfFreeAppointment)}
              />}
              label={translate('sps.labels.warnOfFreeAppointments')}
              labelPlacement="start"
            />
          </div>
        </div>
        {!isSmall &&
          <div className={searchClientContainerStyle}>
            <ClientSearch warnOfFreeAppointment={warnOfFreeAppointment} client={client} setValue={setClient}/>
            <div className={submitButton}>
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose(null)
                }}
                className={buttonStyle}
              >
                {translate('sps.labels.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  let isOldDate = false
                  for (let i = 0; i < (service.start || []).length; i += 1) {
                    const startTime = new Date(new Date(selectedDate).toDateString() + ' ' + new Date(service.start[i]).toTimeString())
                    isOldDate = (differenceInHours(new Date(startTime), new Date()) < 0) || isOldDate
                  }
                  if(appointment && appointment.repeat && appointment.id && appointment.repeat !== 'Non si ripete') {
                    setChangeRepeatSaveDialog(true)
                  } else if (isOldDate) {
                    setOldDateSaveDialog(true)
                  } else {
                    submitForm()
                  }
                }}
                className={buttonStyle}
                style={{marginRight: 15}}
              >
                {isEditForm ? translate('sps.labels.save') : translate('sps.labels.add')}
              </Button>
            </div>
          </div>}
        {isSmall &&
          <div className={mobileSaveButtonContainer}>
            <Button
              variant="contained"
              className="save-bottom-fixed"
              color="primary"
              style={{width: '100%'}}
              onClick={() => {
                const isOldDate = differenceInHours(new Date(selectedDate), new Date()) < 0
                if(appointment && appointment.repeat && appointment.id && appointment.repeat !== 'Non si ripete') {
                  setChangeRepeatSaveDialog(true)
                } else if (isOldDate) {
                  setOldDateSaveDialog(true)
                } else {
                  submitForm()
                }
              }}
            >
              {isEditForm ? translate('sps.labels.save') : translate('sps.labels.add')}
            </Button>
          </div>}
      </div>
      <Modal className={modalContainer} open={showIgnoreOverlapModal}>
        <div className={ignoreOverlapModal}>
          <div className={attentionTextStyle}>{translate('sps.labels.attention')}</div>
          <div className={ignoreOverlapModalText}>{translate('sps.labels.ignore_appointment_overlap_message')}</div>
          <div className={ignoreOverlapModalButtonsContainer}>
            <div className={ignoreOverlapModalButton} onClick={() => setShowIgnoreOverlapModal(false)}>No</div>
            <div
              className={ignoreOverlapModalButton}
              onClick={() => {
                setShowIgnoreOverlapModal(false)
                setAppointments(pendingAppointments.map(app => {
                  const {id,...rest} = app
                  return rest
                }))
              }}
            >
              {translate('sps.labels.yes')}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className={clientModalContainer}
        open={showClientAddModel}
        hideBackdrop
        style={{overflowY: 'scroll'}}
        id={'show-client-modal'}
      >
        <>
          <Header
            title={client ? '' : translate('sps.labels.add_customer')}
            handleClose={() => setShowClientAddModal(false)}
            hasExit
          />
          <div className={mobileClientContainer}>
            <ClientSearch warnOfFreeAppointment={warnOfFreeAppointment} client={client} setValue={setClient}
                          afterSelect={() => setShowClientAddModal(false)}/>
          </div>
        </>
      </Modal>
      <Modal
        className={clientModalContainer}
        open={showServiceAddModal}
        hideBackdrop
      >
        <>
          <Header
            title={translate('sps.labels.service')}
            handleClose={() => setShowServiceAddModal(false)}
            hasExit
          />
          <div className={mobileClientContainer}>
            <ServiceSearch allServices={service}
                           service={service.service[selectedServiceAddModal]}
                           index={selectedServiceAddModal}
                           setValue={setService}
                           afterSelect={() => setShowServiceAddModal(false)}
                           data={[]}
                           errorsMap={errorsMap}
                           setErrorsMap={setErrorsMap}
            />
          </div>
        </>
      </Modal>
      <Modal
        className={cx([clientModalContainer, isSmall && mobileCalendarStyle])}
        open={showCalendarPickerModal}
        hideBackdrop
      >
        <DatePickerModal
          selectedDate={selectedDate}
          repeatOption={repeatOption}
          repeatOptions={repeatOptions}
          setShowCalendarPickerModal={setShowCalendarPickerModal}
          setSelectedDate={setSelectedDate}
          setRepeatOption={setRepeatOption}
        />
      </Modal>
      <Dialog open={oldDaySaveDialog}>
        <DialogContent>
          <DialogContentText>
            {translate('sps.labels.oldDate')}
          </DialogContentText>
        </DialogContent>
        <div className={buttonContainer}>
          <Button
            color="primary"
            onClick={() => {
              if((!!initialValues && initialValues.hasOwnProperty('startSelected')) || isEditForm) {
                handleClose(null)
              }
              setSelectedDate(new Date())
              setOldDateSaveDialog(false)
              setCalendarInputOpen(true)
            }}
          >
            {translate('sps.labels.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOldDateSaveDialog(false)
              submitForm()
            }}
          >
            {translate('sps.labels.yes')}
          </Button>
        </div>
      </Dialog>

      <Dialog open={changeRepeatSaveDialog}>
        <DialogContent>
          <DialogContentText>
            <h3>Aggiorna Appuntamento</h3>
            <p>Hai modificato un appuntamento che si ripete. Selezionare quali appuntamenti devono essere aggiornati:</p>
            <RadioGroup value={changeRepeatAppointment} onChange={handleChangeRepeatAppointment} row>
              <FormControlLabel
                value={'single'}
                control={<Radio color="primary" />}
                label={'Aggiorna solo questo appuntamento'}
              />
              <FormControlLabel
                value={'future'}
                control={<Radio color="primary" />}
                label={'Aggiorna tutti gli appuntamenti imminenti'}
              />
            </RadioGroup>
          </DialogContentText>
        </DialogContent>
        <div className={buttonContainer}>
          <Button
            color="primary"
            onClick={() => {
              setChangeRepeatSaveDialog(false)
            }}
          >
            {translate('sps.labels.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              setChangeRepeatSaveDialog(false)
              submitForm(changeRepeatAppointment)
            }}
          >
            {translate('sps.labels.yes')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
