import React  from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  ReferenceInput,
  FormDataConsumer
} from "react-admin";
import { useMediaQuery, Theme } from "@material-ui/core";
import {
  validateFirstName,
} from "../service/validator";
import SimpleList from "./components/SimpleList";
import {
  SaveCancelDeleteToolbar,
  SaveCancelToolbar,
  SaveDeleteToolbar
} from "./components/Toolbars";
import PhoneNumberField from "./components/PhoneNumberField";
import { useForm } from "react-final-form";
import { ListActions } from "./components/ActionBar";
import {css} from "emotion";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslate } from "ra-core";

const mobileFormTitleStyle = css`
  position: fixed;
  background-color: white;
  color: black;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;
const mobileCloseStyle = css`
  position: absolute;
  right: 20px;
`;


export const EmployeeList = props => {
  window.hasSuperiusAdminChanges = false;
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  if (isXSmall) {
    return (
      <List {...props} bulkActions={false} exporter={false}>
        <SimpleList
          linkType={"edit"}
          primaryText={record => record.firstName + " " + record.lastName}
          secondaryText={record => record.hairdresserSalon.name}
        />
      </List>
    );
  }

  return (
    <List
      {...props}
      sort={{ field: "firstName", order: "ASC" }}
      perPage={25}
      bulkActionButtons={false}
      actions={<ListActions {...props} />}
    >
      <Datagrid rowClick="edit">
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phoneNumber" />
        <TextField source="hairdresserSalon.name" label={"Salon"} />
        <EditButton basePath="/employee" />
      </Datagrid>
    </List>
  );
};

const EmployeeTitle = ({ record }) => {
  return (
    <span>
      Impiegato {record ? `di "${record.tenant.companyName}"` : ""}
    </span>
  );
};

export const EmployeeEdit = props => {
  window.hasSuperiusAdminChanges = true;

  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("xs");
  });
  const urlParams = new URLSearchParams(window.location.search);
  const redirectString = urlParams.has("salonId")
    ? `/hairdresserSalon/${urlParams.get("salonId")}?window=3`
    : "/hairdresserSalon/";

  return (
    <Edit
      title={
        isXSmall ? <MobileFormTitle {...props} redirect={redirectString} /> : <EmployeeTitle {...props} />
      } {...props} undoable={false}>
      <SimpleForm
        redirect={redirectString}
        variant="outlined"
        toolbar={isXSmall ? <SaveDeleteToolbar /> : <SaveCancelDeleteToolbar />}
      >
        <TextInput
          variant="outlined"
          source="firstName"
          label="sps.labels.first_name"
          validate={validateFirstName}
          autoFocus={true}
          format={v => (v ? v.trimStart() : v)}
        />
        <TextInput
          variant="outlined"
          source="lastName"
          label="sps.labels.last_name"
          format={v => (v ? v.trimStart() : v)}
        />
        <PhoneNumberField source="phoneNumber" label={"Phone Number"} />
        <ReferenceInput
          label="Salon"
          source="hairdresserSalon.id"
          reference="hairdresserSalon"
          link="edit"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export const EmployeeCreateForm = ({ formData, ...rest }) => {
  const form = useForm();
  const salonId = window.location.pathname.split("/")[3];
  form.change("hairdresserSalon.id", salonId);

  return (
    <>
      <TextInput
        variant="outlined"
        source="firstName"
        label="sps.labels.first_name"
        validate={validateFirstName}
        autoFocus={true}
        format={v => (v ? v.trimStart() : v)}
      />
      <TextInput
        variant="outlined"
        source="lastName"
        label="sps.labels.last_name"
        format={v => (v ? v.trimStart() : v)}
      />
      <PhoneNumberField
        variant="outlined"
        source="phoneNumber"
        label={"Phone Number"}
      />
    </>
  );
};

const MobileFormTitle = ({ title, record, redirect }) => {
  return (
    <div className={mobileFormTitleStyle}>
      {record ? (record.firstName || '') + ' ' + (record.lastName || '') : title}
      <CloseIcon
        className={mobileCloseStyle}
        onClick={() => (window.location.href = redirect)}
      />
    </div>
  );
};

export const EmployeeCreate = props => {
  const salonId = window.location.pathname.split("/")[3];

  const redirectString = `/hairdresserSalon/${salonId}?window=3`;

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const translate = useTranslate();

  window.hasSuperiusAdminChanges = true;
  return (
    <Create
      title={
        isXSmall ? (
          <MobileFormTitle title={translate('sps.labels.add_employee')} record={null} redirect={redirectString} />
        ) : (
          translate('sps.labels.add_employee')
        )
      }
      {...props}
    >
      <SimpleForm
        redirect={redirectString}
        variant="outlined"
        toolbar={<SaveCancelToolbar {...props} newPath={redirectString} />}
      >
        <FormDataConsumer>
          {formData => <EmployeeCreateForm formData={{ ...formData }} />}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
