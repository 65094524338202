import { client } from '../../graphql/client';
import { SearchClients, SearchDetailedClients } from '../../graphql/generated-queries/clients-queries';
import {getSelectedTenant} from "../../service/store";

export async function getClients(input, includeAppointmentDetails = false) {

  const variables = {
    ...input.filter,
    perPage: input.pagination.perPage,
    skip: (input.pagination.page - 1) * input.pagination.perPage,
    order: [
      {[input.sort.field]: input.sort.order}
    ]
  }

  const tenant = getSelectedTenant();
  const tenantId = tenant ? tenant.tenantId : null;

  try {
    const { data } = await client.query({ query: includeAppointmentDetails ?  SearchDetailedClients : SearchClients, variables: {...variables, tenantId } });
    return { data: data.searchClients.items, total: data.searchClients.total };
  } catch (err) {
    console.error(err);
  }
}
