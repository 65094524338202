/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const Group = gql`
  query Group($id: EntityId!) {
    group(id: $id) {
      id
      name
      order
      tenant {
          id
          companyName
      }
      articles {
        id
        order
      }
    }
  }
`;
