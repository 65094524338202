//
import { client } from '../../graphql/client';
import { Article } from '../../graphql/generated-queries/article-queries';

export async function getArticle(input) {
  try {
    const { data } = await client.query({ query: Article, variables: { id: input.id } });
    return { data: data.article };
  } catch (err) {
    console.error(err);
  }
}
