import { client } from '../../graphql/client';
import { CreateTenant } from "../../graphql/generated-queries/create-tenant-queries";

export async function createTenant(input) {

  try {
    const { data } = await client.mutate({ mutation: CreateTenant, variables: { input: {...input.data} } });
    return { data: {...data.createTenant} };
  } catch (err) {
    console.error(JSON.stringify(err));
    throw new Error(err.graphQLErrors?.[0]?.message || err)
  }
}
