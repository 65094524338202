import React from 'react'
import {
  Button,
  ButtonGroup,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField
} from "@material-ui/core";
import {css} from "emotion";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {MessageScheduledPopper} from "./MessageScheduledPopper";

const containerStyle = css`
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-width: 450px;
`
const formContainerStyle = css`
  display: flex;
  flex-direction: column;
`
const titleContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
`
const switchFieldContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`
const titleTextStyle = css`
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,1);
  font-size: 24px;
  font-weight: bold;
  padding: 0px !important;
`
const buttonContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const textInputStyle = css`
  margin-bottom: 15px !important;
`

interface IMessageCreateFormProps {
  values: any
  handleChange(): any
  handleBlur(): any
  handleSubmit(): any
  translate(value): any
  tenants: Array<any>
  isMenuOpen: any,
  setIsMenuOpen(value): any
  setIsDialogOpen(value): any
  errors: any,
  setFieldValue(name, value): any
}

export const MessageCreateForm = (props: IMessageCreateFormProps) => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    translate,
    isMenuOpen,
    setIsMenuOpen,
    setIsDialogOpen,
    errors,
    setFieldValue
  } = props

  return (
    <>
      <div className={containerStyle}>
        <div className={titleContainerStyle}>
          <DialogTitle className={titleTextStyle}>New message</DialogTitle>
        </div>
        <div className={formContainerStyle}>
          <TextField
            name="title"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            className={textInputStyle}
            variant='outlined'
            error={errors.title}
            helperText={errors.title ? errors.title : ''}
            label={translate('sps.labels.title')}/>
          <TextField
            name="message"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.message}
            helperText={errors.message ? errors.message : ''}
            className={textInputStyle}
            variant='outlined'
            multiline rows={5}
            label={translate('sps.labels.writeAMessage')}/>
          <div className={switchFieldContainerStyle}>

            <FormControlLabel
              control={
                <Switch
                  name="adminOnlyVisible"
                  checked={values.adminOnlyVisible}
                  onChange={() => {
                    setFieldValue('adminOnlyVisible', !values.adminOnlyVisible)
                  }}
                  onBlur={handleBlur}
                  color='primary'/>}
              label={translate('sps.labels.visibleToAdmins')}
              labelPlacement='start'
            />
          </div>
          <div className={switchFieldContainerStyle}>
              <FormControlLabel
                  control={
                    <Switch
                      name="sendInApp"
                      checked={values.sendInApp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color='primary'
                    />}
                  label={translate('sps.labels.sendInApp')}
                  labelPlacement='start'
              />
              <FormControlLabel
                  control={
                    <Switch
                      name="sendViaEMail"
                      checked={values.sendViaEMail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color='primary'/>}
                  label={translate('sps.labels.sendViaEMail')}
                  labelPlacement='start'
              />
          </div>
        </div>
        <div>
          <label>Sending: {(values.scheduledDate && values.scheduledDate.getTime() > Date.now()) ? values.scheduledDate.toLocaleString() : 'immediately'}</label>
        </div>
        <div className={buttonContainerStyle}>
          <Button color={"primary"}
                  onClick={() => setIsDialogOpen(false)}>{translate('sps.labels.cancel')}</Button>
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={handleSubmit as any}>{translate('sps.labels.save')}</Button>
            <Button
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => setIsMenuOpen((!Boolean(isMenuOpen)) ? e.currentTarget : null)}><ArrowDropDownIcon/></Button>
          </ButtonGroup>
          <MessageScheduledPopper setFieldValue={setFieldValue} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} translate={translate}/>
        </div>
      </div>
    </>
  )
}
