import { createPdv } from './pdv/create-pdv';
import { getPdvs } from './pdv/get-pdvs';
import { updatePdv } from './pdv/update-pdv';
import { getPdv } from './pdv/get-pdv';
import { createArticle } from './article/create-article';
import { getDevices } from './device/get-devices';
import { getGroups } from './group/get-groups';
import { createUser } from './user/create-user';
import { createDevice } from './device/create-device';
import { getTenants } from './tenant/get-tenants';
import { getUser } from './user/get-user';
import { getArticles } from './article/get-articles';
import { removeDevice } from './device/remove-device';
import { removeUser } from './user/remove-user';
import { getTenant } from './tenant/get-tenant';
import { updateTenant } from './tenant/update-tenant';
import { updateClient } from './client/update-client';
import { updateGroup } from './group/update-group';
import { removeGroup } from './group/remove-group';
import { removePdv } from './pdv/remove-pdv';
import { getClient } from './client/get-client';
import { getUsers } from './user/get-users';
import { createGroup } from './group/create-group';
import { getGroup } from './group/get-group';
import { getClients } from './client/get-clients';
import { getDevice } from './device/get-device';
import { updateDevice } from './device/update-device';
import { updateUser } from './user/update-user';
import { createClient } from './client/create-client';
import { removeClient } from './client/remove-client';
import { updateArticle } from './article/update-article';
import { createTenant } from './tenant/create-tenant';
import { getArticle } from './article/get-article';
import { removeTenant } from './tenant/remove-tenant';
import { removeArticle } from './article/remove-article';
import { getManagers } from './user/get-managers';

import { getEmployees } from "./employee/get-employees";
import { getEmployee } from "./employee/get-employee";
import { removeEmployee } from "./employee/remove-employee";
import { updateEmployee } from "./employee/update-employee";
import { createEmployee } from "./employee/create-employee";

import { getHairdresserSalon } from "./hairdresserSaloon/get-hairdresser-salon";
import { getHairdresserSalons } from "./hairdresserSaloon/get-hairdresser-salons";
import { removeHairdresserSalon } from "./hairdresserSaloon/remove-hairdresser-salon";
import { updateHairdresserSalon } from "./hairdresserSaloon/update-hairdresser-salon";
import { createHairdresserSalon } from "./hairdresserSaloon/create-hairdresser-salon";

import { getAppointment } from "./appointment/get-appointment";
import { getAppointments } from "./appointment/get-appointments";
import { removeAppointment } from "./appointment/remove-appointment";
import { updateAppointment } from "./appointment/update-appointment";
import { createAppointment } from "./appointment/create-appointment";

import { getMessage } from "./message/get-message";
import { getMessages } from "./message/get-messages";
import { removeMessage } from "./message/remove-message";
import { updateMessage } from "./message/update-message";
import { createMessage } from "./message/create-message";


import { forgotPassword } from "./authentication/forgot-password";
import { resetPassword } from "./authentication/reset-password";

const updateManager = updateUser;
const createManager = createUser;
const getManager = getUser;
const removeManager = removeUser;

export {
  createPdv,
  createGroup,
  removeGroup,
  updateClient,
  createArticle,
  createDevice,
  createTenant,
  getPdvs,
  getClient,
  getTenant,
  updatePdv,
  removeUser,
  updateDevice,
  getDevice,
  getPdv,
  removePdv,
  getDevices,
  removeDevice,
  createUser,
  getClients,
  getGroups,
  getTenants,
  getUser,
  getGroup,
  getUsers,
  createClient,
  updateTenant,
  updateUser,
  removeTenant,
  updateGroup,
  getArticle,
  updateArticle,
  getArticles,
  removeArticle,
  removeClient,
  getManagers,
  createManager,
  getManager,
  updateManager,
  removeManager,
  getMessage,
  getMessages,
  removeMessage,
  updateMessage,
  createMessage,
  getEmployees,
  getEmployee,
  removeEmployee,
  updateEmployee,
  createEmployee,
  getHairdresserSalon,
  getHairdresserSalons,
  removeHairdresserSalon,
  updateHairdresserSalon,
  createHairdresserSalon,
  getAppointment,
  getAppointments,
  removeAppointment,
  updateAppointment,
  createAppointment,
  forgotPassword,
  resetPassword,
};
