/* tslint:disable:max-line-length */
import gql from "graphql-tag";

export const UpdateDevice = gql`
  mutation UpdateDevice($input: DeviceEditInput!) {
    updateDevice(input: $input) {
      id
      rtCode
      serialNumber
      friendlyName
      status
      address
      zip
      city
      province
      customHeader
      printHeader
      printFooter
      tenant {
        id
        companyName
      }
    }
  }
`;
