import { client } from '../../graphql/client';
import { SearchAppointments } from '../../graphql/generated-queries/search-appointments-queries';
import { getSelectedTenant } from "../../service/store";

export async function getAppointments(input) {

  const variables = {
    ...input.filter,
    perPage: input.pagination.perPage,
    skip: (input.pagination.page - 1) * input.pagination.perPage,
    order: [
      {[input.sort.field]: input.sort.order}
    ]
  }

  const tenant = getSelectedTenant();
  const tenantId = tenant ? tenant.tenantId : null;
  const search: any = {tenantId}
  if(input && input.filter && input.filter.client) {
    search.client = input.filter.client
  }
  try {
    const {data} = await client.query({query: SearchAppointments, variables: {...variables, search: search}});
    return { data: data.searchAppointments.items, total: data.searchAppointments.total };
  } catch (err) {
    console.error(err);
  }
}
