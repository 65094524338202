import React from "react";
import {
  Edit,
  SimpleForm,
  ListButton,
  SaveButton,
  Toolbar
} from "react-admin";
import {
  withStyles,
  makeStyles,
  useMediaQuery,
  Theme
} from "@material-ui/core";
import {useDispatch} from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import {SaveToolbar, toolbarStyles} from "./components/Toolbars";
import {useTranslate, toggleSidebar} from "ra-core";
import {css, cx, injectGlobal} from "emotion";
import {AppSettingsInput} from "./components/AppSettingsInput";

const containerStyle = css`
  .simple-form {
    padding: 0px;
    margin: 0px;
    max-width: none;
  }
`;

const buttonContainerStyle = css`
  .MuiToolbar-root {
    width: 50%;
    margin-left: auto;
  }
`;
const mobileFormTitleStyle = css`
  position: fixed;
  background-color: white;
  color: black;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;
const mobileCloseStyle = css`
  position: absolute;
  right: 20px;
`;

injectGlobal`
  .simple-form {
    padding: 20px 0px !important;
    max-width: 720px !important;
  }
`;

makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "0px !important"
  }
}));

export const AdvancedTenantSettingsToolbar = withStyles(toolbarStyles)(
  props => (
    <Toolbar {...props} className={"form-toolbar"}>
      <div id="other-buttons">
        <ListButton variant="outlined" basePath={"/article"} label={"sps.labels.cancel"}>
          Cancella
        </ListButton>
      </div>
      <SaveButton />
    </Toolbar>
  )
);

const MobileFormTitle = ({ record, isXSmall }) => {
  const dispatch = useDispatch();
  if (!isXSmall) {
    return record && record.companyName ? record.companyName : "Edit tenant";
  }

  return (
    <div className={mobileFormTitleStyle}>
      {record && record.companyName ? record.companyName : "Edit tenant"}
      <CloseIcon
        className={mobileCloseStyle}
        onClick={() => {
          dispatch(toggleSidebar())
        }}
      />
    </div>
  );
};

export const AdvancedTenantSettings = props => {
  // const [isManualDataOpen, setIsDataManualOpen] = useState(false);
  // const [isDataOpen, setIsDataOpen] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  // const classes = useStyles();
  const tenantId = localStorage.getItem("selectedTenantId");
  const translate = useTranslate();
  const dispatch = useDispatch();
  // const userRole = localStorage.getItem("userrole");

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <div className={cx([isXSmall && containerStyle])}>
      <Edit
        id={tenantId}
        resource="tenant"
        basePath="/advanced-settings"
        redirect={() => {
          dispatch(toggleSidebar())
        }}
        undoable={false}
        className={cx([!isXSmall && buttonContainerStyle])}
        title={<MobileFormTitle {...props} isXSmall={isXSmall} />}
      >
        <SimpleForm
          toolbar={
            isXSmall ? (
              //@ts-ignore
              <SaveToolbar style={{ position: "relative" }} />
            ) : (
              <AdvancedTenantSettingsToolbar />
            )
          }
        >
          {/*  <div className={dividerContainerStyle}>
            <span className={cx([cancelModalText])} style={{ marginRight: 2 }}>
              {translate("sps.labels.sales")}
            </span>
            <div style={{ width: "100%" }}>
              <div className={sideDivider} />
              <div style={{ height: "50%" }} />
            </div>
          </div>
          <div
            className={cx([
              optionsContainer,
              isXSmall && mobileOptionsContainer
            ])}
          >
            <div className={cancelModalText} style={{ marginBottom: 8 }}>
              {translate("sps.labels.printOnEnd")}
            </div>

            <div
              className={cx([
                mobileOptionsContainer,
                isXSmall &&
                  css`
                    width: 100%;
                  `
              ])}
            >
              <TextInput
                source="scontrinoFooter1"
                label={translate("sps.labels.line") + " 1"}
              />
              <TextInput
                source="scontrinoFooter2"
                label={translate("sps.labels.line") + " 2"}
              />
              <TextInput
                source="scontrinoFooter3"
                label={translate("sps.labels.line") + " 3"}
              />
            </div>
          </div>
        */}
          {/* uncomment for pin field */}
          {/* {userRole == "ADMIN" && (
            <div
              className={cx([
                optionsContainer,
                isXSmall && mobileOptionsContainer
              ])}
            >
              <div
                className={cx([
                  mobileOptionsContainer,
                  isXSmall &&
                    css`
                      width: 100%;
                    `
                ])}
              >
                <CheckboxGroupInput
                  source="pinRequired"
                  label=""
                  choices={[
                    {
                      id: "sendAutomaticMonthly",
                      name: `${translate("sps.labels.setPin")}`
                    }
                  ]}
                />
              </div>
              <div
                className={cx([
                  mobileOptionsContainer,
                  isXSmall &&
                    css`
                      width: 100%;
                    `
                ])}
              >
                <TextInput
                  type="number"
                  source="pin"
                  label={"Pin"}
                  validate={validatePin}
                />
              </div>
            </div>
          )} */}

          <AppSettingsInput
            source="appSettings"
            variant="checkbox"
            subValue={'successiveReceipt'}
            label={translate('sps.labels.successiveReceipt')}
          />


          {/* <div className={cx([optionsContainer, isXSmall && mobileOptionsContainer])}>
            <span className={cx([cancelModalText, !isXSmall && mobileOptionsContainer])} style={{marginBottom: 8}}>{translate('sps.labels.offerValidity')}</span>
            <div className={cx([mobileOptionsContainer, isXSmall && css`width: 100%;`])}>
              <TextInput source="offerValidity" label={translate('sps.labels.validationEstimate')} validate={validateOfferValidDays} />
            </div>
          </div>

          <div className={dividerContainerStyle}>
            <span className={cx([cancelModalText])} style={{marginRight: 2}}>{translate('sps.labels.accounting')}</span>
            <div style={{width: '100%'}}>
              <div className={sideDivider}/>
              <div style={{height: '50%'}}/>
            </div>
          </div>

          <div className={cx([optionsContainer, isXSmall && mobileOptionsContainer])}>
            <div className={cx([mobileOptionsContainer, isXSmall && css`width: 100%;`])}>
              <CheckboxGroupInput source="monthlyData" label="" choices={[
              { id: 'sendAutomaticMonthly', name: `${translate('sps.labels.sendAutomaticMonthly')}` },
            ]} />
            </div>
            <div className={cx([mobileOptionsContainer, isXSmall && css`width: 100%;`])}>
              <TextInput source="email" label={translate('sps.labels.email')} validate={validateEmail} />
            </div>
          </div>

          <div className={cx([optionsContainer, isXSmall && mobileOptionsContainer])}>
            <div className={cancelModalText} style={{marginBottom: 8}}>{translate('sps.labels.sendDataManually')}</div>
            <div className={cx([mobileOptionsContainer, isXSmall && css`width: 100%;`])}>
              <Button style={{marginBottom: 20, width: isXSmall ? '100%' : '50%'}} onClick={() => setIsDataManualOpen((true))} variant="contained">{translate('sps.labels.choose')}</Button>
              <Dialog className={classes.modal} open={isManualDataOpen} onClose={() => setIsDataManualOpen(false)}>
                <div className={cx([dialogStyle, isXSmall && css`width: auto;`])}>
                  <div className={classes.dialogTitle}>
                    <DialogTitle className={classes.dialogTitle}>{translate('sps.labels.accountingDate')}</DialogTitle>
                  </div>
                  <DialogContent>
                    <DialogContentText>{translate('sps.labels.reportPeriods')}</DialogContentText>
                  </DialogContent>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      inputVariant="outlined"
                      views={["year","month"]}
                      style={{width: '100%'}}
                      label={translate('sps.labels.selectMonth')}
                      onChange={setSelectedDate as any}
                      value={selectedDate}
                    />
                    <TextInput style={{width: '100%'}} source="email" label={(translate('sps.labels.insertEmail'))} validate={validateEmail} />
                  </MuiPickersUtilsProvider>
                  <div className={buttonContainer}>
                    <Button onClick={() => setIsDataManualOpen(false)} className={cx([cancelModalText, css`margin-right: 15px;`])}>{translate('sps.labels.cancel').toUpperCase()}</Button>
                    <Button onClick={() => setIsDataOpen(false)} variant='contained' color='primary' className={cx([cancelModalText])}>OK</Button>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>

          <div className={dividerContainerStyle}>
            <span className={cx([cancelModalText])} style={{marginRight: 2}}>{translate('sps.labels.data')}</span>
            <div style={{width: '100%'}}>
              <div className={sideDivider}/>
              <div style={{height: '50%'}}/>
            </div>
          </div>

          <div className={cx([optionsContainer, isXSmall && mobileOptionsContainer])}>
            <div className={cancelModalText} style={{marginBottom: 20, width: isXSmall ? '100%' : '50%'}}>{translate('sps.labels.selectTheData')}</div>
            <div className={cx([mobileOptionsContainer, isXSmall && css`width: 100%;`])}>
              <Button style={{marginBottom: isXSmall ? 40 : 0, width: isXSmall ? '100%' : '50%'}} onClick={() => setIsDataOpen((true))} variant="contained">{translate('sps.labels.choose')}</Button>
              <Dialog className={classes.modal} open={isDataOpen} onClose={() => setIsDataOpen(false)}>
                <div className={cx([dialogStyle, isXSmall && css`width: auto;`])}>
                  <div className={classes.dialogTitle}>
                    <DialogTitle className={classes.dialogTitle}>{translate('sps.labels.accountingDate')}</DialogTitle>
                  </div>
                  <DialogContent>
                    <DialogContentText>{translate('sps.labels.reportPeriods')}</DialogContentText>
                  </DialogContent>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      inputVariant="outlined"
                      views={["year","month"]}
                      style={{width: '100%'}}
                      label={translate('sps.labels.selectMonth')}
                      onChange={setSelectedDate as any}
                      value={selectedDate}
                    />
                    <TextInput style={{width: '100%'}} source="email" label={(translate('sps.labels.insertEmail'))} validate={validateEmail} />
                  </MuiPickersUtilsProvider>
                  <div className={buttonContainer}>

                    <Button onClick={() => setIsDataOpen(false)} className={cx([cancelModalText, css`margin-right: 15px;`])}>{translate('sps.labels.cancel').toUpperCase()}</Button>
                    <Button onClick={() => setIsDataOpen(false)} variant='contained' color='primary' className={cx([cancelModalText])}>OK</Button>
                  </div>
                </div>
              </Dialog>
            </div>
          </div> */}
        </SimpleForm>
      </Edit>
    </div>
  );
};
