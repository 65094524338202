import React, {useState} from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import { Schedule } from './pages/Schedule';
import theme from './../theme';

const ApplicationBar = () => {
  const translate = useTranslate();

  return (
    <AppBar style={{height: '56px'}} position='sticky'>
      <Toolbar>
        <IconButton
          edge="start" color="inherit" aria-label="menu"
          onClick={() => {

            if(window.location.search && window.location.search.includes('view=list')) {
              window.history.back()
              return
            }
            console.log('calling back button', window.Android)
            if (window && window.Android && window.Android.openApp) {
              console.log('called back button', window.Android.openApp)
              window.Android.openApp()
            } else {
              console.log('Did not find function window.Android.openApp');
              window.history.back()
            }
          }}
        >
          <BackIcon />
        </IconButton>
        <Typography variant="h6" >
          { translate('sps.labels.calendar') }
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export const AppointmentAppRoot = () => {

  const selectedEmployee = useState();
  const selectedHQ = useState();

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/hair-and-beauty" >
            <ApplicationBar />
            <Schedule selectedEmployee={selectedEmployee} selectedHQ={selectedHQ} />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
