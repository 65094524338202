/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const HairdresserSalon = gql`
  query HairdresserSalon($id: EntityId!) {
    hairdresserSalon(id: $id) {
      id
      name
      order
      address
      city
      postalCode
      province
      warnIfOverlapping
      remindOffer
      phoneNumber
      signOnMessage
      activateAppointmentReminder
      personalizedMessage
      birthdayMessages
      schedule
      employeeOrder
      tenant {
        id
        vatNumber
        companyName
      }
      createdAt
      updatedAt
    }
  }
`;
