import gql from 'graphql-tag';

export const articleFragmentQueries = gql`
  fragment ArticleFragment on Article {
    id
    name
    price
    actionPrice
    discountPercentage
    discountAmount
    barCode
    type
    order
    group {
      id
      name
      tenant {
        id
        companyName
        firstName
        lastName
        vatNumber
      }
    }
    pdv {
      id
      name
    }
  } `;

