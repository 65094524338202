/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const UpdateEmployee = gql`
  mutation UpdateEmployee($input: EmployeeEditInput!) {
    updateEmployee(input: $input) {
      id
      firstName
      lastName
      phoneNumber
      tenant {
        id
        vatNumber
        companyName
      }
      hairdresserSalon {
        id
        name
        order
      }
    }
  }
`;
