/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const UpdateMessage = gql`
  mutation UpdateMessage($input: MessageEditInput!) {
    updateMessage(input: $input) {
      id
    }
  }
`;
