import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import {useInput} from 'react-admin';

export const AppSettingsInput = props => {
  const {
    input
  } = useInput(props);
  const val = Boolean(input.value ? input.value[props.subValue] || false : false).toString()
  const [value, setValue] = React.useState(val);

  if (props.variant === 'checkbox') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={value === 'true'}
            onChange={(v) => {
              if(!input.value) {
                return
              }
              const data = input.value === '' ? {} : input.value
              data[props.subValue] = v.target.checked
              setValue(Boolean(v.target.checked).toString());
              input.onChange(data)
            }}
            name={props.subValue}
            disabled={props.disabled}
          />
        }
        label={props.label}
        style={props.style}
      />
    )
  }

  return (
    <>
      <FormLabel component="legend" style={{
        transform: 'translate(0, 5px) scale(0.75)',
        transformOrigin: 'top left',
        ...props.style
      }}>{props.label}</FormLabel>
      <RadioGroup row
                  aria-label={props.subValue}
                  name={props.subValue}
                  value={value} onChange={(v) => {
        const data = input.value === '' ? {} : input.value
        data[props.subValue] = v.target.value !== "false"
        setValue(Boolean(v.target.value !== "false").toString());
        input.onChange(data)
      }}>
        <FormControlLabel value="true" control={<Radio disabled={props.disabled}/>} label="ON"/>
        <FormControlLabel value="false" control={<Radio disabled={props.disabled}/>} label="OFF"/>
      </RadioGroup>
    </>
  );
};
