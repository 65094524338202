/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const CreateDevice = gql`
  mutation CreateDevice($input: DeviceCreateInput!) {
    createDevice(input: $input) {
      id
      friendlyName
      rtCode
      serialNumber
      status
      tenant {
          id
          companyName
      }
    }
  }
`;
