import {css} from "emotion";

export const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const toolbarWrapper = css`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 45px;
  z-index: 100;
  background-color: white;
  margin-left: 0;
  padding-top: 10px;
`
export const toolbarWrapperSmall = css`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: white;
  margin-left: 0;
  padding-top: 10px;
`
export const mobileToolbarWrapper = css`
  padding: 0px;
`
export const toolbarContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 20px 0px 80px;
  background-color: white;
  margin-left: 0;
  height: 70px;
`;
export const mobileToolbarContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  height: 100px;
  padding: 10px;
  padding-top: 40px;
`
export const arrowBox = css`
  width: 50px;
  height: 55px;
  background-color: #F5F5F5;
  border-style: solid;
  border-color: rgba(0,0,0,0.12);
  color: rgba(0,0,0,0.54);
  border-radius: 5px;
  border-width: 1px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-right: 8px;
  cursor: pointer;
`;
export const modalContainer = css`
  align-items: center;
  justify-content: center;
  display: flex;
`;
export const fullscreenModal = css`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: scroll;
`;

export const hqName = css`
  text-align: center;
  background-color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
`;

export const fab = css`
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  z-index: 20000;
`;
export const leftToolbarStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const addButtonStyle = css`
  height: 55px !important;
`

export const timeSlotDisabledStyle = css`
    width: 100%;
    height: 50%;
    background: repeating-linear-gradient(
      45deg,
      #cdcdcd,
      #cdcdcd 1px,
      white 1px,
      white 7px
    );
  `
export const calendarStyle = css`
  .rbc-time-view {
    border: none;
    position: relative;
    z-index: 1;
  }
  .rbc-header {
    border: none !important;
  }
  .rbc-time-header {
    height: 100%;
  }
  .rbc-time-header-content {
    border-bottom: 1px solid #DDD !important;
  }
  .rbc-time-header-cell {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important; 
  }
  .rbc-allday-cell {
    display: none;
  }
  .rbc-time-header-gutter {
    border: none !important;
  }
  .rbc-row-resource {
    border-bottom: none !important;
    display: none;
  }
  .rbc-timeslot-group > div {
    display: flex;
    justify-content: flex-end;
    padding-right: 4px;
  }
  .rbc-event-content {
    display: none;
  }
`

export const resourceHeaderStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  padding-top: 30px;
`
export const employeeResourceHeaderStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
`
export const headerTextStyle = css`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`
export const headerTodayStyle = css`
  color: white;
  background-color: #0066ff;
`
export const dateHeaderTextStyle = css`
  color: #0066ff;
`
export const calendarPickerStyle = css`
  width: 220px;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #CCC;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  &:focus {
    border-color: #702588;
  }
`
export const selectTodayStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-right: 1px solid #CCC;
  padding: 0px 10px;
`
export const selectDateStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`
export const optionsDrawerStyle = css`
  .MuiDrawer-paper{
    width: 80% !important;
    padding: 0px !important;
   }
`
export const drawerContainerStyle = css`
  display: flex;
  flex-direction: column;
`
export const closeIconContainer = css`
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;
`
export const viewContainerStyle = css`
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  border-bottom: 1px solid rgba(0,0,0,0.3);
`
export const drawerTitleTextStyle = css`
  color: rgba(0,0,0,0.87);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 16px;
  margin-bottom: 20px;
`
export const viewOptionsContainerStyle = css`
  display: flex;
  justify-content: space-between;
`
export const viewOptionStyle = css`
  display: flex;
  flex-direction: column;
  color: rgba(0,0,0,0.6);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0px;
`
export const selectedViewStyle = css`
  background-color: #F4E9F3;
  color: #702588;
  border-radius: 4px;
`
export const mobileSalonSelectStyle = css`
  .MuiInput-underline {
    :before{ border-bottom: none !important;}
    :after{ border-bottom: none !important;}
  }
  .MuiSelect-iconOpen {
    transform: rotate(90deg) !important;
  }
`
export const personalSelectStyle = css`
  display: flex;
  flex-direction: column;
  overflow: scroll;
`
export const employeeContainerStyle = css`
  display: flex;
  padding: 10px;
`
export const employeeNameStyle = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const personalBadgeStyle = css`
  margin-right: 15px;
`
export const eventContainerStyle = css`
  display: flex;
  flex-direction: column;
  background-color: #F4E9F3;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #AC4FAD;
  border-left: 4px solid #AC4FAD;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
`
export const modeContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  border-radius: 4px;
  color: #CCC;
  height: 100% !important;
`
export const displayModeSelect = css`
  width: 130px;
`
export const customAppointmentListContainer = css`
  display: flex;
  flex-direction: column;
  padding: 20px 80px;
`
export const eventContainerTextStyle = css`
  display: flex;
  align-items: center;
  color: rgba(0,0,0,0.87);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin-bottom: 5px;
`
export const dateStringStyle = css`
  color: rgba(0,0,0,0.87);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-top: 32px;
  margin-bottom: 16px;
`
export const reprogramHeaderStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #0066ff;
  height: 50px;
`
export const closeReprogramStyle = css`
  position: absolute !important;
  right: 15px;
  color: white !important;
`
export const wrapperPeopleStyle = css`
  display: flex;
  align-items: center;
`
export const eventContainerDetails = css`
  display: flex;
  flex-direction: column;
`
export const serviceDetailsStyle = css`
  color: rgba(0,0,0,0.87);
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
`


export const STATUS_COLORS = {
  'CANCELED': {
    color: '#F4E9F3',
    colorLeft: '#AC4FAD',
  },
  'EXPECTED': {
    color: '#E9F4FC',
    colorLeft: '#2697E1',
  },
  'EXECUTED': {
    color: '#E6F7ED',
    colorLeft: '#1CB65F',
  },
  'NOT_ARRIVED': {
    color: '#F9F9F9',
    colorLeft: '#CCCCCC',
  }
}
