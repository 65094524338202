import React from 'react';
import { List, Datagrid, TextField, Create, SimpleForm, TextInput, Edit, EditButton, PasswordInput, SelectInput, Filter } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import {
  validatePassword,
  validatePhoneNumber,
  validateRequiredPhoneNumber,
  validateUsername,
  validateRole,
  validateNonRequiredPassword,
  validateEmail,
} from '../service/validator';
import { MANAGER_ROLES } from "../constants";
import {FullToolbarCheckDeleteSelf, SaveCancelDeleteToolbar} from "./components/Toolbars";
import PhoneNumberField from "./components/PhoneNumberField";
import SimpleList from "./components/SimpleList";


export const ManagersList = (props) => {
  window.hasSuperiusAdminChanges = false
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  if(isXSmall) {
    return (
      <List {...props} perPage={10} bulkActions={false} exporter={false} filters={<UserFilter />} title="sps.labels.allUsers">
        <SimpleList
          linkType={'edit'}
          primaryText={record => record.username}
          secondaryText={record => record.role}
          tertiaryText={record => record.phoneNumber}
        />
      </List>
    )
  }

  return (
    <List {...props} sort={{ field: 'username', order: 'ASC' }} perPage={25} bulkActionButtons={false} filters={<UserFilter />} title="sps.labels.allUsers">
      <Datagrid>
        <TextField source="username" />
        <TextField source="phoneNumber" />
        <TextField source="email" />
        <TextField source="role"/>
        <EditButton basePath="/manager"/>
      </Datagrid>
    </List>
  );
};

export const ManagerCreate = (props) => {
  window.hasSuperiusAdminChanges = true
  return (
    <Create title="Create a user" {...props} undoable={false}>
      <SimpleForm redirect="list" variant="outlined" toolbar={<SaveCancelDeleteToolbar />}>
        <TextInput source="username" validate={validateUsername} autoFocus={true} format={v => v ? v.trimStart() : v}/>
        <PasswordInput source="password" validate={validatePassword} />
        <TextInput source={'firstName'} isRequired={false}/>
        <TextInput source={'lastName'} isRequired={false}/>
        <TextInput source={'email'} type={'email'} validate={validateEmail} isRequired={false}/>
        <PhoneNumberField source="phoneNumber" validate={validatePhoneNumber}/>
        <SelectInput source="role" choices={MANAGER_ROLES} validate={validateRole} defaultValue={MANAGER_ROLES[0].id} />
      </SimpleForm>
    </Create>
  );
}

const UserTitle = ({record}) => {
  return <span>User: {record ? `${record.username}` : ''}</span>;
};


export const ManagerEdit = (props) => {
  window.hasSuperiusAdminChanges = true
  return (
    <Edit title={<UserTitle {...props}  />} {...props} undoable={false}>
      <SimpleForm redirect="list" toolbar={<FullToolbarCheckDeleteSelf />} variant="outlined">
        <TextInput source="username" validate={validateUsername} autoFocus={true} format={v => v ? v.trimStart() : v}/>
        <PasswordInput source="password" type="password" validate={validateNonRequiredPassword} />
        <TextInput source={'firstName'} isRequired={false}/>
        <TextInput source={'lastName'} isRequired={false}/>
        <TextInput source={'email'} validate={validateEmail} isRequired={false}/>
        <PhoneNumberField source="phoneNumber" validate={validatePhoneNumber}/>
        <SelectInput source="role" choices={MANAGER_ROLES} validate={validateRole} />
      </SimpleForm>
    </Edit>
  );
}

const UserFilter = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <TextInput label="Search by username" source="username" alwaysOn />
      <SelectInput source="role" choices={MANAGER_ROLES} />
    </Filter>
  );
}
