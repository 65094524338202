import { client } from '../../graphql/client';
import { SearchManagers } from '../../graphql/generated-queries/users-queries';

export async function getManagers(input) {

  const variables = {
    ...input.filter,
    perPage: input.pagination.perPage,
    skip: (input.pagination.page - 1) * input.pagination.perPage,
    order: [
      {[input.sort.field]: input.sort.order}
    ]
  }


  try {
    const { data } = await client.query({ query: SearchManagers, variables } );
    return { data: data.searchManagers.items, total: data.searchManagers.total };
  } catch (err) {
    console.error(err);
  }
}
