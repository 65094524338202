import React from 'react'
import {Box, Theme, useMediaQuery, Button, Card} from "@material-ui/core";
import {CreateButton} from 'react-admin'
import {useTranslate} from 'ra-core'
import {css, cx} from 'emotion'
import MarketinoLogo from '../../assets/Marketino_logo.jpg'

const boxStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const titleStyle = css`
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  line-height: 30px;
  color: rgba(0,0,0,0.54);
  margin-bottom: 24px;
  width: 100%;
`;
const imgStyle = css`
  width: 100%;
  height: auto;
  max-width: 350px;
`
const buttonStyle = css`
    min-width: 175px !important;
    margin-left: auto !important;
    margin-right: 20px !important;
    margin-bottom: 20px !important;
    margin-top: 50px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1.25px !important;
`
const headerStyle = css`
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin-bottom: 0px;
`
const subtitleStyle = css`
    color: rgba(0,0,0,0.6);
`

export const EmptyItemView = ({ basePath, title }) => {
    const translate = useTranslate()
    const isXSmall = useMediaQuery((theme: Theme) => {
        return theme.breakpoints.down('xs')
    });

    return (
      <Box className={boxStyle} textAlign="center" m={1}>
        <img alt={'Marketino'} src={MarketinoLogo} className={imgStyle}/>
        <span className={cx([titleStyle, isXSmall && css`font-size: 24px;`])}>{title}</span>
        <CreateButton basePath={basePath} label={translate('sps.labels.create')} variant={'contained'}/>
      </Box>
    );
}

export const TenantOnboarding = (props) => {
    const translate = useTranslate()
    return (
      <div>
        <img alt={'Marketino'} src={MarketinoLogo} className={imgStyle}/>
        <h3 className={headerStyle}>{translate('sps.labels.onboarding_header')}</h3>
        <p className={subtitleStyle}>{translate('sps.labels.onboarding_subtitle')}</p>
        <Button onClick={(e) => props.setNextScreen(e)} color={"primary"} variant={'contained'} className={buttonStyle}>Avanti</Button>
      </div>
    );
}

