/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const PDV = gql`
  query PDV($id: EntityId!) {
    pDV(id: $id) {
      id
      internalName
      name
      shortName
      percentage
    }
  }
`;
