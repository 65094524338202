/* tslint:disable:max-line-length */
import gql from "graphql-tag";

export const Tenant = gql`
  query Tenant($id: EntityId!) {
    tenant(id: $id) {
      id
      tenantId
      vatNumber
      isVentilazioneIVA
      status
      companyName
      fiscalCode
      ateco
      vatId
      hasHairAndBeauty
      firstName
      lastName
      address
      zip
      city
      province
      state
      reaCode
      offerValidity
      successiveReceipt
      useQuotes
      appSettings
      currency
      sendAutomaticMonthly
      secretSharedKey
      createdAt
      updatedAt
      bic
      iban
      bankAccountName
    }
  }
`;
