import * as React from 'react';
import {useState} from "react";
import {useDispatch} from 'react-redux';
import {
  showNotification,
} from "react-admin";
import {useEffectAsync} from "../../use-effect-async";
import {getSelectedTenant} from "../../service/store";

export const UserGlobalNotification = (props) => {

  const [isOnboarded, setIsOnboarded] = useState(null)
  const [shownMessage, setShownMessage] = useState(false)
  const dispatch = useDispatch()

  useEffectAsync(async () => {
    const tenant = getSelectedTenant();
    const hasOnboarded = tenant?.iban !== null
    // @ts-ignore
    setIsOnboarded(hasOnboarded);
  }, []);
  if (isOnboarded === null) {
    return (<></>)
  }
  if (!isOnboarded && !shownMessage) {
    dispatch(showNotification('sps.labels.activation_required', 'info', {
      messageArgs: {},
      undoable: false,
    }));
    setShownMessage(true)
  }
  return (<></>)
};

