import { client } from '../../graphql/client';
import { CreateClient } from "../../graphql/generated-queries/create-client-queries";
import { getSelectedTenant } from '../../service/store';

export async function createClient(input) {
  try {
    const tenant = getSelectedTenant();
    const tenantId = tenant ? tenant.id : null;
    if (tenantId) {
      input.data.tenant = {
        id: tenantId
      }
    }
    const { data: { category, ...inputData } } = input
    if(category) {
      for (const notificationType of ["dontSendNotifications", "dontSendBirthdayNotification"]){
          inputData[`${notificationType}`] = category.includes(notificationType)
      }
    }

    const { data } = await client.mutate({ mutation: CreateClient, variables: { input: {...inputData} } });
    return { data: {...data.createClient} };
  } catch (err) {
    console.error(err);
  }
}
