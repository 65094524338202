// @ts-nocheck
import React, { useState } from "react";
import MuiPhoneNumber from 'material-ui-phone-number'
import {
  List,
  Datagrid,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  Create,
  Filter,
  SelectInput,
  DateInput,
  CheckboxGroupInput,
  NumberInput,
  FormDataConsumer,
  RadioButtonGroupInput,
} from "react-admin";
import {
  useMediaQuery,
  Theme,
  TextField as MUITextField,
  InputAdornment,
  Tab,
  Tabs, Button
} from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {COUNTRIES_LIST, INVOICE_VIA, PROVINCE_ITALY_LIST, repeatOptions} from "../constants";
import {
  validateFiscalCode,
  validateZip,
  validateEmail,
  validateRequired,
  validateClientDiscount, validateLotteryId,
} from "../service/validator";
import SimpleList from "./components/SimpleList";
import {
  SaveCancelDeleteToolbar,
  SaveCancelToolbar,
  SaveDeleteToolbar,
  SaveToolbar
} from "./components/Toolbars";
import { formatDecimals, parseDecimals } from "../service/formFormatter";
import { useForm } from "react-final-form";
import { TextField as MuiTextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ListActions } from "./components/ActionBar";
import { css, cx } from "emotion";
import { useTranslate } from "ra-core";
import { EmptyItemView } from "./components/EmptyItemView";
import {vatNumber as validateVatNumber, fiscalCode as validFiscalCode } from "../service/validator";
import {getClientName} from "../appointment-app/repository";
import PersonIcon from '@material-ui/icons/Person';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import {PersonType} from "../appointment-app/enums/PersonType";
import {UserGlobalNotification} from "./components/UserGlobalNotification";
import {useEffectAsync} from "../use-effect-async";
import {getAppointments} from "../queries";
import {differenceInMinutes, format} from "date-fns";
import {it} from "date-fns/locale";
import {capitalize} from "../build-query";
import {AppointmentTypeKeys} from "../appointment-app/enums/AppointmentType";
import * as style from "../appointment-app/components/CalendarStyles";
import {getSelectedTenant} from "../service/store";

const TranslateField = ({ source, record = {} }) => {
  const translate = useTranslate();
  const value = translate("sps.labels." + (record[source] || '').trim().toLowerCase())
  return (<span className="MuiTypography-root MuiTypography-body2">{value}</span>)
};

const ClientsFilter = props => (
  <Filter {...props} variant="outlined">
    <TextInput
      label="sps.labels.search_entity.clients"
      source="expression"
      alwaysOn
      variant="outlined"
    />
  </Filter>
);

const containerStyle = css`
  .simple-form {
    padding: 0px;
    margin: 0px;
    max-width: none;
  }
`;
const radioButtonGroupStyle = css`
  .MuiFormGroup-row {
    display: flex;
    margin-right: auto;
    justify-content: center;
  }
`;

const radioButtonGroupStyleSmall = css`
  .MuiFormGroup-row {
    display: flex;
    margin-left: 0;
    justify-content: center;
  }
`;
const mobileFormTitleStyle = css`
  position: fixed;
  background-color: white;
  color: black;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;
const mobileCloseStyle = css`
  position: absolute;
  right: 20px;
`;
const inputRowStyle = css`
  display: flex;
  justify-content: space-between;
`;
const invoiceStyle = css`
  width: 50% !important;
`;
const insertFieldStyle = css`
  .MuiInputLabel-outlined {
    transform: translate(14px, 13px) scale(1);
  }
`;
const phoneNumberStyle = css`
  width: 100% !important;
  margin-right: 10px !important;
  .MuiOutlinedInput-input {
    padding: 10px 14px;
  }
`;
const formStyle = css`
  .simple-form {
    max-width: 720px !important;
  }
  .MuiOutlinedInput-inputAdornedStart {
    padding: 10px;
    padding-bottom: 11px;
  }
  .MuiOutlinedInput-input {
    padding: 10.5px !important;
  }
`;

const detailsContainerStyle = css`
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, .12);
  box-shadow: 0 2px 5px rgb(0 0 0 / 15%);
  padding-bottom: 0px;
  margin: 40px auto 10px;
  min-width: 60%;
  
`

const formContainerStyle = css`
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-around !important;
  background-color: white !important;
  border-radius: 4px;

  [class*="RaEdit-main"] {
    margin-top: 0px;
  }

  .simple-form {
    max-width: none !important;
    border-radius: 0px;
    border: none !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
    margin: 0px auto;
  }
`
const mobileFormContainerStyle = css`
  .simple-form {
    padding: 5px !important;
    max-width: 720px !important;
  }
`
const tabsStyle = css`
  .edit-page {
    div {
      margin-top: 0px !important;
    }
  }`

const appointmentListStyle = css`
  display: flex;
  padding-bottom: 25px;
`

const titleBorder = css`
  border-bottom: #E4E4E4 solid;
  padding-bottom: 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
`
const appointmentDateMonthStyle = css`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 4px;
`

const appointmentArticleStyle = css`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 4px;
`
const appointmentEmployeeStyle = css`
  color: rgba(0,0,0,0.6);
  margin-bottom: 4px;
`

const appointmentDateDayStyle = css`
  font-size: 17px;
  color: rgba(0,0,0,0.6);
`
const appointmentStatusStyle = css`
  font-size: 11px;
  letter-spacing: 0.28px;

`

const appointmentContainer = css`
  padding-left: 10px;
  padding-top: 15px;
  padding-right: 10px;
  padding-bottom: 15px;
`

const appointmentDateStyle = css`
  display: flex;
  flex-direction: column;
  margin-right: 22px;
`

const appointmentDetailsStyle = css`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`

const appointmentPriceStyle = css`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  font-size: 17px;
  font-weight: 500;
`

export const ClientsList = props => {
  window.hasSuperiusAdminChanges = false;

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const translate = useTranslate();

  if (isXSmall) {
    return (
      <>
        <UserGlobalNotification/>
        <List
          {...props}
          bulkActions={false}
          exporter={false}
          filters={<ClientsFilter/>}
          empty={
            <EmptyItemView
              {...props}
              title={translate("sps.labels.emptyClient")}
            />
          }
        >
          <SimpleList
            linkType={"edit"}
            primaryText={record => {
              let name = "";
              if (record.firstName || record.lastName) {
                name = (record.firstName || "") + " " + (record.lastName || "");
              }
              if (record.companyName) {
                name = record.companyName;
              }

              return name;
            }}
            secondaryText={record => {
              let address = (record.address || '').trim()
              const city = (record.city || '').trim()
              if (address !== '' && city !== '') {
                address += ', '
              }
              address += city;
              return address;
            }}
            leftIcon={(record) => record.personType === PersonType.PRIVATE ? <PersonIcon/> : <LocationCityIcon/>}
          />
        </List>
      </>
    );
  }

  return (
    <>
      <UserGlobalNotification/>
      <List
        {...props}
        sort={{field: "personType", order: "ASC"}}
        bulkActionButtons={false}
        perPage={25}
        filters={<ClientsFilter/>}
        actions={<ListActions {...props} />}
        empty={
          <EmptyItemView {...props} title={translate("sps.labels.emptyClient")}/>
        }
      >
        <Datagrid rowClick="edit">
          <TranslateField source="personType" label="sps.labels.person_type"/>
          <TextField source="vatNumber" label="sps.labels.vat_number"/>
          <TextField source="firstName" label="sps.labels.first_name"/>
          <TextField source="lastName" label="sps.labels.last_name"/>
          <TextField source="companyName" label="sps.labels.company_name"/>
          <EditButton basePath="/client"/>
        </Datagrid>
      </List>
    </>
  );
};

const ClientTitle = ({ record }) => {
  return <span>Cliente: {getClientName(record)}</span>;
};

const ClientForm = ({ record }) => {
  const form = useForm();
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("xs");
  });
  const [isCompanyNameRequired] = useState(
    record.personType === "LEGAL" ? translate("sps.labels.requiredField") : ""
  );

  const personType = form.getFieldState('personType')
  if (!record.hasOwnProperty("id")) {
    form.change("gdpr", new Date());
    if(!personType){
      form.change("personType", "PRIVATE");
    }
  }

  const CLIENT_PERSON_TYPE = [
    { id: "PRIVATE", name: translate("sps.labels.private") }, // first choice is default
    { id: "LEGAL", name: translate("sps.labels.legal") }
  ];

  return (
    <div
      className={css`
        .MuiFormControlLabel-root {
          margin: 0px !important;
        }
      `}
    >
      <div>
        <RadioButtonGroupInput
          row
          className={isXSmall ? radioButtonGroupStyleSmall : radioButtonGroupStyle}
          source="personType"
          label={isXSmall ? translate("sps.labels.person_type") : ""}
          choices={CLIENT_PERSON_TYPE}
        />
      </div>
      <FormDataConsumer>
        {(formDataFull) => {
          const formData =  formDataFull.formData || {}
          return formData.personType === "LEGAL" ? (
            <TextInput
              style={{ marginRight: isXSmall ? 0 : 10 }}
              variant="outlined"
              source="companyName"
              label="Ragione sociale *"
            />
          ) : null;
        }}
      </FormDataConsumer>
      <div className={cx([!isXSmall && inputRowStyle])}>
        <FormDataConsumer>
        {(formDataFull) => {
          const formData =  formDataFull.formData || {}
          return (<TextInput
            style={{ marginRight: isXSmall ? 0 : 10 }}
            variant="outlined"
            source="firstName"
            label={translate('sps.labels.first_name') + (formData.personType === "LEGAL" ? '' : ' *')}
          />)
        }}
        </FormDataConsumer>
        <TextInput
          variant="outlined"
          source="lastName"
          label="sps.labels.last_name"
        />
      </div>
      <div className={cx([!isXSmall && inputRowStyle])}>
        <div
          className={cx([
            phoneNumberStyle,
            isXSmall &&
              css`
                margin-bottom: 14px;
              `
          ])}
        >
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <div style={{ marginBottom: 20 }}>
                  <MuiPhoneNumber
                    defaultCountry={'it'}
                    preferredCountries={'it'}
                    onChange={event =>
                      form.change("mobilePhone", event)
                    }
                    disableAreaCodes={true}
                    value={formData.mobilePhone || '' }
                    isValid={() => true}
                    label={translate('ra.auth.phone_number')}
                    variant="outlined"
                    style={{
                      width: '256px',
                      marginTop: '8px',
                      marginBottom: '4px',
                    }}
                  />
                </div>);
            }}
          </FormDataConsumer>
        </div>
        <TextInput
          variant="outlined"
          source="email"
          label="sps.labels.email"
          validate={validateEmail}
        />
      </div>
      <FormDataConsumer>
        {({ formData }) => {
          return formData.personType == "PRIVATE" ? (
            <div style={{ marginBottom: 20 }}>
              <MuiTextField
                size={"small"}
                onChange={event => {
                  const val = event.currentTarget.value || ''
                  form.change("companyName", val.trim());
                  }
                }
                variant="outlined"
                value={formData.companyName}
                label={translate("sps.labels.companyName")}
                helperText={!!isCompanyNameRequired && !formData.companyName ? isCompanyNameRequired : ''}
                error={!!isCompanyNameRequired && !formData.companyName}
              />
            </div>
          ) : null;
        }}
      </FormDataConsumer>
      <div className={cx([!isXSmall && inputRowStyle])}>
        <TextInput
          style={{ marginRight: isXSmall ? 0 : 10 }}
          variant="outlined"
          source="fiscalCode"
          label="sps.labels.fiscal_code"
          validate={validateFiscalCode}
        />

        <FormDataConsumer>
          {(formDataFull) => {
            const formData =  formDataFull.formData || {}
            return (<TextInput
              style={{ marginRight: isXSmall ? 0 : 10 }}
              variant="outlined"
              source="vatNumber"
              label={translate('sps.labels.vat_number') + (formData.personType === "LEGAL" ? ' *' : '')}
            />)
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {(formDataFull) => {
            const formData =  formDataFull.formData || {}
            return formData.personType === "PRIVATE" ? (
              <TextInput
                variant="outlined"
                source="lotteryId"
                label="sps.labels.lottery_code"
                validate={validateLotteryId}
              />
            ) : null;
          }}
        </FormDataConsumer>
      </div>
      <div className={cx([!isXSmall && inputRowStyle])}>
        <TextInput
          style={{ marginRight: isXSmall ? 0 : 10 }}
          variant="outlined"
          source="address"
          label="sps.labels.address"
        />
        <TextInput
          variant="outlined"
          source="zip"
          label="sps.labels.postal_code"
          validate={validateZip}
        />
      </div>
      <div className={cx([!isXSmall && inputRowStyle])}>
        <TextInput
          style={{ marginRight: isXSmall ? 0 : 10 }}
          variant="outlined"
          source="city"
          label="sps.labels.city"
        />
        <SelectInput
          variant="outlined"
          source="province"
          label="sps.labels.province"
          choices={PROVINCE_ITALY_LIST}
        />
      </div>
      <div className={cx([!isXSmall && inputRowStyle])}>
        <SelectInput
          style={{ marginRight: isXSmall ? 0 : 10 }}
          variant="outlined"
          source="state"
          label="sps.labels.country"
          validate={validateRequired}
          choices={COUNTRIES_LIST}
          defaultValue={"Italy"}
        />
        <NumberInput
          source="discount"
          label="sps.labels.discount"
          variant="outlined"
          validate={validateClientDiscount}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
          format={v => {
            return formatDecimals(v, 2);
          }}
          parse={v => {
            return parseDecimals(v, 2);
          }}
        />
      </div>
      <div className={cx([!isXSmall && inputRowStyle])}>
        <RadioButtonGroupInput
          className={cx([
            !isXSmall && invoiceStyle,
            !isXSmall &&
              css`
                fieldset {
                  width: 50% !important;
                }
              `
          ])}
          variant="outlined"
          source="invoiceVia"
          choices={INVOICE_VIA}
        />
        <div
          className={cx([
            !isXSmall && inputRowStyle,
            !isXSmall &&
              css`
                width: 100% !important;
                align-items: center;
                margin-left: 15px;
              `
          ])}
        >
          <FormDataConsumer>
            {({ formData }) => (
              <MUITextField
                variant={"outlined"}
                value={formData.pec}
                onChange={event => {
                  form.change("sdi", event.currentTarget.value);
                  form.change("pec", event.currentTarget.value);
                }}
                label={translate("sps.labels.insert")}
                className={cx([
                  insertFieldStyle,
                  isXSmall &&
                    css`
                      margin-bottom: 15px !important;
                    `
                ])}
              />
            )}
          </FormDataConsumer>
        </div>
      </div>
      {/*In case you want to return gdpr back remove comments*/}
      {/*<div className={cx([!isXSmall && inputRowStyle])}>*/}
      {/*  <FormDataConsumer>*/}
      {/*    {({formData}) =>*/}
      {/*      <FormControlLabel*/}
      {/*        control={<Switch checked={Boolean(formData.gdpr)} onChange={event => form.change('gdpr', event.target.checked ? new Date() : null)} name="gdpr" />}*/}
      {/*        label="GDPR"*/}
      {/*        className={cx([!isXSmall && css`width: 50% !important;`], isXSmall && css`margin-bottom: 15px;`)}*/}
      {/*      />*/}
      {/*    }*/}
      {/*  </FormDataConsumer>*/}
      {/*  <div className={!isXSmall && css`width: 50% !important;margin-left: 15px;`}>*/}
      <DateInput
        variant="outlined"
        source="birthday"
        label="sps.labels.birthday"
      />
      {/*</div>*/}
      {/*</div>*/}
      <FormDataConsumer>
        {({ formData }) => {
          console.log(formData)
          const choices = [
            {
              id: "dontSendNotifications",
              name: translate("sps.labels.dontSendNotifications"),
            }
          ]
          if(!formData || !formData.category || !formData.category.includes('dontSendNotifications')) {
            choices.push(
              {
                id: "dontSendBirthdayNotification",
                name: translate("sps.labels.dontSendBirthdayNotification")
              }
            )
          }
          return (
            <CheckboxGroupInput
              className={cx([
                css`
            .MuiFormGroup-row {
              display: flex;
              justify-content: ${isXSmall ? 'left' : 'space-around'};
            }
          `
              ])}
              row
              checked={true}
              variant="outlined"
              source="category"
              label=""
              choices={choices}
            />)
        }}
      </FormDataConsumer>
      <TextInput
        multiline={true}
        rows={3}
        variant="outlined"
        source="note"
        label="sps.labels.note"
      />
    </div>
  );
};

const ClientMobileTitle = ({ record, title }) => {
  const translate = useTranslate();
  let name = "";
  if (record.companyName && record.personType === 'LEGAL') {
    name = record.companyName;
  } else if (record.firstName || record.lastName) {
    name = (record.firstName || "") + " " + (record.lastName || "");
  }

  if(name && name.length >= 17) {
    name = name.substring(0,14) + '...'
  }
  return (
    <div className={mobileFormTitleStyle}>
      {name ? translate("sps.labels.client") + ": " + name : title}
      <CloseIcon
        className={mobileCloseStyle}
        onClick={() => (window.location.href = "/client")}
      />
    </div>
  );
};

const validateClientForm = (values, translate) => {
  const errors: any = {};
  if (values.personType === "LEGAL" && (!values.companyName || values.companyName.trim() === '')) {
    errors.companyName = ["sps.labels.requiredField"];
  }
  if (values.personType ===  "LEGAL" && !values.vatNumber) {
    errors.vatNumber = ["sps.labels.requiredField"];
  }
  if((values.personType === "PRIVATE" || !values.personType) && !values.firstName) {
    errors.firstName = ["sps.labels.requiredField"];
  }
  if(values.vatNumber && values.vatNumber !== '') {
    const err = validateVatNumber()(values.vatNumber)
    if(err && err.message) {
      errors.vatNumber = [translate(err.message)]
    }
  }
  if(values.fiscalCode && values.fiscalCode !== '') {
    const err = validFiscalCode()(values.fiscalCode)
    if(err && err.message) {
      errors.fiscalCode = [translate(err.message)]
    }
  }

  return errors;
};

const EditTab = ({isXSmall, parentProps, translate}) => {

  return (
    <Edit
      className={formStyle}
      title={
        isXSmall ? (
          <ClientMobileTitle {...parentProps} />
        ) : (
          <ClientTitle {...parentProps} />
        )
      }
      {...parentProps}
      undoable={false}
    >
      <SimpleForm
        validate={values => validateClientForm(values, translate)}
        redirect="list"
        variant="outlined"
        toolbar={
          isXSmall ? <SaveDeleteToolbar/> : <SaveCancelDeleteToolbar/>
        }
      >
        <ClientForm/>
      </SimpleForm>
    </Edit>
  )
}

export function getAppointmentCard(appointment, translate, removeOpenInNew = false) {
  const status = appointment.status || '-'
  const serviceName = (appointment.service || {}).name || ''
  const servicePrice = (appointment.service || {}).price || ''
  const startDate = appointment.start
  const endDate = appointment.end
  const diff = startDate && endDate ? differenceInMinutes(appointment.end, appointment.start) : ''
  const employeeName = appointment.employee ? ((appointment.employee.firstName || '') + (appointment.employee.lastName || '')) : null
  const statusTranslation = translate('sps.labels.' + status)
  return (
    <div className={[appointmentListStyle]} key={appointment.id}>
      <div className={[appointmentDateStyle]}>
        <div className={[appointmentDateMonthStyle]}>
          {format(startDate, "dd", {locale: it})}
        </div>
        <div className={[appointmentDateDayStyle]}>
          {capitalize(format(startDate, "MMM", {locale: it}))}
        </div>
        <div className={[appointmentDateDayStyle]} style={{marginTop: '2px'}} >
          {(format(startDate, "Y", {locale: it}))}
        </div>

      </div>
      <div className={[appointmentDetailsStyle]}>
        <div className={[appointmentEmployeeStyle]}>{capitalize(format(startDate, "E H:mm", {locale: it}))} <span
          className={[appointmentStatusStyle]}
          style={{
            color: status === AppointmentTypeKeys.CANCELED ? 'red' :
              status === AppointmentTypeKeys.EXECUTED ? '#0AB153' :
                status === AppointmentTypeKeys.NOT_ARRIVED ? '#D84108' : '#0000ff'
          }}>{capitalize(statusTranslation, true)}</span></div>
        <div className={[appointmentArticleStyle]}>{serviceName}</div>
        <div className={[appointmentEmployeeStyle]}>{diff} min da {employeeName}</div>
      </div>
      <div className={[appointmentPriceStyle]}>
        <div >
          EUR {Number(servicePrice).toFixed(2)}
        </div>
        {!removeOpenInNew && (<div style={{marginTop: '10px'}}>
          <Button
            variant={'inline'}
            color={'primary'}
            onClick = {() => {
              window.localStorage.setItem('currently-open-appointment', JSON.stringify({
                ...appointment,
                ...{
                  start: appointment.start,
                  end: appointment.end
                }
              }))
              window.location.href = '/hair-and-beauty'
            }}
          >
            <OpenInNewIcon />
          </Button>
        </div>)}
      </div>
    </div>)
}

const AppointmentsTab = ({parentProps, translate}) => {

  const [appointments, setAppointments] = useState([]);
  const [previousAppointments, setPreviousAppointments] = useState([]);

  useEffectAsync(async () => {
    const appointmentsNewData = await getAppointments({
      filter: {
          client: {id: parentProps.id}
      },
      sort: {
        field: 'start',
        order: 'DESC',
      },
      pagination: {
        perPage: 100,
        page: 1,
      }
    })
    if(!appointmentsNewData) {
      return;
    }
    let data = appointmentsNewData.data
      .filter(a => a.status !== 'CANCELED')
      .map(a => {
        const start = new Date(a.start);
        const end = new Date(a.end);
        const hasRepeat = a.repeat && a.repeat.toLowerCase() !== 'non si ripete'
        const repeatFrequency = hasRepeat ? repeatOptions[a.repeat] : 0;
        const title = a.client ? getClientName(a.client) : '';
        return { ...a, start, end, title, hasRepeat, repeatFrequency };
      });
    const future = [];
    const previous = [];
    for(let i = 0; i < data.length; i +=1) {
      if(data[i].start > Date.now()) {
        future.push(data[i])
      } else {
        previous.push(data[i])
      }
    }
    setAppointments(future)
    setPreviousAppointments(previous)
  }, []);

  return (
    <div className={[appointmentContainer]}>
      <h2 className={[titleBorder]}>Futuri ({appointments.length})</h2>
      {appointments.map(ap => {
        return <>{getAppointmentCard(ap, translate)}</>
      })}
      <h2 className={[titleBorder]}>Passati ({previousAppointments.length})</h2>
      {previousAppointments.map(ap => {
        return <>{getAppointmentCard(ap, translate)}</>
      })}
    </div>

  )
}

export const ClientsEdit = props => {
  const urlParams = new URLSearchParams(window.location.search)
  const translate = useTranslate();
  const selectedTenant = getSelectedTenant();
  window.hasSuperiusAdminChanges = true;

  const [currentTab, setCurrentTab] = useState(urlParams.has('window') ? Number(urlParams.get('window')) : 0)
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );


  const showAppointments = selectedTenant && selectedTenant.id && selectedTenant.hasHairAndBeauty

  return (
    <div className={cx([!isXSmall && detailsContainerStyle])}>
      <div className={cx([formContainerStyle, isXSmall && mobileFormContainerStyle])}>
        <Tabs
          value={currentTab}
          onChange={(event, value) => setCurrentTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="off"
          variant="scrollable"
          className={cx([tabsStyle, isXSmall && css`padding-top: 20px;`])}
        >
          <Tab value={0} label={translate('sps.labels.clientData')}/>
          {showAppointments && (<Tab value={1} label={translate('sps.labels.appointments')} />)}
        </Tabs>
        <div role="tabpanel" hidden={currentTab !== 0} className={cx([isXSmall && containerStyle])}>
          <EditTab isXSmall={isXSmall} parentProps={props} translate={translate} />
        </div>
        <div role="tabpanel" hidden={currentTab !== 1} className={cx([isXSmall && containerStyle])}>
          <AppointmentsTab isXSmall={isXSmall} parentProps={props} translate={translate} />
        </div>
      </div>
    </div>
  );
};

export const ClientsCreate = props => {
  const translate = useTranslate();
  window.hasSuperiusAdminChanges = true;

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <div className={cx([isXSmall && containerStyle])}>
      <Create
        className={formStyle}
        title={
          isXSmall ? (
            <ClientMobileTitle title={"Create a client"} record={null} />
          ) : (
            "Create a client"
          )
        }
        {...props}
        undoable={false}
      >
        <SimpleForm
          validate={values => validateClientForm(values, translate)}
          redirect="list"
          variant="outlined"
          toolbar={
            isXSmall ? (
              <SaveToolbar style={{ position: "relative" }} />
            ) : (
              <SaveCancelToolbar style={{ position: "relative" }} />
            )
          }
        >
          <ClientForm />
        </SimpleForm>
      </Create>
    </div>
  );
};
