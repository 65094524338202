/* tslint:disable:max-line-length */
import gql from 'graphql-tag';
import {articleFragmentQueries} from "../../queries/article/article-fragment-queries";

export const Article = gql`
  ${articleFragmentQueries}
  query Article($id: EntityId!) {
    article(id: $id) {
      ...ArticleFragment
    }
  }
`;
