//
import { client } from '../../graphql/client';
import { DeleteAppointments } from '../../graphql/generated-queries/delete-appointments-queries';

export async function removeAppointment(input) {
  try {
    await client.mutate({ mutation: DeleteAppointments, variables: { ids: [input.id] } });
    return {
      data: {
        id: input.id
      }
    }
  } catch (err) {
    console.error(err);
  }
}
