import React, { useState } from "react";
import { useEffectAsync } from "../../use-effect-async";
import { css } from "emotion";
import { useTranslate } from "ra-core";
import {
  getEmployees,
  getHairdresserSalon,
  updateHairdresserSalon
} from "../../queries";
import { useHistory } from "react-router-dom";
import {
  Button,
  Theme,
  useMediaQuery,
  TableBody,
  TableCell,
  TableRow,
  Fab
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import classnames from "classnames";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";

const containerStyle = css`
  display: flex;
  flex-direction: column;
`;

const titleStyle = css`
  border-bottom: 1px solid #cdcdcd;
  margin-bottom: 10px;
`;
const employeeItemStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const textStyle = css`
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  cursor: pointer;
`;
const resultNumberStyle = css`
  display: flex;
  padding-left: 20px;
  margin-top: 10px;
`;
const resultNumberTextStyle = css`
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
`;

const createButtonContainer = css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
`;
const nameContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const fab = css`
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  z-index: 100;
`;
const handleStyle = css`
  position: relative;
  top: 1px;
  display: block;
  width: 18px;
  height: 11px;
  opacity: 0.25;
  margin-right: 20px;
  background: -webkit-linear-gradient(
    top,
    #000,
    #000 20%,
    #fff 0,
    #fff 40%,
    #000 0,
    #000 60%,
    #fff 0,
    #fff 80%,
    #000 0,
    #000
  );
  background: linear-gradient(
    180deg,
    #000,
    #000 20%,
    #fff 0,
    #fff 40%,
    #000 0,
    #000 60%,
    #fff 0,
    #fff 80%,
    #000 0,
    #000
  );
`;

const DragHandle = SortableHandle(() => <span className={handleStyle} />);

const SortableItem = SortableElement((props: any) => {
  const { employee, employeeIndex } = props;
  const salonId = window.location.pathname.split("/")[2];

  const translate = useTranslate();

  if (!employee) {
    return null;
  }
  return (
    <TableRow
      id={employeeIndex}
      data-articleId={employeeIndex}
      className="MuiTableRow-root RaDatagrid-row-318 RaDatagrid-rowEven-320 RaDatagrid-clickableRow-319 MuiTableRow-hover DraggableElement"
      style={{ display: "flex", width: "100%", zIndex: 100 }}
    >
      <TableCell
        style={{ display: "flex", width: "100%" }}
        className="MuiTableCell-root MuiTableCell-body column-name RaDatagrid-rowCell-322 MuiTableCell-sizeSmall"
      >
        <div className={employeeItemStyle}>
          <div className={nameContainerStyle}>
            <DragHandle />
            <span
              onClick={() =>
                (window.location.href = `/employee/${employee.id}?salonId=${salonId}`)
              }
              className={textStyle}
            >{`${employee.firstName} ${employee.lastName}`}</span>
          </div>
          <Button
            color={"primary"}
            onClick={() => {
              window.location.href = `/employee/${employee.id}?salonId=${salonId}`;
            }}
          >
            {translate("sps.labels.edit")}
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
});

const SortableEmployeeContainer = SortableContainer(
  ({ employees, history, sortedIds }) => {
    const sortedEmployees = sortedIds
      .map((employeeId, index) => employees.find(e => e.id == employeeId))
      .filter(e => e != undefined);
    return (
      <>
        {sortedIds && sortedIds.length && (
          <TableBody className={classnames("datagrid-body")}>
            {/* <div className={employeeContainerStyle}> */}
            {sortedEmployees.map((employee, index) => (
              <SortableItem
                key={`item-${index}`}
                index={index}
                value={index}
                employee={employee}
                history={history}
                employeeIndex={index}
              />
            ))}
            {/* </div> */}
          </TableBody>
        )}
      </>
    );
  }
);

export const HSEmployees = () => {
  const [employees, setEmployees] = useState([]);
  const [sortedIds, setSortedIds] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const translate = useTranslate();
  const salonId = window.location.pathname.split("/")[2];
  const history = useHistory();
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("xs");
  });

  useEffectAsync(async () => {
    const employeeData = await getEmployees({
      sort: { field: "hairdresserSalon.name" },
      filter: { search: { hairdresserSalon: { id: salonId } } },
      pagination: {
        per_page: 50,
        page: 1
      }
    });
    const result = await getHairdresserSalon({ id: salonId });
    if (employeeData && employeeData.total) {
      const { data: employees } = employeeData;
      setEmployees(employees);
      if (result && result.data && result.data.employeeOrder) {
        const {
          data: { employeeOrder }
        } = result;
        let newOrder = JSON.parse(employeeOrder);
        if (newOrder.length < employees.length) {
          employees.forEach(e => {
            if (!newOrder.includes(e.id)) {
              newOrder.push(e.id);
            }
          });
        } else if (newOrder.length > employees.length) {
          JSON.parse(employeeOrder).forEach(e => {
            const found = employees.find(em => em.id == e);
            if (!found) {
              newOrder.splice(newOrder.indexOf(e), 1);
            }
          });
        }
        updateHairdresserSalon({
          data: { employeeOrder: JSON.stringify(newOrder), id: salonId }
        });
        setSortedIds(newOrder);
      } else {
        setSortedIds(employees.map(employee => employee.id));
      }
    }
  }, []);

  const onSortEnd = props => {
    const { oldIndex, newIndex } = props;
    let newIdOrder = arrayMove([...sortedIds], oldIndex, newIndex);
    setSortedIds(newIdOrder);
    setIsFirstLoad(false);
    updateHairdresserSalon({
      data: { employeeOrder: JSON.stringify(newIdOrder), id: salonId }
    });
  };

  if (isFirstLoad) {
    onSortEnd({ oldIndex: 0, newIndex: 0 });
  }

  return (
    <div className={containerStyle}>
      {employees.length === 0 && (
        <div
          className={css`
            color: rgba(0, 0, 0, 0.3);
          `}
        >
          {translate("sps.labels.no_employees")}
        </div>
      )}
      <div className={createButtonContainer}>
        {!isXSmall && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(`/employee/create/${salonId}`)}
            startIcon={<AddIcon />}
          >
            {translate("sps.labels.create")}
          </Button>
        )}
      </div>
      {employees.length > 0 && sortedIds.length && (
        <>
          <div className={titleStyle}>
            <span className={textStyle}>{translate("sps.labels.name")}</span>
          </div>
          <SortableEmployeeContainer
            onSortEnd={onSortEnd}
            history={history}
            employees={employees}
            sortedIds={sortedIds}
            useDragHandle
          />
          <div className={resultNumberStyle}>
            <span className={resultNumberTextStyle}>{`${translate(
              "sps.labels.numberOfResults"
            )}${employees.length}`}</span>
          </div>
        </>
      )}
      {isXSmall && (
        <Fab
          color="primary"
          className={fab}
          onClick={() => history.push(`/employee/create/${salonId}`)}
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
};

function arrayMove(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}
