/* tslint:disable:max-line-length */
import gql from 'graphql-tag';

export const Message = gql`
  query Message($id: EntityId!) {
    message(id: $id) {
      id
      title
      message
      annotation
      sentDate
      adminOnlyVisible
      scheduledDate
      recipient
      sendInApp
      sendViaEMail
      sendViaSMS
    }
  }
`;
