import { client } from '../../graphql/client';
import {CreateArticle} from "../../graphql/generated-queries/create-article-queries";
import {Group} from "../../graphql/generated-queries/group-queries";

export async function createArticle(input) {
  try {

    if (input.data) {
      input.data.actionPrice = input.data.actionPrice === '' ? null : input.data.actionPrice
      input.data.discountPercentage = input.data.discountPercentage === '' ? null : input.data.discountPercentage
      input.data.discountAmount = input.data.discountAmount === '' ? null : input.data.discountAmount
    }

    const {data: {group: {id: groupId}}} = input
    const {data: {group: {articles}}} = await client.query({query: Group, variables: {id: groupId}})
    const {data} = await client.mutate({
      mutation: CreateArticle,
      variables: {input: {...input.data, order: articles.length + 1}}
    });
    return {data: {...data.createArticle}};
  } catch (err) {
    console.error(JSON.stringify(err));
    throw new Error(err.graphQLErrors?.[0]?.message || err)
  }
}
