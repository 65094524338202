//
import { client } from '../../graphql/client';
import { Group } from '../../graphql/generated-queries/group-queries';

export async function getGroup(input) {
  try {
    const { data } = await client.query({ query: Group, variables: { id: input.id } });
    return { data: data.group };
  } catch (err) {
    console.error(err);
  }
}
