// @ts-nocheck
import React, {useState} from 'react';
import {
  List,
  Toolbar,
  SaveButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ListButton,
} from 'react-admin';
import {useMediaQuery, Theme, Button, Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  expansionPanelSummaryStyle,
  groupDetailContainerStyle,
  deleteIconStyle,
  dialogContainer,
  buttonContainer,
  saveCancelButtonsStyle,
  buttonStyleExpanded,
  buttonStyle,
  groupTextStyle,
  articleContainerStyle,
  blankDivStyle,
  dragHandleStyle,
  articleStyle,
  mobileFormTitleStyle,
  mobileCloseStyle,
  articleListContainerStyle,
} from './GroupsListStyle'
import {getGroupFilter} from "./components/filters";
import {validateGroupName} from "../service/validator";
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DragHandle from '@material-ui/icons/DragHandle'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import CloseIcon from '@material-ui/icons/Close';
import {css, cx} from 'emotion';
import {getArticle, getGroup, getGroups, updateArticle, updateGroup, getArticles} from "../queries";
import {toolbarStyles} from "./components/Toolbars";
import {useTranslate} from 'ra-core'
import {useEffectAsync} from "../use-effect-async";
import {useHistory} from 'react-router-dom'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {ListActions} from "./components/ActionBar";
import {EmptyItemView} from "./components/EmptyItemView";
import {client} from "../graphql/client";
import {DeleteGroups} from "../graphql/generated-queries/delete-groups-queries";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {UserGlobalNotification} from "./components/UserGlobalNotification";

const ARTICLES_PER_PAGE = 20;

const caseInsensitiveSort = (a, b) => {
  const textA = (a.name || '').toUpperCase();
  const textB = (b.name || '').toUpperCase();
  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

const orderedSort = (a, b) => {
  const orderA = a.order !== null ? a.order : 0;
  const orderB = b.order !== null ? b.order : 0;
  return orderA - orderB;
}

export const GroupsList = (props) => {
  const [groups, setGroups] = useState<Array<any> | undefined>(undefined)
  const [articlesMap, setArticlesMap] = useState<Map<any> | undefined>(undefined)
  const [currentArticlesPage, setCurrentArticlesPage] = useState<number>(0)
  const [expanded, setExpanded] = useState<string | null>(null)
  const [articleUpdate, setArticleUpdate] = useState<Object<any> | null>(null)
  const [groupUpdate, setGroupUpdate] = useState<Object<any> | null>(null)
  const [search, setSearch] = useState('')
  window.hasSuperiusAdminChanges = false
  let filters = getGroupFilter({...props, setSearch: setSearch})

  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('xs')
  });
  const translate = useTranslate()

  useEffectAsync(async () => {
    const articlesSearchParams = {
      sort: {
        field: 'name',
        order: 'ASC',
      },
      pagination: {
        perPage: ARTICLES_PER_PAGE,
        page: currentArticlesPage + 1,
      },
    }

    if (articleUpdate) {
      const {sourceIndex, destIndex, sourceDrop: sourceGroupId, destDrop: destGroupId, draggableId} = articleUpdate

      if (sourceGroupId !== destGroupId) {
        // let {data: article} = await getArticle({id: draggableId})
        let article = articlesMap[expanded].data.find(e => e.id === draggableId)
        let {data: group} = await getGroup({id: destGroupId})
        await updateArticle({
          data: {
            ...article,
            id: draggableId,
            group: {id: destGroupId},
            order: group.articles.length ? group.articles.sort((a: any, b: any) => a.order < b.order)[group.articles.length - 1].order + 1 : 1
          }
        })
        setArticleUpdate(null)
      } else if (sourceGroupId == destGroupId && destIndex != sourceIndex) { // sort, dont switch groups
        // const groupArticles = await getArticles({...articlesSearchParams, filter: {group: {id: destGroupId}}})
        const groupArticles = articlesMap[expanded];
        const sortedArticles = groupArticles.data.sort((a, b) => a.order - b.order);
        if (sourceIndex > destIndex) {
          const el = sortedArticles[sourceIndex]
          sortedArticles.splice(sourceIndex, 1);
          sortedArticles.splice(destIndex, 0, el)
        } else {
          sortedArticles.splice(destIndex + 1, 0, sortedArticles[sourceIndex])
          sortedArticles.splice(sourceIndex, 1);
        }

        for (let i = 0; i < sortedArticles.length; i += 1) {
          sortedArticles[i].order = i
        }

        articlesMap[expanded] = {
          data: sortedArticles,
          total: sortedArticles.length,
        }

        setArticlesMap({...articlesMap});
        setGroups([...groups])
        Promise.all(sortedArticles.map((k, i) => {
          return updateArticle({
            data: {
              ...sortedArticles[i],
            }
          })
        }))
      }
    } else {
      const {data: groupData} = await getGroups({
        order: [{order: "ASC"}],
        perPage: 25,
        skip: 0,
        name: search,
      })
      setGroups(groupData)

      const articlesList = await Promise.all(groupData.map(async (g) => {
        return {
          id: g.id,
          articles: await getArticles({...articlesSearchParams, filter: {group: {id: g.id}}})
        }
      }));
      const newArticlesMap = {};
      for (let i = 0; i < articlesList.length; i += 1) {
        newArticlesMap[articlesList[i].id] = {
          data: articlesList[i].articles.data,
          total: articlesList[i].articles.total,
        };
      }
      setArticlesMap(newArticlesMap)
    }
  }, [articleUpdate]);

  useEffectAsync(async () => {
    if (!groups) {
      return;
    }
    const articlesSearchParams = {
      sort: {
        field: 'name',
        order: 'ASC',
      },
      pagination: {
        perPage: ARTICLES_PER_PAGE,
        page: currentArticlesPage + 1,
      },
    }
    const articles = await getArticles({...articlesSearchParams, filter: {group: {id: expanded}}})
    const newArticlesMap = articlesMap;

    newArticlesMap[expanded] = {
      data: articles.data,
      total: articles.total,
    }
    setArticlesMap(...newArticlesMap)
    setGroups([...groups])
  }, [currentArticlesPage])

  useEffectAsync(async () => {
    if (groupUpdate) {
      const {sourceIndex, destIndex} = groupUpdate
      if (sourceIndex != destIndex) {
        const sortedGroups = groups.sort((a, b) => a.order < b.order)
        const first = sortedGroups.findIndex((group) => group.order == sourceIndex)
        const last = sortedGroups.findIndex((group) => group.order == destIndex)
        const from = (first < last ? first + 1 : last)
        const to = (first < last ? last + 1 : first)
        let [initial, end] = [0, 0]
        for (let i = from; i < to; i++) {
          const {data: groupData} = await getGroup({id: sortedGroups[i].id})  // this is not required
          if (i == from) {
            initial = groupData.order
          }
          if (i == to - 1) {
            end = groupData.order
          }
          await updateGroup({data: {...groupData, order: (first < last ? groupData.order - 1 : groupData.order + 1)}}) // upate group ony sort
        }
        const {data: groupData} = await getGroup({id: sortedGroups[first < last ? from-1 : to].id})  // also not required
        await updateGroup({data: {...groupData, order: (first < last ? end : initial)}}) // not required
        setGroupUpdate(null)
      }
    }
    const {data: groupData} = await getGroups({
      order: [{order: "ASC"}],
      perPage: 25,
      skip: 0,
      name: search,
    })
    setGroups(groupData)
  }, [groupUpdate])

  useEffectAsync(async () => {
    const {data: groupData} = await getGroups({
      order: [{order: "ASC"}],
      perPage: 25,
      skip: 0,
      filter: {name: search},
      type: 'mine',
    })
    setGroups(groupData)
  }, [search])

  const onDragEnd = (dragData) => {
    const {source, destination, draggableId} = dragData
    if (!source || !destination) {
      return
    }
    const {index: sourceIndex, droppableId: sourceDrop} = source
    const {index: destIndex, droppableId: destDrop} = destination
    if (destDrop.indexOf("outerDrop") > -1) {
      setGroupUpdate({
        sourceIndex: sourceIndex,
        destIndex: destIndex,
      })
    } else {
      setArticleUpdate({
        sourceIndex: sourceIndex,
        destIndex: destIndex,
        destDrop: destDrop.split(';')[1],
        draggableId: draggableId,
        sourceDrop: sourceDrop.split(';')[1]
      })
    }
  }

  return (
    <>
      <UserGlobalNotification/>
      <DragDropContext onDragEnd={onDragEnd}>
        <List {...props} hasList={false} bulkActionButtons={false} pagination={null} filters={filters}
              sort={{field: 'order', order: 'ASC'}}
              perPage={1000} actions={<ListActions/>}
              empty={<EmptyItemView {...props} title={translate('sps.labels.emptyGroups')}/>}>
          <GroupItemsList
            isXSmall={isXSmall}
            groups={groups}
            currentArticlesPage={currentArticlesPage}
            setCurrentArticlesPage={setCurrentArticlesPage}
            articlesMap={articlesMap}
            expanded={expanded}
            setExpanded={(status) => setExpanded(status)}
          />
        </List>
      </DragDropContext>
    </>
  );
}

interface IGroupItemsProps {
  groups: Array<any>
  expanded: string | null

  setExpanded(status): any

  data: any
}

const GroupItemsList = (props: IGroupItemsProps) => {
  const {groups: groupData, articlesMap, expanded, setExpanded, isXSmall, currentArticlesPage, setCurrentArticlesPage} = props
  const translate = useTranslate()
  const history = useHistory()

  if (!groupData || !groupData.length) {
    return null
  }

  const handleExpansion = (id: string) => (event: any, newExpanded: any) => {
    setExpanded(expanded === id ? null : id)
  }

  const getGroupPanelSummaryStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'rgba(146,39,143, 0.6)' : '#F7F7F8',
  })

  const GroupListExpandedContainer = (props) => {
    let {group, articles} = props;
    const isExpanded = expanded === group.id.toString();
    return (
      <Droppable isDropDisabled={expanded == null} droppableId={'destDrop;' + group.id.toString()}>
        {(provided, snapshot) => (
          <ExpansionPanel
            square
            expanded={expanded === group.id.toString()}>
            <ExpansionPanelSummary
              style={getGroupPanelSummaryStyle(snapshot.isDraggingOver)}
            >
              <div
                className={css`display: flex; justify-content: space-between; width: 100%;`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div className={css`display: flex;`}>
                  <span onClick={handleExpansion(group.id.toString())} className={groupTextStyle}
                        style={{marginLeft: '34px'}}>{group.name}</span>
                  <Button
                    variant="contained"
                    className={isExpanded ? buttonStyleExpanded : buttonStyle}
                    onClick={handleExpansion(group.id.toString())}
                  >
                    <span style={{textTransform: 'lowercase'}}>{articles.total}x</span>
                  </Button>
                </div>
                <Button
                  variant='text'
                  color='primary'
                  onClick={() => history.push(`/group/${group.id}`)}
                >
                  {!isXSmall && translate('sps.labels.edit')}
                  {isXSmall && <CreateOutlinedIcon/>}
                </Button>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails  className={'expansion-panel-summary-style'}>
              <>
                {(expanded === group.id.toString() && articles.total != 0) &&
                (<Droppable droppableId={"droppable;" + group.id} groupId={group.id}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{width: '100%'}}
                      className={articleContainerStyle}
                    >
                      {(articles.data || []).sort(orderedSort).map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                                {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={articleListContainerStyle}
                            >
                              <div style={{padding: '0px 24px'}}>
                                <span className={articleStyle}>{item.name}</span>
                              </div>
                              <Button
                                variant='text'
                                color='primary'
                                onClick={() => history.push(`/article/${item.id}`)}
                                style={{paddingRight: 32}}
                              >
                                {!isXSmall && translate('sps.labels.edit')}
                                {isXSmall && <CreateOutlinedIcon/>}
                              </Button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>)
                }
                {ARTICLES_PER_PAGE < articles.total && (<div>
                    {currentArticlesPage > 0 &&
                    (<Button
                      variant='text'
                      color='primary'
                      onClick={(e) => {
                        let scrollTarget = e.target && e.target.closest('.expansion-panel-summary-style');
                        if(scrollTarget) {
                          if (scrollTarget.closest('.MuiPaper-elevation1')) {
                            scrollTarget.closest('.MuiPaper-elevation1').scrollIntoView({
                              behavior: 'smooth',
                              block: 'start'
                            })
                          } else {
                            scrollTarget.scrollIntoView({behavior: 'smooth', block: 'start'})

                          }
                        }
                        setCurrentArticlesPage(currentArticlesPage - 1)
                      }}
                      style={{paddingRight: 32}}
                    >
                      <ArrowBackIosIcon />
                    </Button>)}
                    {((currentArticlesPage + 1) * ARTICLES_PER_PAGE < articles.total) && (<Button
                      variant='text'
                      color='primary'
                      onClick={(e) => {
                        let scrollTarget = e.target && e.target.closest('.expansion-panel-summary-style');
                        if (scrollTarget.closest('.MuiPaper-elevation1')) {
                          scrollTarget.closest('.MuiPaper-elevation1').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                          })
                        } else {
                          scrollTarget.scrollIntoView({behavior: 'smooth', block: 'start'})

                        }
                        setCurrentArticlesPage(currentArticlesPage + 1)
                      }}
                      style={{paddingRight: 32}}
                    >
                      <ArrowForwardIosIcon />
                    </Button>)}
                  </div>
                )}
                {(articles.data.length != 0 && articles.data.map(article => article.id).includes(snapshot.draggingFromThisWith ? snapshot.draggingFromThisWith.toString() : 'p')) &&
                <div className={blankDivStyle}/>
                }
                {(articles.data.length == 0) &&
                <span>Non ci sono articoli in questo gruppo.</span>
                }
              </>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
      </Droppable>
    )
  }

  const GroupListContainer = (props) => {
    const {group, provided, articles} = props
    return (
      <div
        className={expansionPanelSummaryStyle}
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        <div className={groupDetailContainerStyle} style={{padding: '0px 24px'}}>
          <div className={dragHandleStyle} {...provided.dragHandleProps}>
            <DragHandle/>
          </div>
          <span onClick={handleExpansion(group.id.toString())} className={groupTextStyle}>{group.name}</span>
          <Button
            variant="contained"
            className={buttonStyle}
            onClick={handleExpansion(group.id.toString())}
          >
            <span>{articles.total}x</span>
          </ Button>
        </div>

        <Button
          variant='text'
          color='primary'
          style={{marginRight: 27}}
          onClick={() => history.push(`/group/${group.id}`)}
        >
          {!isXSmall && translate('sps.labels.edit')}
          {isXSmall && <CreateOutlinedIcon/>}
        </Button>
      </div>
    )
  }

  if (!expanded) {
    return (
      <Droppable droppableId='outerDrop'>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {
              groupData.map((group) => (
                  <Draggable key={group.id} draggableId={group.id} index={group.order}>
                    {(provided, snapshot) => (
                      <GroupListContainer
                        provided={provided}
                        group={group}
                        articles={articlesMap ? articlesMap[group.id] : {}}
                        currentArticlesPage={currentArticlesPage}
                      />
                    )}
                  </Draggable>
                )
              )}
          </div>
        )}
      </Droppable>
    )
  } else {
    return (
      groupData.map((group) => {
          return <GroupListExpandedContainer
            group={group}
            articles={articlesMap ? articlesMap[group.id] : {}}
            currentArticlesPage={currentArticlesPage}
          />
        }
      )
    )
  }
}

const GroupTitle = ({record}) => {
  return <span>Group {record ? `"${record.name}"` : ''}</span>;
};

const GroupMobileTitle = ({record, title}) => {

  return (
    <div className={mobileFormTitleStyle}>
      {(record && record.name) ? record.name : title}
      <CloseIcon
        className={mobileCloseStyle}
        onClick={() => window.location.href='/group'}
      />
    </div>
  )
}

const GroupToolbar = withStyles(toolbarStyles)(props => {
  const translate = useTranslate()
  const [warningDialogOpen,setWarningDialogOpen] = useState(false)
  const noArticlesInGroup = (props.record.articles || []).length === 0
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('xs')
  });

  const removeGroup = async (id) => {
    await client.mutate({ mutation: DeleteGroups , variables: { ids: [id] } });
    window.location = '/group'
  }

  return (
    <Toolbar {...props} className={cx(['form-toolbar', css`position: relative !important;`])}>
      <div className={cx([css`display: flex;justify-content: space-between;`], isXSmall && css`flex-direction: column;`)}>
        <div className={cx([css`display: flex;justify-content: flex-start; width: 100%;`, isXSmall && css`margin-bottom: 15px;`])}>
          {props.record.id &&
          <>
            <Button
              className={deleteIconStyle}
              color="default"
              startIcon={<DeleteIcon/>}
              onClick={() => setWarningDialogOpen(true)}
            >
              {translate('sps.labels.delete')}
            </Button>
            <Dialog className={dialogContainer} open={warningDialogOpen}>
              <DialogTitle>{translate('sps.labels.attention')}</DialogTitle>
              <DialogContent>{noArticlesInGroup ? translate('ra.message.delete_title') : translate('sps.labels.groupsExistAttention')}</DialogContent>
              <div className={buttonContainer}>
                {noArticlesInGroup &&
                <Button
                  className={deleteIconStyle}
                  color="secondary"
                  startIcon={<DeleteIcon/>}
                  onClick={async () => {

                    setWarningDialogOpen(false);
                    await removeGroup(props.record.id)
                  }}
                >
                  {translate('sps.labels.delete')}
                </Button>}
                <Button onClick={() => setWarningDialogOpen(false)}>
                  {translate('sps.labels.cancel')}
                </Button>
              </div>
            </Dialog>
          </>
          }
        </div>
        <div className={saveCancelButtonsStyle}>
          {!isXSmall &&
          <div className={css`width: 100%;`} id='other-buttons'>
            <ListButton {...props} variant="outlined" basePath={props.basePath}
                        label={translate('sps.labels.cancel')}></ListButton>
          </div>}
          <SaveButton {...props} record={props.record} className={cx([css`width: 100%;`,isXSmall && css`padding: 0px !important;`])}/>
        </div>
      </div>
    </Toolbar>
  )
});

export const GroupsEdit = (props) => {
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('xs')
  });

  window.hasSuperiusAdminChanges = true
  return (
    <Edit title={isXSmall ? <GroupMobileTitle {...props} /> : <GroupTitle {...props}  />} {...props} undoable={false}>
      <SimpleForm redirect="list" variant="outlined" toolbar={<GroupToolbar/>}>
        <TextInput source="name" autoFocus={true} validate={validateGroupName} format={v => v ? v.trimStart() : v}/>
      </SimpleForm>
    </Edit>
  );
}

export const GroupCreate = (props) => {
  const isXSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('xs')
  });

  const translate = useTranslate()
  window.hasSuperiusAdminChanges = true
  return (
    <Create title={isXSmall ? <GroupMobileTitle title="Create a Group"/> :"Create a Group" } {...props} undoable={false}>
      <SimpleForm redirect="list" variant="outlined" toolbar={<GroupToolbar {...props} translate={translate}/>}>
        <TextInput source="name" autoFocus={true} validate={validateGroupName}  format={v => v ? v.trimStart() : v} />
      </SimpleForm>
    </Create>
  );
}
