import jwtDecode from 'jwt-decode';

export function storeUserTokenData(token) {
  const data = jwtDecode(token);

  localStorage.setItem('token', token);
  localStorage.setItem('userId', data.user.id);
  localStorage.setItem('username', data.user.username);
  localStorage.setItem('userrole', data.user.role);
  localStorage.setItem('tenantId', data.user.tenantId || data.user.tenant?.id);
  localStorage.setItem('tenantRole', data.user.tenantRole);
  return data;
}

export function clearUserTokenData() {
  localStorage.removeItem('username');
  localStorage.removeItem('token');
  localStorage.removeItem('userrole');
  localStorage.removeItem('userId');
  localStorage.removeItem('tenantId');
  localStorage.removeItem('tenantRole');
}
